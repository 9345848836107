<template>
	<div class="modal" id="update-profile" data-backdrop="static" tabindex="-1" aria-labelledby="update-profileLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<form @submit.prevent="updateProfile" class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="update-profileLabel">{{$t('update-profile')}}</h5>
					<button type="button" class="close d-none" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="form-group">
						<label>{{$t('organization-name')}}</label>
						<input v-model="title" type="text" class="form-control form-control-sm shadow-sm" readonly>
					</div>
					<div class="row">
						<div class="form-group col-md-6">
							<label>{{$t('first-name')}}</label>
							<input v-model="form.user.firstName" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs['user.firstName']">
							<div class="invalid-feedback d-block" v-html="validation.errors['user.firstName']"></div>
						</div>
						<div class="form-group col-md-6">
							<label>{{$t('last-name')}}</label>
							<input v-model="form.user.lastName" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs['user.lastName']">
							<div class="invalid-feedback d-block" v-html="validation.errors['user.lastName']"></div>
						</div>
					</div>
					<div class="row">
						<div class="form-group col-md-6">
							<label>{{$t('email')}}</label>
							<input v-model="form.user.email" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs['user.email']">
							<div class="invalid-feedback d-block" v-html="validation.errors['user.email']"></div>
						</div>
						<div class="form-group col-md-6">
							<label>{{$t('phone-number')}}</label>
							<phone-number-component :phone-number="form.user.phoneNumber" @update-phone="updatePhone" :hasError="validation.inputs['user.phoneNumber']"></phone-number-component>
							<div class="invalid-feedback d-block" v-html="validation.errors['user.phoneNumber']"></div>
						</div>
					</div>
					<div class="form-group">
						<label>{{$t('support-email')}}</label>
						<input v-model="form.user.supportEmail" type="text" class="form-control form-control-sm shadow-sm">
					</div>
				</div>
				<div class="modal-footer">
					<button-component :label="'Save changes'" :busy="isBusy" :buttonClass="'btn btn-primary'"></button-component>
					<button type="button" class="btn btn-secondary btn-sm shadow-sm" data-dismiss="modal">{{$t('cancel')}}</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import { isEmpty, find } from 'lodash';
	import { mapGetters, mapActions } from 'vuex';
	import { activity, validation } from '@/mixins';

	export default {
		name: 'UpdateProfileModal',
		mixins: [activity, validation],
		mounted() {
			this.setProfile();

			window
				.$('#update-profile')
				.on('hidden.bs.modal', () => this.setProfile());
		},
		data() {
			return {
				title: null,
				form: {
					memberId: null,
					user: {
						firstName: null,
						lastName: null,
						email: null,
						phoneNumber: null,
						supportEmail : null,
						marketplaceId : null
					},
					isProfile: null,
				},
			};
		},
		computed: {
			...mapGetters({
				isBusy: 'isBusy',
				profile: 'profile/user',
				language: 'locale'
			}),
		},
		watch: {
			profile: {
				handler: function () {
					this.setProfile();
				},
				deep: true,
			},
		},
		methods: {
			...mapActions({
				setAppState: 'setAppIsBusy',
				attemptGetProfile: 'profile/attemptGetProfile',
				attemptUpdateProfile: 'profile/attemptUpdateProfile',
			}),
			setProfile() {
				const {
					operatorPerson: { id, firstName, lastName, email, phoneNumber },
					locale,
					id: memberId,
					marketplace
				} = this.profile;

				const {data : {supportEmail} , id : marketplaceId} = marketplace

				if (!isEmpty(locale)) {
					this.title = find(locale, ['locale', this.language]).title;
				}

				this.form = {
					memberId,
					user: {
						id,
						firstName,
						lastName,
						email,
						phoneNumber,
						supportEmail,
						marketplaceId
					},
					isProfile: true,
				};
			},
			updatePhone(phoneNumber) {
				this.form.user.phoneNumber = phoneNumber;
			},
			updateProfile() {
				this.setAppState(true);

				this.attemptUpdateProfile(this.form)
					.then(() => window.$('#update-profile').modal('hide'))
					.catch((e) => {
						this.validationErrors(e);
					})
					.finally(() => this.setAppState(false));
			},
		},
	};
</script>

<style>
</style>
