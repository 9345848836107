<template>
	<div>
		<div class="modal" id="edit-fee" tabindex="-1" aria-labelledby="edit-feeLabel" aria-hidden="true">
			<div class="modal-dialog">
				<form @submit.prevent="updateFee" class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="edit-feeLabel">{{$t('edit')}}</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group">
							<label>{{$t('title')}}</label>
							<input v-model="form.title" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs['title']">
							<div class="invalid-feedback d-block" v-html="validation.errors['title']"></div>
						</div>
						<div class="form-group">
							<label>Description / en</label>
							<input v-model="form.description.en" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs['description.en']">
							<div class="invalid-feedback d-block" v-html="validation.errors['description.en']"></div>
						</div>
						<div class="form-group">
							<label>Description / fr</label>
							<input v-model="form.description.fr" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs['description.fr']">
							<div class="invalid-feedback d-block" v-html="validation.errors['description.fr']"></div>
						</div>
						<div class="form-group">
							<label>{{$t('value')}}</label>
							<input v-model="form.value" type="number" step="any" class="form-control form-control-sm shadow-sm" :class="validation.inputs['value']">
							<div class="invalid-feedback d-block" v-html="validation.errors['value']"></div>
						</div>
						<div class="form-group">
							<label>{{$t('subscription-type')}}</label>
							<select v-model="form.membershipType" class="form-control form-control-sm shadow-sm" :class="validation.inputs['membershipType']">
								<option disabled :value="null">{{$t('select-option')}}</option>
								<option v-for="(type, index) in this.filteredTypes" :value="index" v-bind:key="index">{{ feeMembershipType(index) }}</option>
							</select>
							<div class="invalid-feedback d-block" v-html="validation.errors['membershipType']"></div>
						</div>
						<div class="form-group">
							<label>{{$t('charged-to')}}</label>
							<select v-model="form.chargedTo" class="form-control form-control-sm shadow-sm" :class="validation.inputs['chargedTo']">
								<option disabled :value="null">{{$t('select-option')}}</option>
								<option v-for="(value, index) in chargedTo" :value="value" v-bind:key="index">{{ value|toStartCase }}</option>
							</select>
							<div class="invalid-feedback d-block" v-html="validation.errors['chargedTo']"></div>
						</div>
						<div class="form-group">
							<label>{{$t('status')}}</label>
							<select v-model="form.active" class="form-control form-control-sm shadow-sm" :class="validation.inputs['active']">
								<option disabled :value="null">{{$t('select-option')}}</option>
								<option v-for="({value, name}, index) in status" :value="value" v-bind:key="index">{{ name|toStartCase }}</option>
							</select>
							<div class="invalid-feedback d-block" v-html="validation.errors['active']"></div>
						</div>
					</div>
					<div class="modal-footer">
						<button-component :label="'Save changes'" :busy="isBusy" :buttonClass="'btn btn-primary'"></button-component>
						<button type="button" class="btn btn-secondary btn-sm shadow-sm" data-dismiss="modal">Cancel</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex';
	import { validation, transactions } from '@/mixins';

	export default {
		name: 'UpdateFeeModal',
		mixins: [validation, transactions.fees],
		mounted() {
			window.$('#edit-fee').on('show.bs.modal', (event) => {
				const button = window.$(event.relatedTarget);

				this.feeId = button.data('id');
				this.form = {
					title: button.data('title'),
					description: {
						en: button.data('en'),
						fr: button.data('fr'),
					},
					membershipType: button.data('type'),
					value: button.data('value'),
					chargedTo: button.data('to'),
					active: button.data('active'),
				};
			});
		},
		data() {
			return {
				feeId: null,
				form: {
					title: null,
					description: {
						en: null,
						fr: null,
					},
					membershipType: null,
					value: null,
					chargedTo: null,
					active: null,
				},
			};
		},
		computed: {
			...mapGetters({
				isBusy: 'isBusy',
				user: 'auth/user',
				chargedTo: 'transactions/fees/chargedTo',
				status: 'transactions/fees/status',
				membershipTypes: 'marketplace/membershipTypes',
				marketplace: 'marketplace/marketplace'
			}),
			filteredTypes(){
				let types = this.membershipTypes
				let obj = {}
				if (this.user.permissions.groupAccess){

					const filtered = this.marketplace.partners
						.filter((part) => this.user.permissions.groupAccess.includes(part.categoryId))
						.map(p => p.id)


					types = Object.entries(this.membershipTypes)
						.map(item => {
							item[1].id = item[0]
							return item[1]
						})
						.filter(item => filtered.includes(item.partnerId))

					types.forEach(function(data){
						obj[data.id] = data
					});
				}else{
					obj = this.membershipTypes
				}

				return obj
			}
		},
		methods: {
			...mapActions({
				setAppState: 'setAppIsBusy',
				attemptFeeUpdate: 'transactions/fees/attemptFeeUpdate',
			}),
			updateFee() {
				this.setAppState(true);

				this.attemptFeeUpdate({
					data: this.form,
					feeId: this.feeId,
				})
					.then(() => {
						window.$('#edit-fee').modal('hide');

						this.$emit('update');
					})
					.catch((e) => {
						this.validationErrors(e);
					})
					.finally(() => this.setAppState(false));
			},
		},
	};
</script>

<style>
</style>
