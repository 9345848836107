export default function (mutation) {
	const {
		type,
		payload
	} = mutation

	switch (type) {
		case 'profile/SET_PROFILE':
			if (payload) {
				localStorage.setItem('profile', JSON.stringify(payload))
			} else {
				localStorage.removeItem('profile')
			}
			break

		default:
			break
	}
}
