<template>
    <div class="signin-wrapper">
        <form @submit.prevent="forgotPassword">
            <div v-if="message" class="alert alert-light alert-dismissible shadow-sm" role="alert">
                <button type="button" @click="close" class="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                {{ message }}
            </div>

            <img class="mb-4" src="@/assets/img/logo.png" alt="logo" width="72" height="72">

            <h1 class="h3 mb-3 font-weight-normal">Forgot Password</h1>

            <label for="username" class="sr-only">Username</label>
            <input v-model="form.email" type="text" id="username" class="form-control form-control-sm" :class="validation.inputs.email" placeholder="Username" autofocus>

            <button-component :label="'Reset Password'" :busy="isBusy"></button-component>

            <div class="invalid-feedback d-block" v-html="validation.message"></div>
        </form>

        <div class="mt-4">
            <router-link :to="{ name: 'login' }" class="text-muted">Back to login</router-link>
        </div>
    </div>
</template>

<style scoped>
    input[type="text"] {
        margin-bottom: 10px!important;
        border-bottom-right-radius: .25rem!important;
        border-bottom-left-radius: .25rem!important;
    }
</style>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import { validation } from '@/mixins'

    export default {
        name: "EmailView",
        mixins: [validation],
        data() {
            return{
                resetLinkSent: null,
                form: {
                    email: null,
                    domain: 'bizbizshare.com'
                }
            }
        },
        watch: {},
        computed: {
            ...mapGetters({
                isBusy: 'isBusy',
                message: 'message'
            })
        },
        methods: {
            ...mapActions({
                setAppState: 'setAppIsBusy',
                setMessage: 'setMessage',
                attemptForgotPassword: 'auth/attemptForgotPassword'
            }),
            close() {
                this.setMessage(null)
            },
            forgotPassword() {
                this.close()
                this.setAppState(true)

                this.attemptForgotPassword(this.form).catch((e) => {
                    this.validationErrors(e)
                }).finally(() => {
                    this.setAppState(false)
                })
            }
        }
    }
</script>

