<template>
	<div>
		<router-view v-if="$route.name !=='dashboard.banners'"></router-view>

		<div v-else>
			<breadcrumb :label="$t('banners')"></breadcrumb>

			<template v-if="banners.length">
				<banners-view-component v-for="(banner, index) in banners" v-bind:key="index" :data="banner" :type="banner.type"></banners-view-component>
			</template>

			<template v-else>
				<div class="jumbotron shadow-sm">
					<h2 class="display-4">Lorem ipsum dolor sit.</h2>
					<p class="lead">Amet consectetur adipisicing elit. Dolorum doloribus, totam adipisci maxime officiis eligendi tempora deleniti a sapiente repellat inventore molestiae in fugiat.</p>
					<hr class="my-4">
					<p>Expedita aspernatur voluptas ex molestias accusantium.</p>

					<router-link class="btn btn-primary shadow-sm" :to="{ name: 'dashboard.banners.create'}">
						<em class="bi bi-paint-bucket"></em> {{$t('create-banners')}}
					</router-link>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import { orderBy } from 'lodash';

	export default {
		name: 'Banners',
		mounted() {
			this.banners = orderBy(this.marketplaceBanners, ['type'], ['desc']);
		},
		watch:{
			marketplaceBanners(b){
				console.log(b)
			}
		},
		data() {
			return {
				banners: [],
			};
		},
		computed: {
			...mapGetters({
				marketplaceBanners: 'banners/banners',
			}),
		},
	};
</script>

<style lang="scss">
	.banners .form-label {
		margin-bottom: 0;
		padding-top: 25px;
	}
</style>
