<template>
	<div>
		<table class="table table-bordered table-striped">
			<draggable
				:list="list"
				tag="tbody"
				:move="checkMove"
				@start="dragging = true"
				@end="dragging = false"
				v-model='list'
			>
				<tr v-for="(data, index) in list" :key="index">
					<td>
						<specificity-list-information-component :index="index" :specificity="list[index]" @remove="removeSpecifity" :validation="validation" :is-edit="isEdit"></specificity-list-information-component>
					</td>
				</tr>
			</draggable>
		</table>


		<button type="button" class="btn btn-primary btn-sm shadow-sm float-right" @click="newSpecificity">{{$t('add-specificity')}}</button>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import draggable from 'vuedraggable'

	export default {
		name: 'SpecificitiesListComponent',
		components:{
			draggable
		},
		mounted() {
			this.newSpecificity();
		},
		data() {
			return {
				list: [],
				dragging: false
			};
		},
		computed: {
			...mapGetters({
				options: 'categories/specificities/options',
				marketplace: 'marketplace/marketplace'
			}),
			locales() {
				const { locales } = this.marketplace;

				return locales;
			}
		},
		watch: {
			list(value) {
				this.$emit('input', {
					field: this.field,
					value,
				});
			},
		},
		methods: {
			newSpecificity() {
				const { type, locale } = this.options.specificity;

				const locales = []

				this.locales.forEach((item) => {
					locales.push({
						...locale,
						locale:item
					})
				})
				let index
				if (this.specs){
					index = this.specs.length + 1
				}else {
					index = this.list.length + 1
				}

				this.list.push({
					...type,
					locale: locales,
					index
				});
			},
			checkMove: function(e) {
				const len = this.specs ? this.specs.length : 0
				this.list.splice(e.draggedContext.futureIndex, 0, this.list.splice(e.draggedContext.index, 1)[0]);
				// update order properties based on position in array
				this.list.forEach(function(item, index){
					item.index = index + len +  1;
				});

			},
			removeSpecifity(index) {
				this.list.splice(index, 1);
			},
		},
		props: {
			field: {
				type: String,
				required: true,
			},
			specificitiesList: {
				type: Array,
				required: true,
			},
			specs: {
				type: Array,
				required: true,
			},
			validation: {
				type: Object,
			},
			isEdit: {
				type: Boolean,
				default: false,
			},
		},
	};
</script>

<style>
</style>
