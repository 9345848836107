<template>
    <div>
        <breadcrumb :label="$t('payments')"></breadcrumb>

        <payments-table-component></payments-table-component>
    </div>
</template>

<script>
	export default {
		name: 'PaymentsView',
	};
</script>

<style>
</style>
