<template>
	<div>
		<form v-if="masterCategories" @submit.prevent="createResourceCategory">
			<breadcrumb :label="$t('create-category')">
				<button-component :label="$t('save')" :busy="isBusy" :button-class="'btn btn-primary btn-sm mr-2'"></button-component>
				<router-link class="btn btn-white btn-sm shadow-sm" :to="{ name: 'dashboard.categories.resource.index'}" exact>
					{{$t('cancel')}}
				</router-link>
			</breadcrumb>

			<div class="row">
				<div class="col-lg-6">
					<card-component :waves="true">
						<h5>{{$t('master-category')}}
							<a v-show="!hasMaster" href="#existing-master-category" @click.prevent="setHasMaster" class="float-right">{{$t('create-master')}}</a>
							<a v-show="hasMaster" href="#existing-master-category" @click.prevent="setHasMaster" class="float-right">{{$t('choose-existing')}}</a>
						</h5>
						<div class="form-group" v-show="!hasMaster">
							<label class="w-100">{{$t('master-category')}}<a href="#new-master-category" @click.prevent="setHasMaster" class="float-right"></a></label>
							<select v-model="form.master.id" class="form-control form-control-sm shadow-sm" :required="!hasMaster ? true : false">
								<option disabled :value="null">{{$t('select-option')}}</option>
								<option v-for="(category, index) in masterCategories" :key="index" :value="category.id">{{ category.textLocale[$i18n.locale] }}</option>
							</select>

							<div class="invalid-feedback d-block" v-html="validation.message"></div>
						</div>
						<div v-if="hasMaster">
							<div class="row" v-for="locale in this.form.master.locale" :key="locale.locale">
								<div class="form-group col-12">
									<label>{{locale.locale.toUpperCase()}} {{$t('title')}}</label>
									<input v-model="locale.title" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs['locale.title']" required>
									<div class="invalid-feedback d-block" v-html="validation.message"></div>
								</div>

								<div class="form-group col-12">
									<label>{{locale.locale.toUpperCase()}} {{$t('description')}}</label>
									<textarea v-model="locale.description" class="form-control shadow-sm" rows="4" :class="validation.inputs['locale.description']" required></textarea>
									<div class="invalid-feedback d-block" v-html="validation.message"></div>
								</div>
							</div>
						</div>

						<h5>{{$t('resource-info')}}</h5>

						<div class="row" v-for="locale in this.form.locale" :key="locale.locale">
							<div class="form-group col-12">
								<label>{{locale.locale.toUpperCase()}} {{$t('title')}}</label>
								<input v-model="locale.title" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs['locale.title']" required>
								<div class="invalid-feedback d-block" v-html="validation.message"></div>
							</div>

							<div class="form-group col-12">
								<label>{{locale.locale.toUpperCase()}} {{$t('description')}}</label>
								<textarea v-model="locale.description" class="form-control shadow-sm" rows="4" :class="validation.inputs['locale.description']" required></textarea>
								<div class="invalid-feedback d-block" v-html="validation.message"></div>
							</div>
						</div>

						<div class="row">
							<div class="form-group col-12">
								<label>{{$t('photo')}}</label>
								<input @change="setPhoto" type="file" max="1" class="form-control-file" accept="image/x-png,image/gif,image/jpeg">
								<div class="invalid-feedback d-block" v-html="validation.message"></div>
							</div>
						</div>
					</card-component>
				</div>

				<div class="col-lg-6">
					<card-component :waves="true">
						<h5>{{$t('category-specificities')}}</h5>
						<specificities-list-component :specificities-list="form.specificitiesList" :field="'specificitiesList'" :validation="validation" @input="setInput"></specificities-list-component>
					</card-component>
				</div>
			</div>

		</form>
		<div v-else>
			<div v-if="isBusy" class="spinner">
				<spinner-component></spinner-component>
			</div>
		</div>
	</div>
</template>

<style scoped>
	h5 a {
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
	}
</style>

<script>
	import { mapGetters, mapActions } from 'vuex';
	import { activity, validation } from '@/mixins';

	export default {
		name: 'ResourceCategoriesCreate',
		mixins: [activity, validation],
		created() {
			this.fetchData();
		},
		data() {
			return {
				hasMaster: false,
				form: {
					master: {
						id: null,
						locale: [],
					},
					locale: [],
					topCategoryId: null,
					adminUserGroupId: null,
					specificitiesList: [],
					isInitialized: false,
					photo: null,
				},
			};
		},
		mounted() {
			const masterLocale = [];
			const subLocale = [];
			this.locales.forEach(function (obj) {
				masterLocale.push({
					title: null,
					description: null,
					locale: obj,
				});

				subLocale.push({
					title: null,
					description: null,
					locale: obj,
				});
			});

			this.form = {
				master: {
					id: null,
					locale: masterLocale,
				},
				locale: subLocale,
				topCategoryId: null,
				adminUserGroupId: null,
				specificitiesList: [],
				isInitialized: true,
				photo: null,
			}
		},
		computed: {
			...mapGetters({
				isBusy: 'isBusy',
				masterCategories: 'categories/resourcesMasterCategories',
				marketplace: 'marketplace/marketplace',
			}),
			locales() {
				const { locales } = this.marketplace;

				return locales;
			},
		},
		watch: {
			$route: function () {
				this.fetchData();
			},
			hasMaster() {
				this.form.master = {
					id: null,
					locale: [
						{
							title: null,
							description: null,
							locale: 'fr',
						},
						{
							title: null,
							description: null,
							locale: 'en',
						},
					],
				};
			},
		},
		methods: {
			...mapActions({
				setAppState: 'setAppIsBusy',
				attemptGetResourceCategories:
					'categories/attemptGetResourceCategories',
				attemptResourceCategoryCreation:
					'categories/attemptResourceCategoryCreation',
			}),
			setHasMaster() {
				this.hasMaster = !this.hasMaster;
			},
			setPhoto(photo) {
				this.form.photo = photo.target.files[0];
			},
			fetchData() {
				this.attemptGetResourceCategories(true);
			},
			performHouseActivities() {},
			createResourceCategory() {
				this.setAppState(true);
				const { topCategoryId, adminUserGroupId, id } = this.marketplace;

				this.attemptResourceCategoryCreation({
					...this.form,
					topCategoryId,
					adminUserGroupId,
					marketplaceId: id,
					photo: this.form.photo,
				})
					.then(({ data }) => {
						const {
							category: { id: categoryId },
						} = data;

						this.hasActivity = false;
						this.shouldWatch = false;
						this.$router.push({
							name: 'dashboard.categories.resource.edit',
							params: { categoryId },
						});
					})
					.catch((e) => this.validationErrors(e));
			},
		},
	};
</script>
