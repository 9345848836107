export default {
	types(state) {
		return state.types
	},
	priceTerms(state) {
		return state.priceTerms
	},
	headers(state) {
		return state.headers
	},
	resource(state) {
		return state.resource
	},
}
