<template>
    <div>
        <breadcrumb :label="$t('profile')"></breadcrumb>

		<div class="row">
			<div class="col-lg-3 col-md-4">
				<card-component :waves="true">
					<div class="card-header">{{$t('account')}}</div>
					<div class="profile-wrapper">
						<div class="my-4">
							<img :src="logo" alt="" class="img img-thumbnail">
						</div>

						<span class="label">{{$t('full-name')}}</span>
						<p>{{ name|placeholder }}</p>

						<span class="label">{{$t('organization-name')}}</span>
						<p>{{ title|placeholder }}</p>

						<span class="label">{{$t('email')}}</span>
						<p>{{ email|placeholder }}</p>

						<span class="label">{{$t('phone-number')}}</span>
						<p>{{ phoneNumber|placeholder }}</p>

						<span class="label">{{$t('support-email')}}</span>
						<p>{{ supportEmail|placeholder }}</p>
					</div>

					<div v-if="role !== 'Super Admin'" class="text-center">
						<a href="#update-profile" class="btn btn-primary btn-sm" data-toggle="modal">{{$t('update-profile')}}</a>
					</div>
				</card-component>
			</div>
			<div class="col-lg-9 col-md-8">
				<nav class="nav nav-borders">
					<router-link v-if="role !== 'Super Admin'" class="nav-link" :to="{ name: 'dashboard.profile.account.invoices'}" exact>{{$t('invoices')}}</router-link>
					<router-link class="nav-link d-none" :to="{ name: 'dashboard.profile.account.payments'}" exact>{{$t('payments')}}</router-link>
					<router-link class="nav-link" :to="{ name: 'dashboard.profile.account.settings'}" exact>{{$t('settings')}}</router-link>
				</nav>
				<hr class="mt-0 mb-4">
				<router-view></router-view>
			</div>
		</div>

		<update-profile-modal-component v-if="role !== 'Super Admin'"></update-profile-modal-component>
    </div>
</template>

<style lang="scss" scoped>
	.card-header {
		background-color: transparent;
		border-bottom: none;
		padding: 0.75rem 0.25rem;
		margin-bottom: 0.5rem;
		font-size: 1.25rem;
		font-weight: 500;
		line-height: 1.2;
	}

	img {
		padding: 0.25rem;
		max-width: 100%;
		height: auto;
		background-color: transparent;
		border: none;
		border-radius: none;
	}
</style>

<script>
	import { isEmpty, find } from 'lodash';
	import { mapActions, mapGetters } from 'vuex';

	export default {
		name: 'Profile',
		beforeRouteEnter(to, from, next) {
			next(async (vm) => vm.attemptGetProfile());
		},
		mounted() {
			this.setProfile();
		},
		data() {
			return {
				name: null,
				photo: null,
				title: null,
				email: null,
				phoneNumber: null,
				supportEmail: null
			};
		},
		computed: {
			...mapGetters({
				user: 'auth/user',
				role: 'auth/role',
				profile: 'profile/user',
				marketplace: 'marketplace/marketplace',
				logo: 'logo',
				language: 'locale',
			}),
		},
		watch: {
			profile: {
				handler: function () {
					this.setProfile();
				},
				deep: true,
			},
		},
		methods: {
			...mapActions({
				setAppState: 'setAppIsBusy',
				attemptGetProfile: 'profile/attemptGetProfile',
			}),
			setProfile() {
				const { firstName, lastName, email, phoneNumber, locale } =
					this.getProfile();

				this.name = `${firstName} ${lastName}`;
				this.email = email;
				this.phoneNumber = phoneNumber;
				this.supportEmail = this.marketplace.data.supportEmail || null

				if (!isEmpty(locale) && this.role !== 'Super Admin') {
					this.title = find(locale, ['locale', this.language]).title;
				}
			},
			getProfile() {
				if (this.role !== 'Super Admin') {
					let {
						operatorPerson: { firstName, lastName, email, phoneNumber },
						locale,
					} = this.profile;

					return {
						firstName,
						lastName,
						email,
						phoneNumber,
						locale,
					};
				}

				let { firstName, lastName, email, phoneNumber, locale } =
					this.profile;

				return {
					firstName,
					lastName,
					email,
					phoneNumber,
					locale,
				};
			},
		},
	};
</script>
