import store from '@/store'

export default function (mutation) {
	const {
		type,
		payload
	} = mutation

	switch (type) {
		case 'resources/SET_RESOURCE':
			if (payload) {
				store.dispatch('categories/attemptGetResourceCategories')
				store.dispatch('resources/attemptGetResourcePriceTerms')
			} else {
				store.commit('resources/SET_RESOURCE', null)
			}
			break

		default:
			break
	}
}
