<template>
	<div class="modal" id="export-as" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="export-asLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<form @submit.prevent="exportResources" class="modal-content" ref="form">
				<div class="modal-header">
					<h5 class="modal-title" id="export-asLabel">{{$t("export")}}</h5>
					<button type="button" class="close d-none" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="form-group col-12">
							<label>Category</label>
							<select-component :options="categories" :value="form.categoryId" :field="'categoryId'" @input="setInput" :required="true"/>
							<div class="invalid-feedback d-block" v-html="validation.message"></div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button-component :label="'Export Transactions Report'" :busy="isBusy" :buttonClass="'btn btn-primary'"></button-component>
					<button type="button" class="btn btn-secondary btn-sm shadow-sm" data-dismiss="modal">Cancel</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { activity, validation } from '@/mixins';

export default {
	name: 'ExportAsModalComponent',
	mixins: [activity, validation],
	mounted() {
		window.$('#export-as').on('hidden.bs.modal', () => this.resetForm());
	},
	data() {
		return {
			form: {
				categoryId: null,
			},
			specificitiesList: [],
		};
	},
	computed: {
		...mapGetters({
			isBusy: 'isBusy',
			categories: 'categories/resourcesCategories',
		}),
	},
	methods: {
		...mapActions({
			setAppState: 'setAppIsBusy',
			attemptExportResources: 'resources/attemptExportResources',
		}),
		exportResources() {
			this.setAppState(true);

			this.attemptExportResources(this.form)
				.then(() => window.$('#export-as').modal('hide'))
				.catch((e) => this.validationErrors(e));
		},
	},
};
</script>

<style>
</style>
