<template>
	<fieldset class="row" v-if="isReady">
		<legend class="w-100 px-3">{{ textLocale[$i18n.locale] }}</legend>

		<div class="form-group col-md-12 row" v-if="type === 'TEXT'">
			<div class="form-group col-md-6" v-for="locale in marketplaceLocales" :key="locale">
				<label>{{ locale.toUpperCase() }}</label>
				<input v-model="specLocale[locale].value" :type="inputType" class="form-control form-control-sm shadow-sm" @change="setSpecificityInput" :placeholder="placeholder[$i18n.locale]" :required="required">
			</div>
		</div>

		<div class="form-group col-md-12 row" v-else>
			<div v-if="type === 'SELECT' || type ==='MULTI_SELECT'" class="input-group col-md-6">
				<select v-model="specLocale[marketplaceLocales[0]].value" class="form-control form-control-sm shadow-sm" @change="setSpecificityInput">
					<option disabled :value="null" >{{$t('select-option')}}</option>
					<option v-for="(value, index) in options[$i18n.locale]" :value="index" :key="value" >{{ value }}</option>
				</select>
			</div>

			<div v-else-if="type === 'MEASURE'" class="input-group measure col-md-6">
				<input v-model="specLocale[marketplaceLocales[0]].value"  step="any" type="number" class="form-control form-control-sm shadow-sm" @keyup="setSpecificityInput" :placeholder="placeholder[$i18n.locale]">
				<div class="input-group-append">
					<select v-model="defaultUnit" class="form-control form-control-sm shadow-sm" :disabled="isEdit">
						<option v-for="(value, index) in options[$i18n.locale]" :value="index" :key="value" >{{ value }}</option>
					</select>
				</div>
			</div>

			<div v-else class="input-group col-md-6">
				<input v-model="specLocale[marketplaceLocales[0]].value" :type="inputType" class="form-control form-control-sm shadow-sm" @change="setSpecificityInput" :placeholder="placeholder[$i18n.locale]" :required="required">
			</div>
		</div>
	</fieldset>
</template>

<style lang="scss" scoped>
	legend {
		font-size: 0.85rem;
		margin-bottom: 0rem;
	}

	.measure {
		.input-group-append {
			select {
				border-radius: 0rem 0.2rem 0.2rem 0rem;
			}
		}
	}
</style>

<script>
	import { mapGetters } from 'vuex';
	import { startCase, lowerCase, find } from 'lodash';

	export default {
		name: 'SpecificityComponent',
		data() {
			const { name, locale, required, specificity, type, defaultUnit } =
				this.sanitizeSpecificityList(this.specificityList);

			const textLocale = locale.reduce((acc, obj) => {
				acc[obj.locale] = obj.name.trim();
				return acc;
			}, {});

			return {
				name,
				locale,
				required,
				specificity,
				defaultUnit,
				type,
				options: null,
				placeholder: null,
				inputName: startCase(name),
				inputType: lowerCase(type),
				textLocale,
				marketplaceLocales: [],
				specLocale: null,
			};
		},
		created() {
			const locales = {};
			this.specificity.locale.forEach(function (l) {
				locales[l.locale] = l;
			});

			this.specLocale = locales;
		},
		mounted() {
			let options = {};
			const placeholder = {};

			this.locales.forEach((locale) => {
				options[locale] = this.sanitizeOptions(locale, this.locale);
				placeholder[locale] = this.sanitizePlaceholder(locale, this.locale);
			});

			this.options = options;
			this.placeholder = placeholder;
			this.marketplaceLocales = this.locales;
		},
		computed: {
			...mapGetters({
				specificityType: 'categories/specificities/type',
				specificityTypes: 'categories/specificities/specificityTypes',
				measureTypes: 'categories/specificities/measureTypes',
				marketplace: 'marketplace/marketplace',
			}),
			locales() {
				const { locales } = this.marketplace;

				return locales;
			},
			isSimple() {
				const { simple } = this.specificityType;

				return simple.includes(this.type);
			},
			isComplex() {
				const { complex } = this.specificityType;

				return complex.includes(this.type);
			},
			isReady() {
				return this.placeholder && this.options;
			},
		},
		watch: {
			specificity: {
				handler: function (value) {
					this.emit(value);
				},
				deep: true,
			},
			specificityList:{
				handler: function(){
					this.$forceUpdate();
				},
				deep: true,
				immediate:true
			}
		},
		methods: {
			sanitizeSpecificityList(specificityList) {
				const {
					name,
					specificitiesListLocales: locale,
					required,
					type,
					specificity,
					defaultUnit,
					id,
				} = specificityList;

				if (type === 'DATE') {
					const { locale: dateLocale } = specificity;

					dateLocale.forEach((_locale) => {
						const { value } = _locale;

						_locale.value = this.$options.filters.toDate(
							value,
							'YYYY-MM-DD'
						);
					});
				}

				this.emit(specificity);

				return {
					id,
					name,
					locale,
					required,
					specificity,
					type,
					defaultUnit : specificity.locale[0].unit,
				};
			},
			sanitizeOptions(text, locale) {
				const select = find(locale, (obj) => obj.locale === text);

				return JSON.parse(select.options);
			},
			sanitizePlaceholder(text, locale) {
				const holder = find(locale, (obj) => obj.locale === text);

				return holder.placeholder;
			},
			emit(specificity) {
				this.$emit('update', {
					index: this.index,
					specificity,
				});
			},
			setSpecificityInput(event) {
				if (this.type !== 'TEXT') {
					const {
						target: { value },
					} = event;

					this.specificity.locale[0].value = value;
				}
			},
		},
		props: {
			index: {
				type: String,
				required: true,
			},
			specificityList: {
				type: Object,
				required: true,
			},
			validation: {
				type: Object,
			},
			isEdit: {
				type: Boolean,
				default: false,
			},
		},
	};
</script>
