<template>
	<card-component :waves="true">

	</card-component>
</template>

<script>
	export default {};
</script>

<style>
</style>
