import crypto from 'crypto'

const secret = process.env.VUE_APP_SECRET

export default {
    data() {
        return {
            isFiltering: false,
        }
    },
    methods: {
        generateSignature(params) {
            const iv = crypto.randomBytes(16)

            const cipher = crypto.createCipheriv('AES-256-CBC', Buffer.from(secret, 'hex'), iv)
            let encrypted = cipher.update(params.join('-'))
            encrypted = Buffer.concat([encrypted, cipher.final()])
            return `${iv.toString('hex')}_${encrypted.toString('hex')}`
        }
    }
}