<template>
	<div class="col-12">
		<div class="invoice-group">
			<label>{{$t('select-year')}}</label>
			<select v-model="invoice.year" class="invoice-control invoice-control-sm shadow-sm">
				<option disabled :value="null" >{{$t('select-option')}}</option>
				<option v-for="(year, index) in years" :value="year" :key="index">{{ year }}</option>
			</select>
			<div class="invalid-feedback d-block" v-html="validation.message"></div>
		</div>
		<div v-if="invoice.year" class="invoice-group">
			<label>{{$t('select-month')}}</label>
			<select v-model="invoice.month" class="invoice-control invoice-control-sm shadow-sm">
				<option disabled :value="null" >{{$t('select-option')}}</option>
				<option v-for="(month, index) in months" :value="month" :key="index">{{ month }}</option>
			</select>
			<div class="invalid-feedback d-block" v-html="validation.message"></div>
		</div>

		<div v-if="invoice.month" class="float-right mb-3">
			<button type="button" class="btn btn-primary shadow-sm btn-sm mr-2" @click="add">{{$t('select-invoice')}}</button>
			<button type="button" class="btn btn-white shadow-sm btn-sm" @click="cancel">{{$t('cancel')}}</button>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex';

	export default {
		name: 'HostingFeeComponent',
		beforeMount() {
			this.setYears();
		},
		data() {
			return {
				invoice: {
					type: 'Hosting Fee',
					year: null,
					month: null,
				},
			};
		},
		computed: {
			...mapGetters({
				years: 'profile/invoices/years',
				months: 'profile/invoices/months',
			}),
		},
		watch: {
			'invoice.year': function (year) {
				this.setMonths(year);
			},
		},
		methods: {
			...mapActions({
				setYears: 'profile/invoices/setYears',
				setMonths: 'profile/invoices/setMonths',
			}),
			add() {
				this.$emit('select', this.invoice);
			},
			cancel() {
				this.$emit('cancel');
			},
		},
		props: {
			validation: {
				type: Object,
			},
		},
	};
</script>
