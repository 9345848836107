<template>
	<div>
		<payments-table-filters v-show="state.isFiltering"></payments-table-filters>
		<table-component
            :header="header"
            :data="data"
            :total="total"
            :cta="cta"
            :state="state"
            :id="'payments'"
			:searchTerm="searchTerm"
			:isFetching="isFetching"
			:isInitializing="isInitializing"
			@setSearchTerm="setSearchTerm"
			@setSort="setSort"
			@setPerPage="setPerPage"

            @setData="setData"
			:whithouButtons="true"
			:options="{
				hideFilters: true
			}"
        >
        </table-component>

		<view-invoice-modal-component @update="getData"></view-invoice-modal-component>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import { find } from 'lodash';
	import { request } from '@/config';
	import { table, transactions } from '@/mixins';

	export default {
		name: 'PaymentsTable',
		mixins: [table, transactions.payments],
		data() {
			return {
				header: [
					{ label: this.$t('buyer'), type: 'email', sort: false },
					{ label: this.$t('seller'), type: 'email', sort: false },
					{ label: this.$t('resource'), type: 'text', sort: false },
					{ label: this.$t('price'), type: 'text', sort: false },
					{ label: this.$t('start-date'), type: 'date', sort: false },
					{ label: this.$t('end-date'), type: 'date', sort: false },
					{ label: this.$t('created-date'), type: 'date', sort: false },
					{ label: this.$t('buyer-paid'), type: 'html', sort: false },
					{ label: this.$t('buyer-invoice'), type: 'html', sort: false },
					{ label: this.$t('seller-invoice'), type: 'html', sort: false },
					{ label: this.$t('uploaded-invoice'), type: 'html', sort: false },
					{ label: 'ID', type: 'id', sort: false },
				],
				data: {
					table: [],
					metaData: [],
				},
				total: 0,
				cta: null,
			};
		},
		mounted() {
			this.getData();
		},
		computed: {
			...mapGetters({
				marketplace: 'marketplace/marketplace'
			})
		},
		watch: {
			'state.page': function (page) {
				if (page) {
					this.getData();
				}
			},
		},
		methods: {
			setData(data) {
				this.data = data;
			},
			getData: async function () {
				const { toCurrency } = this.$options.filters;

				try {
					const data = {
						table: [],
						metaData: [],
					};

					const { data: payments, total } = await request
						.get('/payment/invoices', {
							headers: {
								Authorization: `Bearer ${this.token}`,
							},
							params: {
								marketplaceId : this.marketplace.id,
								groupAccess: this.groupAccess,
								locale: this.locale,
								isDatatable: true,
								page: this.state.page,
								load: this.state.perPage,
							},
						})
						.then(({ data: { data: response } }) => response);

					payments.forEach((item) => {
						const { title } = this.getObjectLocale(
							item.resource.locales
						);

						const parties = this.getNegotiationParties({
							buyer: item.buyer,
							seller: item.seller,
						});

						const { id: buyerInvoice } = find(item.invoices, [
							'userId',
							item.buyerId,
						]);
						const { id: sellerInvoice } = find(item.invoices, [
							'userId',
							item.sellerId,
						]);

						const sellerInvoiceObj = find(item.invoices, [
							'userId',
							item.sellerId,
						]);


						const buyerInv = find(item.invoices, [
							'userId',
							item.buyerId,
						]);

						console.log(sellerInvoiceObj.sellerInvoice === null)

						data.table.push([
							item.buyer.email,
							item.seller.email,
							title,
							toCurrency(item.finalPrice),
							item.startDate,
							item.endDate,
							item.createdAt,
							`<p>${buyerInv.status === 9 ? this.$t('yes') : this.$t('no')}</p>`,
							`<a href="#view-invoice" data-toggle="modal" data-id="${buyerInvoice}" data-start="${item.startDate}" data-end="${item.endDate}" data-buyer="${parties.buyer}" data-seller="${parties.seller}" data-type="buyer">${this.$t('view')}</a>`,
							`<a href="#view-invoice" data-toggle="modal" data-id="${sellerInvoice}" data-start="${item.startDate}" data-end="${item.endDate}" data-buyer="${parties.buyer}" data-seller="${parties.seller}" data-type="seller">${this.$t('view')}</a>`,
							`<a href=${sellerInvoiceObj.sellerInvoice ? sellerInvoiceObj.sellerInvoice  : '#'} >${this.$t('view')}</a>`,
							item.id,
						]);
					});

					this.data = data;
					this.total = total;
					this.isFetching = false;
					this.isInitializing = false;
					this.scrollTableToTop()
				} catch (err) {
					console.log({ err });
				}
			},
		},
	};
</script>

<style lang="scss">
	.table-wrapper td.hasHtml {
		display: table-cell !important;
		min-width: 250px;
		max-width: 250px;
		text-align: center;
		> div {
			display: flex;
			justify-content: center;
			padding-right: 10px;
		}
	}
	.table-wrapper th.html {
		min-width: 250px;
		max-width: 250px;
		display: flex;
		justify-content: center;
	}
</style>
