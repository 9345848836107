import store from '@/store'

export default function (mutation) {
	const {
		type,
		payload
	} = mutation

	switch (type) {
		case 'marketplace/SET_MARKETPLACE':
			if (payload) {
				localStorage.setItem('marketplace', JSON.stringify(payload))
				store.dispatch('setAppLogo', payload.logo)

				if (store.getters['auth/role'] !== 'Super Admin') {
					store.dispatch('marketplace/attemptGetMembershipTypes')

					store.dispatch('categories/attemptGetResourceCategories')
					store.dispatch('categories/attemptGetResourceCategories', true)
					store.dispatch('categories/attemptGetCompanyCategories')
				}
			} else {
				localStorage.removeItem('marketplace')
			}
			break
		case 'marketplace/SET_MEMBERSHIP_TYPES':
			if (payload) {
				localStorage.setItem('membershipTypes', JSON.stringify(payload))
			} else {
				localStorage.removeItem('membershipTypes')
			}
			break

		default:
			break
	}
}
