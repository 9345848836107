import {
	groupBy
} from 'lodash'

export default {
	beforeMount() {
		this.resetErrors()
		this.originalData = this.validationObject(this.form)
	},
	data() {
		return {
			validation: {
				inputs: null,
				errors: null,
				message: null,
			},
			originalData: null
		}
	},
	watch: {
		'$data.form': {
			handler() {
				this.resetErrors()
			},
			deep: true
		}
	},
	methods: {
		validationErrors(error) {
			const {
				data,
				status,
			} = error

			if (status !== 200) {
				this.setErrors(data)
			}
		},
		setErrors(data) {
			const inputs = {}
			const {
				errors
			} = data
			const fields = groupBy(errors, (error) => error.param);

			Object.values(fields).map((x) => inputs[x[0].param] = x[0].msg)

			const validation = Object.assign({}, {
				errors: {
					...this.validation.errors,
					...inputs
				},
				...this.getFormErrors(data)
			})

			this.validation = JSON.parse(JSON.stringify(validation))

			this.setAppState(false);
		},
		resetErrors() {
			const fields = this.validationObject(this.originalData)

			this.validation = {
				inputs: fields,
				errors: fields,
				message: null
			}
		},
		resetForm() {
			this.resetErrors();

			this.form = this.originalData

			this.$refs.form.reset()
		},
		getFormErrors({
			errors,
			message
		}) {

			return {
				inputs: this.sanitizeFormErrors(errors),
				message: this.sanitizeFormErrorMessage(errors, message)
			}
		},
		sanitizeFormErrors(errors) {
			const inputs = {}

			Object.values(errors).map((x) => inputs[x.param] = 'is-invalid')

			return inputs
		},
		sanitizeFormErrorMessage(errors, message) {
			const error = (typeof (errors[0]) === 'object') ? 'The field(s) cannot be empty.' : errors[0]

			return message ? message : error
		},
		validationObject(object) {
			return JSON.parse(JSON.stringify(Object.assign({}, object)))
		}
	}
}
