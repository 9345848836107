import store from '@/store'
import {
	isEmpty,
	filter
} from 'lodash'

import i18n from '../../../i18n';

export default {
	SET_RESOURCE_MASTER_CATEGORIES(state, categories) {
		state.resourcesMasterCategories = categories.map((category) => {
			const textLocale = category.categoryLocale.reduce((acc, obj) => {
				acc[obj.locale] = obj.title;
				return acc;
			}, {});
			const text = textLocale[i18n.locale]
			const {
				id,
				photo,
				type
			} = category

			return {
				id,
				text,
				textLocale,
				photo,
				type
			}
		})

		localStorage.setItem('resourcesMasterCategories', JSON.stringify(state.resourcesMasterCategories))
	},
	SET_RESOURCE_CATEGORIES(state, categories) {
		state.resourcesCategories = categories.map((category) => {
			const {
				id,
				children: _children,
				photo,
				type
			} = category

			const textLocale = category.categoryLocale.reduce((acc, obj) => {
				acc[obj.locale] = obj.title;
				return acc;
			}, {});

			const text = textLocale[i18n.locale]
			const hasChildren = !isEmpty(_children)
			let children = []

			if (hasChildren) {
				children = _children.map((child) => {
					const {
						id: _id,
						photo: _photo,
						specificitiesList
					} = child
					const _text = child.categoryLocale[0].title
					return {
						id: _id,
						text: _text,
						photo: _photo,
						specificitiesList
					}
				})
			}

			if (hasChildren){
				return {
					id,
					text,
					textLocale,
					photo,
					hasChildren,
					children,
					type
				}
			}else{
				return {
					id,
					text,
					textLocale,
					photo,
					type
				}
			}
		})

		localStorage.setItem('resourcesCategories', JSON.stringify(state.resourcesCategories))
	},
	SET_RESOURCE_CATEGORY(state, category) {
		const {
			parentId
		} = category

		const {
			topCategoryId
		} = store.getters['marketplace/marketplace']

		category.isMasterCategory = (topCategoryId === parentId)

		state.resourceCategory = category
	},
	SET_COMPANY_CATEGORIES(state, categories) {
		state.companyCategories = categories.map((category) => {
			category['text'] = category.categoryLocale[0].title
			delete category.children
			delete category.marketplaceId
			return category
		})

		localStorage.setItem('companyCategories', JSON.stringify(state.companyCategories))
	},
	SET_COMPANY_CATEGORY(state, category) {
		const {
			companyListLocale
		} = category

		const _default = filter(companyListLocale, (obj) => {
			return obj.locale === 'default'
		});
		const en = filter(companyListLocale, (obj) => {
			return obj.locale !== 'default'
		});

		category.categoryLocale = [_default, en]

		state.companyCategory = category
	}
}
