import store from '@/store'

export default [{
	path: 'emails',
	name: 'dashboard.emails',
	component: () => import('@/views/dashboard/marketplace/emails/SendEmail'),
	beforeEnter: async (to, from, next) => {
		const marketplace = await store.dispatch('marketplace/attemptGetMarketplace')

		if (marketplace) {
			return next()
		}

		return next({
			name: 'dashboard'
		})
	}
}]
