import store from '@/store'

export default [{
		path: 'users/set-uid',
		name: 'dashboard.users.set-uid',
		component: () => import('@/views/dashboard/users/SetuidView.vue'),
		beforeEnter: (to, from, next) => {
			const {
				setuid
			} = store.getters['auth/permissions'].users

			if (setuid) {
				return next()
			}

			return next({
				name: 'dashboard'
			})
		}
	},
	{
		path: 'users/activation-links',
		name: 'dashboard.users.activation-links',
		component: () => import('@/views/dashboard/users/ActivationLinksView.vue'),
		beforeEnter: (to, from, next) => {
			const {
				activationLinks
			} = store.getters['auth/permissions'].users

			if (activationLinks) {
				return next()
			}

			return next({
				name: 'dashboard'
			})
		}
	},
	{
		path: 'users/members',
		name: 'dashboard.users.members.index',
		component: () => import('@/views/dashboard/users/members/MembersView.vue'),
		beforeEnter: (to, from, next) => {
			const {
				report
			} = store.getters['auth/permissions'].users.members

			if (report) {
				return next()
			}

			return next({
				name: 'dashboard'
			})
		},
		meta: {
			parent: 'users.members'
		}
	},
	{
		path: 'users/members/import',
		name: 'dashboard.users.members.import',
		component: () => import('@/views/dashboard/users/members/MembersImport.vue'),
		beforeEnter: (to, from, next) => {
			const {
				import: canImport
			} = store.getters['auth/permissions'].users.members

			if (canImport) {
				return next()
			}

			return next({
				name: 'dashboard.users.members.index'
			})
		},
		meta: {
			parent: 'users.members'
		}
	},
	{
		path: 'users/members/:memberId',
		name: 'dashboard.users.members.edit',
		component: () => import('@/views/dashboard/users/members/MembersEdit.vue'),
		beforeEnter: (to, from, next) => {
			const {
				edit
			} = store.getters['auth/permissions'].users.members

			if (edit) {
				return next()
			}

			return next({
				name: 'dashboard.users.members.index'
			})
		},
		meta: {
			parent: 'users.members'
		}
	},
]
