<template>
	<div>
		<form v-if="masterCategories" @submit.prevent="createSubAdministrator">
			<breadcrumb :label="$t('new-admin')">
				<button-component :label="$t('save')" :busy="isBusy" :button-class="'btn btn-primary btn-sm mr-2'"></button-component>
				<router-link class="btn btn-white btn-sm shadow-sm" :to="{ name: 'dashboard.sub-administrators.index'}" exact>
					{{$t('cancel')}}
				</router-link>
			</breadcrumb>

			<div class="row">
				<div class="col-lg-6">
					<card-component :waves="true">
						<div class="row">
							<div class="form-group col-12">
								<label>{{$t('first-name')}}</label>
								<input v-model="form.firstName" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs['firstName']" required>
								<div class="invalid-feedback d-block" v-html="validation.errors.firstName"></div>
							</div>

							<div class="form-group col-12">
								<label>{{$t('last-name')}}</label>
								<input v-model="form.lastName" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs['lastName']" required>
								<div class="invalid-feedback d-block" v-html="validation.errors.lastName"></div>
							</div>

							<div class="form-group col-12">
								<label>{{$t('username')}}</label>
								<input v-model="form.email" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs['email']" required>
								<div class="invalid-feedback d-block" v-html="validation.errors.email"></div>
							</div>

							<div class="form-group col-12">
								<label>{{$t('locale')}}</label>
								<select v-model="form.locale" class="form-control form-control-sm shadow-sm" required>
									<option disabled :value="null">{{$t('select-option')}}</option>
									<option v-for="locale in locales" :value="locale" :key="locale" >{{ locale|toStartCase }}</option>
								</select>
								<div class="invalid-feedback d-block" v-html="validation.errors.locale"></div>
							</div>



							<div class="form-group col-12">
								<label class="w-100">{{$t('password')}} </label>
								<input v-model="form.password" :placeholder="$t('password')" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs.password" required>
								<div class="invalid-feedback d-block" v-html="validation.errors.password"></div>
							</div>
							<!-- <div v-else class="form-group col-12">
								<a href="#auto-generate" @click.prevent="password = !password">{{$t('create-password')}}</a>
							</div> -->
						</div>
					</card-component>
				</div>

				<div class="col-lg-6">
					<card-component :waves="true">
						<permissions-component :permissions="form.permissions" :partners="partners" :field="'permissions'" :validation="validation" @input="setInput"></permissions-component>
					</card-component>
				</div>
			</div>

		</form>
		<div v-else>
			<div v-if="isBusy" class="spinner">
				<spinner-component></spinner-component>
			</div>
		</div>
	</div>
</template>

<style>
</style>

<script>
	import { mapGetters, mapActions } from 'vuex';
	import { activity, validation } from '@/mixins';

	export default {
		name: 'SubAdministratorsCreate',
		mixins: [activity, validation],
		created() {
			this.fetchData();
		},
		data() {
			return {
				password: false,
				form: {
					email: null,
					firstName: null,
					lastName: null,
					password: null,
					locale: null,
					isInitialized: true,
					permissions: {
						users: {
							setuid: false,
							activation_links: false,
							members: { report: false, import: false, edit: false },
						},
						resources: { report: false, import: false, edit: false },
						categories: {
							resource: {
								create: false,
								edit: false,
								delete: false,
								report: false,
							},
							member: {
								create: false,
								edit: false,
								delete: false,
								report: false,
							},
						},
						banners: { create: false, edit: false },
						transactions: {
							negotiations: { report: false, edit: false },
							payments: { report: false, edit: false },
							shipping: { report: false, edit: false },
							fees: { create: false, report: false, edit: false },
							payouts: { report: false, edit: false },
						},
						groupAccess: [],
					},
				},
			};
		},
		mounted() {},
		computed: {
			...mapGetters({
				isBusy: 'isBusy',
				masterCategories: 'categories/companyCategories',
				marketplace: 'marketplace/marketplace',
				user: 'auth/user',
			}),
			locales() {
				const { locales } = this.marketplace;

				return locales;
			},
			partners() {
				const { partners } = this.marketplace;

				partners.map(p => {
					p.text = p.name
				})

				return partners;
			}
		},
		watch: {
			$route: function () {
				this.fetchData();
			},
		},
		methods: {
			...mapActions({
				setAppState: 'setAppIsBusy',
				attemptGetCompanyCategories:
					'categories/attemptGetCompanyCategories',
				attemptSubAdministratorCreation:
					'administration/attemptSubAdministratorCreation',
			}),
			fetchData() {
				this.attemptGetCompanyCategories();
			},
			performHouseActivities() {},
			createSubAdministrator() {
				this.setAppState(true);
				const { id: companyId } = this.user.companyInfo;

				if(this.form.permissions.groupAccess.length === 0){
					this.$delete(this.form.permissions, 'groupAccess')
				}

				this.attemptSubAdministratorCreation({
					...this.form,
					companyId,
				})
					.then(({ data }) => {
						const { user: administratorId } = data;

						this.hasActivity = false;
						this.shouldWatch = false;
						this.$router.push({
							name: 'dashboard.sub-administrators.edit',
							params: { administratorId },
						});
					})
					.catch((e) => this.validationErrors(e));
			},
		},
	};
</script>
