<template>
	<div>
		<form class="banners" v-if="!banners" @submit.prevent="createBanners">
			<breadcrumb :label="$t('create-banners')">
				<button-component :label="$t('save')" :busy="isBusy" :button-class="'btn btn-primary btn-sm mr-2'"></button-component>
				<router-link class="btn btn-white btn-sm shadow-sm" :to="{ name: 'dashboard.banners'}" exact>
					{{$t('cancel')}}
				</router-link>
			</breadcrumb>

			<div class="row">
				<div class="col-lg-5">
					<banners-view-component :data="form.banners.top" :image="form.topImage" :type="'TOP'" :is-preview="false"></banners-view-component>
				</div>
				<div class="col-lg-7 border-left">
					<card-component :waves="true">
						<fieldset>
							<legend class="w-100">{{$t('top-banner')}}</legend>
							<template  v-for="(section, index) in form.banners.top.sections">
								<div class="form-row" v-bind:key="`top-${index}`">
									<div class="form-group col-2">
										<p class="form-label">{{ $t(section.type.toLowerCase()) }}</p>
									</div>
									<div class="form-group col-5">
										<label>{{$t('english')}}</label>
										<input v-model="section.enValue" :type="section.type === 'BUTTON-LINK' ? 'url' : 'text'" class="form-control form-control-sm shadow-sm" required>
										<div class="invalid-feedback d-block" v-html="validation.message"></div>
									</div>
									<div class="form-group col-5"  v-if='marketplace.domain !== "ben.usgreenchamber.com"'>
										<label>{{$t('french')}}</label>
										<input v-model="section.frValue" :type="section.type === 'BUTTON-LINK' ? 'url' : 'text'" class="form-control form-control-sm shadow-sm" required>
										<div class="invalid-feedback d-block" v-html="validation.message"></div>
									</div>
									<div v-if="section.type === 'BUTTON-LINK'" class="col-10 offset-2">
										<div class="custom-control custom-checkbox">
											<input type="checkbox" class="custom-control-input" id="top-open-in-new-tab" v-model="form.banners.top.newTab">
											<label class="custom-control-label" for="top-open-in-new-tab">{{$t('new-tab')}}</label>
										</div>
									</div>
								</div>
							</template>
							<div class="form-row">
								<div class="form-group col-2">
									<p class="form-label">{{$t('banner-image')}}</p>
								</div>
								<div class="form-group col-10">
									<label>{{$t('file')}}</label>
									<input name="topImage" @change="setPhoto" type="file" max="1" class="form-control-file" accept="image/x-png,image/gif,image/jpeg" required>
									<div class="invalid-feedback d-block" v-html="validation.message"></div>
								</div>
							</div>
						</fieldset>
					</card-component>
				</div>
			</div>

			<div class="row">
				<div class="col-lg-5">
					<banners-view-component :data="form.banners.bottom" :image="form.bottomImage" :type="'BOTTOM'" :is-preview="false"></banners-view-component>
				</div>
				<div class="col-lg-7 border-left">
					<card-component :waves="true">
						<fieldset>
							<legend class="w-100">{{$t('bottom-banner')}}</legend>
							<template  v-for="(section, index) in form.banners.bottom.sections">
								<div class="form-row" v-bind:key="`bottom-${index}`">
									<div class="form-group col-2">
										<p class="form-label">{{ $t(section.type.toLowerCase()) }}</p>
									</div>
									<div class="form-group col-5">
										<label>{{$t('english')}}</label>
										<input v-model="section.enValue" :type="section.type === 'BUTTON-LINK' ? 'url' : 'text'" class="form-control form-control-sm shadow-sm" required>
										<div class="invalid-feedback d-block" v-html="validation.message"></div>
									</div>
									<div class="form-group col-5">
										<label>{{$t('french')}}</label>
										<input v-model="section.frValue" :type="section.type === 'BUTTON-LINK' ? 'url' : 'text'" class="form-control form-control-sm shadow-sm" required>
										<div class="invalid-feedback d-block" v-html="validation.message"></div>
									</div>
									<div v-if="section.type === 'BUTTON-LINK'" class="col-10 offset-2">
										<div class="custom-control custom-checkbox">
											<input type="checkbox" class="custom-control-input" id="bottom-open-in-new-tab" v-model="form.banners.bottom.newTab">
											<label class="custom-control-label" for="bottom-open-in-new-tab">{{$t('new-tab')}}</label>
										</div>
									</div>
								</div>
							</template>
							<div class="form-row">
								<div class="form-group col-2">
									<p class="form-label">{{$t('banner-image')}}</p>
								</div>
								<div class="form-group col-10">
									<label>{{$t('file')}}</label>
									<input name="bottomImage" @change="setPhoto" type="file" max="1" class="form-control-file" accept="image/x-png,image/gif,image/jpeg" required>
									<div class="invalid-feedback d-block" v-html="validation.message"></div>
								</div>
							</div>
						</fieldset>
					</card-component>
				</div>
			</div>

			<banners-photo-crop-component :crop="crop" @save="setCroppedPhoto"></banners-photo-crop-component>
		</form>
		<div v-else>
			<div v-if="isBusy" class="spinner">
				<spinner-component></spinner-component>
			</div>

			<error-component v-else :label="$t('banner-already-created')" :button="$t('view-banners')" :return-to="{ name: 'dashboard.banners' }"></error-component>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	.custom-checkbox {
		margin-top: -1rem;
		margin-bottom: 1rem;
	}
</style>

<script>
	import { mapGetters, mapActions } from 'vuex';
	import { activity, validation } from '@/mixins';
	import 'verte/dist/verte.css';

	export default {
		name: 'BannersCreate',
		mixins: [activity, validation],
		mounted() {
			this.fetchData();
		},
		data() {
			return {
				banners: [],
				form: {
					isInitialized: true,
					banners: {
						top: {
							sections: [
								{
									type: 'TYPE',
									enValue: null,
									frValue: null,
								},
								{
									type: 'TITLE',
									enValue: null,
									frValue: null,
								},
								{
									type: 'SUB-TITLE',
									enValue: null,
									frValue: null,
								},
								{
									type: 'BUTTON-TEXT',
									enValue: null,
									frValue: null,
								},
								{
									type: 'BUTTON-LINK',
									enValue: null,
									frValue: null,
								},
							],
							newTab: false,
						},
						bottom: {
							sections: [
								{
									type: 'TYPE',
									enValue: null,
									frValue: null,
								},
								{
									type: 'TITLE',
									enValue: null,
									frValue: null,
								},
								{
									type: 'SUB-TITLE',
									enValue: null,
									frValue: null,
								},
								{
									type: 'BUTTON-TEXT',
									enValue: null,
									frValue: null,
								},
								{
									type: 'BUTTON-LINK',
									enValue: null,
									frValue: null,
								},
							],
							newTab: false,
						},
					},
					topImage: null,
					bottomImage: null,
				},
				crop: {
					type: null,
					image: null,
				},
			};
		},
		computed: {
			...mapGetters({
				isBusy: 'isBusy',
				marketplace: 'marketplace/marketplace',
				user: 'auth/user'
			})
		},
		methods: {
			...mapActions({
				setAppState: 'setAppIsBusy',
				attemptCreateBanners: 'banners/attemptCreateBanners',
			}),
			fetchData() {
				const { banner: banners } = this.marketplace;

				this.banners = banners;
			},
			setPhoto(event) {
				if (event) {
					const {
						name,
						files: [file],
					} = event.target;

					this.form[name] = file;
					this.crop = {
						type: name,
						image: file,
					};

					window.$('#crop-banner-photo').modal('show');
				}
			},
			setCroppedPhoto({ type, image }) {
				if (type && image) {
					this.form[type] = image;

					window.$('#crop-banner-photo').modal('hide');
				}
			},
			cancelCroppedPhoto({ type, image }) {
				this.form[type] = image;
			},
			createBanners() {
				this.setAppState(true);
				const { topCompanyId } = this.marketplace;

				const filtered = this.marketplace.partners
					.filter((part) => this.user.permissions.groupAccess.includes(part.categoryId))
					.map(p => p.id)


				this.attemptCreateBanners({
					...this.form,
					topCompanyId,
					topImage: this.form.topImage,
					bottomImage: this.form.bottomImage,
					groupAccess: filtered,
				})
					.then(() => {
						this.hasActivity = false;
						this.shouldWatch = false;
						this.$router.push({
							name: 'dashboard.banners',
						});
					})
					.catch((e) => this.validationErrors(e));
			},
		},
	};
</script>
