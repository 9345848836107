import {
	startCase
} from 'lodash'
import {
	request
} from '@/config'
import store from '@/store'

export default {
	setUserRole({
		commit
	}, value) {
		const {
			name
		} = value

		commit('SET_USER_ROLE', startCase(name))
	},
	setUserPermissions({
		commit
	}, value) {
		commit('SET_USER_PERMISSIONS', value)
	},
	async attemptLogin({
		dispatch
	}, credentials) {
		const token = await request.post('/login', credentials)
			.then(({
				data: {
					data: {
						token
					}
				}
			}) => {
				return token
			})
			.catch(({
				response: error
			}) => {
				throw error
			})

		dispatch('attemptGetUser', token)
	},
	async attemptLogout({
		commit
	}) {
		localStorage.removeItem('appLanguage')
		return request.post('/logout').finally(() => {
			commit('SET_TOKEN', null)
			commit('SET_USER', null)
			commit('SET_APP_LANGUAGE',null)
		})
	},
	async attemptGetUser({
		commit,
		state
	}, token) {
		if (token) {
			commit('SET_TOKEN', token)
		}

		if (!state.token) {
			commit('SET_TOKEN', null)

			return
		}

		const user = await request.get('/user')
			.then(({
				data: {
					data: {
						user
					}
				}
			}) => user)
			.catch(() => {
				commit('SET_TOKEN', null)
				commit('SET_USER', null)
			})


		commit('SET_USER', user)
	},
	async attemptForgotPassword(_, credentials) {
		const response = await request.post('/password/reset', credentials)
			.then(({
				data: {
					message
				}
			}) => message)
			.catch(({
				response: error
			}) => {
				throw error
			})

		store.commit('SET_MESSAGE', response)

		return response
	},
	async attemptVerifyPasswordReset(_, credentials) {
		const {
			id,
			confirmEmailToken,
			signature
		} = credentials

		const response = await request.get(`/password/reset/${id}/${confirmEmailToken}?signature=${signature}`)
			.then(({
				data: {
					message
				}
			}) => message)
			.catch(({
				response: error
			}) => {
				throw error
			})

		store.commit('SET_MESSAGE', response)

		return response
	},
	async attemptResetPassword(_, credentials) {
		const {
			id,
			confirmEmailToken,
			signature,
			password,
		} = credentials

		const response = await request.post(`/password/reset/${id}/${confirmEmailToken}?signature=${signature}`, {
				password
			})
			.then(({
				data: {
					message
				}
			}) => message)
			.catch(({
				response: error
			}) => {
				throw error
			})

		store.commit('SET_MESSAGE', response)

		return response
	}
}
