<template>
    <div>
        <breadcrumb :label="$t('setuid-unique')"></breadcrumb>

        <div class="row">
            <div class="col-lg-4 col-md-6 m-auto">
				<card-component :waves="true">
					<form @submit.prevent="setuid">
						<div class="d-flex flex-column -align-items-center justify-content-center">
							<div class="text-center">
								<svg xmlns="http://www.w3.org/2000/svg" height="5em" version="1.1" viewBox="-53 1 511 511.99906" width="5em">
									<path style="fill:#343a40;" d="M211.853,213.159c-58.514,0-106.58-48.065-106.58-106.58S153.339,0,211.853,0s106.58,48.065,106.58,106.58S270.367,213.159,211.853,213.159z M211.853,31.347c-41.273,0-75.233,33.959-75.233,75.233s33.959,75.233,75.233,75.233s75.233-33.959,75.233-75.233S253.127,31.347,211.853,31.347z"/>
									<path style="fill:#343a40;" d="M379.037,423.706H347.69c0-80.457-58.514-141.584-135.837-141.584S76.016,343.249,76.016,423.706H44.669c0-97.176,73.665-172.931,167.184-172.931S379.037,326.531,379.037,423.706z"/>
								</svg>
								<h3 class="tx-color-01 mt-3">{{$t('setuid-unique')}}</h3>
								<p class="tx-color-03 tx-13">{{$t('enter-email')}}</p>
							</div>

							<div class="form-group">
								<label for="email" class="sr-only">{{$t('email')}}</label>
								<input v-model="form.username" type="email" id="email" class="form-control form-control-sm shadow-sm" :class="validation.inputs.email" :placeholder="$t('email')" required>
								<div class="invalid-feedback d-block" v-html="validation.errors.email"></div>
							</div>

							<button-component :label="$t('proceed')" :busy="isBusy"></button-component>
						</div>
					</form>
				</card-component>
            </div>
        </div>
    </div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex';
	import { validation } from '@/mixins';
	export default {
		name: 'SetuidView',
		mixins: [validation],
		data() {
			return {
				form: {
					username: null,
				},
			};
		},
		mounted(){
			const usernameQuery = this.$route.query.username
			this.form.username = usernameQuery
		},
		computed: {
			...mapGetters({
				isBusy: 'isBusy',
				message: 'users/message',
				marketplace: 'marketplace/marketplace',
			}),
		},
		methods: {
			...mapActions({
				setAppState: 'setAppIsBusy',
				setMessage: 'users/setMessage',
				attemptSetUid: 'users/attemptSetUid',
			}),
			close() {
				this.setMessage(null);
			},
			setuid() {
				this.close();
				this.setAppState(true);

				const { id: marketplaceId } = this.marketplace;

				this.attemptSetUid({
					...this.form,
					marketplaceId,
				})
					.then((url) => {
						window.open(url, '_blank');
						this.form.email = null;
					})
					.catch((e) => {
						this.validationErrors(e);
					})
					.finally(() => this.setAppState(false));
			},
		},
	};
</script>

<style>
</style>
