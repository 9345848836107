<template>
	<router-view></router-view>
</template>

<script>
	export default {
		name: 'MarketplaceView',
	};
</script>

<style>
</style>
