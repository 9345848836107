<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-white sticky-top border-bottom justify-content-between justify-content-sm-start shadow-sm -fixed-top" aria-label="navbar">
        <a class="navbar-brand" @click.prevent="toggleMenu" v-html="navbarLogo"></a>
        <button class="btn btn-light btn-sm" id="menu-toggle" @click.prevent="toggleMenu">
            <em class="bi bi-list"></em>
        </button>
        <button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
				<li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div class="avatar avatar-sm d-inline d-sm-none">
                            <img src="#" class="rounded-circle" alt="">
                        </div>
                        {{ this.$i18n.locale }}
                    </a>
                    <div class="dropdown-menu dropdown-menu-right shadow-sm" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" v-for="locale in locales" :key="locale" @click="swtichLocale(locale)">{{locale}}</a>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div class="avatar avatar-sm d-inline d-sm-none">
                            <img src="#" class="rounded-circle" alt="">
                        </div>
                        {{ username }}
                    </a>
                    <div class="dropdown-menu dropdown-menu-right shadow-sm" aria-labelledby="navbarDropdown">
                        <router-link class="dropdown-item" :to="{ name: profile}">Profile</router-link>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#logout" @click.prevent="logout">Logout</a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>

<style scoped>
	.navbar-brand {
		padding: 0;
		display: none;
	}

	#wrapper.toggled .navbar-brand {
		overflow-x: hidden;
		display: block;
	}

	#menu-toggle {
		border-radius: 40px;
		padding: 10px 15px;
	}

	.dropdown-item.active,
	.dropdown-item:active {
		color: #212529;
		background-color: transparent;
	}

	@media (max-width: 425px) {
		.navbar-brand {
			display: revert;
		}
	}
</style>

<script>
	import { startCase } from 'lodash';
	import { mapActions, mapGetters } from 'vuex';

	export default {
		name: 'Navbar',
		computed: {
			...mapGetters({
				user: 'auth/user',
				role: 'auth/role',
				marketplace: 'marketplace/marketplace',
				navbarLogo: 'navbarLogo',
				appLangauge: 'appLanguage'
			}),
			locales() {
				const { locales } = this.marketplace;

				return locales;
			},
			username() {
				if (this.user) {
					const { username } = this.user;

					return startCase(username);
				}

				return null;
			},
			profile() {
				if (this.role === 'Super Admin') {
					return 'dashboard.profile.account.settings';
				}

				return 'dashboard.profile.account.invoices';
			},
		},
		methods: {
			...mapActions({
				attemptLogout: 'auth/attemptLogout',
				toggleMenu: 'setAppMenuToggled',
			}),
			logout() {
				this.attemptLogout().finally(() => {
					this.$router.replace({ name: 'login' });
				});
			},
			swtichLocale(locale){
				if(this.$i18n.locale !== locale){
					this.$store.commit('SET_APP_LANGUAGE', locale)
					//this.$store.state.app.appLanguage = locale
					//this.appLanguage = locale
					this.$i18n.locale = locale
				}

			}
		},
	};
</script>
