<template>
	<div>
		<select :id="id" :required="required">
			<option :value="null" disabled selected>{{ $t('select-option') }} </option>
		</select>
	</div>
</template>

<style lang="scss">
	@import '@/assets/scss/app.scss';

	select {
		display: block;
		width: 100%;
		height: calc(1.5em + 0.5rem + 2px) !important;
		padding: 0.25rem 0.5rem;
		font-size: 0.875rem;
		font-weight: 400;
		line-height: 1.5;
		color: #495057;
		background-color: #ffff;
		background-clip: padding-box;
		border: 1px solid #ced4da;
		border-radius: 0.2rem;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
	}

	.select2-container--bootstrap4 {
		.select2-selection--single {
			display: block;
			width: 100%;
			height: calc(1.5em + 0.5rem + 2px) !important;
			padding: 0.25rem 0.5rem;
			font-size: 0.875rem;
			font-weight: 400;
			line-height: 1.5;
			color: #495057;
			background-color: #ffff;
			background-clip: padding-box;
			border: 1px solid #ced4da;
			border-radius: 0.2rem;
			transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
			box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
			.select2-selection__rendered {
				padding-left: 0;
				font-size: 0.875rem;
				font-weight: 400;
				line-height: 1.5 !important;
				color: #495057;
			}
		}

		&.select2-container--focus {
			.select2-selection {
				border-color: lighten($primary, 25) !important;
				box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
			}
		}

		.select2-results__option--highlighted {
			color: #fff;
			background-color: var(--color-medium);
			font-size: 0.875rem;
		}
		.select2-results__option--highlighted.select2-results__option[aria-selected='true'] {
			color: #fff;
			background-color: var(--color-medium);
			font-size: 0.875rem;
		}

		.select2-results > .select2-results__options {
			max-height: 15em;
			overflow-y: auto;
			font-size: 0.875rem !important;
			border-top: 1px solid #ced4da;
		}

		.select2-results__option {
			padding: 0.2rem 0.5em;
			&[role='group'] {
				border-top: 1px solid #ced4da;
			}
		}

		.select2-results__option--selectable {
			&[role='option'] {
				border-top: 1px solid #e2e6e9;
			}
		}

		.select2-results__option[aria-disabled='true'] {
			color: #6c757d;
		}

		.select2-results__group {
			display: list-item;
			padding: 6px;
			color: #495057;
			font-weight: 600;
		}
	}
</style>

<script>
	import { v4 as uuidv4 } from 'uuid';

	export default {
		name: 'SelectComponent',
		mounted() {
			this.mountSelectComponent();
		},
		data() {
			return {
				id: `select-${uuidv4()}`,
				selectComponent: null,
				input: this.value,
			};
		},
		watch: {
			input(value) {
				this.$emit('input', {
					field: this.field,
					value,
				});
			},
			options(value) {
				if (value) {
					this.mountSelectComponent();
				}
			},
		},
		methods: {
			clear() {
				this.input = '';
			},
			select(selected) {
				this.input = selected.id;
			},
			mountSelectComponent() {
				this.selectComponent = window
					.$(`#${this.id}`)
					.select2({
						data: this.options,
						minimumResultsForSearch: -1,
						theme: 'bootstrap4',
					})
					.val(this.value)
					.trigger('change')
					.on('change', () => {
						const selected = this.selectComponent.select2('data')[0];

						this.select(selected);
					});

				this.setCollapse();
			},
			setCollapse() {
				const optgroupState = {};

				window
					.$('body')
					.on(
						'click',
						'.select2-container--open .select2-results__group',
						function () {
							window.$(this).siblings().toggle();
							let id = window
								.$(this)
								.closest('.select2-results__options')
								.attr('id');
							let index = window
								.$('.select2-results__group')
								.index(this);
							optgroupState[id][index] = !optgroupState[id][index];
						}
					);

				this.selectComponent.on('select2:open', function () {
					window.$('.select2-dropdown--below').css('opacity', 1);
					setTimeout(() => {
						let groups = window.$(
							'.select2-container--open .select2-results__group'
						);
						let id = window.$('.select2-results__options').attr('id');
						if (!optgroupState[id]) {
							optgroupState[id] = {};
						}
						window.$.each(groups, (index, v) => {
							optgroupState[id][index] =
								optgroupState[id][index] || false;
							optgroupState[id][index]
								? window.$(v).siblings().show()
								: window.$(v).siblings().hide();
						});
						window.$('.select2-dropdown--below').css('opacity', 1);
					}, 0);
				});
			},
		},
		destroyed() {
			if (this.selectComponent != null) {
				this.selectComponent.off().select2('destroy');
			}
		},
		props: {
			label: {
				type: String,
			},
			field: {
				type: String,
				required: true,
			},
			options: {
				type: Array,
				required: true,
			},
			value: {
				type: [String, Number],
				required: false,
			},
			required: {
				type: Boolean,
				default: false,
			},
		},
	};
</script>


