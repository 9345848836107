export default {
	marketplaces: null,
	marketplace: (typeof localStorage.marketplace !== 'undefined') ? JSON.parse(localStorage.getItem('marketplace')) : {
		colorPalette: {
			dark: '#222222',
			semiDark: '#2D3E65',
			medium: '#F1592A',
			semiBright: '#BEBCBC',
			bright: '#F8F8F8',
			gradientPrimary: 'linear-gradient(90deg, rgba(247,69,69,1) 0%, rgba(255,154,80,1) 100%)'
		},
		locales: ['fr', 'en'],
		logoId: process.env.NODE_ENV === 'development' ? 'VUXZW1ZSR0BBYC9W3EO00KAGILTT0111' : '6FAGWA0XZBB69E200099YEZZUAS09999',
		logo: {
			id: process.env.NODE_ENV === 'development' ? 'VUXZW1ZSR0BBYC9W3EO00KAGILTT0111' : '6FAGWA0XZBB69E200099YEZZUAS09999',
			path: process.env.NODE_ENV === 'development' ? 'VUXZW1ZSR0BBYC9W3EO00KAGILTT0111.jpg' : '6FAGWA0XZBB69E200099YEZZUAS09999.jpg',
			photoIndex: null
		}
	},
	membershipTypes: (typeof localStorage.membershipTypes !== 'undefined') ? JSON.parse(localStorage.getItem('membershipTypes')) : null
}
