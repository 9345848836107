import store from '@/store'

export default function (mutation) {
	const {
		type,
		payload
	} = mutation

	switch (type) {
		case 'users/SET_MEMBER':
			if (payload) {
				store.dispatch('categories/attemptGetCompanyCategories')
			} else {
				store.commit('users/SET_MEMBER', null)
			}
			break

		default:
			break
	}
}
