export default {
	status: [{
			id: 10,
			value: "Requested"
		},
		{
			id: 11,
			value: "Paid Out"
		}, {
			id: 12,
			value: "Failed"
		}, {
			id: 13,
			value: "Waiting For Approval"
		}
	]
}
