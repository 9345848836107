const elasticsearch = require('elasticsearch');

const client = new elasticsearch.Client({
	host: process.env.VUE_APP_ELASTIC_SEARCH_CLIENT,
	apiVersion: '7.x'
})

const query = (index, id) => {
	return {
		query: {
			bool: {
				must: {
					match: {id}
				}
			}
		}
	}
}

const callback = ({
	hits: {
		hits: data
	}
}, objectId) => {
	return data.find(({
		_source: {
			id
		}
	}) => id === objectId)._source
}

const get = (index, id) => {
	return client.search({
		index,
		type: "_doc",
		body: query(index, id)
	}).then((result) => callback(result, id))
}

export default {
	client,
	query,
	callback,
	get
}
