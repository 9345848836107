import {
	request
} from '@/config'
import store from '@/store'

export default {
	async attemptGetSubAdministrator({
		commit
	}, administratorId) {
		const administrator = await request.get(`/users/adminstrators/sub/${administratorId}`).then(({
			data: {
				data: {
					administrator: response
				}
			}
		}) => response).catch(({
			response: {
				data: {
					message: error
				}
			}
		}) => {
			throw error
		}).finally(() => store.dispatch('setAppIsBusy', false))

		commit('SET_ADMINISTRATOR', administrator)
	},
	async attemptSubAdministratorCreation(_, data) {
		const {
			message,
			...details
		} = await request.post('/signup', data)
			.then(async ({
				data: response
			}) => response)
			.catch(({
				response: error
			}) => {
				throw error
			})
			.finally(() => {
				store.dispatch('setAppIsBusy', false)
			})

		store.commit('SET_MESSAGE', message)

		return details
	},
	async attemptSubAdministratorUpdate(_, {
		data,
		administratorId
	}) {
		const response = await request.patch(`/users/adminstrators/sub/${administratorId}`, data)
			.then(({
				data: {
					message
				}
			}) => message)
			.catch(({
				response: error
			}) => {
				throw error
			}).finally(() => store.dispatch('setAppIsBusy', false))

		store.commit('SET_MESSAGE', response)

		return response
	},
	async attemptDeleteAdministrator(_, administratorId) {
		const response = await request.delete(`/users/adminstrators/sub/${administratorId}`)
			.then(({
				data: {
					message
				}
			}) => message)
			.catch(({
				response: error
			}) => {
				throw error
			}).finally(() => store.dispatch('setAppIsBusy', false))

		store.commit('SET_MESSAGE', response)

		return response
	}
}
