<template>
	<div>
		<form class="banners" v-if="banner" @submit.prevent="updateBanner">
			<breadcrumb :label="`${$t('edit-banner')} - ${$t(type.toLowerCase())}`">
				<button-component :label="$t('save')" :busy="isBusy" :button-class="'btn btn-primary btn-sm mr-2'"></button-component>
				<router-link class="btn btn-white btn-sm shadow-sm" :to="{ name: 'dashboard.banners'}" exact>
					{{$t('cancel')}}
				</router-link>
			</breadcrumb>

			<div class="row">
				<div class="col-xl-5">
					<banners-view-component :data="form.banner" :color='form.color' :font='form.font' :image="form.photo" :type="type" :is-preview="false"></banners-view-component>
				</div>
				<div class="col-xl-7 border-left">
					<card-component :waves="true">
						<fieldset>
							<legend class="w-100">{{$t(type.toLowerCase())}}</legend>
							<template v-for="(section, index) in form.banner.sections">
								<div v-if="section.type !== 'IMAGE'" class="form-row" v-bind:key="index">
									<div class="form-group col-2">
										<p class="form-label">{{ $t(section.type.toLowerCase()) }}</p>
									</div>
									<div class="form-group col-3">
										<label>{{$t('english')}}</label>
										<input v-model="section.enValue" :type="section.type === 'BUTTON-LINK' ? 'url' : 'text'" class="form-control form-control-sm shadow-sm" required>
										<div class="invalid-feedback d-block" v-html="validation.message"></div>
									</div>
									<div class="form-group col-3" v-if='marketplace.domain !== "ben.usgreenchamber.com"'>
										<label>{{$t('french')}}</label>
										<input v-model="section.frValue" :type="section.type === 'BUTTON-LINK' ? 'url' : 'text'" class="form-control form-control-sm shadow-sm" required>
										<div class="invalid-feedback d-block" v-html="validation.message"></div>
									</div>
									<div class="form-group col-3">
										<label>{{$t('spanish')}}</label>
										<input v-model="section.esValue" :type="section.type === 'BUTTON-LINK' ? 'url' : 'text'" class="form-control form-control-sm shadow-sm" required>
										<div class="invalid-feedback d-block" v-html="validation.message"></div>
									</div>
									<div v-if="section.type === 'BUTTON-LINK'" class="col-10 offset-2">
										<div class="custom-control custom-checkbox">
											<input type="checkbox" class="custom-control-input" id="open-in-new-tab" v-model="form.banner.newTab">
											<label class="custom-control-label" for="open-in-new-tab">{{$t('new-tab')}}</label>
										</div>
									</div>
								</div>
							</template>
							<div class="form-row">
								<div class="form-group col-12">
									<p class="form-label">{{$t('background-color')}} : <verte style="position:absolute;display: contents" v-model='form.color' picker="wheel" model="hex"></verte></p>
								</div>
							</div>
							<div class="form-row">
								<div class="form-group col-12">
									<p class="form-label">{{$t('font-color')}} : <verte style="position:absolute;display: contents" v-model='form.font' picker="wheel" model="hex"></verte></p>
								</div>
							</div>
							<div class="form-row">
								<div class="form-group col-2">
									<p class="form-label">{{$t('banner-image')}}</p>
								</div>
								<div class="form-group col-md-5">
									<label>{{$t('file')}}</label>
									<input name="photo" @change="setPhoto" type="file" max="1" class="form-control-file" accept="image/x-png,image/gif,image/jpeg">
									<div class="invalid-feedback d-block" v-html="validation.message"></div>
								</div>
							</div>
							<banners-photo-crop-component :crop="crop" @save="setCroppedPhoto"></banners-photo-crop-component>
						</fieldset>
					</card-component>
				</div>
			</div>
		</form>
		<div v-else>
			<div v-if="isBusy" class="spinner">
				<spinner-component></spinner-component>
			</div>
			<error-component v-else :label="$t(`${type.toLowerCase()}-banner-not-created`)" :button="$t('create-banners')" :return-to="{ name: 'dashboard.banners.create' }"></error-component>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	.custom-checkbox {
		margin-top: -1rem;
		margin-bottom: 1rem;
	}
</style>

<script>
	import { mapGetters, mapActions } from 'vuex';
	import { activity, validation } from '@/mixins';
	import { find } from 'lodash';
	import Verte from 'verte';
	import 'verte/dist/verte.css';

	export default {
		name: 'BannersEdit',
		mixins: [activity, validation],
		components: {
			Verte
		},
		mounted() {
			this.fetchData();
		},
		data() {
			const { id, type } = this.$route.params;
			const { toLowerCase, toStartCase } = this.$options.filters;
			const label = toStartCase(toLowerCase(type));

			return {
				id,
				type,
				label,
				banner: null,
				form: {
					isInitialized: false,
					banner: {
						sections: [
							{
								type: 'TYPE',
								enValue: null,
								frValue: null,
								esValue: null
							},
							{
								type: 'TITLE',
								enValue: null,
								frValue: null,
								esValue: null
							},
							{
								type: 'SUB-TITLE',
								enValue: null,
								frValue: null,
								esValue: null
							},
							{
								type: 'BUTTON-TEXT',
								enValue: null,
								frValue: null,
								esValue: null
							},
							{
								type: 'BUTTON-LINK',
								enValue: null,
								frValue: null,
								esValue: null
							},
						],
						newTab: null,
					},
					photo: null,
					color: null,
					font: null
				},
				crop: {
					type: null,
					image: null,
				},
			};
		},
		computed: {
			...mapGetters({
				isBusy: 'isBusy',
				marketplaceBanners: 'banners/banners',
				marketplace: 'marketplace/marketplace'
			}),
		},
		methods: {
			...mapActions({
				setAppState: 'setAppIsBusy',
				attemptUpdateBanner: 'banners/attemptUpdateBanner',
			}),
			fetchData() {
				this.banner = find(
					this.marketplaceBanners,
					(banner) => banner.type === this.type
				);

				console.log(this.marketplace)
				let color
				let font = this.marketplace.colorPalette.bannerFontColor
				if(this.type === 'TOP' && this.marketplace.colorPalette.bannerBackground) {
					color = this.marketplace.colorPalette.bannerBackground;
				}else if (this.type === 'BOTTOM' && this.marketplace.colorPalette.bannerBottomBackground){
					color = this.marketplace.colorPalette.bannerBottomBackground;
				}

				if(this.marketplace.colorPalette.bannerFontColor) {
					color = this.marketplace.colorPalette.bannerBackground;
				}

				if (this.type === 'TOP'){
					font = this.marketplace.colorPalette.bannerFontColor
				}else if(this.type === 'BOTTOM'){
					font = this.marketplace.colorPalette.bannerBottomFontColor
				}


				const { bannerLocales: sections, newTab } = this.banner;

				this.form = {
					...this.form,
					color,
					font,
					banner: { sections, newTab },
					isInitialized: true
				}
			},
			setPhoto(event) {
				if (event) {
					const {
						files: [file],
					} = event.target;

					this.form.photo = file;
					this.crop = {
						type: 'photo',
						image: file,
					};

					window.$('#crop-banner-photo').modal('show');
				}
			},
			setCroppedPhoto({ type, image }) {
				if (type && image) {
					this.form[type] = image;

					window.$('#crop-banner-photo').modal('hide');
				}
			},
			cancelCroppedPhoto({ type, image }) {
				this.form[type] = image;
			},
			updateBanner() {
				this.setAppState(true);

				this.attemptUpdateBanner({
					id: this.id,
					data: {
						...this.form,
						photo: this.form.photo,
						marketplaceId : this.marketplace.id,
						type: this.type
					},
				})
					.then(() => {
						this.hasActivity = false;
						this.shouldWatch = false;
						this.$router.push({
							name: 'dashboard.banners',
						});
						// this.fetchData();
					})
					.catch((e) => this.validationErrors(e));
			},
		},
	};
</script>
