import store from '@/store'

export default [{
		path: 'fees/new',
		name: 'dashboard.transactions.fees.create',
		component: () => import('@/views/dashboard/transactions/fees/FeesCreate.vue'),
		beforeEnter: (to, from, next) => {
			const {
				create
			} = store.getters['auth/permissions'].transactions.fees

			if (create) {
				return next()
			}

			return next({
				name: 'dashboard'
			})
		},
		meta: {
			parent: 'transactions.fees'
		}
	},
	{
		path: 'fees',
		name: 'dashboard.transactions.fees.index',
		component: () => import('@/views/dashboard/transactions/fees/FeesView.vue'),
		beforeEnter: (to, from, next) => {
			const {
				report
			} = store.getters['auth/permissions'].transactions.fees

			if (report) {
				return next()
			}

			return next({
				name: 'dashboard'
			})
		},
		meta: {
			parent: 'transactions.fees'
		}
	}
]
