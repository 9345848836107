import {
	mapGetters
} from 'vuex'

import '@/assets/scss/_table.scss';


function getInitialState(instance){

	const initialState = {
		state: {
			perPage: 10,
			page: 1,
			sort: {
				index: null,
				direction: null
			}
		},
		searchTerm: "",
		isFetching: true,
		isInitializing: true
	}

	let initialQuery = {}


	const query = instance.$route.query
	const { page, sort, load, search } = query

	if(page && !isNaN(+page)){
		initialState.state.page = +page
	} else {
		initialQuery.page = 1
	}

	if(load && !isNaN(+load)){
		initialState.state.perPage = +load
	} else {
		initialQuery.load = 10
	}

	if(sort){
		try {
			const decryptedSort = window.atob(sort)
			const [ index, direction ] = decryptedSort.split("-")

			if(typeof +index === "number" && direction){
				initialState.state.sort.index = +index
				initialState.state.sort.direction = direction
			} else {
				initialQuery = {
					...query,
					sort: null
				}
			}
		} catch(err){
			console.log({ err })
			initialQuery = {
				...query,
				sort: null
			}
		}
	}

	if(search){
		initialState.searchTerm = search
	}

	if(Object.keys(initialQuery).length > 0){
		const query = {}
		Object.keys(initialQuery).forEach(key => {
			if(initialQuery[key]){
				query[key] = initialQuery[key]
			}
		})
		instance.$router.push({ query })
	}

	return initialState
}


export default {
	data() {
		return getInitialState(this)
	},
	computed: {
		...mapGetters({
			token: 'auth/token',
			user: 'auth/user',
			marketplace: 'marketplace/marketplace',
			locale: 'locale'
		}),
		marketplaceId() {
			const {
				id
			} = this.marketplace;
			return id;
		},
		groupAccess() {
			if (this.user.permissions.groupAccess) {
				const filtered = this.marketplace.partners
					.filter((part) => this.user.permissions.groupAccess.includes(part.categoryId))
					.map(p => p.id)

				return filtered
			}else {
				return null
			}
		},
		topCompanyId() {
			const {
				topCompanyId
			} = this.marketplace;
			return topCompanyId;
		}
	},
	methods: {
		setPerPage(perPage){
			this.state.perPage = perPage
		},
		setSort(sort){
			this.state.sort = sort
		},
		setSearchTerm(term){
			this.searchTerm = term
		},
		setIsFetching(value){
			this.isFetching = value
		},
		resetFilters(){
			const query = {
				page: this.state.page,
				load: this.state.perPage
			}
			this.$router.push({ query })

		},
		validateResponse({ lastPage }){
			if(!lastPage || this.state.page > lastPage){
				this.state.page = 1
			}
		},
		scrollTableToTop(){
			const tableBodyEl = document.querySelector(`.table-body`);
			tableBodyEl.scrollTo(0,0)
		}
	}
}
