<template>
    <div>
		<negotiations-table-filters v-show="state.isFiltering" :status="status" @setStatus="setStatus"></negotiations-table-filters>
		<table-component
            :header="header"
            :data="data"
            :total="total"
            :cta="cta"
            :state="state"
            :id="'negotiations'"
			:searchTerm="searchTerm"
			:isFetching="isFetching"
			:isInitializing="isInitializing"
			:options="{
				hideFilters: true
			}"
			@setSort="setSort"
			@setPerPage="setPerPage"
			@setSearchTerm="setSearchTerm"
        >
        </table-component>
    </div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { request } from '@/config';
	import { table, transactions } from '@/mixins';

	export default {
		name: 'NegotiationsTable',
		mixins: [table, transactions.negotiations],
		data() {
			return {
				header: [
					{ label: this.$t('buyer'), type: 'email', sort: false, key: "buyer.email" },
					{ label: this.$t('seller'), type: 'email', sort: false },
					{ label: this.$t('resource'), type: 'text', sort: false },
					{ label: this.$t('price'), type: 'text', sort: false },
					{ label: this.$t('start-date'), type: 'date', sort: false },
					{ label: this.$t('end-date'), type: 'date', sort: false },
					{ label: this.$t('status'), type: 'html', sort: false },
					{ label: this.$t('created-date'), type: 'date', sort: false },
					{ label: 'ID', type: 'id', sort: false },
				],
				data: {
					table: [],
					metaData: [],
				},
				status: 0,
				total: 0,
				cta: [
					{
						label: () => this.$t('view'),
						onClick: (negotiation) => {
							const id = negotiation[8];

							this.viewResource(id);
						},
					},
					{
						label: (negotiation, { status }) => {
							return status === 1 ? this.$t('cancel') : this.$t('re-activate');
						},
						onClick: (negotiation, { status }) => {
							if ([1, 3].includes(status)) {
								return this.updateNegotiation(
									negotiation[8],
									status === 1 ? this.$t('cancel') : this.$t('re-activate')
								);
							}
						},
						style: (negotiation, { status }) => {
							let style = null;
							if ([1, 3].includes(status)) {
								style = status === 1 ? 'danger' : 'success';
							}

							return style ? style : 'white disabled';
						},
					},
				],
			};
		},
		mounted() {
			this.getData();
		},
		computed: {
			...mapGetters({
				marketplace: 'marketplace/marketplace'
			})
		},
		watch: {
			'state': {
				handler: function(){
					this.getData()
				},
				deep: true
			},
			'searchTerm': function(){
				this.getData()
			},
			'status': function(){
				this.getData()
			}
		},
		methods: {
			...mapActions({
				attemptNegotiationUpdate:
					'transactions/negotiations/attemptNegotiationUpdate',
			}),
			viewResource(negotiationId) {
				this.$router.push({
					path: `/dashboard/transactions/negotiations/${negotiationId}`,
				});
			},
			updateNegotiation(negotiationId, type) {
				const answer = window.confirm(
					`Do you really want to ${type} this negotiation?\nNegotiation ID: ${negotiationId}`
				);

				if (answer) {
					this.attemptNegotiationUpdate({ negotiationId, type })
						.then(() => this.getData())
						.catch((e) => this.validationErrors(e));
				}
			},
			setStatus(status){
				this.status = status
			},
			setData(data) {
				this.data = data;
			},
			getData: async function () {
				const { toCurrency, placeholder } = this.$options.filters;

				try {
					const data = {
						table: [],
						metaData: [],
					};

					const requestParams = {
						marketplaceId: this.marketplaceId,
						locale: this.locale,
						groupAccess: this.groupAccess,
						isDatatable: true,
						page: this.state.page,
						load: this.state.perPage,
						topCompanyId: this.marketplace.topCompanyId,
						q: this.searchTerm,
						status: this.status
					}

					if(this.state.sort.index != null && this.state.sort.direction != null){
						requestParams.orderBy = this.header[this.state.sort.index].key
						requestParams.orderDirection = this.state.sort.direction.toUpperCase()
					}

					const { data: negotiations, total } = await request
						.get('negotiations', {
							headers: {
								Authorization: `Bearer ${this.token}`,
							},
							params: requestParams
						})
						.then(({ data: { data: response } }) => response);

					if (negotiations){
						negotiations.forEach((item) => {
							const title = item.resource.locale.length > 0 ? this.getObjectLocale(item.resource.locale).title : ""
							data.table.push([
								item.buyer.email,
								item.seller.email,
								placeholder(title.toString()),
								toCurrency(item.finalPrice),
								item.startDate,
								item.endDate,
								this.negotiationStatus(item.status),
								item.createdAt,
								item.id,
							]);

							data.metaData.push({
								status: item.status,
							});
						});
					}

					this.data = data;
					this.total = total;
					this.isFetching = false;
					this.isInitializing = false;
					this.scrollTableToTop()
				} catch (err) {
					console.log({ err });
				}
			},
		},
	};
</script>


