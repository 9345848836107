<template>
	<div>
		<form ref="form" class="w-50" @submit.prevent="changePassword">
			<fieldset class="row">
				<legend class="h6 col-12">{{$t('change-password')}}</legend>
				<div class="form-group col-12">
					<label>{{$t('current-password')}}</label>
					<input v-model="form.currentPassword" type="password" class="form-control form-control-sm shadow-sm" :class="validation.inputs.currentPassword">
					<div class="invalid-feedback d-block" v-html="validation.errors.currentPassword"></div>
				</div>
				<div class="form-group col-12">
					<label>{{$t('new-password')}}</label>
					<input v-model="form.password" type="password" class="form-control form-control-sm shadow-sm" :class="validation.inputs.password">
					<div class="invalid-feedback d-block" v-html="validation.errors.password"></div>
				</div>
				<div class="form-group col-12">
					<label>{{$t('confirm-password')}}</label>
					<input v-model="form.confirmPassword" type="password" class="form-control form-control-sm shadow-sm" :class="validation.inputs.confirmPassword">
					<div class="invalid-feedback d-block" v-html="validation.errors.confirmPassword"></div>
				</div>
			</fieldset>
			<button-component :label="'Change Password'" :busy="isBusy"></button-component>
		</form>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex';
	import { validation } from '@/mixins';

	export default {
		name: 'ChangePasswordComponent',
		mixins: [validation],
		data() {
			return {
				form: {
					currentPassword: null,
					password: null,
					confirmPassword: null,
				},
			};
		},
		computed: {
			...mapGetters({
				isBusy: 'isBusy',
				message: 'profile/message',
			}),
		},
		methods: {
			...mapActions({
				setAppState: 'setAppIsBusy',
				setMessage: 'profile/setMessage',
				attemptChangePassword: 'profile/attemptChangePassword',
			}),
			changePassword() {
				this.setAppState(true);

				this.attemptChangePassword(this.form)
					.then(() => this.resetForm())
					.catch((e) => {
						this.validationErrors(e);
					})
					.finally(() => this.setAppState(false));
			},
		},
	};
</script>

<style>
</style>
