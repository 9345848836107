import {
	mapGetters
} from 'vuex'

import '@/assets/scss/_table.scss';

export default {
	data() {
		return {
			table: null,
			isFiltering: false,
			language: {
				decimal: "",
				emptyTable: this.$t('no-data'),
				editMaster: this.$t('edit-master'),
				info: this.$t('pagination'),
				infoEmpty: "Affichage de 0 à 0 de 0 entrées",
				infoFiltered: "",
				infoPostFix: "",
				thousands: ",",
				lengthMenu: "_MENU_",
				loadingRecords: this.$t('loading'),
				processing: this.$t('fetch'),
				search: "",
				searchPlaceholder: this.$t('search'),
				zeroRecords: this.$t('no-data'),
				paginate: {
					"first": "<<",
					"last": ">>",
					"next": ">",
					"previous": "<"
				},
				aria: {
					"sortAscending": ": activate to sort column ascending",
					"sortDescending": ": activate to sort column descending"
				}
			},
			config: {
				responsive: false,
				scrollY: window.innerHeight * .72,
				scrollX: true,
				scrollCollapse: true,
				fixedColumns:   {
					leftColumns: 0,
					rightColumns: 1
				}
			}
		}
	},
	computed: {
		...mapGetters({
			token: 'auth/token',
			user: 'auth/user',
		}),
		marketplaceId() {
			const { id } = this.user.companyInfo.marketplace;
			return id;
		}
	},
	mounted(){
		window.setuid = (email) => this.setuid(email)
	},
	methods: {
		initDataTableButtons: function (table, position, title = 'Document', exportColumns = []) {
			new window.$.fn.dataTable.Buttons(table, {
				buttons: [{
						extend: 'copyHtml5',
						title,
						exportOptions: {
							columns: exportColumns
						}
					},
					{
						extend: 'excelHtml5',
						title,
						exportOptions: {
							columns: exportColumns
						}
					},
					{
						extend: 'csvHtml5',
						title,
						exportOptions: {
							columns: exportColumns
						}
					},
					{
						extend: 'pdfHtml5',
						title,
						exportOptions: {
							columns: exportColumns
						}
					}
				]
			})

			table.buttons().container().appendTo(window.$(position, table.table().container()))
		},
		initDataTableFilter: function (table, button, position) {
			new window.$.fn.dataTable.SearchPanes(table, {})

			table.searchPanes.container().prependTo(`${position}`)

			window.$(
				'<div class="dt-buttons d-inline-flex">' +
				'<button type="button" class="btn btn-white btn-sm shadow-sm filter"><em data-feather="filter"></em> Filtres </button>' +
				'</div>'
			).prependTo(window.$(button, table.table().container()))

			window.$(`${button} .dt-buttons .filter`).on("click", () => {
				this.isFiltering = !this.isFiltering
			})

			window.feather.replace()
		},
		initDataTableGroupSort: function (table, $, groupColumn) {
			window.$(`${$} tbody`).on('click', 'tr.group', () => {
				const currentOrder = table.order()[0];
				if (currentOrder[0] === groupColumn && currentOrder[1] === 'asc') {
					table.order([groupColumn, 'desc']).draw();
				} else {
					table.order([groupColumn, 'asc']).draw();
				}
			});
		},
		setLanguage() {
			return this.language
		},
		reloadTable() {
			this.table.ajax.reload()
		},
		setuid(username){
			this.$router.push({
				name: 'dashboard.users.set-uid',
				query: { username },
			});
		},
		scrollDrag: function(id){
			var tableBody = document.querySelector (`#${id} .dataTables_scrollBody`);
			var headerTable = document.querySelector (`#${id} .dataTables_scrollHead`);
			var curDown = false
			var oldScrollLeft = 0;
			var curXPos = 0;
			if (tableBody) {
				tableBody.addEventListener("mousemove", function (e) {
					if (curDown === true) {
						tableBody.scrollLeft = oldScrollLeft + (curXPos - e.pageX);
						headerTable.scrollLeft = oldScrollLeft + (curXPos - e.pageX);
					}
				})
				tableBody.addEventListener("mousedown", function (e) {
					curDown = true;
					curXPos = e.pageX;
					oldScrollLeft = tableBody.scrollLeft;
				})
				tableBody.addEventListener("mouseup", function () {
					curDown = false;
				})
				tableBody.addEventListener("scroll", function () {
					headerTable.scrollLeft = tableBody.scrollLeft;
				})
			}
		}
	}
}
