<template>
	<div class="nk-content">
		<div class="nk-block nk-block-middle wide-md mx-auto">
			<div class="nk-block-content nk-error-ld text-center">
				<img class="nk-error-gfx" src="/demo7/images/gfx/error-404.svg" alt="">
				<div class="wide-xs mx-auto">
					<h3 class="nk-error-title">{{ title }}</h3>
					<p class="nk-error-text">{{ label }}</p>
					<router-link :to="returnTo" class="btn btn-sm btn-primary shadow-sm mt-2">{{ button }}</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
	.nk-content {
		padding: 0 !important;
		min-height: 80vh;
		display: flex;
		flex-direction: column;
	}

	.nk-block-middle {
		margin-top: auto;
		margin-bottom: auto;
	}

	.nk-error-ld {
		padding: 2rem 1rem;
	}

	.nk-error-title {
		padding-bottom: 1rem;
	}

	.nk-error-text {
		font-size: 1rem;
	}
</style>

<script>
	export default {
		name: 'ErrorComponent',
		props: {
			title: {
				type: String,
				default: 'Oops! Why you’re here?',
			},
			label: {
				type: String,
				required: false,
			},
			button: {
				type: String,
				default: 'Go Back',
			},
			returnTo: {
				type: Object,
				default() {
					return { name: 'dashboard' };
				},
			},
		},
	};
</script>


