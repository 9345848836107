import measureTypes from '../../../../json/measureTypes.json'
import specificityTypes from '../../../../json/specificityTypes.json'

export default {
	type: {
		simple: ['TEXT', 'NUMBER', 'DATE','RANGE','UNIQUE'],
		complex: ['SELECT','MULTI_SELECT', 'MEASURE']
	},
	options: {
		specificity: {
			type: {
				type: null,
				defaultUnit: null,
				required: false,
				name: null
			},
			locale: {
				name: null,
				options: null,
				placeholder: null,
			}
		}
	},
	specificityTypes,
	measureTypes
}
