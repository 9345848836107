<template>
	<div v-if="details" class="modal" id="resource-details" data-keyboard="false" tabindex="-1" aria-labelledby="resource-detailsLabel" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="resource-detailsLabel">{{$t('details')}}</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="head">
						<div class="category">
							<span class="icon">
								<em class="bi bi-circle-fill"></em>
							</span>
							<span>{{ category.title }}</span>
						</div>
						<h3>{{ locale.title|toStartCase }}</h3>
						<h5 class="price">{{ resource.price|toCurrency }}<span v-if="hasPriceTerm">{{priceTerm.text}}</span></h5>
					</div>

					<div class="detail">
						<label>{{$t('sold-by')}}</label>
						<a>{{ company.locale.title }}</a>
					</div>
					<div class="detail">
						<label>Description</label>
						<!-- <p v-html="locale.description"></p> -->
					</div>
					<div class="detail">
						<label>{{$t('location')}}</label>
						<p>{{ address.text }}</p>
						<div class="map">
							<mapbox-map style="height: 300px" :access-token="token" map-style="mapbox://styles/mapbox/light-v10" :center="[address.coordinates.lng, address.coordinates.lat]" :zoom="10">
								<mapbox-marker :lng-lat="[address.coordinates.lng, address.coordinates.lat]">
									<div class="marker">
										<div class="area"></div>
									</div>
								</mapbox-marker>
							</mapbox-map>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';

	export default {
		name: 'NegotiationResourceComponent',
		data() {
			return {
				token: process.env.VUE_APP_MAPBOX_TOKEN,
			};
		},
		watch: {
			priceTerm: function (value) {
				console.log(value);
			},
		},
		computed: {
			...mapGetters({
				types: 'resources/types',
				priceTerms: 'resources/priceTerms',
				categories: 'categories/resourcesCategories',
			}),
			priceTerm() {
				return this.priceTerms.find(
					({ id }) => id == this.details.priceTerm
				);
			},
			hasPriceTerm() {
				return !['negotiable', 'sell'].includes(this.priceTerm.name);
			},
			resource() {
				return this.details.resource;
			},
			category() {
				if (this.resource) {
					const {
						category: { categoryLocale: locales },
					} = this.resource.resourceCategory[0];

					return this.getObjectLocale(locales);
				}

				return null;
			},
			locale() {
				if (this.resource) {
					const { locale: locales } = this.resource;

					return this.getObjectLocale(locales);
				}

				return null;
			},
			address() {
				if (this.resource) {
					const {
						address: text,
						latitude: lat,
						longitude: lng,
						//location: { lat, lon: lng },
					} = this.resource;

					return {
						text,
						coordinates: {
							lng,
							lat,
						},
					};
				}

				return null;
			},
			company() {
				if (this.details) {
					const {
						id,
						streetAddress,
						location: coordinates,
					} = this.resource.company;

					return {
						id,
						address: {
							text: streetAddress,
							coordinates,
						},
						locale: {
							title: this.details.companyInfo.seller.title,
						},
					};
				}

				return null;
			},
		},
		props: {
			details: { type: Object },
		},
	};
</script>

<style lang="scss">
	.head {
		margin: 0rem 0rem 1rem 0;

		.category {
			font-size: 12px;
			display: flex;

			span {
				&.icon {
					font-size: 5px;
					color: var(--color-semi-dark);
					align-self: center;
				}
				margin-left: 0.2rem;
			}
		}

		.price {
			color: var(--color-medium);
		}
	}

	.detail {
		margin-bottom: 1rem;

		label {
			display: inline-block;
			margin-bottom: 0rem;
			color: #bebcbc;
			font-size: 12px;
		}

		a,
		p {
			display: block;
			margin-top: 0;
			margin-bottom: 1rem;
		}

		.map {
			.marker {
				position: relative;
				z-index: 3;
				width: 0.938rem;
				height: 0.938rem;
				background: var(--color-medium);
				border-radius: 50%;
				opacity: 1 !important;

				.area {
					width: 70px;
					height: 70px;
					background: var(--color-medium);
					border-radius: 50%;
					opacity: 0.3;
					position: absolute;
					top: 0;
					left: -1.75rem;
					right: 0;
					bottom: 0;
					margin: auto;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}
</style>
