export default {
	SET_TOKEN(state, token) {
		state.token = token
	},
	SET_USER(state, user) {
		state.user = user
	},
	SET_USER_ROLE(state, role) {
		state.role = role
	},
	SET_USER_PERMISSIONS(state, permissions) {
		state.permissions = permissions
	},
	SET_MARKETPLACE(state, marketplace) {
		state.marketplace = marketplace
	}
}
