import store from '@/store'

export default {
	banners() {
		const {
			banners
		} = store.state.marketplace.marketplace

		return banners
	}
}
