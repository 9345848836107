import {
	request
} from '@/config'
import store from '@/store'
import {
	serialize
} from 'object-to-formdata';
import {
	snakeCase
} from 'lodash'

export default {
	async attemptGetResourceCategories({
		commit
	}, isMaster = false) {
		const {
			id: marketplaceId
		} = store.state.marketplace.marketplace

		const {
			locale
		} = store.state.app

		const categories = await request.get('/categories/resource', {
			params: {
				marketplaceId,
				locale
			}
		}).then(({
			data: {
				data: {
					categories: response
				}
			}
		}) => response).finally(() => store.dispatch('setAppIsLoading', false))

		isMaster ? commit('SET_RESOURCE_MASTER_CATEGORIES', categories) : commit('SET_RESOURCE_CATEGORIES', categories)

		return categories
	},
	async attemptGetResource(_, data) {
		return await request.get(`/resources/sample/resource`, {
			params: {
				categoryId: data.categoryId,
			}
		}).then(({
			data: {
				data: {
					resource: response
				}
			}
		}) =>
		response).finally(() => store.dispatch('setAppIsLoading', false))
		.catch(function (error){
			if(error.response){
				console.log(error.response)
			}
		})
	},
	async attemptGetSpecs(_,data){
		return await request.get(`/specificities/resource/specs-list`, {
			params: {
				resourceId: data.resourceId
			}
		}).then(({
			data: {
				data: {
					specs: response
				}
			}
		}) => response).finally(() => store.dispatch('setAppIsBusy', false))
	},
	async attemptGetResourceCategory({
		commit
	}, categoryId) {
		const category = await request.get(`/categories/resource/${categoryId}`).then(({
			data: {
				data: {
					category: response
				}
			}
		}) => response).finally(() => store.dispatch('setAppIsBusy', false))

		return commit('SET_RESOURCE_CATEGORY', category)
	},
	async attemptResourceCategoryCreation(_, data) {
		const response = await request.post('/categories/resource', data, {
				transformRequest: [function (data) {
					return serialize(data, {
						indices: true
					});
				}],
			})
			.then(({
				data: category
			}) => category)
			.catch(({
				response: error
			}) => {
				throw error
			})
			.finally(() => store.dispatch('setAppIsBusy', false))

		const {
			message
		} = response

		store.commit('SET_MESSAGE', message)

		return response
	},
	async attemptResourceCategoryUpdate(_, {
		data,
		categoryId
	}) {
		const response = await request.patch(`/categories/resource/${categoryId}`, data, {
				transformRequest: [function (data) {
					return serialize(data, {
						indices: true
					});
				}],
			})
			.then(({
				data: {
					message
				}
			}) => {
				store.dispatch('categories/attemptGetResourceCategory', categoryId);
				return message
			})
			.catch(({
				response: error
			}) => {
				throw error
			}).finally(() => store.dispatch('setAppIsBusy', false))

		store.commit('SET_MESSAGE', response)

		return response
	},
	async attemptResourceCategoryDelete(_, categoryId) {
		const response = await request.delete(`/categories/resource/${categoryId}`)
			.then(({
				data: {
					message
				}
			}) => message)
			.catch(({
				response: error
			}) => {
				throw error
			}).finally(() => store.dispatch('setAppIsBusy', false))

		store.commit('SET_MESSAGE', response)

		return response
	},
	async attemptGetCompanyCategories({
		commit
	}) {
		const {
			id: marketplaceId
		} = store.state.auth.user.companyInfo.marketplace
		const {
			appLanguage: locale
		} = store.state.app

		const categories = await request.get('/categories/company', {
			params: {
				marketplaceId,
				locale
			}
		}).then(({
			data: {
				data: {
					categories: response
				}
			}
		}) => response).finally(() => store.dispatch('setAppIsLoading', false))

		commit('SET_COMPANY_CATEGORIES', categories)

		return categories
	},
	async attemptGetCompanyCategory({
		commit
	}, categoryId) {
		const category = await request.get(`/categories/company/${categoryId}`).then(({
			data: {
				data: {
					category: response
				}
			}
		}) => response).catch(({
			response: error
		}) => {
			throw error
		}).finally(() => store.dispatch('setAppIsBusy', false))

		return commit('SET_COMPANY_CATEGORY', category)
	},
	async attemptResourceUploadTemplateDownload(_, {
		categoryId,
		categoryName
	}) {
		const date = new Date().toISOString()
		const fileName = `${categoryName.trim()}-${date}.xlsx`

		const {
			headers
		} = store.state.resources

		const {
			marketplace: {
				locales
			}
		} = store.state.marketplace

		const resourceLocales = locales.map((locale) => {
			return [
				`title_${locale}`, `description_${locale}`
			]
		})

		const {
			specificitiesList
		} = store.state.categories.resourceCategory

		const specificities = specificitiesList.map(specificity => {
			const name = snakeCase(specificity.name)

			return `spec_${name}`
		})

		const csvHeaders = `${[...headers,...resourceLocales,...specificities].join(',')}\n`

		return {
			categoryId,
			fileName,
			csvHeaders
		}
	},
	async attemptGetCategoryResources(_, categoryId) {
		return request.get(`/categories/resource/${categoryId}/resources`)
			.then(({
				data: {
					data: {
						categoryResources
					}
				}
			}) => {
				return categoryResources.map(({
					id,
					locale: resourceLocale
				}) => {
					let text = '';
					const locale = resourceLocale.filter((el) => el.locale === store.state.app.locale)

					if (locale.length) {
						text = locale[0].title
					} else {
						text = resourceLocale[0].title
					}

					return {
						id,
						text
					}
				})
			})
			.catch(({
				response: error
			}) => {
				throw error
			}).finally(() => store.dispatch('setAppIsBusy', false))
	}
}
