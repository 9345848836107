<template>
    <div>
        <breadcrumb :label="$t('resources')">
			<a href="#export-as" class="btn btn-outline-primary btn-sm shadow-sm mr-2" data-toggle="modal">
				<em class="bi bi-download"></em> {{$t("export")}}
			</a>
            <router-link class="btn btn-outline-primary btn-sm shadow-sm" :to="{ name: 'dashboard.resources.import'}">
                <em class="bi bi-upload"></em> {{$t("import")}}
            </router-link>
        </breadcrumb>

		<export-as-modal-component></export-as-modal-component>

        <resources-table-component></resources-table-component>
    </div>
</template>

<script>
	export default {
		name: 'ResourcesView',
	};
</script>

<style>
</style>
