<template>
	<div class="modal" :id='"previous-images"+ this.id'  tabindex="-1" aria-labelledby="previous-images" aria-hidden="true">
		<div class="modal-dialog modal-dialog-scrollable shadow modal-xl">
			<div class="modal-content" >
				<div class="modal-header">
					<h5 class="modal-title" id="view-invoiceLabel">Previously used images</h5>
				</div>
				<div class="modal-body">
					<vue-select-image :dataImages="images" @onselectimage="selectImage"></vue-select-image>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary btn-sm shadow-sm" data-dismiss="modal">{{$t('close')}}</button>
				</div>
			</div>
		</div>
	</div>
</template>

<style>
.vue-select-image__thumbnail{
	max-width: 200px;
}
</style>
<script>
import VueSelectImage from 'vue-select-image'
import "vue-select-image/dist/vue-select-image.css";

export default {
	name: 'PreviousImagesComponent',
	components: {
		'vue-select-image' : VueSelectImage
	},
	mounted() {
		this.usedImages.forEach(image=> {
			this.images.push({
				id: image.id,
				src: image.path
			})
		})
	},
	props: {
		usedImages: {
			type: Array
		},
		id:{
			type: String
		},
		type:{
			type: String
		}
	},
	methods:{
		selectImage(image){
			console.log(this.$parent.input)
			this.$emit('changeBody', {
				value : image.src,
				id: this.$parent.input.id,
				type : this.type
			})
		}
	},
	data(){
		return {
			images : [],
			currentID: this.id
		}

	}
};
</script>

<style scoped>

</style>
