import {
	request
} from '@/config'
import store from '@/store'
import {
	serialize
} from 'object-to-formdata';

export default {
	async attemptCreateBanners(dispatch, data) {
		const {
			message,
			...banners
		} = await request.post('/marketplace/banner', data, {
				transformRequest: [function (unserialized) {
					return serialize(unserialized, {
						indices: true
					});
				}],
			})
			.then(async (
				response
			) => {
				await store.dispatch('marketplace/attemptGetMarketplace')

				return response
			})
			.catch(({
				response: error
			}) => {
				throw error
			})
			.finally(() => {
				store.dispatch('setAppIsBusy', false)
			})

		store.commit('SET_MESSAGE', message)

		return banners
	},
	async attemptUpdateBanner(dispatch, {
		id,
		data
	}) {
		const {
			message,
			...banner
		} = await request.patch(`/marketplace/banner/${id}`, data, {
				transformRequest: [function (unserialized) {
					return serialize(unserialized, {
						indices: true
					});
				}],
			})
			.then(async (
				response
			) => {
				await store.dispatch('marketplace/attemptGetMarketplace')

				return response.data
			})
			.catch((
				response
			) => {
				throw response
			})
			.finally(() => {
				store.dispatch('setAppIsBusy', false)
			})

		store.commit('SET_MESSAGE', message)

		return banner
	},
}
