import style from '@/store/json/badgeStyle.json'

const {
	_class,
} = style

export default {
	methods: {
		payoutStatus(status, isBlock = false) {
			const block = isBlock ? 'd-block' : ''
			status = (!status) ? 'Unknown' : status

			return `<span class="badge badge-sm badge-dim ${
                _class[status.toLowerCase()]
            } ${block}">${status.toUpperCase()}</span>`
		},
	}
}
