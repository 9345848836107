<template>
	<div>
		<div v-show="isFiltering" id="resource-categories-table-search-pane" class="card shadow-sm mb-3"></div>
		<card-component :waves="true">
			<table class="table compact hover display responsive nowrap w-100" id="resource-categories-table" aria-describedby="resource-categories-table">
				<thead>
					<tr>
						<th scope="col">{{$t('master-category')}}</th>
						<th scope="col">{{$t('title')}}</th>
						<th scope="col">{{$t('description')}}</th>
						<th scope="col">{{$t('options')}}</th>
					</tr>
				</thead>
			</table>
		</card-component>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex';
	import { datatables } from '@/mixins';

	export default {
		name: 'ResourceCategoriesTableComponent',
		mixins: [datatables],
		mounted() {
			window.editResourceCategory = (categoryId) =>
				this.editResourceCategory(categoryId);
			window.deleteResourceCategory = (categoryId) =>
				this.deleteResourceCategory(categoryId);

			const groupColumn = 0;

			let scope = this

			this.table = window.$('#resource-categories-table').DataTable({
				processing: true,
				ajax: {
					headers: {
						Authorization: `Bearer ${this.token}`,
					},
					url: `${process.env.VUE_APP_API_URL}/categories/resource?isDatatable=true`,
					data: {
						marketplaceId: this.marketplaceId,
						locale: 'en',
					},
					dataSrc: 'data',
				},
				responsive: true,
				deferRender: true,
				columns: [
					{
						data: function (data) {
							const { categoryLocaleTitle, id } = data;

							return `${categoryLocaleTitle}_${id}`;
						},
					},
					{ data: 'childrenCategoryLocaleTitle' },
					{
						data: 'childrenCategoryLocaleDescription',
						defaultContent: 'N/A',
					},
					{ data: 'childrenId' },
				],
				columnDefs: [
					{
						visible: false,
						targets: groupColumn,
					},
					{
						targets: 1,
						width: '20%',
					},
					{
						targets: 2,
						render: (data) => this.$options.filters.truncate(data, 100),
						width: '70%',
					},
					{
						targets: 3,
						orderable: false,
						render: (data) => {
							return (
								`<button value="" type="button" class="btn btn-white btn-sm shadow-sm mr-2" onclick="editResourceCategory('${data}')"><em data-feather="edit"></em>${this.$t('edit')}</button>` +
								`<button type="button" class="btn btn-danger btn-sm shadow-sm" onclick="deleteResourceCategory('${data}')"><em data-feather="trash-2"></em>${this.$t('delete')}</button>`
							);
						},
						width: '10%',
						className: 'text-center',
					},
				],
				searchPanes: {
					columns: [0],
					cascadePanes: true,
					viewTotal: true,
				},
				language: this.setLanguage(),
				initComplete: () => {},
				drawCallback: function () {
					const api = this.api();
					const rows = api.rows({ page: 'current' }).nodes();
					let last = null;

					api.column(groupColumn, { page: 'current' })
						.data()
						.each(function (group, i) {
							if (last !== group) {
								const [name, id] = group.split('_');

								window
									.$(rows)
									.eq(i)
									.before(
										`<tr class="group"><td colspan="3">${name}<a class="btn btn-white btn-sm shadow-sm float-right" onclick="editResourceCategory('${id}')">${scope.language.editMaster}</a></td></tr>`
									);

								last = group;
							}
						});
				},
				stateSave: true,
			});

			this.initDataTableButtons(
				this.table,
				'#resource-categories-table_length',
				this.filename,
				[1, 2]
			);
			this.initDataTableFilter(
				this.table,
				'#resource-categories-table_filter',
				'#resource-categories-table-search-pane'
			);
		},
		data() {
			return {
				filename: 'Resource Categories',
			};
		},
		computed: {
			...mapGetters({
				user: 'auth/user',
			}),
			marketplaceId() {
				const { id } = this.user.companyInfo.marketplace;

				return id;
			},
		},
		methods: {
			...mapActions({
				attemptResourceCategoryDelete:
					'categories/attemptResourceCategoryDelete',
			}),
			editResourceCategory(categoryId) {
				window.location = `/dashboard/categories/resources/${categoryId}`;
			},
			deleteResourceCategory(categoryId) {
				const answer = window.confirm(
					`Do you really want to delete this resource category?\nCategory ID: ${categoryId}`
				);

				if (answer) {
					this.attemptResourceCategoryDelete(categoryId)
						.then(() => this.reloadTable())
						.catch((e) => this.validationErrors(e));
				}
			},
		},
	};
</script>

<style>
</style>
