<template>
	<div>
		<table class="table table-bordered table-striped" aria-describedby="specificities-list">
			<tr class="d-none"><th scope="col">{{$t('specificities-list')}}</th></tr>
			<tbody>
				<tr v-for="(specificityList) in specificitiesList" :key="specificityList.id">
					<td>
						<specificity-component :index="specificityList.id" :specificityList="specificityList" :validation="validation" @update="setSpecificity" :is-edit="isEdit"></specificity-component>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
	export default {
		name: 'SpecificitiesComponent',
		data() {
			return {
				specificities: [],
			};
		},
		watch: {
			specificities: {
				handler: function (value) {
					this.$emit('input', {
						field: this.field,
						value,
					});
				},
				deep: true,
			},
		},
		methods: {
			setSpecificity(value) {
				const { index, specificity } = value;

				this.specificities = [
					...this.specificities.slice(0, index),
					specificity,
					...this.specificities.slice(index + 1),
				];
			},
		},
		props: {
			field: {
				type: String,
				required: true,
			},
			specificitiesList: {
				type: Array,
				required: true,
			},
			validation: {
				type: Object,
			},
			isEdit: {
				type: Boolean,
				default: false,
			},
		},
	};
</script>

<style>
</style>
