<template>
	<div v-if="data" :class="[index !== null ? 'list' : '']">
		<ul v-for="(child, index) in data" v-bind:key="index">
			<li :class="{'list-node':!isObject(child)}" v-if="index !== 'groupAccess'" >
				{{ isObject(child) ? $t(index) : ''|toStartCase }}
				<permissions-list-component v-if="isObject(child)" :data="child" :index="index" v-bind:key="index"></permissions-list-component>
				<!-- <div >
					{{ child }}
				</div> -->

				<div v-else class="form-group mb-0">
					<div class="custom-control custom-checkbox">
						<input type="checkbox" class="custom-control-input" :id="`check-${index}-${uuid}}`" v-model="data[index]">
						<label class="custom-control-label" :for="`check-${index}-${uuid}}`">{{ $t(index) }}</label>
					</div>
				</div>
			</li>
		</ul>
	</div>
</template>

<style scoped>
	.list-node {
		/* display: inline-flex; */
		list-style: none;
		margin-left: -25 px;
	}

	.custom-control-label {
		width: 100%;
	}
</style>

<script>
	import { isObject } from 'lodash';
	import { v4 as uuidv4 } from 'uuid';

	export default {
		data() {
			return {
				uuid: uuidv4(),
			};
		},
		methods: {
			isObject(value) {
				return isObject(value);
			},
		},
		props: {
			data: {
				type: [Object, Boolean],
				default: null,
			},
			index: {
				type: String,
				default: null,
			},
		},
	};
</script>
