<template>
    <div>
        <breadcrumb :label="$t('activation-url')"></breadcrumb>

        <activation-links-table-component></activation-links-table-component>
    </div>
</template>

<script>
    export default {
        name: "ActivationLinksView",
    }
</script>

<style>

</style>
