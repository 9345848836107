<template>
    <div>
        <breadcrumb :label="$t('import-members')">
            <router-link class="btn btn-white btn-sm shadow-sm" :to="{ name: 'dashboard.users.members.index'}">
                {{$t('cancel')}}
            </router-link>
        </breadcrumb>

		<form class="row" enctype="multipart/form-data" @submit.prevent="upload">
			<div v-if="!proceed" class="col-lg-4 col-md-6 m-auto">
				<card-component :waves="true">
					<div class="d-flex flex-column -align-items-center justify-content-center">
						<div class="text-center">
							<svg xmlns="http://www.w3.org/2000/svg" height="5em" version="1.1" viewBox="-53 1 511 511.99906" width="5em">
								<path d="M 276.410156 3.957031 C 274.0625 1.484375 270.84375 0 267.507812 0 L 67.777344 0 C 30.921875 0 0.5 30.300781 0.5 67.152344 L 0.5 444.84375 C 0.5 481.699219 30.921875 512 67.777344 512 L 338.863281 512 C 375.71875 512 406.140625 481.699219 406.140625 444.84375 L 406.140625 144.941406 C 406.140625 141.726562 404.65625 138.636719 402.554688 136.285156 Z M 279.996094 43.65625 L 364.464844 132.328125 L 309.554688 132.328125 C 293.230469 132.328125 279.996094 119.21875 279.996094 102.894531 Z M 338.863281 487.265625 L 67.777344 487.265625 C 44.652344 487.265625 25.234375 468.097656 25.234375 444.84375 L 25.234375 67.152344 C 25.234375 44.027344 44.527344 24.734375 67.777344 24.734375 L 255.261719 24.734375 L 255.261719 102.894531 C 255.261719 132.945312 279.503906 157.0625 309.554688 157.0625 L 381.40625 157.0625 L 381.40625 444.84375 C 381.40625 468.097656 362.113281 487.265625 338.863281 487.265625 Z M 338.863281 487.265625 " style="stroke:none;fill-rule:nonzero;fill:#343a40;fill-opacity:1;" />
								<path d="M 305.101562 401.933594 L 101.539062 401.933594 C 94.738281 401.933594 89.171875 407.496094 89.171875 414.300781 C 89.171875 421.101562 94.738281 426.667969 101.539062 426.667969 L 305.226562 426.667969 C 312.027344 426.667969 317.59375 421.101562 317.59375 414.300781 C 317.59375 407.496094 312.027344 401.933594 305.101562 401.933594 Z M 305.101562 401.933594 " style=" stroke:none;fill-rule:nonzero;fill:#343a40;fill-opacity:1;" />
								<path d="M 140 268.863281 L 190.953125 214.074219 L 190.953125 349.125 C 190.953125 355.925781 196.519531 361.492188 203.320312 361.492188 C 210.125 361.492188 215.6875 355.925781 215.6875 349.125 L 215.6875 214.074219 L 266.640625 268.863281 C 269.113281 271.457031 272.332031 272.820312 275.667969 272.820312 C 278.636719 272.820312 281.730469 271.707031 284.078125 269.480469 C 289.027344 264.78125 289.398438 256.988281 284.699219 252.042969 L 212.226562 174.253906 C 209.875 171.78125 206.660156 170.296875 203.199219 170.296875 C 199.734375 170.296875 196.519531 171.78125 194.171875 174.253906 L 121.699219 252.042969 C 117 256.988281 117.371094 264.902344 122.316406 269.480469 C 127.511719 274.179688 135.300781 273.808594 140 268.863281 Z M 140 268.863281" style="stroke:none;fill-rule:nonzero;fill:#343a40;fill-opacity:1;" />
							</svg>
							<h3 class="tx-color-01 mt-3">{{$t('import-members')}} (CSV)</h3>
							<p class="tx-color-03 tx-13">{{$t('accepted-file',{accepted})}}</p>
						</div>

						<div class="form-group mb-0">
							<label class="wd-100p">{{$t('file')}}</label>
							<div class="custom-file">
								<input type="file" class="custom-file-input" name="file" :accept="accepted" id="file" @change="selectFile" :class="validation.inputs.data">
								<label class="custom-file-label shadow-sm" for="customFile" :data-browse="$t('select-file')" :class="validation.inputs.data">{{ fileName }}</label>
								<div class="invalid-feedback d-block" v-html="validation.errors.data"></div>
							</div>

						</div>
						<div class="form-group">
							<div class="custom-control custom-checkbox">
								<input type="checkbox" class="custom-control-input" id="send-email" v-model="form.sendPasswordEmail">
								<label class="custom-control-label" for="send-email">{{$t('send-password-email')}}</label>
							</div>
						</div>

						<div>
							<button-component :label="this.$t('upload')" :busy="isBusy" :button-class="'btn btn-primary btn-sm btn-block'"></button-component>
								<div class="or-divider" :data-content="$t('or')"></div>
							<button class="btn btn-white btn-sm btn-block shadow-sm" type="button" @click="checkFile">{{$t('edit-file-content')}}</button>
						</div>
					</div>
				</card-component>
			</div>
			<div v-else class="col-12 m-auto">
				<card-component :waves="true">
					<div class="row flex-between-center mb-3">
						<div class="col-6 col-sm-auto d-flex align-items-center pr-0">
							<h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">{{ form.file.name }}</h5>
						</div>
						<div class="col-6 col-sm-auto ml-auto text-right pl-0">
							<div id="table-purchases-replace-element">
								<router-link class="btn btn-white shadow-sm btn-sm mr-2" :to="{ name: 'dashboard.users.members.import'}">{{$t('new-file')}}</router-link>
								<button-component :label="this.$t('upload')" :busy="isBusy" :button-class="'btn btn-primary btn-sm'"></button-component>
							</div>
						</div>
					</div>
					<div class="table-responsive" id="memberList">
						<table class="table" aria-describedby="members">
							<thead>
								<tr>
									<th v-for="(field,index) in parsedFile.meta.fields" :key="index" scope="col">{{ field|toStartCase }}</th>
								</tr>
							</thead>
							<tbody class="list">
								<tr v-for="(field,index) in parsedFile.data" :key="index">
									<td v-for="(value, index) in field" :key="index">{{ value }}</td>
								</tr>
							</tbody>
						</table>

					</div>
				</card-component>
			</div>
		</form>
    </div>
</template>

<style scoped>
	.table {
		width: 100%;
		font-size: 12px;
	}

	.table th,
	.table td {
		padding: 0.5rem;
		vertical-align: top;
		border-top: 1px solid #dee2e6;
	}

	.table tr {
		font-size: 9px;
	}
</style>

<script>
	import Papa from 'papaparse';
	import { mapGetters, mapActions } from 'vuex';
	import { validation, file } from '@/mixins';

	export default {
		name: 'MembersImport',
		mixins: [validation, file],
		data() {
			return {
				proceed: false,
				parsedFile: null,
				accepted: '.csv',
				form: {
					file: null,
					data: null,
					sendPasswordEmail: false,
				},
			};
		},
		computed: {
			...mapGetters({
				isBusy: 'isBusy',
			}),
		},
		watch: {
			'form.file': function (file) {
				if (file instanceof File) {
					Papa.parse(file, {
						header: true,
						escapeChar: '\\',
						skipEmptyLines: true,
						complete: (results) => (this.parsedFile = results),
					});
				} else {
					this.parsedFile = null;
					this.checkFile();
				}
			},
			proceed: function (value) {
				if (value) {
					window.feather.replace();
				}
			},
		},
		methods: {
			...mapActions({
				setAppState: 'setAppIsBusy',
				attemptMemberUpload: 'users/attemptMembersUpload',
			}),
			upload() {
				this.setAppState(true);

				this.form.data = this.parsedFile ? this.parsedFile.data : null;

				this.attemptMemberUpload(this.form)
					.then(() =>
						this.$router.push({
							name: 'dashboard.users.members.import',
						})
					)
					.catch((e) => this.validationErrors(e))
					.finally(() => this.setAppState(false));
			},
		},
	};
</script>

<style>
</style>
