<template>
	<div>
		<div class="modal" id="edit-payout" tabindex="-1" aria-labelledby="edit-payoutLabel" aria-hidden="true">
			<div class="modal-dialog">
				<form  @submit.prevent="updatePayout" class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="edit-payoutLabel">{{$t('update-payout')}}</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group">
							<label>{{$t('status')}}</label>
							<select v-model="form.status" class="form-control form-control-sm shadow-sm" :class="validation.inputs['status']">
								<option disabled :value="null">{{$t('select-option')}}</option>
								<option v-for="({id, value}, index) in payoutStatus" :value="id" v-bind:key="index">{{ value }}</option>
							</select>
							<div class="invalid-feedback d-block" v-html="validation.errors['status']"></div>
						</div>
					</div>
					<div class="modal-footer">
						<button-component :label="'Save changes'" :busy="isBusy" :buttonClass="'btn btn-primary'"></button-component>
						<button type="button" class="btn btn-secondary btn-sm shadow-sm" data-dismiss="modal">{{$t('cancel')}}</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex';
	import { validation } from '@/mixins';

	export default {
		name: 'UpdatePayoutModal',
		mixins: [validation],
		mounted() {
			window.$('#edit-payout').on('show.bs.modal', (event) => {
				const button = window.$(event.relatedTarget);

				this.payoutId = button.data('id');
				this.form = {
					status: button.data('status'),
				};
			});
		},
		data() {
			return {
				payoutId: null,
				form: {
					status: null,
				},
			};
		},
		computed: {
			...mapGetters({
				isBusy: 'isBusy',
				user: 'auth/user',
				payoutStatus: 'transactions/payouts/status',
			}),
		},
		methods: {
			...mapActions({
				setAppState: 'setAppIsBusy',
				attemptPayoutUpdate: 'transactions/payouts/attemptPayoutUpdate',
			}),
			updatePayout() {
				this.setAppState(true);

				this.attemptPayoutUpdate({
					data: this.form,
					payoutId: this.payoutId,
				})
					.then(() => {
						window.$('#edit-payout').modal('hide');

						this.$emit('update');
					})
					.catch((e) => {
						this.validationErrors(e);
					})
					.finally(() => this.setAppState(false));
			},
		},
	};
</script>

<style>
</style>
