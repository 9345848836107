<template>
	<div>
		<div v-show="isFiltering">
			<slot name="filters"></slot>
		</div>
		<card-component>
			<div class="table-container">
				<div class="table-header pt-1 pb-4">
					<div>
						<button class="table-header__perpage btn btn-sm btn-white shadow-sm mr-2" @click="togglePerPage">
							<span class="mr-3">{{ state.perPage }}</span>
							<em class="bi bi-chevron-expand"></em>
							<div v-if="showPerPage" class="table-header__perpage__list">
								<div v-for="(item, index) in perPageList.filter(i => i !== state.perPage)" :key="index" @click.stop="onClickPerPageHandler(item)">
									{{ item }}
								</div>
							</div>
						</button>
						<button class="table-header__export btn btn-sm btn-white shadow-sm mr-2">
							<json-csv
								:data="exportData"
								:name="`${id}.csv`"
							>
								<span>CSV</span>
							</json-csv>
						</button>
						<button class="table-header__export btn btn-sm btn-white shadow-sm">
							<json-excel
								:data="exportData"
								:name="`${id}.xls`"
							>
								<span>Excel</span>
							</json-excel>
						</button>
					</div>
					<div class="d-flex">
						<div class="table-loader">
							<spinner-component v-show="isFetching" :loading="true" :classes="{'spinner-border-s': true, 'spinner-border': true}"/>
						</div>
						<button class="btn btn-sm btn-white shadow-sm mr-2 d-flex align-items-center" @click="toggleFilter" v-if="showFilters">
							<em class="bi bi-funnel"></em>
							<span>{{$t('filter')}}</span>
						</button>
						<input
							v-model="currentSearchTerm"
							class="form-control form-control-sm shadow-sm"
							:placeholder= "$t('search')"
						>
					</div>
				</div>
				<div
					class="table-wrapper"
					:class="{
						[id]: true,
						isLoading: isInitializing,
						largeButtons: useLargeButtons,
						withoutButtons: withoutButtons
					}">
					<table aria-describedby="table">
						<thead class="table-head" :class="id">
							<tr class="pb-3">
								<th scope="col"
									v-for="(item, itemIndex) in header"
									:key="item.label"
									class="px-2"
									:class="{
										[item.type]: item.type,
										noWrap: item.noWrap
									}"
								>
									<div>
										<span>{{ item.label }}</span>
										<span v-if="item.sort" class="ml-2" @click="onSortHandler(itemIndex)">
											<span
												class="sort"
												:class="{active: state.sort.index === itemIndex && state.sort.direction === 'desc'}"
											>
												&#9206;
											</span>
											<span
												class="sort"
												:class="{active: state.sort.index === itemIndex && state.sort.direction === 'asc'}"
											>
												&#9207;
											</span>
										</span>
									</div>
								</th>
								<th scope="col" v-if="cta"></th>
							</tr>
							<div class="hide-cta" v-if="cta" v-show="data.table" :class="id"></div>
						</thead>
						<tbody class="table-body" :class="id">
							<tr
								v-for="(record, recordIndex) in data.table"
								:key="recordIndex"
								:class="{ even: recordIndex % 2 === 0, odd: Math.abs(recordIndex % 2) === 1 }"
							>
								<td
									v-for="(item, itemIndex) in record"
									:key="`${recordIndex}-${itemIndex}`"
									class="py-3 px-2"
									:class="{
										[header[itemIndex].type]: header[itemIndex].type,
										noWrap:  header[itemIndex].noWrap,
										hasHtml: header[itemIndex].type ==='html'
									}"
									@click="onClickHandler(item, header[itemIndex].type)"
								>


									<copy-to-clipboard
										v-if="header[itemIndex].type ==='id'"
										:id="`copy-${item}`"
										:bottom="recordIndex === 0"
									>
										{{ tdData(item, "id" )}}
									</copy-to-clipboard>
									<div v-else-if="header[itemIndex].type ==='html'" v-html="item" class="table-body-html"/>
									<span v-else>
										{{ tdData(item, header[itemIndex].type )}}
									</span>
								</td>
							</tr>
						</tbody>
						<div class="table-wrapper__loader" v-if="isInitializing">
							<spinner-component></spinner-component>
						</div>
					</table>
					<div class="cta" v-if="cta" :class="id">
						<div
							v-for="(record, recordIndex) in data.table"
							:key="recordIndex"
							:class="{
								even: recordIndex % 2 === 0,
								odd: Math.abs(recordIndex % 2) === 1,
							}"
						>
							<div
								v-for="(item, index) in cta"
								:key="index"
								:class="{'cta-html': item.html }"
							>
								<div v-if="item.html" v-html="item.html(recordIndex)"></div>
								<button
									v-if="!item.html"
									class="btn btn-sm shadow-sm mr-2"
									:class="item.style ? `btn-${item.style(record, data.metaData[recordIndex])}` : 'btn-white'"
									@click="item.onClick(record, data.metaData[recordIndex])"
								>
									{{ item.label(record, data.metaData[recordIndex]) }}
								</button>
							</div>

						</div>
					</div>
				</div>
				<pagination
					v-model="state.page"
					:records="total"
					:per-page="state.perPage"
					:options="paginationOptions"
					@paginate="onChangePageHandler"
					v-if="data.table"
				/>
			</div>
		</card-component>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import Pagination from 'vue-pagination-2';
	import JsonCSV from 'vue-json-csv';
	import JsonExcel from 'vue-json-excel';
	import stopwords from '@/assets/stopwords.json';

	export default {
		name: 'TableComponent',
		components: {
			Pagination,
			'json-csv': JsonCSV,
			'json-excel': JsonExcel,
		},
		data() {
			return {
				isFiltering: false,
				showPerPage: false,
				perPageList: [10, 25, 50, 100, 500 , 1000],
				currentSearchTerm: '',
				prevSearchTerm: '',
				timeout: null,
				paginationOptions: {
					texts: {
						count: this.$t('pagination_count')
					}
				},
				default: {
					text: 'N/A',
					email: 'N/A',
					date: 'N/A',
					boolean: 'false',
					id: 'N/A',
					number: 0,
					html: 'N/A',
				},
			};
		},
		created(){
			if(this.searchTerm){
				this.currentSearchTerm = this.searchTerm
			}
		},
		mounted(){
			const nonFiltersKeys = ["page", "load", "sort"]
			const queries = this.$route.query
			let hasFilters = false
			Object.keys(queries).forEach(key => {
				if(!hasFilters){
					hasFilters = !nonFiltersKeys.includes(key)
				}
			})
			if(hasFilters){
				this.isFiltering = true
			}
		},
		props: {
			id: {
				type: String,
				require: true,
			},
			header: {
				type: Array,
				required: true,
			},
			state: {
				type: Object,
				required: true,
			},
			data: {
				type: Object,
				required: true,
				default: function () {
					return {
						table: [],
						metaData: [],
					};
				},
			},
			total: {
				type: Number,
				default: 0,
			},
			cta: {
				type: Array,
				required: false,
			},
			// searchTerm: {
			// 	type: String,
			// },
			useLargeButtons: {
				type: Boolean,
				required: false,
			},
			withoutButtons: { type: Boolean, required: false, default: false },
			isFetching: {
				type: Boolean,
			},
			isInitializing: {
				type: Boolean,
			},
			options: {
				required: false,
				type: Object,
			},
		},
		watch: {
			data: function ({ table }) {
				if (table) {
					this.scrollDrag();
					this.scrollCta();
				}
			},
			currentSearchTerm: function (value) {
				if (!value) {
					this.prevSearchTerm = '';
					this.updateRouter("search", null)
					return this.$emit('setSearchTerm', '');
				}
				if(value !== this.searchTerm){
					clearTimeout(this.timeout);
					this.timeout = setTimeout(() => {
						this.searchHandler(value);
					}, 1000);
				}
			},
		},
		computed: {
			...mapGetters({
				locale: 'locale',
			}),
			tdData() {
				const { toDate, placeholder } = this.$options.filters;

				return function (item, type) {
					if (!item) return this.default[type];
					if (type === 'boolean') return item ? 'true' : 'false';
					if (type === 'date')
						return placeholder(toDate(item, 'Do MMMM YYYY'));
					return item;
				};
			},
			headerLabels() {
				const labels = [];
				this.header.forEach((item) => {
					labels.push(item.label);
				});
				return labels;
			},
			exportData() {
				const { table } = this.data;

				let exported = table || [];
				exported = [this.headerLabels, ...exported];
				return exported;
			},
			showFilters() {
				if (this.options && this.options.hideFilters) {
					return false;
				}
				return true;
			},
		},
		methods: {
			searchHandler(value) {
				const _terms = value.split(' ');
				const terms = [];
				_terms.forEach((term) => {
					if (!stopwords[this.locale].includes(term) && term.trim() !== '') {
							terms.push(term);
					}
				});
				const currentSearchTerm = terms.join(' ');
				if (currentSearchTerm !== this.prevSearchTerm) {
					this.prevSearchTerm = currentSearchTerm;
					this.updateRouter("search", currentSearchTerm)
					this.$emit('setSearchTerm', currentSearchTerm);
				}
			},
			onClickHandler(payload, type) {
				if (type === 'email') {
					this.setuid(payload);
				}
			},
			onChangePageHandler(page) {
				this.updateRouter("page", page)
			},
			onSortHandler(index) {
				const nextDirection =
					this.state.sort.direction &&
					this.state.sort.direction === 'desc' ? 'asc': 'desc';
				this.updateRouter("sort", window.btoa(`${index}-${nextDirection}`))
				this.$emit('setSort', { index, direction: nextDirection });
			},
			onClickPerPageHandler(value) {
				this.updateRouter("load", value);
				this.$emit('setPerPage', value);
				this.showPerPage = false;
			},
			updateRouter(key, value){
				const currentQuery = {...this.$route.query}
				if(value){
					currentQuery[key] = value
				} else {
					delete currentQuery[key]
				}
				this.$router.push({ query: currentQuery })
			},
			toggleFilter() {
				this.isFiltering = !this.isFiltering;
			},
			togglePerPage() {
				this.showPerPage = !this.showPerPage;
			},
			setuid(username) {
				this.$router.push({
					name: 'dashboard.users.set-uid',
					query: { username },
				});
			},
			scrollDrag() {
				const tableElement = document.querySelector(
					`.table-wrapper.${this.id}`
				);
				var curDown = false;
				var oldScrollLeft = 0;
				var curXPos = 0;
				if (tableElement) {
					tableElement.addEventListener('mousemove', function (e) {
						if (curDown === true) {
							tableElement.scrollLeft =
								oldScrollLeft + (curXPos - e.pageX);
						}
					});
					tableElement.addEventListener('mousedown', function (e) {
						curDown = true;
						curXPos = e.pageX;
						oldScrollLeft = tableElement.scrollLeft;
					});
					tableElement.addEventListener('mouseup', function () {
						curDown = false;
					});
				}
			},
			scrollCta() {
				if (this.cta) {
					const tableWrapperEl = document.querySelector(
						`.table-wrapper.${this.id}`
					);
					const tableHeadEl = document.querySelector(
						`.table-head.${this.id}`
					);
					const tableBodyEl = document.querySelector(
						`.table-body.${this.id}`
					);
					const ctaEl = document.querySelector(`.cta.${this.id}`);
					const ctaHeaderHideEl = document.querySelector(
						`.hide-cta.${this.id}`
					);
					if (tableWrapperEl) {
						ctaEl.style.top = `${
							tableWrapperEl.offsetTop + tableHeadEl.clientHeight
						}px`;
						ctaHeaderHideEl.style.top = `${tableWrapperEl.offsetTop}px`;
					}
					if (tableBodyEl) {
						tableBodyEl.addEventListener('scroll', function (e) {
							const scrollTop = e.target.scrollTop;
							ctaEl.scrollTop = scrollTop;
						});
					}
				}
			},
		},
	};
</script>

<style lang="scss">
</style>

