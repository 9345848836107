<template>
    <div>
        <breadcrumb :label="$t('negotiations')"></breadcrumb>

        <negotiations-table-component></negotiations-table-component>
    </div>
</template>

<script>
	export default {
		name: 'NegotiationsView',
	};
</script>

<style>
</style>
