<template>
	<div>
		<div class="toggle">
			<label class="toggle__label">{{$t('categories')}}: </label>

            <div class="toggle__list ml-3">

                <div class="toggle__list__label px-3" @click="toggleList" :class="{active: showCategoryList}">
                    <span>{{ displayedLabel }}</span>
                    <em class="bi bi-chevron-down"></em>
                </div>

                <div class="toggle__list__content p-1" v-show="showCategoryList">
                    <div v-if="displayedLabel !== 'All'" class="toggle__list__content__item px-3 my-1" @click="selectMasterCategoryHandler('all')">
                        <div class="toggle__list__content__item__text">All</div>
                    </div>
                    <div
                        v-for="item in resourcesCategories"
                        :key="item.id"
                        class="toggle__list__content__item px-3 my-1"
                        @click="selectMasterCategoryHandler(item)"
                        :class="{active: filters[item.id]}"
                    >
                        <div class="toggle__list__content__item__text"> {{ item.text }}</div>
                    </div>
                </div>

            </div>
		</div>
        <div class="pl-2 pr-4 my-2">

            <div v-for="masterCategoryId in Object.keys(filters)" :key="masterCategoryId" class="row my-3">
                <div class="p-2">
                    <div class="active-category">
                        <em class="bi bi-x-circle" @click="selectMasterCategoryHandler(filters[masterCategoryId])"></em>
                        {{ filters[masterCategoryId].text }}:
                    </div>
                </div>
                <div class="select">
                    <div
                        class="p-2 select__item"
						:class="{active: filters[masterCategoryId].displayed.length === 0}"
						@click="selectSubCategoryHandler(masterCategoryId, 'all')"
                    >
                        All
                    </div>
                    <div
                        v-for="sub in filters[masterCategoryId].children"
                        :key="sub.id"
                        class="p-2  select__item"
						:class="{active: filters[masterCategoryId].displayed.includes(sub.id)}"
						@click="selectSubCategoryHandler(masterCategoryId, sub.id)"
                    >
                        {{ sub.text }}
                    </div>
                </div>
            </div>

        </div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex';
	import { ClickOutside } from '@/directives/index.js';
	export default {
		name: 'ResourcesTableFiltersCategories',
		directives: {
			ClickOutside,
		},
		props: {
			filters: {
				type: Object,
			},
		},
		data() {
			return {
				showCategoryList: null,
			};
		},
		created(){
			if (this.resourcesCategories.length === 0) {
				this.attemptGetResourceCategories();
			}
		},
		computed: {
			...mapGetters({
				resourcesCategories: 'categories/resourcesCategories',
			}),
			displayedLabel: function () {
				const activeCategoriesCounter = Object.keys(this.filters).length;
				if (activeCategoriesCounter) {
					return `${activeCategoriesCounter} selected`;
				}
				return 'All';
			},
			categoriesObject: function () {
				const object = {};
				this.categories.forEach((category) => {
					object[category.id] = category;
				});
				return object;
			},
		},
		methods: {
			...mapActions({
				attemptGetResourceCategories:
					'categories/attemptGetResourceCategories',
			}),
			toggleList() {
				const updatedValue = this.showCategoryList ? false : true;
				this.showCategoryList = updatedValue;
			},
			selectMasterCategoryHandler(masterCategory) {
				let data = {}
				if(masterCategory !== "all"){
					if(!this.filters[masterCategory.id]){
						data = {
							[masterCategory.id]:  {...masterCategory, displayed: [] }
						}
						this.updateRouter("category", masterCategory.id)
					} else {
						this.updateRouter("category", null)
						this.updateRouter("sub", null)
					}
				} else {
					this.updateRouter("category", null)
				}
				this.$emit('setCategoriesFilters', data);
			},
			selectSubCategoryHandler(masterCategoryId, subCategoryId) {
				const updatedDisplayed = 
						subCategoryId === "all" ||
						this.filters[masterCategoryId].displayed === subCategoryId  ? [] 
						: [subCategoryId]
				const updatedFilters = {
					...this.filters,
					[masterCategoryId]: {
						...this.filters[masterCategoryId],
						displayed: updatedDisplayed
					},
				};
				this.updateRouter("sub", updatedDisplayed.length === 0 ? null : subCategoryId)
				this.$emit('setCategoriesFilters', updatedFilters);
			},
			updateRouter(key, value){
				const currentQuery = {...this.$route.query}
				if(value){
					currentQuery[key] = value
				} else {
					delete currentQuery[key]
				}
				this.$router.push({ query: currentQuery })
			}
		}
	};
</script>

<style lang="scss" scoped>
	.toggle {
		display: flex;
		align-items: center;
		&__label {
			margin-bottom: 0 !important;
		}
		&__list {
			cursor: pointer;
			position: relative;
			border-radius: 5px;

			&__label {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 40px;
				border-radius: 5px;
				background: rgb(248, 248, 248);
				border: 1px solid var(--color-semi-bright);
				width: 150px;
			}
			&__content {
				position: absolute;
				top: 45px;
				left: 0;
				width: 300px;
				z-index: 2;
				background: white;
				border-radius: 5px;
				box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
					0 3px 6px rgba(0, 0, 0, 0.23);
				border: 1px solid var(--color-semi-bright);
				overflow: hidden;
				&__item {
					height: 40px;
					display: flex;
					align-items: center;
					border-radius: 5px;
					&:hover {
						background: rgb(230, 230, 230);
					}
					&.active {
						background: var(--color-medium);
						color: white;
					}
					&__text {
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: initial;
						max-width: 90%;
					}
				}
			}
		}
	}

	.select {
		display: flex;
		border: 1px solid var(--color-semi-bright);
		border-radius: 5px;
		overflow: hidden;
		> div:not(:last-child) {
			border-right: 1px solid var(--color-semi-bright);
		}
		&__item {
			min-width: 70px;
			text-align: center;
			cursor: pointer;
			background: rgb(248, 248, 248);
			&:hover {
				background: rgb(230, 230, 230);
			}
			&.active {
				background: var(--color-medium);
				color: white;
			}
		}
	}

	.active-category {
		min-width: 180px;
		em {
			cursor: pointer;
			&:hover {
				color: red;
			}
		}
	}
</style>
