<template>
    <div>
        <breadcrumb :label="'Payouts'"></breadcrumb>

        <payouts-table-component></payouts-table-component>
    </div>
</template>

<script>
	export default {
		name: 'PayoutsView',
	};
</script>

<style>
</style>
