import {
	isEmpty,
	flattenDeep
} from 'lodash'

export default {
	methods: {
		mapMasterCategories(params) {
			return params.map((category) => {
				const {
					id,
					categoryLocale: {
						title: text
					},
					photo,
					type
				} = category

				return {
					id,
					text,
					photo,
					type
				}
			})
		},
		mapCategories(params) {
			return flattenDeep(params.map((category) => {
				const {
					children: _children,
				} = category
				const hasChildren = !isEmpty(_children)
				let children = []

				if (hasChildren) {
					children = _children.map((child) => {
						const {
							id: _id,
							photo: _photo,
							specificitiesList
						} = child
						const _text = child.categoryLocale[0].title

						return {
							id: _id,
							text: _text,
							photo: _photo,
							specificitiesList
						}
					})
				}

				return children
			}))
		},
	}
}
