import store from '@/store'
import {
	request
} from '@/config'

export default {
	async attemptPayoutUpdate(_, {
		payoutId,
		data
	}) {
		const response = await request.patch(`/payout/${payoutId}`, data)
			.then(({
				data: {
					message
				}
			}) => message)
			.catch(({
				response: error
			}) => {
				throw error
			})

		store.commit('SET_MESSAGE', response)

		return response
	},
}
