import {
	find
} from 'lodash';
import store from '@/store'
import style from '@/store/json/badgeStyle.json'

const {
	_text,
	_class,
} = style

export default {
	methods: {
		feeMembershipType(type) {
			const {
				locale
			} = store.getters
			const membershipTypes = store.getters['marketplace/membershipTypes']

			const membershipType = find(membershipTypes, (value, index) => index === type)

			if (membershipType) {
				const {
					nameEn,
					nameFr
				} = membershipType

				return locale === 'en' ? nameEn : nameFr
			}
		},
		feeChargedTo(to, isBlock = false) {
			const block = isBlock ? 'd-block' : ''
			to = (!to) ? 'unknown' : to

			return `<strong class="${
                _text[to.toLowerCase()]
            } ${block}">${to.toUpperCase()}</strong>`


		},
		feeStatus(status, isBlock = false) {
			const block = isBlock ? 'd-block' : ''
			status = (status) ? 'Active' : 'Inactive'

			return `<span class="badge badge-sm badge-dim ${
                _class[status.toLowerCase()]
            } ${block}">${status.toUpperCase()}</span>`
		},
	}
}
