<template>
    <div class="d-sm-flex align-items-center justify-content-between pt-3 pb-2 mb-3 border-bottom">
        <div>
            <h1 class="h2">{{ heading }}</h1>
        </div>

        <div :class="[(hideSm) ? 'd-none d-md-block' : '']">
            <slot></slot>
        </div>
    </div>
</template>

<script>
	import { startCase } from 'lodash';

	export default {
		name: 'Breadcrumb',
		computed: {
			heading() {
				if (!this.label) {
					const names = this.$route.name.split('.');
					const { 0: view } = names.slice(-1);
					const { 0: name } =
						view === 'index' ? names.slice(-2) : names.slice(-1);

					return startCase(name);
				}

				return this.label;
			},
		},
		props: {
			label: {
				type: String,
			},
			hideSm: {
				type: Boolean,
				default: true,
			},
		},
	};
</script>
