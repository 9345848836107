<template>
	<div class="col-md-6">
		<fieldset class="row">
			<legend class="h6 col-12">Current {{ invoice.type }} Invoice</legend>
			<div class="form-group col-12">
				<label>Select Invoice</label>
				<select v-model="invoice.type" class="form-control form-control-sm shadow-sm">
					<option disabled :value="null" >{{$t('select-option')}}</option>
					<option v-for="(invoiceType, index) in invoiceTypes" :value="invoiceType" :key="index">{{ invoiceType }}</option>
				</select>
			</div>

			<hosting-fee-component v-if="invoice.type === 'Hosting Fee'" :validation="validation" @select="selectInvoice" @cancel="cancel"></hosting-fee-component>

			<license-fee-component v-if="invoice.type === 'License Fee'" :validation="validation" @select="selectInvoice" @cancel="cancel"></license-fee-component>
		</fieldset>

		<form v-if="form.invoice.length" ref="form" class="mt-2" @submit.prevent="submitRequest">
			<ul class="list-group shadow-sm mb-5">
				<li v-for="(invoice, index) in uniqWith(form.invoice, isEqual)" class="list-group-item" v-bind:key="index">
					{{ invoiceText(invoice) }} <a href="#remove-invoice" class="float-right" @click.prevent="removeInvoice(index)">Remove</a>
				</li>
			</ul>

			<div class="float-right">
				<button-component :label="'Send Request'" :busy="isBusy" :button-class="'btn btn-primary btn-sm mr-2'"></button-component>
				<button type="button" class="btn btn-white btn-sm shadow-sm" @click.prevent="removeAll">Cancel</button>
			</div>
		</form>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { activity, validation } from '@/mixins';
	import { uniqWith, isEqual } from 'lodash';

	export default {
		name: 'InvoicesView',
		mixins: [activity, validation],
		mounted() {
			this.activityMessage =
				'Do you really want to leave? You have not submitted your request';
		},
		data() {
			return {
				uniqWith,
				isEqual,
				invoice: {
					type: null,
					year: null,
					month: null,
				},
				form: {
					invoice: [],
				},
			};
		},
		computed: {
			...mapGetters({
				isBusy: 'isBusy',
				user: 'auth/user',
				marketplace: 'marketplace/marketplace',
				years: 'profile/invoices/years',
				months: 'profile/invoices/months',
				invoiceTypes: 'profile/invoiceTypes',
			}),
		},
		watch: {
			'form.invoice': {
				handler: function (selected) {
					if (!selected.length) {
						this.hasActivity = false;
					}
				},
				deep: true,
			},
		},
		methods: {
			...mapActions({
				setAppState: 'setAppIsBusy',
				setMessage: 'profile/setMessage',
				attemptInvoiceRequest: 'profile/invoices/attemptInvoiceRequest',
			}),
			cancel() {
				this.invoice = {
					type: null,
					year: null,
					month: null,
				};
			},
			selectInvoice(data) {
				this.form.invoice.push(data);

				this.cancel();
			},
			removeAll() {
				this.cancel();
				this.resetForm();
			},
			removeInvoice(index) {
				this.form.invoice.splice(index, 1);
			},
			invoiceText(invoice) {
				const { type, year, month } = invoice;
				const period = month ? `${month}, ${year}` : year;

				return `${type} invoice for ${period}`;
			},
			submitRequest() {
				this.setAppState(true);
				const {
					domain: domainName,
					data: { name: marketplace },
				} = this.marketplace;
				//const { email } = this.user;

				this.attemptInvoiceRequest({
					email : 'support@bizbizshare.com',
					domainName,
					marketplace,
					...this.form,
				})
					.then(() => {
						this.removeAll();
					})
					.catch((e) => {
						this.validationErrors(e);
					})
					.finally(() => this.setAppState(false));
			},
		},
	};
</script>
