import store from '@/store'
import marketplace from './marketplace'
import categories from './categories'
import profile from './profile'
import resources from './resources'
import transactions from './transactions'
import users from './users'
import reports from './reports'


export default [{
	path: '/dashboard',
	name: 'dashboard',
	component: () => import('@/views/dashboard/Dashboard.vue'),
	beforeEnter: (to, from, next) => {
		const isAuthenticated = store.getters['auth/isAuthenticated']

		if (isAuthenticated) {
			return next()
		}

		return next({
			name: 'login'
		})
	},
	children: [
		...marketplace,
		...categories,
		...profile,
		...resources,
		...transactions,
		...users,
		...reports
	]
}]
