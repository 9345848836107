export default function (mutation) {
	const {
		type,
		payload
	} = mutation

	switch (type) {
		case 'transactions/negotiations/SET_VIEW_AS':
			localStorage.setItem('viewAs', payload)
			break

		default:
			break
	}
}
