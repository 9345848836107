import store from '@/store'

export default [{
	path: 'banners',
	name: 'dashboard.banners',
	component: () => import('@/views/dashboard/marketplace/banners/Banners.vue'),
	beforeEnter: async (to, from, next) => {
		const marketplace = await store.dispatch('marketplace/attemptGetMarketplace')

		if (marketplace) {
			return next()
		}

		return next({
			name: 'dashboard'
		})
	},
	children: [{
			path: 'create',
			name: 'dashboard.banners.create',
			component: () => import('@/views/dashboard/marketplace/banners/manage/BannersCreate.vue'),

		},
		{
			path: ':id/:type',
			name: 'dashboard.banners.edit',
			component: () => import('@/views/dashboard/marketplace/banners/manage/BannersEdit.vue'),
		}
	]
}]
