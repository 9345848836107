<template>
	<div>
		<breadcrumb></breadcrumb>
		<form @submit.prevent="submitEmail">
			<div class='row'>
				<div class="col-xl-3 form-group" style='display: grid'>
					<h5> 1. {{ $t('select-template')}} </h5>
					<select v-model='templateId' class="form-control flex-fill mr-2 shadow-sm" @change='setTemplate' required>
						<option disabled :value="null"  selected>{{$t('select-option')}}</option>
						<option v-for="(template, index) in templates" :value="template.id" :key="index">{{ template.templateName }}</option>
					</select>
				</div>

				<div class="col-xl-3 form-group" style='display: grid' >
					<h5> 2. {{ $t('select-recipients')}}</h5>
					<select v-model='categoryId' class="form-control shadow-sm" @change='setTemplate'>
						<option disabled :value="null"  selected>{{$t('select-option')}}</option>
						<option value='all'>{{$t('all')}}</option>
						<option v-for="(category, index) in this.companyCategories" :value="category.id" :key="index">{{ getCategoryLocale(category.categoryLocale) }}</option>
					</select>
				</div>

				<div class='col-xl-3 form-group' style='display: grid'>
					<h5> 3. {{$t('custom_list')}}</h5>
					<input type='text' class='form-control shadow-sm' v-on:keydown.enter.prevent='addEmailToList' :placeholder='$t("click_enter")'>
				</div>

				<div class="col-xl-2">
					<h5>&nbsp;</h5>
					<button type="submit" class="btn btn-primary  shadow-sm" >{{$t('send')}}</button>
				</div>
			</div>

			<div class='row'>
				<div class="col-xl-3 form-group" >
					<h5>  {{ $t('select-subject')}} </h5>
					<input type='text' v-model='subject' required class="form-control flex-fill mr-2 shadow-sm">
				</div>

				<div class="col-xl-3 form-group" >
					<h5> {{$t('select-language')}} </h5>
					<select v-model='locale' class="form-control shadow-sm" @change='setTemplate'>
						<option disabled :value="null"  selected>{{$t('select-language')}}</option>
						<option value='all'>{{$t('all')}}</option>
						<option value='fr'>FR</option>
						<option value='en'>EN</option>
					</select>
				</div>

				<div class="col-xl-3 form-group" style='display: grid'>
					<h5>&nbsp;</h5>
					<a class="btn btn-primary form-control" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
						{{ $t('show') }} {{ emails.length }} Emails
					</a>

					<div class="collapse" id="collapseExample" style='margin-top: 10px;'>
						<ul class="list-group">
							<li class="list-group-item" v-for='(email , index) in emails' :key='index'>{{ email }}
								<i class="bi bi-trash" style='float: right' @click='deleteEmail(email)'></i>
							</li>
						</ul>
					</div>

				</div>
			</div>
		</form>



		<hr>


		<div class="row" v-if='templateId'>
			<div class="col-xl-6">
				<h5> 5. {{ $t('build-email')}} </h5>
				<div v-html="form.body" id='email_body'></div>
			</div>
			<div class="col-xl-6 border-left">
				<form class='row'>
					<div class='col-xl-6' v-for='(value , index) in form.variables' :key="index">
						<email-input-component @mouseover.native="highlightElement('over',value.id,value.class)" @mouseleave.native="highlightElement('leave',value.id,value.class)" :input='value' :currentId='value.id' :images='usedImages' :body='form.body' @updateBody="updateBody"></email-input-component>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EmailInputComponent from '@/components/dashboard/emails/EmailInputComponent';

export default {
	name: 'SendEmail',
	components: { EmailInputComponent  },
	mounted() {
		this.fetchData();
	},
	data(){
		return {
			form:{
				body: null,
				variables: []
			},
			categoryId: null,
			emails: [],
			subject: null,
			templateId : null,
			locale: null,
			templates: [],
			usedImages: []
		}
	},
	methods: {
		...mapActions({
			setAppState: 'setAppIsBusy',
			getTemplates: 'marketplace/attemptGetEmailTemplates',
			sendEmail: 'marketplace/attemptSendEmail'
		}),
		setTemplate(){
			const template = this.templates.find((t) => t.id === this.templateId)

			this.usedImages = template.images

			this.form = {
				body: template.templateBody,
				variables: template.templateVariables
			}
		},
		async fetchData() {
			this.templates = await this.getTemplates()
		},
		addEmailToList(e){
			if (/^[^@]+@\w+(\.\w+)+\w$/.test(e.target.value)) {
				this.emails.push(e.target.value)
				e.target.value= null
			}else{
				alert("Enter valid email")
			}
		},
		deleteEmail(email){
			this.emails = this.emails.filter(function(item) {
				return item !== email
			})
		},
		updateBody(variable) {
			let element
			if (variable.class !== undefined){
				element = document.getElementsByClassName(variable.class)
			}else{
				element = document.getElementById(variable.id)
			}

			if (variable.type === 'text'){
				if (element.length !== undefined){
					for (let e of element) {
						e.innerText = variable.value
					}
				}else{
					element.innerText = variable.value
				}
			}else if(variable.type === 'image'){
				setTimeout(function(){
					element.src = variable.value
				},1000);
			}else if(variable.type === 'link'){
				element.href = variable.value
			}else if(variable.type === 'text_html'){
				element.innerHTML = variable.value
			}
		},
		getCategoryLocale(categoryLocale){
			return categoryLocale[0].title
		},
		highlightElement(action,id,className){
			let element
			if (className !== undefined){
				element = document.getElementsByClassName(className)
			}else{
				element = document.getElementById(id)
			}

			if (element.length !== undefined){
				for (let e of element) {
					if (action === 'over'){
						e.style.outline = 'none'
						e.style.boxShadow = '0 0 0 4px rgba(21, 156, 228, 0.4)'
					}else {
						e.style.outline = 'none'
						e.style.boxShadow = 'none'
					}
				}
			}else {
				if (action === 'over'){
					element.style.outline = 'none'
					element.style.boxShadow = '0 0 0 4px rgba(21, 156, 228, 0.4)'
				}else {
					element.style.outline = 'none'
					element.style.boxShadow = 'none'
				}
			}
		},
		submitEmail(){
			if (this.emails.length === 0 && this.categoryId === null){
				alert(this.$t('select_category_or_custom'))
				return false
			}else if (this.categoryId != null && this.locale === null){
				alert(this.$t('select_locale'))
				return false
			}

			const element = document.getElementById('email_body').innerHTML

			this.sendEmail({
				body: element,
				marketplaceId : this.marketplace.id,
				categoryId: this.categoryId,
				subject: this.subject,
				locale: this.locale,
				emails: this.emails
			})
		}
	},
	computed: {
		...mapGetters({
			companyCategories: 'categories/companyCategories',
			marketplace: 'marketplace/marketplace'
		})
	}
};
</script>

<style scoped>

</style>
