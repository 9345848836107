import {
	request
} from '@/config'
import store from '@/store'

export default {
	async attemptGetResourcePriceTerms({
		commit
	}) {
		const priceTerms = await request.get('/price-terms').then(({
			data: {
				data: {
					priceTerms
				}
			}
		}) => priceTerms).finally(() => store.dispatch('setAppIsLoading', false))

		commit('SET_RESOURCES_PRICE_TERMS', priceTerms)
	},
	async attemptGetResource({
		commit
	}, resourceId) {
		const resource = await request.get(`/resources/${resourceId}`, {
			params: {
				isAdmin: true
			}
		}).then(({
			data: {
				data: {
					resource: response
				}
			}
		}) => response).catch(({
			response: {
				data: {
					message: error
				}
			}
		}) => {
			throw error
		}).finally(() => store.dispatch('setAppIsBusy', false))

		commit('SET_RESOURCE', resource)
	},
	async attemptResourceUpload(_, {
		data,
		categoryId,
		type: typeIndex
	}) {
		const type =
			typeIndex === 0 ? 'update' :
				typeIndex === 1 ? 'create' : '';

		const response = await request.post(`/resources/${type}/upload`, {
			data,
			categoryId
		})
			.then(({
				data: {
					message
				}
			}) => message)
			.catch(({
				response: error
			}) => {
				throw error
			}).finally(() => store.dispatch('setAppIsBusy', false))

		store.commit('SET_MESSAGE', response)

		return response
	},
	async attemptExportResources(_, {
		categoryId
	}) {
		const response = await request.get('/resources/export', {
			params: {
				categoryId
			}
		})
			.then(({
				data
			}) => {
				const url = window.URL.createObjectURL(new Blob([data]));
				const link = document.createElement('a');

				link.href = url;
				link.setAttribute('download', `Resources - ${categoryId}.csv`);
				document.body.appendChild(link);
				link.click();

				return 'Resource downloaded successfully.'
			})
			.catch(({
				response: error
			}) => {
				throw error
			}).finally(() => store.dispatch('setAppIsBusy', false))

		store.commit('SET_MESSAGE', response)

		return response
	},
	async attemptGetNegotiationReport(_, { marketplaceId , startDate , endDate }
	) {
		console.log(marketplaceId)
		const response = await request.get('/negotiations/report', {
			params: {
				marketplaceId,
				startDate,
				endDate
			}
		})
			.then(({ data }) => {
				const url = window.URL.createObjectURL(new Blob([data]));
				const link = document.createElement('a');

				link.href = url;
				link.setAttribute('download', `report.csv`);
				document.body.appendChild(link);
				link.click();

				return 'Report downloaded successfully.'
			})
			.catch(({
						response: error
					}) => {
				throw error
			}).finally(() => store.dispatch('setAppIsBusy', false))

		store.commit('SET_MESSAGE', response)

		return response
	},
	async attemptGetIncomingReport(_, { marketplaceId , startDate , endDate , productId }
	) {
		console.log(marketplaceId)
		const response = await request.get('/negotiations/report_fund', {
			params: {
				marketplaceId,
				startDate,
				endDate,
				productId
			}
		})
			.then(({ data }) => {
				const url = window.URL.createObjectURL(new Blob([data]));
				const link = document.createElement('a');

				link.href = url;
				link.setAttribute('download', `report.csv`);
				document.body.appendChild(link);
				link.click();

				return 'Report downloaded successfully.'
			})
			.catch(({
						response: error
					}) => {
				throw error
			}).finally(() => store.dispatch('setAppIsBusy', false))

		store.commit('SET_MESSAGE', response)

		return response
	},
	async attemptResourceUpdate(_, {
		data,
		resourceId
	}) {
		const response = await request.put(`/resources/${resourceId}`, data)
			.then(({
				data: {
					message
				}
			}) => message)
			.catch(({
				response: error
			}) => {
				throw error
			}).finally(() => store.dispatch('setAppIsBusy', false))

		store.commit('SET_MESSAGE', response)

		return response
	},
	async attemptResourceDelete(_, resourceId) {
		const response = await request.delete(`/resources/${resourceId}`)
			.then(({
				data: {
					message
				}
			}) => message)
			.catch(({
				response: error
			}) => {
				throw error
			}).finally(() => store.dispatch('setAppIsBusy', false))

		store.commit('SET_MESSAGE', response)

		return response
	}
}
