<template>
    <div></div>
</template>

<style>
	.toasted-container.top-right {
		top: 13% !important;
		right: 1% !important;
	}
</style>

<script>
	import { mapGetters, mapActions } from 'vuex';

	export default {
		computed: {
			date() {
				return new Date().toISOString();
			},
			...mapGetters({
				isBusy: 'isBusy',
				message: 'message',
			}),
		},
		watch: {
			message(value) {
				if (value) {
					this.$toasted.show(value, {
						theme: 'bubble',
						position: 'top-right',
						keepOnHover: true,
						duration: 5000,
						singleton: true,
						onComplete: () => {
							this.setMessage(null);
						},
					});
				}
			},
		},
		methods: {
			...mapActions({
				setMessage: 'setMessage',
			}),
		},
	};
</script>
