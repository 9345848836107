import store from '@/store'
import {
	request
} from '@/config'

export default {
	async attemptShippingUpdate(_, {
		quoteId,
		data
	}) {
		const response = await request.patch(`/shipping/${quoteId}`, data)
			.then(() => 'Shipping quote updated successfully.')
			.catch(({
				response: error
			}) => {
				throw error
			})

		store.commit('SET_MESSAGE', response)

		return response
	},
}
