<template>
    <div>
        <input :id="refId ? refId : 'intl-tel-input'" v-model="input" type="tel" class="form-control form-control-sm shadow-sm" :class="{ 'is-invalid': hasError }" placeholder="" :readonly="readonly"/>
        <input type="hidden" v-model="phone" />
    </div>
</template>

<style lang="scss">
	@import '~intl-tel-input/build/css/intlTelInput.min.css';

	.iti {
		position: relative;
		display: block;
	}

	.iti--separate-dial-code .iti__selected-flag,
	.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
		background-color: #ffffff;
	}
</style>

<script>
	import intlTelInput from 'intl-tel-input';
	import utils from 'intl-tel-input/build/js/utils.js';
	import { split, upperCase } from 'lodash';

	export default {
		mounted() {
			const id = this.refId ? this.refId : 'intl-tel-input';
			const input = document.querySelector(`#${id}`);

			let iti = intlTelInput(input, {
				initialCountry: this.country ? this.country : 'auto',
				geoIpLookup: function (callback) {
					window.$.get('https://ip2c.org/s').always((response) => {
						let array = split(response, ';', 4);

						let countryCode = this.country ? this.country : array[1];

						callback(countryCode);
					});
				},
				formatOnDisplay: true,
				preferredCountries: ['ca'],
				utilsScript: utils,
			});

			this.iti = iti;

			input.addEventListener('countrychange', () => {
				let country = upperCase(iti.getSelectedCountryData().iso2);

				this.phone = iti.getNumber();
				this.$emit('update-phone-country', country);
			});

			input.addEventListener('keyup', () => {
				this.phone = iti.getNumber();
			});
		},
		data() {
			return {
				iti: {},
				input: this.phoneNumber ? this.phoneNumber : null,
				phone: this.phoneNumber ? this.phoneNumber : null,
			};
		},
		watch: {
			country: function (country) {
				this.iti.setCountry(country);
			},
			phone: function (phone) {
				this.$emit('update-phone', phone);
			},
			phoneNumber(value) {
				this.input = value;
			},
		},
		props: ['refId', 'readonly', 'country', 'phoneNumber', 'hasError'],
	};
</script>
