<template>
    <div class="bg-white border-right shadow-sm" id="sidebar-wrapper">
        <div class="sidebar-heading" v-html="navbarLogo">{{$t('dashboard')}}</div>

        <ul class="list-group list-group-flush">
            <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'report'}" exact>
                    {{ $t('dashboard')}}
                </router-link>
            </li>
        </ul>

		<ul v-show="marketplace.id === '675BE02DAAE63F1BBED76B91D0121225'" class="list-group list-group-flush">
            <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'dashboard.create-marketplace'}" exact>
                    Create Marketplace
                </router-link>
            </li>
        </ul>

        <div class="content" v-if="permissions">
            <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>Users</span>
            </h6>
            <ul class="list-group list-group-flush mb-2">
                <li class="nav-item" :style="[!permissions.users.setuid ? {'display' : 'none'} : null]">
                    <router-link class="nav-link" :to="{ name: 'dashboard.users.set-uid'}" exact>
                        {{ $t('setuid') }}
                    </router-link>
                </li>
                <li class="nav-item" :style="[!permissions.users.activationLinks ? {'display' : 'none'} : null]">
                    <router-link class="nav-link" :to="{ name: 'dashboard.users.activation-links'}" exact>
                        {{ $t('activation-url') }}
                    </router-link>
                </li>
				<ul class="nav flex-column mb-2">
					<li class="nav-item" :style="[!permissions.users.members.report ? {'display' : 'none'} : null]">
						<router-link class="nav-link" :class="[$route.name === 'dashboard.users.members.edit' ? 'active' : '']" :to="{ name: 'dashboard.users.members.index'}" exact>
							{{ $t('all-members') }}
						</router-link>
					</li>

					<li class="nav-item" :style="[!permissions.users.members.import ? {'display' : 'none'} : null]">
						<router-link class="nav-link" :to="{ name: 'dashboard.users.members.import'}" exact>
							{{ $t('import-members') }}
						</router-link>
					</li>
				</ul>
            </ul>

			<h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
				<span> {{ $t('reports') }} </span>
			</h6>
			<ul class="nav flex-column mb-2">
				<li class="nav-item" :style="[!permissions.transactions.negotiations.report ? {'display' : 'none'} : null]">
					<router-link class="nav-link" :to="{ name: 'dashboard.marketplaces.incoming.funds'}" exact>
						{{$t('incomingFundReport')}}
					</router-link>
				</li>
				<li class="nav-item" :style="[!permissions.resources.import ? {'display' : 'none'} : null]">
					<router-link class="nav-link" :to="{ name: 'dashboard.transactions.negotiations.report'}" exact>
						{{ $t('transactionsReport') }}
					</router-link>
				</li>
			</ul>

            <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>{{ $t('resources') }}</span>
            </h6>
            <ul class="nav flex-column mb-2">
                <li class="nav-item" :style="[!permissions.resources.report ? {'display' : 'none'} : null]">
                    <router-link class="nav-link" :class="[$route.name === 'dashboard.resources.edit' ? 'active' : '', { 'disabled': !permissions.resources.report }]" :to="{ name: 'dashboard.resources.index'}"  exact>
                        {{ $t('all-resources') }}
                    </router-link>
                </li>
				<li class="nav-item" :style="[!permissions.resources.import ? {'display' : 'none'} : null]">
                    <router-link class="nav-link" :to="{ name: 'dashboard.resources.import'}" exact>
                        {{ $t('import-resources') }}
                    </router-link>
                </li>
            </ul>

			<h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>{{$t('categories')}}</span>
            </h6>
			<ul class="nav flex-column mb-2">
				<li class="nav-item">
					<a href="#resource-categories" data-toggle="collapse" :aria-expanded="isParent('categories.resource')" class="nav-link dropdown-toggle">{{$t('resource-categories')}}</a>
					<ul class="collapse list-unstyled" :class="{ 'show' : isParent('categories.resource') }" id="resource-categories">
						<li :style="[!permissions.categories.resource.create ? {'display' : 'none'} : null]">
							<router-link class="nav-link" :to="{ name: 'dashboard.categories.resource.create'}" exact>
								{{ $t('create-category') }}
							</router-link>
						</li>
						<li :style="[!permissions.categories.resource.report ? {'display' : 'none'} : null]">
							<router-link class="nav-link" :class="[$route.name === 'dashboard.categories.resource.edit' ? 'active' : '']" :to="{ name: 'dashboard.categories.resource.index'}" exact>
								{{ $t('all-categories') }}
							</router-link>
						</li>
					</ul>
				</li>
			</ul>

			<h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>Transactions</span>
            </h6>
			<ul class="list-group list-group-flush mb-2">
                <li class="nav-item" :style="[!permissions.transactions.negotiations.report ? {'display' : 'none'} : null]">
                    <router-link class="nav-link" :to="{ name: 'dashboard.transactions.negotiations.index'}" exact>
                        {{ $t('negotiations') }}
                    </router-link>
                </li>
				<li class="nav-item" :style="[!permissions.transactions.shipping.report || marketplace.domain === 'espacesetcie.com' ? {'display' : 'none'} : null]">
                    <router-link class="nav-link" :to="{ name: 'dashboard.transactions.shipping.index'}" exact>
                        {{ $t('shipping') }}
                    </router-link>
                </li>
				<li class="nav-item" :style="[!permissions.transactions.payments.report || marketplace.domain === 'espacesetcie.com' ? {'display' : 'none'} : null]">
                    <router-link class="nav-link" :to="{ name: 'dashboard.transactions.payments.index'}" exact>
                        {{ $t('payments') }}
                    </router-link>
                </li>
				<li class="nav-item" :style="[!permissions.transactions.payouts.report || marketplace.domain === 'espacesetcie.com' ? {'display' : 'none'} : null]">
                    <router-link class="nav-link" :to="{ name: 'dashboard.transactions.payouts.index'}" exact>
                        {{ $t('payouts') }}
                    </router-link>
                </li>
				<li class="nav-item" :style="[marketplace.domain === 'espacesetcie.com' ? {'display' : 'none'} : null]">
					<a href="#fees" data-toggle="collapse" :aria-expanded="isParent('transactions.fees')" class="nav-link dropdown-toggle">{{$t('fees')}}</a>
					<ul class="collapse list-unstyled" :class="{ 'show' : isParent('transactions.fees') }" id="fees">
						<li :style="[!permissions.transactions.fees.create ? {'display' : 'none'} : null]">
							<router-link class="nav-link" :to="{ name: 'dashboard.transactions.fees.create'}" exact>
								{{ $t('new-fees') }}
							</router-link>
						</li>
						<li :style="[!permissions.transactions.fees.report ? {'display' : 'none'} : null]">
							<router-link class="nav-link" :to="{ name: 'dashboard.transactions.fees.index'}" exact>
								{{ $t('manage-fees') }}
							</router-link>
						</li>
					</ul>
				</li>
            </ul>

			<h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>Marketplace</span>
            </h6>
			<ul class="list-group list-group-flush">
				<li class="nav-item">
					<router-link class="nav-link" :to="{ name: 'dashboard.emails'}">
						Email
					</router-link>
				</li>
				<li class="nav-item">
					<router-link class="nav-link" :to="{ name: 'dashboard.banners'}">
						{{ $t('banners') }}
					</router-link>
				</li>
				<li class="nav-item">
					<router-link class="nav-link" :to="{ name: 'dashboard.library'}">
						{{ $t('library') }}
					</router-link>
				</li>
				<li class="nav-item" :style="[role !== 'Admin'? {'display' : 'none'} : null]">
					<a href="#sub-administrators" data-toggle="collapse" :aria-expanded="isParent('marketplace.sub-administrators')" class="nav-link dropdown-toggle">{{$t('admin')}}</a>
					<ul class="collapse list-unstyled" :class="{ 'show' : isParent('marketplace.sub-administrators') }" id="sub-administrators">
						<li>
							<router-link class="nav-link" :to="{ name: 'dashboard.sub-administrators.create'}" exact>
								{{ $t('new-admin') }}
							</router-link>
						</li>
						<li>
							<router-link class="nav-link" :to="{ name: 'dashboard.sub-administrators.index'}" exact>
								{{ $t('manage-admin') }}
							</router-link>
						</li>
					</ul>
				</li>
			</ul>
        </div>

		<div class="content" v-if="role === 'Super Admin'">
			<h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>Marketplaces</span>
            </h6>
			<ul class="list-group list-group-flush">
				<li class="nav-item">
					<router-link class="nav-link" :to="{ name: 'dashboard.marketplaces.resources'}">
						{{$t('resources')}}
					</router-link>
				</li>
			</ul>
		</div>
    </div>
</template>

<style lang="scss" scoped>
	@import '@/assets/scss/app';

	#sidebar-wrapper {
		min-height: 100vh;
		margin-left: -15rem;
		-webkit-transition: margin 0.25s ease-out;
		-moz-transition: margin 0.25s ease-out;
		-o-transition: margin 0.25s ease-out;
		transition: margin 0.25s ease-out;
		.sidebar-heading {
			padding: 0.5rem 1rem;
			font-size: 0.85rem;
			text-transform: initial;
		}
		.content {
			max-height: 90vh;
			overflow: overlay;
		}
		.list-group {
			width: 15rem;
		}
		.nav-item {
			> .nav-link {
				z-index: 2;
				font-weight: 500;
				color: #333;
			}
			> .nav-link.active {
				color: var(--color-medium);
			}
		}
		.dropdown-toggle::after {
			vertical-align: 0.1em;
		}
		.list-unstyled {
			background-color: #f8f9fa !important;

			.nav-link {
				display: block;
				padding: 0.2rem 1rem 0.2em 1.575em;
				color: #333;
				&.active {
					color: var(--color-medium);
				}
			}
		}
	}

	@media (min-width: 768px) {
		#sidebar-wrapper {
			margin-left: 0;
		}
		#wrapper.toggled {
			#sidebar-wrapper {
				margin-left: -15rem;
			}
		}
	}
</style>

<script>
	import { mapGetters } from 'vuex';

	export default {
		name: 'Sidebar',
		computed: {
			...mapGetters({
				permissions: 'auth/permissions',
				marketplace: 'marketplace/marketplace',
				role: 'auth/role',
				navbarLogo: 'navbarLogo',
			}),
			currentPage() {
				return this.$route.path;
			},
		},
		methods: {
			isParent(parent) {
				return this.$route.meta.parent === parent;
			},
		},
	};
</script>
