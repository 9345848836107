<template>
    <div>
        <breadcrumb :label="$t('resource-categories')">
            <router-link class="btn btn-outline-primary btn-sm shadow-sm" :to="{ name: 'dashboard.categories.resource.create'}">
                <em class="bi bi-plus-circle-dotted"></em> {{$t('new-category')}}
            </router-link>
        </breadcrumb>

        <resource-categories-table-component></resource-categories-table-component>
    </div>
</template>

<script>
	export default {
		name: 'ResourcesView',
	};
</script>

<style>
</style>
