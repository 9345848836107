<template>
    <div class="signin-wrapper">
        <form @submit.prevent="login">
            <div v-if="message" class="alert alert-light alert-dismissible shadow-sm" role="alert">
                <button type="button" @click="close" class="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                {{ message }}
            </div>

            <img class="mb-4" src="@/assets/img/logo.png" alt="logo" width="72" height="72">

            <h1 class="h3 mb-3 font-weight-normal">Login</h1>

            <label for="username" class="sr-only">Username</label>
            <input v-model="form.username" type="text" id="username" class="form-control form-control-sm" :class="validation.inputs.username" placeholder="Username" autofocus>

            <label for="password" class="sr-only">Password</label>
            <input v-model="form.password" type="password" id="password" class="form-control form-control-sm" :class="validation.inputs.password" placeholder="Password">

            <button-component :label="'Sign in'" :busy="isBusy"></button-component>

            <div class="invalid-feedback d-block" v-html="validation.message"></div>
        </form>
    </div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex';
	import { validation } from '@/mixins';

	export default {
		name: 'LoginView',
		mixins: [validation],
		data() {
			return {
				form: {
					username: null,
					password: null,
				},
			};
		},
		computed: {
			...mapGetters({
				isBusy: 'isBusy',
				message: 'message',
			}),
		},
		methods: {
			...mapActions({
				setAppState: 'setAppIsBusy',
				setMessage: 'setMessage',
				attemptLogin: 'auth/attemptLogin',
			}),
			close() {
				this.setMessage(null);
			},
			login() {
				this.close();
				this.setAppState(true);

				this.attemptLogin(this.form)
					.then(() => {
						this.$router.replace({ path: 'dashboard' });
					})
					.catch((e) => {
						this.validationErrors(e);
					})
					.finally(() => this.setAppState(false));
			},
		},
	};
</script>
