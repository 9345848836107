<template>
    <div id="resources-table-filter">
        <card-component>
            <div class="w-100 row p-0 m-0">
                <resources-table-filters-categories
                    :filters="filters.categories"
                    @setCategoriesFilters="setCategoriesFilters"
                >
                </resources-table-filters-categories>
            </div>
            <div class="row py-0">
                <div class="col-3 px-3">
                    <label class="px-0">Type</label>
                    <div class="row py-0 px-3">
                        <div
                            class="button-filter mr-2 py-2 px-3"
                            :class="{active: filters.type === 'SHARE'}"
                             @click="selectTypeFilter('SHARE')"
                        >
                            <span>Share</span>
                            <span></span>
                        </div>
                        <div
                            class="button-filter py-2 px-3"
                            :class="{active: filters.type === 'REQUEST'}"
                            @click="selectTypeFilter('REQUEST')"
                        >
                            <span>Request</span>
                        </div>
                    </div>
                </div>
                <div class="col-3 px-3">
                    <label class="px-0">{{$t('status')}}</label>
                    <div class="row py-0 px-3">
                        <div
                            class="button-filter mr-2 py-2 px-3"
                            :class="{active: filters.status === 'PUBLIC'}"
                            @click="selectStatusFilter('PUBLIC')"
                        >
                            <span>Public</span>
                            <span></span>
                        </div>
                        <div
                            class="button-filter py-2 px-3"
                            :class="{active: filters.status === 'PRIVATE'}"
                            @click="selectStatusFilter('PRIVATE')"
                        >
                            <span>{{$t('private')}}</span>
                        </div>
                    </div>
                </div>
				<div class="col-3 px-3">
					<label class="px-0">{{$t('featured')}}</label>
					<div class="row py-0 px-3">
						<div
							class="button-filter mr-2 py-2 px-3"
							:class="{active: filters.featured === 1}"
							@click="selectFeaturedFilter(1)"
						>
							<span>{{ $t('yes') }}</span>
							<span></span>
						</div>
						<div
							class="button-filter py-2 px-3"
							:class="{active: filters.featured === 0}"
							@click="selectFeaturedFilter(0)"
						>
							<span>{{$t('no')}}</span>
						</div>
					</div>
				</div>
                <div class="col-3 cta">
                    <div>
                        <span class="mr-2">{{$t('active-filters')}}: </span>
                        <span>{{ activeFiltersCounter }}</span>
                    </div>
                    <div class="cta__clear" @click="clearFilters">{{$t('clear')}}</div>
                </div>
            </div>

        </card-component>
    </div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import ResourcesTableFiltersCategories from './ResourcesTableFiltersCategories.vue';

	export default {
		components: { ResourcesTableFiltersCategories },
		name: 'ResourcesTableFilters',
		props: {
			filters: {
				type: Object
			}
		},
        mounted(){
            const queryFilters = {}

			const query = this.$route.query
            if(query.type){
				const typeIsValid = query.type === "SHARE" || query.type === "REQUEST"
				if(typeIsValid){
					queryFilters.type = query.type
				} else {
					this.updateRouter("type", null)
				}
			}

			if(query.status){
				const statusIsValid = query.status === "PUBLIC" || query.status === "PRIVATE"
				if(statusIsValid){
					queryFilters.status = query.status
				} else {
					this.updateRouter("status", null)
				}
			}

			if(query.featured){
				const featuredIsValid = query.featured
				if(featuredIsValid){
					queryFilters.featured = query.featured
				} else {
					this.updateRouter("featured", null)
				}
			}

            if(query.category){
				const categoryIndex = this.resourcesCategories.findIndex(item => item.id === query.category)
				const categoryIsValid = categoryIndex > -1
				if(categoryIsValid){

                    queryFilters.categories = {
                        [query.category]: {
                            ...this.resourcesCategories[categoryIndex],
                            displayed: []
                        }
                    }
					if(query.sub){
						const subIndex = queryFilters.categories[query.category].children.findIndex(item => item.id === query.sub)
						const subIsValid = subIndex > -1
						if(subIsValid){
                            queryFilters.categories[query.category].displayed = [query.sub]
						} else {
							this.updateRouter("sub", null)
						}
					}
				} else {
					this.updateRouter("category", null)
					this.updateRouter("sub", null)
				}
			}

            if(Object.keys(queryFilters).length > 0){
				const initialQuery = {
					...this.filters,
					...queryFilters
				}
                console.log({ initialQuery, filters: this.filters, queryFilters })
				this.$emit('setFilters', initialQuery);
			}
        },
		computed: {
			...mapGetters({
				resourcesCategories: 'categories/resourcesCategories',
				user: 'auth/user',
			}),
			marketplaceId() {
				const { id } = this.user.companyInfo.marketplace;
				return id;
			},
            activeFiltersCounter(){
                const categoriesFilterCounter = Object.keys(this.filters.categories).length > 0 ? 1 : 0;
				const statusCounter = this.filters.status ? 1 : 0;
                const typeCounter = this.filters.type ? 1 : 0;
                return categoriesFilterCounter + statusCounter + typeCounter
            }
		},
		methods: {
			setCategoriesFilters(filters) {
				const updatedFilters = {...this.filters, categories: filters}
				this.$emit("setFilters", updatedFilters)
			},
            clearFilters(){
                const filters = {
                    categories: {},
					status: null,
					type: null,
                }
                this.$emit('setFilters', filters);
				this.$emit('resetFilters')
            },
			selectTypeFilter(type) {
				const updatedFilters = {...this.filters, type: this.filters.type === type ? null : type}
                this.updateRouter("type", updatedFilters.type)
				this.$emit("setFilters", updatedFilters)
			},
			selectStatusFilter(status) {
				const updatedFilters = {...this.filters, status: this.filters.status === status ? null : status}
                this.updateRouter("status", updatedFilters.status)
				this.$emit("setFilters", updatedFilters)
			},
			selectFeaturedFilter(featured) {
				const updatedFilters = {...this.filters, featured: this.filters.featured === featured ? null : featured}
				this.updateRouter("featured", updatedFilters.featured)
				this.$emit("setFilters", updatedFilters)
			},
            updateRouter(key, value){
				const currentQuery = {...this.$route.query}
				if(value){
					currentQuery[key] = value
				} else {
					delete currentQuery[key]
				}
				this.$router.push({ query: currentQuery })
			}
		}
	};
</script>

<style lang="scss" scoped>
	#resources-table-filter {
		.cta {
            width: 100%;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;

            > div:first-child {
                margin-right: 5rem;
            }
			&__clear {
				color: var(--color-semi-bright);
				cursor: pointer;
				&:hover {
					color: black;
				}
			}
		}
	}
</style>

