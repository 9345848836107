export default {
	message(state) {
		return state.message
	},
	user(state) {
		return state.user
	},
	invoiceTypes(state) {
		return state.invoiceTypes
	},
}
