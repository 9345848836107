<template>
    <div class="container h-100 tx-center">
        <div class="h-100 d-flex flex-column align-items-center justify-content-center">
            <div class="wd-50p wd-sm-100 wd-lg-150 mg-b-15">
                <img src="#" class="img-fluid" alt="#">
            </div>
            
            <h1 class="tx-color-01 tx-24 tx-sm-32 tx-lg-36 mg-xl-b-5">Not Found</h1>
            <p class="tx-color-03 mg-b-30"></p>
        </div>
        <div class="mg-b-40">
            
        </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style>

</style>