<template>
	<div>
		<form v-if="category" @submit.prevent="updateResourceCategory">
			<breadcrumb :label="$t('edit-category')">
				<button-component :label="$t('save')" :busy="isBusy" :button-class="'btn btn-primary btn-sm mr-2'"></button-component>
				<router-link class="btn btn-white btn-sm shadow-sm" :to="{ name: 'dashboard.categories.resource.index'}" exact>
					{{$t('cancel')}}
				</router-link>
			</breadcrumb>

			<div class="row">
				<div class="col-lg-6">
					<card-component :waves="true">
						<h5>{{$t('category-information')}}</h5>
						<div class="row">
							<div class="form-group col-12">
								<label>{{$t('photo')}}</label>
								<photo-component :type="'preview'" :image="imagePreview" :field="'photo'" @input="setInput"></photo-component>
								<div class="invalid-feedback d-block" v-html="validation.message"></div>
							</div>
						</div>

						<div class="row" v-for="locale in this.form.locale" :key="locale.locale">
							<div class="form-group col-12">
								<label>{{locale.locale.toUpperCase()}} {{$t('title')}}</label>
								<input v-model="locale.title" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs['locale.title']" required>
								<div class="invalid-feedback d-block" v-html="validation.message"></div>
							</div>

							<div class="form-group col-12">
								<label>{{locale.locale.toUpperCase()}} {{$t('description')}}</label>
								<textarea v-model="locale.description" class="form-control shadow-sm" rows="4" :class="validation.inputs['locale.description']" required></textarea>
								<div class="invalid-feedback d-block" v-html="validation.message"></div>
							</div>
						</div>
					</card-component>
				</div>

				<div class="col-lg-6">
					<card-component :waves="true">
						<template v-if="!category.isMasterCategory">
							<h5>
								{{$t('category-specificities')}}
							</h5>

							<table aria-describedby="specificities-table" class="table table-bordered table-striped">
								<thead>
									<tr>
										<th>{{$t('spec')}}</th>
										<th>{{$t('type')}}</th>
									</tr>
								</thead>

								<draggable
									:list="form.existingSpecs"
									tag="tbody"
									:move="checkMove"
									@start="dragging = true"
									@end="dragging = false"
									v-model="form.existingSpecs"
								>
									<tr v-for="(option, index) in form.existingSpecs" :key="index">
										<td>{{ `${option.specificitiesListLocales[$i18n.locale].name}` }}</td>
										<td>{{ `${$t(`specificityTypes.${option.type.toLowerCase()}`).toUpperCase()}` }}</td>
									</tr>
								</draggable>
							</table>

							<specificities-list-component :specificities-list="form.specificitiesList" :specs='this.category.specificitiesList' :field="'specificitiesList'" :validation="validation" @input="setInput" :is-edit="true"></specificities-list-component>

						</template>
					</card-component>
				</div>
			</div>
		</form>
		<div v-else>
			<div v-if="isBusy" class="spinner">
				<spinner-component></spinner-component>
			</div>
			<error-component v-else :label="$t('no-category')" :return-to="{ name: 'dashboard.categories.resource.index' }"></error-component>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex';
	import { activity, validation } from '@/mixins';
	import draggable from 'vuedraggable'

	export default {
		name: 'ResourceCategoriesEdit',
		mixins: [activity, validation],
		components:{
			draggable
		},
		created() {
			this.categoryId = this.$route.params.categoryId;

			this.fetchData(this.categoryId);
		},
		data() {
			return {
				hasMaster: true,
				categoryId: null,
				form: {
					locale: [],
					specificitiesList: [],
					existingSpecs:[],
					isInitialized: false,
					photo: null,
				},
				imagePreview: null,
				specificities: [],
				existingSpecs:[]
			};
		},
		computed: {
			...mapGetters({
				isBusy: 'isBusy',
				googleCloudUrl: 'googleCloudUrl',
				category: 'categories/resourceCategory',
				marketplace: 'marketplace/marketplace',
			}),
			locales() {
				const { locales } = this.marketplace;

				return locales;
			},
		},
		mounted() {
			const locales = [];
			this.locales.forEach(function (obj) {
				locales.push({
					title: null,
					description: null,
					locale: obj,
				});
			});

			this.form.locale = locales;
		},
		watch: {
			$route: function () {
				this.fetchData(this.categoryId);
			},
			category(category) {
				const { categoryLocale, specificitiesList, photo } = category;

				const catLocale = this.form.locale.map((el) => {
					const filtered = categoryLocale.find(l => {
						return l.locale === el.locale
					})

					if (filtered) {
						const {
							id,
							title,
							description,
							locale: _locale,
						} = filtered;

						return { id, title, description, locale: _locale };
					}
					return el;
				});

				const _specs = specificitiesList.sort((a, b) => a.index - b.index)

				const _existingSpecs = _specs.map(spec => {
					const specificitiesListLocales =
						spec.specificitiesListLocales.reduce((acc, obj) => {
							acc[obj.locale] = obj;
							return acc;
						}, {});

					return {
						...spec,
						specificitiesListLocales
					}
				})

				this.form = {
					locale: catLocale,
					specificitiesList : _specs,
					existingSpecs: _existingSpecs,
					isInitialized: true,
					photo: null,
				};

				this.specificities = _specs;

				if (photo) {
					this.imagePreview = `${this.googleCloudUrl}/photos/categories/${photo.path}`;
				}
			},
		},
		methods: {
			...mapActions({
				setAppState: 'setAppIsBusy',
				attemptGetResourceCategory: 'categories/attemptGetResourceCategory',
				attemptResourceCategoryUpdate:
					'categories/attemptResourceCategoryUpdate',
			}),
			performHouseActivities() {},
			fetchData(categoryId) {
				this.setAppState(true);
				this.attemptGetResourceCategory(categoryId);
			},
			updateResourceCategory() {
				this.setAppState(true);
				console.log(this.form)
				this.attemptResourceCategoryUpdate({
					data: this.form,
					categoryId: this.categoryId,
				})
					.then(() => {
						this.hasActivity = false;
						this.shouldWatch = false;
						this.$forceUpdate();
					})
					.catch((e) => this.validationErrors(e));
			},
			checkMove: function(e) {
				this.form.existingSpecs.splice(e.draggedContext.futureIndex, 0, this.form.existingSpecs.splice(e.draggedContext.index, 1)[0]);
				// update order properties based on position in array
				this.form.existingSpecs.forEach(function(item, index){
					item.index = index +  1;
				});
			}
		},
	};
</script>

<style>
</style>
