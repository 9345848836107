import fees from './fees'
import negotiations from './negotiations'
import payments from './payments'
import payouts from './payouts'
import shipping from './shipping'

export default {
	fees,
	negotiations,
	payments,
	payouts,
	shipping
}
