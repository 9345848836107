<template>
    <div>
        <div v-show="isFiltering" id="fees-table-search-pane" class="card shadow-sm mb-3"></div>
		<card-component :waves="true">
			<table class="table compact hover display responsive nowrap w-100" id="fees-table" aria-describedby="fees-table">
				<thead>
					<tr>
						<th scope="row">ID</th>
						<th scope="col">{{$t('subscription-type')}}</th>
						<th scope="col">{{$t('first-name')}}</th>
						<th scope="col">Description</th>
						<th scope="col">{{$t('value')}} (%)</th>
						<th scope="col">{{$t('charged-to')}}</th>
						<th scope="col">{{$t('status')}}</th>
						<th scope="col">Options</th>
					</tr>
				</thead>
			</table>
		</card-component>

		<update-fee-modal-component @update="reloadTable"></update-fee-modal-component>
    </div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import { datatables, transactions } from '@/mixins';

	export default {
		name: 'FeesTableComponent',
		mixins: [datatables, transactions.fees],
		mounted() {
			const { placeholder } = this.$options.filters;

			this.table = window.$('#fees-table').DataTable({
				processing: true,
				ajax: {
					headers: {
						Authorization: `Bearer ${this.token}`,
					},
					url: `${process.env.VUE_APP_API_URL}/fees`,
					data: {
						marketplaceId : this.marketplaceId,
						locale: 'en',
						allowedFees : this.allowedFees
					},
					dataSrc: 'data.fees',
				},
				responsive: true,
				deferRender: true,
				columns: [
					{ data: 'id' },
					{ data: 'membershipType' },
					{ data: 'title' },
					{ data: 'description' },
					{ data: 'value' },
					{ data: 'chargedTo' },
					{ data: 'active' },
					{ data: 'id' },
				],
				columnDefs: [
					{
						targets: [1, 4, 5, 6],
						className: 'text-center',
					},
					{
						targets: 1,
						render: (data) => placeholder(this.feeMembershipType(data)),
					},
					{
						targets: 3,
						render: (data) => {
							const language =
								this.locale === 'default' ? 'fr' : this.locale;

							return data[language];
						},
					},
					{
						targets: 4,
						render: (data) => this.$options.filters.toPercentage(data),
					},
					{
						targets: 5,
						render: (data) => this.feeChargedTo(data),
					},
					{
						targets: 6,
						render: (data) => this.feeStatus(data),
					},
					{
						targets: 7,
						orderable: false,
						render: (
							data,
							type,
							{
								membershipType,
								title,
								value,
								chargedTo,
								active,
								description,
							}
						) =>
							`<a href="#edit-fee" data-toggle="modal" data-id="${data}" data-type="${membershipType}" data-title="${title}" data-en="${description.en}" data-fr="${description.fr}" data-value="${value}" data-to="${chargedTo}" data-active="${active}" class="btn btn-white btn-sm shadow-sm"><em data-feather="pencil"></em> ${this.$t('edit')} </a>`,
						className: 'text-center',
					},
				],
				order: [[1, 'asc']],
				searchPanes: {
					columns: [1, 3, 4, 5],
					cascadePanes: true,
					viewTotal: true,
					layout: 'columns-4',
				},
				language: this.setLanguage(),
				initComplete: () => {},
				drawCallback: () => {},
				stateSave: false,
			});

			this.initDataTableButtons(
				this.table,
				'#fees-table_length',
				this.filename,
				[0, 1, 2, 3, 4, 5]
			);
			this.initDataTableFilter(
				this.table,
				'#fees-table_filter',
				'#fees-table-search-pane'
			);
		},
		data() {
			return {
				table: null,
				filename: 'Fees',
			};
		},
		computed: {
			...mapGetters({
				marketplace: 'marketplace/marketplace',
				user: 'auth/user',
				locale: 'locale',
				membershipTypes: 'marketplace/membershipTypes'
			}),
			marketplaceId() {
				const { id } = this.marketplace;

				return id;
			},
			allowedFees() {
				let types
				if(this.user.groupAccess){
					const filtered = this.marketplace.partners
						.filter((part) => this.user.permissions.groupAccess.includes(part.categoryId))
						.map(p => p.id)



					if (this.user.permissions.groupAccess){
						types = Object.keys(this.membershipTypes)
							.filter(key => filtered.includes(this.membershipTypes[key].partnerId))
					}
				}else{
					types = Object.keys(this.membershipTypes)
				}


				return types
			}
		},
	};
</script>

<style>
</style>
