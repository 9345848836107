<template>
	<div>
		<quill-editor ref="myQuillEditor" :content="input" :options="editorOption" @change="onEditorChange"/>
	</div>
</template>

<style lang="scss">
	@import '~quill/dist/quill.core.css';
	@import '~quill/dist/quill.snow.css';
	@import '~quill/dist/quill.bubble.css';

	.ql-toolbar {
		&.ql-snow {
			border-radius: 0.2rem 0.2rem 0 0;
		}
	}
	.ql-container {
		min-height: 150px;

		&.ql-snow {
			background: #ffffff;
			border: 1px solid #ced4da;
			border-radius: 0.2rem;
			border-radius: 0 0 0.2rem 0.2rem;
		}
	}

	.quill-editor {
		box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, .08) !important;
		border-radius: 0.25rem !important;
	}
</style>

<script>
	import { quillEditor } from 'vue-quill-editor';

	export default {
		components: {
			quillEditor,
		},
		mounted() {},
		data() {
			return {
				input: this.content,
				editorOption: {
					modules: {
						toolbar: [
							['bold', 'italic', 'underline', 'strike'],
							[
								// { header: '1' },
								// { header: '2' },
								// 'blockquote',
								'code-block',
							],
							[{ script: 'super' }, { script: 'sub' }],
							[{ font: [] } /* { size: [] }*/],
							[
								{ list: 'ordered' },
								{ list: 'bullet' },
								{ indent: '-1' },
								{ indent: '+1' },
							],
							[/*'direction', */ { align: [] }],
							[{ color: [] } /*{ background: [] }*/],
							['link', 'image' /*'video', 'formula'*/],
							['clean'],
						],
					},
				},
			};
		},
		watch: {
			input(value) {
				this.$emit('input', {
					field: this.field,
					value,
				});
			},
		},
		computed: {
			editor() {
				return this.$refs.myQuillEditor.quill;
			},
		},
		methods: {
			onEditorChange({ html }) {
				//  quill, html, text

				this.input = html;
			},
		},
		props: {
			field: {
				type: String,
				required: true,
			},
			content: {
				type: String,
				default: null,
			},
		},
	};
</script>


