<template>
    <div>
		<activation-links-filters-component v-show="state.isFiltering"></activation-links-filters-component>
		<table-component
            :header="header"
            :data="data"
            :total="total"
            :cta="cta"
            :state="state"
            :id="'activation-links'"
			:searchTerm="searchTerm"
			:isFetching="isFetching"
			:isInitializing="isInitializing"
			@setSort="setSort"
            @setData="setData"
			@setSearchTerm="setSearchTerm"
        >
        </table-component>
    </div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { request } from '@/config';
	import { table } from '@/mixins';

	export default {
		name: 'ActivationLinksTable',
		mixins: [table],
		data() {
			return {
				header: [
					{ label: this.$t('first-name'), type: 'text', sort: true },
					{ label: this.$t('last-name'), type: 'text', sort: true },
					{ label: this.$t('email'), type: 'email', sort: true },
					{ label: this.$t('token'), type: 'id' },
					{ label: this.$t('created-date'), type: 'date', sort: true, key: 'createdAt', },
				],
				data: {
					table: [],
					metaData: [],
				},
				total: 0,
				cta: [
					{
						label: () => this.$t('resend'),
						onClick: (link) => {
							const email = link[2];

							this.resendActivationLink(email,false);
						},
						style: () => 'outline-primary',
					},
					{
						label: () => this.$t('send_set_password'),
						onClick: (link) => {
							const email = link[2];

							this.resendActivationLink(email,true);
						},
						style: () => 'outline-primary',
					},
				],
			};
		},
		mounted() {
			this.getData();
		},
		watch: {
			state: {
				handler: function () {
					this.getData();
				},
				deep: true,
			},
			searchTerm: function () {
				console.log('Test');
				this.getData();
			},
			filters: {
				handler: function () {
					this.getData();
				},
				deep: true,
			},
		},
		methods: {
			...mapActions({
				attemptResendActivationLink: 'users/attemptResendActivationLink',
			}),
			resendActivationLink(email,set) {
				const answer = window.confirm(
					`Do you really want to resend an activation link to "${email}"?`
				);

				if (answer) {
					this.attemptResendActivationLink({email,set}).catch((e) => alert(e));
				}
			},
			setData(data) {
				this.data = data;
			},
			getData: async function () {
				try {
					const data = {
						table: [],
						metaData: [],
					};

					const requestParams = {
						marketplaceId: this.marketplaceId,
						locale: this.locale,
						isDatatable: true,
						page: this.state.page,
						load: this.state.perPage,
						q: {
							filter: {},
							search: {
								columns: [
									'id',
									'firstName',
									'lastName',
									'email',
									'confirmEmailToken',
								],
								searchTerm: this.searchTerm,
							},
						},
					};

					if (
						this.state.sort.index != null &&
						this.state.sort.direction != null
					) {
						requestParams.orderBy =
							this.header[this.state.sort.index].key;
						requestParams.orderDirection =
							this.state.sort.direction.toUpperCase();
					}

					const { data: activationLinks, total } = await request
						.get('users/activation-links', {
							headers: {
								Authorization: `Bearer ${this.token}`,
							},
							params: requestParams,
						})
						.then(({ data: { data: response } }) => response);

					activationLinks.forEach((item) => {
						data.table.push([
							item.firstName,
							item.lastName,
							item.email,
							item.confirmEmailToken,
							item.createdAt,
						]);
					});

					this.data = data;
					this.total = total;
					this.isFetching = false;
					this.isInitializing = false;
				} catch (err) {
					console.log({ err });
				}
			},
		},
	};
</script>

<style>
	table{
		width: auto;
	}

	.table-wrapper .cta{
		width: auto;
	}

	.table-wrapper .cta > div {
		padding-left: 10px;
	}
</style>
