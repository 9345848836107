<template>
	<div class="bz-preview">
		<h5 class="col-12">
			{{$t(type.toLowerCase())}}

			<router-link v-if="isPreview" class="btn btn-outline-primary btn-sm shadow-sm float-right" :to="{ name: 'dashboard.banners.edit', params: { type, id }}">
				<em class="bi bi-pencil"></em> {{$t('edit-banner')}}
			</router-link>
			<small v-else class="text-muted float-right">{{$t('banner-preview')}} - {{$t(type.toLowerCase())}}.</small>
		</h5>

		<banners-preview-component :image="src" :color='color' :font='font' :type="type" :locale="locale" :id="id" :is-preview="isPreview"></banners-preview-component>

		<p v-if="isPreview" class="col-12">
			<small class="text-muted">{{$t('banner-preview')}} - {{$t(type.toLowerCase())}}.</small>
		</p>
	</div>
</template>

<style lang="scss" scoped>
	.bz-preview {
		h5 {
			margin-bottom: 1rem;
			font-weight: 500;
			line-height: 1.5;

			a {
				z-index: 1000;
				position: relative;
			}

			small {
				line-height: 3;
			}
		}

		p {
			margin-bottom: 2rem;
		}
	}
</style>

<script>
	import { find } from 'lodash';

	export default {
		name: 'BannersViewComponent',
		data() {
			let preview = null;

			const { bannerLocales, sections, id } = this.data;
			const banner = bannerLocales || sections;
			const imageValues = find(banner, (obj) => obj.type === 'IMAGE');

			if (imageValues) {
				const { enValue, frValue } = imageValues;

				preview = enValue || frValue;
			}

			return {
				locale: banner,
				id,
				src: preview ? preview : this.image,
			};
		},
		watch: {
			image(value) {
				this.src = value;
			},
		},
		props: {
			type: {
				type: String,
			},
			data: {
				type: Object,
				required: true,
			},
			color: {
				type: String
			},
			font:{
				type: String
			},
			image: {
				type: [Object, String, File],
			},
			isPreview: {
				type: Boolean,
				default: true,
			},
		},
	};
</script>
