import auth from './auth'
import administration from './administration'
import banners from './banners'
import categories from './categories'
import marketplace from './marketplace'
import profile from './profile'
import resources from './resources'
import transactions from './transactions'
import users from './users'

export default {
	auth,
	administration,
	banners,
	categories,
	marketplace,
	profile,
	resources,
	transactions,
	users
}
