import Vue from 'vue'
import {
	Cropper
} from 'vue-advanced-cropper'
import Toasted from 'vue-toasted';
import VueMapbox from '@studiometa/vue-mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
	window.Popper = require('popper.js').default
	window.$ = window.jQuery = require('jquery')

	require('bootstrap')
} catch (e) {
	console.log(e)
}

/**
 * Loading packages
 */

try {
	Vue.use(Toasted)
	Vue.use(Cropper)
	Vue.use(VueMapbox)

	require('@/mixins')
	require('@/filters')

	window.feather = require("feather-icons")
	window.DataTable = require('datatables.net-bs4')
	window.JSZip = require('jszip')
	window.ClipboardJS = require('clipboard')
	window.select2 = require('select2')
	window.List = require('list.js')

	require('datatables.net-buttons-bs4')
	require('datatables.net-buttons/js/buttons.colVis')
	require('datatables.net-buttons/js/buttons.flash')
	require('datatables.net-buttons/js/buttons.html5')
	require('datatables.net-buttons/js/buttons.print')

	require('datatables.net-responsive-bs4')

	require('datatables.net-searchpanes-bs4')
	require('datatables.net-select-bs4')
	require( 'datatables.net-fixedcolumns-bs4' )
	// require('datatables.net/plug-ins/1.10.25/sorting/date-de.js' )
} catch (e) {
	console.log(e)
}

require('@/store/subscriber')

// require( 'jszip' )
// require( 'pdfmake' )
// require( 'datatables.net-bs4' )()
// require( 'datatables.net-autofill-bs4' )()
// require( 'datatables.net-buttons-bs4' )()
// require( 'datatables.net-buttons/js/buttons.colVis.js' )()
// require( 'datatables.net-buttons/js/buttons.flash.js' )()
// require( 'datatables.net-buttons/js/buttons.html5.js' )()
// require( 'datatables.net-buttons/js/buttons.print.js' )()
// require( 'datatables.net-colreorder-bs4' )()
// require( 'datatables.net-fixedcolumns-bs4' )()
// require( 'datatables.net-fixedheader-bs4' )()
// require( 'datatables.net-keytable-bs4' )()
// require( 'datatables.net-responsive-bs4' )()
// require( 'datatables.net-rowgroup-bs4' )()
// require( 'datatables.net-rowreorder-bs4' )()
// require( 'datatables.net-scroller-bs4' )()
// require( 'datatables.net-searchbuilder-bs4' )()
// require( 'datatables.net-searchpanes-bs4' )()
// require( 'datatables.net-select-bs4' )()
