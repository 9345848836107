<template>
    <button type="submit" class="shadow-sm btn-sm" :class="[buttonClass ? buttonClass : 'btn btn-primary btn-block']" :disabled="busy">
        <template v-if="busy">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            {{ (labelLoading) ? labelLoading : $t('please-wait') }}
        </template>
        <template v-else>
            {{ label }}
        </template>
    </button>
</template>

<style scoped>
	@media (max-width: 425px) {
		button:only-child {
			display: block;
			width: 100%;
		}
	}
</style>

<script>
	export default {
        name: "ButtonComponent",
		props: ["buttonClass", "busy", "label", "label-loading"],
	};
</script>
