<template>
    <div>
        <table-component
            :header="header"
            :data="data"
            :total="total"
            :cta="cta"
            :state="state"
            :id="'sub-adminitrators'"
			:searchTerm="searchTerm"
			:isFetching="isFetching"
			:options="{
				hideFilters:true
			}"
			@setSearchTerm="setSearchTerm"
			@setSort="setSort"
			@setPerPage="setPerPage"
        >
        </table-component>
    </div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { request } from '@/config';
	import { table } from '@/mixins';

	export default {
		name: 'SubAdminitratorsTable',
		mixins: [table],
		data() {
			return {
				header: [
					{
						label: this.$t('username'),
						type: 'text',
						sort: true,
						key: 'username',
					},
					{
						label: this.$t('first-name'),
						type: 'text',
						sort: true,
						key: 'firstName',
					},
					{
						label: this.$t('last-name'),
						type: 'text',
						sort: true,
						key: 'lastName',
					},
					{ label: this.$t('admin-id'), type: 'id' },
				],
				data: {
					table: [],
					metaData: [],
				},
				total: 0,
				cta: [
					{
						label: () => this.$t('edit'),
						onClick: (member) => {
							const administratorId = member[3];
							this.editAdministrator(administratorId);
						},
					},
					{
						label: () => this.$t('delete'),
						onClick: (member) => {
							const administratorId = member[3];

							this.deleteAdministrator(administratorId);
						},
						style: () => 'danger',
					},
				],
				filters: {
					category: '',
					status: '',
				},
			};
		},
		mounted() {
			this.getData();
		},
		watch: {
			state: {
				handler: function () {
					this.getData();
				},
				deep: true,
			},
			searchTerm: function () {
				this.getData();
			},
			filters: function () {
				this.getData();
			},
		},
		methods: {
			...mapActions({
				attemptDeleteAdministrator:
					'administration/attemptDeleteAdministrator',
			}),
			setData(data) {
				this.data = data;
			},
			getData: async function () {
				this.isFetching = true;

				try {
					const data = {
						table: [],
						metaData: [],
					};

					const requestParams = {
						locale: this.locale,
						isDatatable: true,
						page: this.state.page,
						load: this.state.perPage,
						q: {
							filter: {},
							search: {
								columns: [
									'lastName',
									'firstName',
									'username',
									'id',
								],
								searchTerm: this.searchTerm,
							},
						},
					};

					if (
						this.state.sort.index != null &&
						this.state.sort.direction != null
					) {
						requestParams.orderBy =
							this.header[this.state.sort.index].key;
						requestParams.orderDirection =
							this.state.sort.direction.toUpperCase();
					}

					const { data: administrators, total } = await request
						.get('users/adminstrators/sub', {
							headers: {
								Authorization: `Bearer ${this.token}`,
							},
							params: requestParams,
						})
						.then(({ data: { data: response } }) => response);

					administrators.forEach((item) => {
						data.table.push([
							item.username,
							item.firstName,
							item.lastName,
							item.id,
						]);
					});

					this.data = data;
					this.total = total;
					this.state.isLoading = false;
					this.isFetching = false;
				} catch (err) {
					console.log({ err });
				}
			},
			editAdministrator(administratorId) {
				this.$router.push({
					path: `/dashboard/sub-administrators/${administratorId}`,
				});
			},
			setFilters(filters) {
				this.filters = filters;
			},
			deleteAdministrator(administratorId) {
				const answer = window.confirm(
					`Do you really want to delete this account?\nAdministrator ID: ${administratorId}`
				);

				if (answer) {
					this.attemptDeleteAdministrator(administratorId)
						.then(() => this.getData())
						.catch((e) => this.validationErrors(e));
				}
			},
		},
	};
</script>

<style>
</style>
