import {
	findKey,
	findLast,
	findLastIndex,
	map
} from 'lodash'
import moment from 'moment'
import style from '@/store/json/badgeStyle.json'
import constants from './constants'

const {
	_class,
} = style

const {
	NEGOTIATION_STATUS
} = constants

export default {
	methods: {
		negotiationStatus(status, isBlock = false) {
			const block = isBlock ? 'd-block' : ''
			status = (!status) ? 'unknown' : findKey(NEGOTIATION_STATUS, (value) => value === status)

			return `<span class="badge badge-sm badge-dim ${
                _class[status.toLowerCase()]
            } ${block}">${status.toUpperCase()}</span>`
		},
		pushMessage(message) {
			this.messages.push(message)
			this.scrollToLatest()

			if (message.type === 'message') {
				this.content = null
			}
		},
		getLastOffer() {
			return findLast(this.messages, ({
				type
			}) => type === 'offer')
		},
		updateOffer(params, newOffer = null) {
			const {
				offerId,
				negotiationId,
				status,
				type,
				offerDetails
			} = params
			const {
				recipient,
				fromId: userId
			} = this.message

			return map(this.messages, (message) => {
				if (message.id === offerId) {
					message.status = status

					if (['edit', 'counter'].includes(type)) {
						const data = {
							...newOffer,
							resourceId: this.resource.id,
							sellerId: this.parties['seller'],
							negotiationId,
							id: offerId,
							recipient,
							fromId: userId,
							toId: recipient,
							createdAt: moment().format(),
							domain: this.domain,
							status: {
								desc: 'Sent',
								id: 4,
							},
							type: 'offer',
							isAdmin: true
						}

						this.$socket.emit('edit-offer', data)
					}

					if (type === 'accept') {
						const {
							price: finalPrice
						} = offerDetails

						this.$socket.emit('accept-offer', {
							negotiationId,
							offerId,
							recipient,
							resourceId: this.resource.id,
							userId,
							finalPrice,
							domain: this.domain,
							isAdmin: true
						})
					}
				}
			})
		},
		cancelNegotiation(negotiationId) {
			if (negotiationId === this.negotiationId) {
				return map(this.messages, (message, index) => {
					if (index === findLastIndex(this.messages)) {
						message.status = {
							desc: 'Cancelled',
							id: 6,
						}
					}
				});
			}
		},
		scrollToLatest() {
			return this.$nextTick(function () {
				const container = this.$children[1].$refs['messages-content']
				container.scrollTop = container.scrollHeight + 120
			})
		}
	}
}
