import {
	camelCase,
	mapKeys,
	mapValues,

} from 'lodash'

export default {
	SET_RESOURCES_PRICE_TERMS(state, priceTerms) {
		state.priceTerms = priceTerms.map((priceTerm) => {
			priceTerm['text'] = `/${priceTerm['name']}`
			priceTerm['attributes'] = mapValues({
				...{
					startDate: false,
					endDate: false,
					price: false,
					quantity: false,
				},
				...mapKeys(priceTerm['attributes'], (value, key) => camelCase(key))
			}, (attribute) => attribute === 'required')

			return priceTerm
		})
	},
	SET_RESOURCE(state, resource) {
		state.resource = resource
	},
}
