// import members from './members'
import resources from './resources'

export default [{
	path: 'categories',
	component: () => import('@/views/dashboard/categories/Categories.vue'),
	children: [
		// ...members,
		...resources,
	]
}]
