<template>
    <div class="content content-fixed content-auth-alt">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {

    }
</script>

<style>

</style>