import store from '@/store'
import app from './app'
import auth from './auth'
import resources from './resources'
import users from './users'
import profile from './profile'
import marketplace from './marketplace'
import transactions from './transactions'
import administration from './administration'

const getModule = (mutationType) => {
	return mutationType.split('/')[0]
}

store.subscribe((mutation) => {
	switch (getModule(mutation.type)) {
		case 'auth':
			auth(mutation)
			break
		case 'marketplace':
			marketplace(mutation)
			break
		case 'resources':
			resources(mutation)
			break
		case 'users':
			users(mutation)
			break
		case 'profile':
			profile(mutation)
			break
		case 'transactions':
			transactions(mutation)
			break
		case 'administration':
			administration(mutation)
			break

		default:
			app(mutation)
			break
	}
})
