<template>
	<div class="content" ref="messages-content">
		<div v-for="({id:offerId, negotiationId, type, fromId, toId, status, ...message}, index) in messages" v-bind:key="index" :class="(fromId === viewAs) ? 'outgoing-message' : 'incoming-message'">
			<div class="message-content">
				<div class="card" v-if="type === 'offer'">
					<div class="card-header text-center"><strong> {{ }} {{$t('offer')}}</strong></div>
					<div class="card-body">
						<table class="table table-sm" aria-describedby="negotiation-details">
							<thead class="sr-only">
								<tr>
									<th scope="col">{{$t('first-name')}}</th>
									<th scope="col">Description</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>{{$t('start-date')}}: <span>{{ message.startDate|toDate('DD/MM/Y') }}</span></td>
									<td>{{$t('end-date')}}: <span>{{ message.endDate|toDate('DD/MM/Y') }}</span></td>
								</tr>
								<tr>
									<td>{{$t('price')}}: <span>{{ message.price|toCurrency }}<span v-if="hasPriceTerm">{{priceTerm.text}}</span></span></td>
									<td>{{$t('quantity')}}: <span>{{ message.quantity }} <span v-if="hasPriceTerm">{{ priceTerm.name|pluralize(message.quantity) }}</span></span></td>
								</tr>
							</tbody>
						</table>
					</div>
					<negotiation-message-option-component @accept="accept" :offerId="offerId" :negotiationId="negotiationId" :status="status" :view-as="viewAs" :receiver="toId" :message="message"></negotiation-message-option-component>
				</div>
				<p v-if="type === 'message'">{{ message.content }}</p>
			</div>
			<div v-if="message.comment" class="message-content">
				<p>{{ message.comment }}</p>
			</div>
			<negotiation-message-timestamp-component :created-at="message.createdAt"></negotiation-message-timestamp-component>
		</div>

		<div v-if="typing.active" :class="(typing.recipient !== viewAs) ? 'outgoing-message' : 'incoming-message'">
			<div class="is-typing">
				<dot-flashing-component></dot-flashing-component>
				<p>{{$t('typing')}}...</p>
			</div>
		</div>
	</div>
</template>

<script>
	import typingMp3 from '@/assets/sounds/typing.mp3';

	export default {
		name: 'NegotiationMessagesComponent',
		data() {
			return {
				typingMp3: new Audio(typingMp3),
				typing: {
					active: false,
					recipient: null,
				},
			};
		},
		computed: {
			hasPriceTerm() {
				return !['negotiable', 'sell'].includes(this.priceTerm.name);
			},
		},
		watch: {
			isTyping: {
				handler({ recipient, typing: active, isAdmin }) {
					if (active && !isAdmin) {
						this.playSound();
					}

					this.typing = {
						active,
						recipient,
					};
				},
				deep: true,
			},
		},
		methods: {
			playSound() {
				return this.typingMp3.play();
			},
			accept(params) {
				return this.$emit('update-negotiation', params);
			},
		},
		props: {
			messages: {
				type: Array,
				default: null,
			},
			viewAs: {
				type: String,
				required: true,
			},
			details: { type: Object },
			priceTerm: {
				type: Object,
				required: true,
			},
			isTyping: {
				type: Object,
				default: function () {
					return {
						typing: false,
						isAdmin: false,
					};
				},
			},
		},
	};
</script>

<style lang="scss" scoped>
	.card-header,
	.card-footer {
		background-color: #ffffff;
	}
	.card-body {
		background-color: #ebebeb;
	}

	.table {
		margin-bottom: 0;

		th,
		td {
			border-top: none;

			span {
				font-weight: 600;
			}
		}

		@media (max-width: 425px) {
			font-size: 12px;
		}
	}

	.content {
		max-height: 70vh;
		height: 70vh;
		overflow-y: auto;
		padding: 25px;

		@media (max-width: 768px) {
			max-height: 65vh;
		}

		.time-stamp {
			color: #747474;
			display: block;
			font-size: 12px;
			margin: 8px 0 0;
		}

		.is-typing {
			margin-top: 0.5rem;
			p {
				font-size: 10px;
				font-style: italic;
				color: #646464;
				margin-bottom: 0;
			}
		}

		.outgoing-message {
			overflow: hidden;
			margin: 0 0 25px;

			+ .outgoing-message {
				margin-top: -15px;
			}

			> .message-content {
				+ .message-content {
					padding-top: 1rem;
				}

				p {
					background: var(--color-medium) none repeat scroll 0 0;
					border-radius: 3px;
					font-size: 14px;
					margin: 0;
					color: #fff;
					padding: 5px 10px 5px 12px;
					width: 100%;
				}

				margin-left: auto;
				width: 30%;

				@media (max-width: 768px) {
					width: 60%;
				}

				@media (max-width: 425px) {
					width: 90%;
				}
			}

			> .is-typing {
				margin-left: auto;
				width: 5%;
			}

			.time-stamp {
				text-align: right;
				margin-left: auto;
			}
		}

		.incoming-message {
			display: inline-block;
			vertical-align: top;
			margin: 0 0 20px;
			width: 100%;

			+ .incoming-message {
				margin-top: -10px;
			}

			> .message-content {
				+ .message-content {
					padding-top: 1rem;
				}

				p {
					background: #ebebeb none repeat scroll 0 0;
					border-radius: 3px;
					color: #646464;
					font-size: 14px;
					margin: 0;
					padding: 5px 10px 5px 12px;
					width: 100%;
				}

				width: 30%;

				@media (max-width: 768px) {
					width: 90%;
				}
			}
			> .is-typing {
				width: 30%;
			}
		}
	}
</style>
