<template>
		<div>
			<div class="form-group" v-if='this.input.type === "text" || this.input.type === "link"'>
				<label>{{ this.input.id|toLowerCase|toStartCase }}</label>
				<input :type="this.input.type" class="form-control" v-on:input='updateFormBody'>
			</div>

			<div class="form-group" v-if='this.input.type === "image"'>
				<label>{{ this.input.id|toLowerCase|toStartCase }}</label>
				<input type='file' class="form-control" style='height: auto;' accept="image/gif, image/jpeg, image/png , image/webp" @change='uploadImage'>
				<a :href='"#previous-images"+ this.input.id' data-toggle="modal" >Previous Images</a>

				<previous-images-component :usedImages='images' :id='input.id' :type='this.input.type' @changeBody='updateImage'></previous-images-component>
			</div>

			<div class='form-group' v-if='this.input.type === "text_html"' >
				<label>{{ this.input.id|toLowerCase|toStartCase }}</label>
				<vue-editor ref="vEditor" :editorToolbar='this.customToolbar' @text-change='updateTextBody'></vue-editor>
			</div>
		</div>
</template>

<script>

import PreviousImagesComponent from '@/components/dashboard/emails/PreviousImagesComponent';
import { VueEditor } from "vue2-editor";
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'EmailInputComponent',
	components: { PreviousImagesComponent , VueEditor },
	props: {
		input: {
			type: Object,
		},
		body: {
			type: String
		},
		images: {
			type: Array
		}
	},
	computed: {
		...mapGetters({
			isBusy: 'isBusy',
			marketplace: 'marketplace/marketplace',
		}),
		customToolbar() {
			return [
				["bold", "italic"],
				["link"],
				[{ color: [] }],
				[{ list: "ordered" }, { list: "bullet" }]
			]
		}
	},
	methods:{
		...mapActions({
			setAppState: 'setAppIsBusy',
			uploadImageToServer : 'marketplace/attemptUploadImage'
		}),
		updateFormBody (e) {
			this.$emit('updateBody', {
				value : e.target.value,
				id: this.input.id,
				type : this.input.type,
				class: this.input.class
			})
		},
		updateImage (params) {
			this.$emit('updateBody', {
				value : params.value,
				id: this.input.id,
				type : params.type,
				class: this.input.class
			})
		},
		updateTextBody(){
			const html = this.$refs.vEditor.quill.root.innerHTML

			this.$emit('updateBody', {
				value : html,
				id: this.input.id,
				type : this.input.type,
				class: this.input.class
			})
		},
		async uploadImage (e) {
			const file = e.target.files[0]

			let formData = new FormData();

			formData.append('image', file);
			formData.append('marketplaceId',this.marketplace.id)

			const data = await this.uploadImageToServer(formData).then(result => {
				return result
			})

			this.$emit('updateBody', {
				value : data.file,
				id: this.input.id,
				type : this.input.type,
				class: this.input.class
			})
		}
	}
};
</script>

<style scoped>

</style>
