export default {
	methods: {
		findDeep(collection, key, value) {
			for (const o of collection) {
				for (const [k, v] of Object.entries(o)) {
					if (k === key && v === value) {
						return o
					}

					if (Array.isArray(v)) {
						const _o = this.findDeep(v, key, value)
						if (_o) {
							return _o
						}
					}
				}
			}
		}
	}
}
