<template>
    <div>
		<shipping-table-filters v-show="state.isFiltering"></shipping-table-filters>
		<table-component
            :header="header"
            :data="data"
            :total="total"
            :cta="cta"
            :state="state"
            :id="'shipping'"
			:searchTerm="searchTerm"
			:isFetching="isFetching"
			:isInitializing="isInitializing"
			:options="{
				hideFilters: true
			}"
			@setSort="setSort"
			@setPerPage="setPerPage"
			@setSearchTerm="setSearchTerm"
            @setData="setData"

        >
        </table-component>

		<update-shipping-modal-component @update="getData"></update-shipping-modal-component>
    </div>
</template>

<script>
	import { upperCase } from 'lodash';
	import { request } from '@/config';
	import { table, transactions } from '@/mixins';

	export default {
		name: 'ShippingTable',
		mixins: [table, transactions.shipping],
		data() {
			return {
				header: [
					{ label: 'ID', type: 'id', sort: false },
					{ label: 'Negotiation ID', type: 'html', sort: false },
					{ label: 'Description', type: 'text', sort: false },
					{ label: this.$t('from-tailgate'), type: 'text', sort: false },
					{ label: this.$t('to-tailgate'), type: 'text', sort: false },
					{ label: this.$t('status'), type: 'html', sort: false },
					{ label: this.$t('from-address'), type: 'text', sort: false },
					{ label: this.$t('to-address'), type: 'text', sort: false },
					{ label: this.$t('return-trip'), type: 'text', sort: false },
					{ label: 'Type', type: 'text', sort: false },
					{ label: this.$t('weight'), type: 'text', sort: false },
					{ label: this.$t('length'), type: 'text', sort: false },
					{ label: this.$t('width'), type: 'text', sort: false },
					{ label: this.$t('height'), type: 'text', sort: false },
					{ label: this.$t('skids'), type: 'text', sort: false },
					{ label: this.$t('price'), type: 'text', sort: false },
					{ label: this.$t('provider-reply'), type: 'text', sort: false },
					// { label: 'Actions', type: 'html', sort: false },
				],
				data: {
					table: [],
					metaData: [],
				},
				total: 0,
				cta: [
					{
						label: () => 'Update',
						html: (recordIndex) => {
							const item = this.data.metaData[recordIndex];
							const disabled =
								status === 'accepted' ? 'disabled' : '';
							return `<a href="#edit-shipping" data-toggle="modal" data-id="${item.id}" data-price="${item.price}" data-provider="${item.shippingProviderReply}" data-status="${item.status}" class="btn btn-white btn-sm shadow-sm ${disabled}"><em data-feather="pencil"></em>${this.$t('update')}</a>`;
						},
					},
				],
			};
		},
		mounted() {
			this.getData();
		},
		watch: {
			'state.page': function () {
				this.getData();
			},
		},
		methods: {
			setData(data) {
				this.data = data;
			},
			getData: async function () {
				const { toCurrency } = this.$options.filters;

				try {
					const data = {
						table: [],
						metaData: [],
					};

					const { data: shipping, total } = await request
						.get('/shipping', {
							headers: {
								Authorization: `Bearer ${this.token}`,
							},
							params: {
								locale: this.locale,
								isDatatable: true,
								page: this.state.page,
								load: this.state.perPage,
								groupAccess: this.groupAccess,
								marketplaceId: this.marketplaceId,
							},
						})
						.then(({ data: { data: response } }) => response);

					shipping.forEach((item) => {
						data.table.push([
							item.id,
							`<a href="/dashboard/transactions/negotiations/${item.negotiationId}">${item.negotiationId}</a>`,
							item.description,
							item.fromTailgate,
							item.toTailgate,
							this.shippingStatus(item.status),
							item.fromAddress,
							item.toAddress,
							item.returnTrip ? 'YES' : 'NO',
							upperCase(item.type),
							item.weight,
							item.length,
							item.width,
							item.height,
							item.skids,
							toCurrency(item.price),
							item.shippingProviderReply,
						]);
						data.metaData.push(item);
					});
					this.data = data;
					this.total = total;
					this.isFetching = false;
					this.isInitializing = false;
					this.scrollTableToTop()
				} catch (err) {
					console.log({ err });
				}
			},
		},
	};
</script>

<style>
</style>
