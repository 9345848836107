<template>
	<div>
		<form v-if="resource" @submit.prevent="updateResource">
			<breadcrumb :label="$t('edit-resource')">
				<button-component :label="'Save'" :busy="isBusy" :button-class="'btn btn-primary btn-sm mr-2'"></button-component>
				<router-link class="btn btn-white btn-sm shadow-sm" :to="{ name: 'dashboard.resources.index'}" exact>
					{{$t('cancel')}}
				</router-link>
			</breadcrumb>


			<div class="row">
				<div class="col-lg-6">
					<card-component :waves="true">
						<h4 class="text-center">{{$t('information')}}</h4>
						<div class="row">
							<div class="form-group col-md-6">
								<label>{{$t('category')}}</label>
								<select-component :options="categories" :value="form.categoryId" :field="'categoryId'" @input="setInput"/>
								<div class="invalid-feedback d-block" v-html="validation.message"></div>
							</div>
							<div class="form-group col-md-6">
								<label>{{$t('type')}}</label>
								<select class="form-control form-control-sm shadow-sm" v-model="form.type" required>
									<option disabled :value="null">{{$t('select-option')}}</option>
									<option v-for="(type, index) in types" :key="index" :value="type.id">{{ $t(type.text) }}</option>
								</select>
								<div class="invalid-feedback d-block" v-html="validation.message"></div>
							</div>
							<div class="form-group col-md-6">
								<label>{{$t('price')}}</label>
								<input v-model="form.price" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs['resourceLocale[1].description']" required>
								<div class="invalid-feedback d-block" v-html="validation.message"></div>
							</div>
							<div class="form-group col-md-6">
								<label>{{$t('term')}}</label>
								<select class="form-control form-control-sm shadow-sm" v-model="form.priceTerm" required>
									<option disabled :value="null">{{$t('select-option')}}</option>
									<option v-for="(term, index) in priceTerms" :key="index" :value="term.id">{{ term.text }}</option>
								</select>
								<div class="invalid-feedback d-block" v-html="validation.message"></div>
							</div>
							<div class="form-group col-md-6">
								<label>{{$t('visible')}}</label>
								<select class="form-control form-control-sm shadow-sm" v-model="form.visible" required>
									<option disabled :value="null">{{$t('select-option')}}</option>
									<option :value="true">{{$t('yes')}}</option>
									<option :value="false">{{$t('no')}}</option>
								</select>
								<div class="invalid-feedback d-block" v-html="validation.message"></div>
							</div>

							<div class="form-group col-md-6">
								<label>{{$t('featured')}}</label>
								<select class="form-control form-control-sm shadow-sm" v-model="form.featured" required>
									<option disabled :value="null">{{$t('select-option')}}</option>
									<option :value="true">{{$t('yes')}}</option>
									<option :value="false">{{$t('no')}}</option>
								</select>
								<div class="invalid-feedback d-block" v-html="validation.message"></div>
							</div>
						</div>

						<div class="row" v-for="locale in this.form.resourceLocale" :key="locale.locale">
							<div class="form-group col-12">
								<label>{{locale.locale.toUpperCase()}} {{$t('title')}}</label>
								<input v-model="locale.title" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs['locale.title']" required>
								<div class="invalid-feedback d-block" v-html="validation.message"></div>
							</div>

							<div class="form-group col-12">
								<label>{{locale.locale.toUpperCase()}} {{$t('description')}}</label>
								<textarea v-model="locale.description" class="form-control shadow-sm" rows="4" :class="validation.inputs['locale.description']" required></textarea>
								<div class="invalid-feedback d-block" v-html="validation.message"></div>
							</div>
						</div>
					</card-component>
				</div>
				<div class="col-lg-6">
					<card-component :waves="true">
						<h4 class="text-center">{{$t('specificities')}}</h4>
						<specificities-component :specificitiesList="specificitiesList"  :field="'specificities'" :validation="validation" @input="setInput" :is-edit="true"></specificities-component>
					</card-component>
				</div>
			</div>


		</form>
		<div v-else>
			<div v-if="isBusy" class="spinner">
				<spinner-component></spinner-component>
			</div>
			<error-component v-else :label="$t('no-resource')" :return-to="{ name: 'dashboard.resources.index' }"></error-component>
		</div>
	</div>
</template>

<script>
	import { find } from 'lodash';
	import { mapGetters, mapActions } from 'vuex';
	import { activity, validation } from '@/mixins';

	export default {
		name: 'ResourcesEdit',
		mixins: [activity, validation],
		created() {
			this.resourceId = this.$route.params.resourceId;

			this.fetchData(this.resourceId);
		},
		data() {
			return {
				resourceId: null,
				form: {
					categoryId: null,
					type: null,
					price: null,
					currency: null,
					priceTerm: null,
					isInitialized: false,
					resourceLocale: [],
					address: null,
					publishedById: null,
					publishedForId: null,
					specificities: [],
					visible: null,
					featured: null
				},
				specificitiesList: [],
			};
		},
		computed: {
			...mapGetters({
				isBusy: 'isBusy',
				types: 'resources/types',
				priceTerms: 'resources/priceTerms',
				categories: 'categories/resourcesCategories',
				marketplace: 'marketplace/marketplace',
				resource: 'resources/resource',
			}),
			locales() {
				const { locales } = this.marketplace;

				return locales;
			},
		},
		mounted() {
			const locales = [];
			this.locales.forEach(function (obj) {
				locales.push({
					title: null,
					description: null,
					locale: obj,
				});
			});

			this.form.resourceLocale = locales;
		},
		watch: {
			$route: function () {
				this.fetchData(this.resourceId);
			},
			resource(resource) {
				const {
					resourceCategory,
					type,
					price,
					currency,
					priceTerm,
					publishedById,
					publishedForId,
					locale,
					specificities,
				} = resource;
				const resourceLocale = this.form.resourceLocale.map((el) => {
					const filtered = find(locale, { locale: el.locale });

					if (filtered) {
						const {
							id,
							title,
							description,
							locale: _locale,
						} = filtered;

						return { id, title, description, locale: _locale };
					}
					return el;
				});
				const visible = resourceCategory[0].visible
				const featured = resourceCategory[0].featured
				const categoryId = resourceCategory[0].category.id;
				const resourceSpecs =
					resourceCategory[0].category.specificitiesList;

				this.form = {
					categoryId,
					type,
					price,
					currency,
					priceTerm,
					resourceLocale,
					publishedById,
					publishedForId,
					isInitialized: true,
					specificities,
					visible,
					featured
				};

				resourceSpecs.forEach((item, index) => {
					const spec = specificities.find(
						(i) => i.specificitiesListId === item.id
					);

					resourceSpecs[index].specificity = {
						locale: spec.specificitiesLocales,
						specificitiesListId: item.id,
					};
				});

				this.specificitiesList = resourceSpecs;
			},
			'form.categoryId': function (value) {
				if (this.resource.resourceCategory[0].categoryId !== value){
					let specs
					this.categories.forEach(category => {
						if (category.children){
							const rec = category.children.find(c => c.id === value)
							if (rec){
								specs = rec.specificitiesList
							}
						}

					})

					let newSpecsList = []
					specs.forEach(spec => {
						spec['specificity'] = {
							resourceId:this.resource.id,
							specificitiesListId: spec.id,
							locale: [
								{
									locale: 'en',
									value:''
								},
								{
									locale: 'fr',
									value:''
								}
							]
						}

						newSpecsList.push({
							resourceId:this.resource.id,
							specificitiesListId: spec.id,
							locale: [
								{
									locale: 'en',
									value:''
								},
								{
									locale: 'fr',
									value:''
								}
							]
						})
					})

					this.specificitiesList = specs
					this.form.specificities = newSpecsList
				}

				if (value) this.form.isInitialized = true;
			},
		},

		methods: {
			...mapActions({
				setAppState: 'setAppIsBusy',
				attemptGetResource: 'resources/attemptGetResource',
				attemptResourceUpdate: 'resources/attemptResourceUpdate',
			}),
			performHouseActivities() {},
			fetchData(resourceId) {
				this.setAppState(true);
				this.attemptGetResource(resourceId);
			},
			setTextArea(data) {
				const { field, value } = data;

				this.form.resourceLocale[field].description = value;
			},
			updateResource() {
				this.setAppState(true);

				this.attemptResourceUpdate({
					data: this.form,
					resourceId: this.resourceId,
				})
					.then(() => {
						this.hasActivity = false;
						this.shouldWatch = false;
						this.fetchData(this.resourceId);
					})
					.catch((e) => this.validationErrors(e));
			},
		},
	};
</script>

<style>
</style>
