<template>
	<div id="negotiation-table-filter">
		<card-component>
			<div class="row py-0">
				<label class="px-3">{{$t('status')}}</label>
			</div>
			<div class="row py-0 px-3">
                <div
                    class="button-filter mr-2 py-2 px-3"
					:class="{active: status === 0 }"
					@click="selectStatus(0)"
                >
                    <span>All</span>
                    <span></span>
                </div>
                <div
                    class="button-filter mr-2 py-2 px-3"
                    :class="{active: status === 1 }"
                     @click="selectStatus(1)"
                >
                    <span>{{$t('ongoing')}}</span>
                    <span></span>
                </div>
				<div
                    class="button-filter mr-2 py-2 px-3"
                    :class="{active: status === 2 }"
                    @click="selectStatus(2)"
                >
                    <span>{{$t('successful')}}</span>
                    <span></span>
                </div>
				<div
                    class="button-filter mr-2 py-2 px-3"
                    :class="{active: status === 3 }"
                    @click="selectStatus(3)"
                >
                    <span>{{$t('cancelled')}}</span>
                    <span></span>
                </div>
            </div>
		</card-component>
	</div>
</template>

<script>
	export default {
		name: 'NegotiationsTableFilters',
		props: {
			status: {
				type: Number,
			},
		},
		methods: {
			selectStatus(selectedStatus) {
				this.$emit('setStatus', selectedStatus);
			},
		},
		mounted() {
			//
		},
	};
</script>

<style lang="scss">
	.button-filter {
		border: 1px solid var(--color-semi-bright);
		cursor: pointer;
		background: rgb(248, 248, 248);
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		min-width: 160px;
		&.active {
			background: var(--color-medium);
			color: white;
			border-color: var(--color-medium);
		}
	}
</style>
