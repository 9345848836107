<template>
	<div>
		<div class="modal" id="view-invoice" :data-backdrop="invoice ? 'static' : ''" tabindex="-1" aria-labelledby="view-invoiceLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-scrollable shadow">
				<div v-if="isBusy" class="modal-content">
					<div class="d-flex justify-content-center mx-auto my-5">
						<spinner-component :loading="isBusy"/>
					</div>
				</div>

				<div class="modal-content" v-show="invoice">
					<div class="modal-header">
						<h5 class="modal-title" id="view-invoiceLabel"></h5>

						<div v-if="canUpdateStatus || canUpdateCoupon" class="dropdown">
							<a class="btn btn-white btn-sm dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								Options
							</a>
							<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
								<a v-if="canUpdateStatus" class="dropdown-item" href="#update-invoice" data-toggle="modal" data-type="status">{{$t('update-status')}}</a>
								<a v-if="canUpdateCoupon" class="dropdown-item" href="#update-invoice" data-toggle="modal" data-type="coupon">{{$t('apply-coupon')}}</a>
							</div>
						</div>
					</div>
					<div v-if="invoice" class="modal-body">
						<table class="table table-borderless" aria-describedby="invoice-parties">
							<thead>
								<tr>
									<th scope="row" class="sr-only">{{$t('first-name')}}</th>
									<th scope="col" class="sr-only">Description</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>{{$t('status')}}</td>
									<td class="float-right" v-html="paymentStatus(invoice.status)"></td>
								</tr>
								<tr>
									<td>{{$t('buyer')}}</td>
									<td class="float-right">{{ buyer|toStartCase }}</td>
								</tr>
								<tr>
									<td>{{$t('seller')}}</td>
									<td class="float-right">{{ seller|toStartCase }}</td>
								</tr>
								<tr>
									<td>{{$t('start-date')}}</td>
									<td class="float-right">{{ startDate|toDate('DD-MMM-Y') }}</td>
								</tr>
								<tr>
									<td>{{$t('end-date')}}</td>
									<td class="float-right">{{ endDate|toDate('DD-MMM-Y') }}</td>
								</tr>
								<tr v-show="invoice.purchaseOrderUrl">
									<td>Purchase Order</td>
									<td class="float-right"><a :href="invoice.purchaseOrderUrl" target="_blank" >  Link </a></td>
								</tr>
								<tr class="border-bottom">
									<th scope="col">{{$t('price')}}:</th>
									<th scope="col" class="font-weight-light float-right">{{ invoice.baseAmount|toCurrency }}</th>
								</tr>
							</tbody>
						</table>

						<table class="table table-borderless" aria-describedby="invoice-taxes">
							<thead>
								<tr>
									<th scope="col" class="font-weight-light">Taxes</th>
									<th scope="col" class="sr-only">Description</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(tax, index) in invoice.taxes.type" v-bind:key="index">
									<td>{{ index|toUpperCase }}({{ tax.percentage|toPercentage(false) }})</td>
									<td class="float-right">{{ tax.amount|toCurrency }}</td>
								</tr>
								<tr class="border-top">
									<td><strong>Total:</strong></td>
									<td class="float-right">{{ (invoice.taxes.total + invoice.baseAmount)|toCurrency }}</td>
								</tr>
								<tr v-if="invoiceType === 'byter'">
									<td>{{$t('shipping')}}:</td>
									<td class="float-right">{{ (invoice.shippingAmount)|toCurrency }}</td>
								</tr>
							</tbody>
						</table>

						<table class="table table-borderless" aria-describedby="invoice-fees">
							<thead>
								<tr>
									<th scope="col" class="font-weight-light"><strong>{{$t('fees')}}</strong></th>
									<th scope="col" class="sr-only">Description</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(fee, index) in invoice.fees.data" v-bind:key="index">
									<td>{{ `${fee.description['en']} (${fee.value}%)` }} </td>
									<td class="float-right">{{ fee.amount|toCurrency }}</td>
								</tr>
								<tr class="border-top">
									<td><strong>Total:</strong></td>
									<td class="float-right">{{ invoice.finalAmount|toCurrency }}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary btn-sm shadow-sm" data-dismiss="modal">{{$t('close')}}</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" id="update-invoice" tabindex="-1" aria-labelledby="update-invoiceLabel" aria-hidden="true">
			<div class="modal-dialog modal-sm shadow">
				<form @submit.prevent="updateInvoice" class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="update-invoiceLabel"></h5>
					</div>
					<div class="modal-body">
						<div class="row" v-if="type === 'coupon'">
							<div class="form-group col-12 mb-0">
								<label>Coupon</label>
								<input v-model="form.coupon" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs['coupon']">
								<div class="invalid-feedback d-block" v-html="validation.errors['coupon']"></div>
							</div>
							<div class="form-group col-12 mb-0">
								<div class="custom-control custom-checkbox">
									<input type="checkbox" class="custom-control-input" id="remove-coupon" v-model="form.removeCoupon" :readonly="form.removeCoupon">
									<label class="custom-control-label" for="remove-coupon">{{$t('remove-coupon')}}</label>
								</div>
								<div class="invalid-feedback d-block" v-html="validation.errors['coupon']"></div>
							</div>
						</div>

						<div v-if="type === 'status'" class="form-group">
							<label>Status</label>
							<select v-model="form.status" class="form-control form-control-sm shadow-sm" :class="validation.inputs['status']">
								<option disabled :value="null">{{$t('select-option')}}</option>
								<option v-for="({value, id}, index) in status" :value="id" v-bind:key="index">{{ value|toStartCase }}</option>
							</select>
							<div class="invalid-feedback d-block" v-html="validation.errors['status']"></div>
						</div>
					</div>
					<div class="modal-footer">
						<button-component :label="'Save changes'" :busy="isBusy" :buttonClass="'btn btn-primary'"></button-component>
						<button type="button" class="btn btn-secondary btn-sm shadow-sm" data-dismiss="modal">{{$t('cancel')}}</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<style scoped>
	@media (min-width: 576px) {
		#update-invoice .modal-dialog {
			margin: 17.75rem auto;
		}
	}
</style>

<script>
	import { mapGetters, mapActions } from 'vuex';
	import { validation, transactions } from '@/mixins';

	export default {
		mixins: [validation, transactions.payments],
		mounted() {
			const { toStartCase } = this.$options.filters;

			window.$('#view-invoice').on('show.bs.modal', (event) => {
				const button = window.$(event.relatedTarget);
				const modal = window.$('#view-invoice');
				this.invoice = null;
				this.invoiceType = button.data('type');

				modal
					.find('.modal-title')
					.text(`${toStartCase(this.invoiceType)} Invoice`);

				this.fetchData(button.data('id'));

				this.buyer = button.data('buyer');
				this.seller = button.data('seller');
				this.startDate = button.data('start');
				this.endDate = button.data('end');
			});

			window.$('#update-invoice').on('show.bs.modal', (event) => {
				const button = window.$(event.relatedTarget);
				const modal = window.$('#update-invoice');

				this.type = button.data('type');

				modal.find('.modal-title').text(`Update ${toStartCase(this.type)}`);
			});
		},
		data() {
			return {
				startDate: null,
				endDate: null,
				invoiceType: null,
				invoice: null,
				loading: false,
				type: null,
				form: {
					negotiationId: null,
					status: null,
					coupon: null,
					removeCoupon: false,
				},
			};
		},
		computed: {
			...mapGetters({
				isBusy: 'isBusy',
				status: 'transactions/payments/status',
			}),
			canUpdateStatus() {
				if (this.invoice) {
					const { status } = this.invoice;

					if (this.invoiceType === 'buyer') {
						return status !== 9;
					}
				}

				return false;
			},
			canUpdateCoupon() {
				if (this.invoice) {
					const { status } = this.invoice;

					if (this.invoiceType === 'buyer') {
						return status !== 9;
					}

					if (this.invoiceType === 'seller') {
						return status !== 10;
					}
				}
				return false;
			},
		},
		watch: {
			invoice(invoice) {
				if (invoice) {
					const { negotiationId, status, coupon } = invoice;

					this.form.negotiationId = negotiationId;

					if (this.invoiceType === 'buyer') {
						this.form.status = status;
					}

					this.setCoupon(coupon);
				}
			},
			'form.coupon': function (value) {
				if (value && this.invoice.coupon) {
					const { coupon } = this.invoice;

					this.form.removeCoupon = coupon !== value;
				}
			},
			'form.removeCoupon': function (value) {
				if (value) {
					this.form.coupon = null;
					return;
				}

				const { coupon } = this.invoice;

				this.setCoupon(coupon);
			},
		},
		methods: {
			...mapActions({
				setAppState: 'setAppIsBusy',
				attemptGetInvoice: 'transactions/payments/attemptGetInvoice',
				attemptUpdateInvoice: 'transactions/payments/attemptUpdateInvoice',
			}),
			setCoupon(coupon) {
				if (coupon) {
					const { code } = coupon;

					this.form.coupon = code;
				}
			},
			fetchData(invoiceId) {
				this.setAppState(true);

				this.attemptGetInvoice(invoiceId)
					.then((invoice) => {
						this.invoice = invoice;
					})
					.catch((e) => {
						this.validationErrors(e);
					})
					.finally(() => this.setAppState(false));
			},
			updateInvoice() {
				this.attemptUpdateInvoice({
					data: this.form,
					invoiceId: this.invoice.id,
					type: this.$options.filters.toStartCase(this.invoiceType),
				})
					.then(({ id }) => {
						window.$('#update-invoice').modal('hide');

						this.fetchData(id);

						this.$emit('update');
					})
					.catch((e) => {
						this.validationErrors(e);
					})
					.finally(() => this.setAppState(false));
			},
		},
	};
</script>
