import store from '@/store'

export default [{
		path: 'resources',
		name: 'dashboard.categories.resource.index',
		component: () => import('@/views/dashboard/categories/resources/ResourceCategoriesView.vue'),
		beforeEnter: (to, from, next) => {
			const {
				report
			} = store.getters['auth/permissions'].categories.resource

			if (report) {
				return next()
			}

			return next({
				name: 'dashboard'
			})
		},
		meta: {
			parent: 'categories.resource'
		}
	}, {
		path: 'resources/create',
		name: 'dashboard.categories.resource.create',
		component: () => import('@/views/dashboard/categories/resources/ResourceCategoriesCreate.vue'),
		beforeEnter: async (to, from, next) => {
			const response = await store.dispatch('categories/attemptGetResourceCategories')
			const {
				create
			} = store.getters['auth/permissions'].categories.resource

			if (response && create) {
				return next()
			}

			return next({
				name: 'dashboard'
			})
		},
		meta: {
			parent: 'categories.resource'
		}
	},
	{
		path: 'resources/:categoryId',
		name: 'dashboard.categories.resource.edit',
		component: () => import('@/views/dashboard/categories/resources/ResourceCategoriesEdit.vue'),
		beforeEnter: (to, from, next) => {
			const {
				edit
			} = store.getters['auth/permissions'].categories.resource

			if (edit) {
				return next()
			}

			return next({
				name: 'dashboard'
			})
		},
		meta: {
			parent: 'categories.resource'
		}
	}
]
