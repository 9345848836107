<template>
	<div class="tree">
		<ul class="tree-list">
			<permissions-list-component :data="permissions"></permissions-list-component>
		</ul>
	</div>
</template>

<style>
	.tree-list ul {
		padding-left: 15px;
		/* margin: 6px 0; */
		margin: 0px 0px 0px 0px;
		width: fit-content;
	}
</style>

<script>
	export default {
		props: {
			permissions: Object,
		},
	};
</script>
