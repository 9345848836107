<template>
	<div>
		<div class="modal" id="edit-shipping" tabindex="-1" aria-labelledby="edit-shippingLabel" aria-hidden="true">
			<div class="modal-dialog">
				<form  @submit.prevent="updateShipping" class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="edit-shippingLabel">{{$t('update-quote')}}</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group">
							<label>{{$t('price')}}</label>
							<input v-model="form.price" type="number" step="any" class="form-control form-control-sm shadow-sm" :class="validation.inputs['price']">
							<div class="invalid-feedback d-block" v-html="validation.errors['price']"></div>
						</div>
						<div class="form-group">
							<label>{{$t('provider-reply')}}</label>
							<textarea v-model="form.provider" class="form-control form-control-sm shadow-sm" :class="validation.inputs['provider']" rows="8"></textarea>
							<div class="invalid-feedback d-block" v-html="validation.errors['provider']"></div>
						</div>
						<div class="form-group">
							<label>{{$t('status')}}</label>
							<select v-model="form.status" class="form-control form-control-sm shadow-sm" :class="validation.inputs['status']">
								<option disabled :value="null">{{$t('select-option')}}</option>
								<option v-for="(status, index) in shippingStatus" :value="status" v-bind:key="index">{{ status|toStartCase }}</option>
							</select>
							<div class="invalid-feedback d-block" v-html="validation.errors['status']"></div>
						</div>
					</div>
					<div class="modal-footer">
						<button-component :label="'Save changes'" :busy="isBusy" :buttonClass="'btn btn-primary'"></button-component>
						<button type="button" class="btn btn-secondary btn-sm shadow-sm" data-dismiss="modal">{{$t('cancel')}}</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex';
	import { validation } from '@/mixins';

	export default {
		name: 'UpdateShippingModal',
		mixins: [validation],
		mounted() {
			window.$('#edit-shipping').on('show.bs.modal', (event) => {
				const button = window.$(event.relatedTarget);

				this.quoteId = button.data('id');
				this.form = {
					price: button.data('price'),
					provider: button.data('provider'),
					status: button.data('status'),
				};
			});
		},
		data() {
			return {
				quoteId: null,
				form: {
					price: null,
					provider: null,
					status: null,
				},
			};
		},
		computed: {
			...mapGetters({
				isBusy: 'isBusy',
				user: 'auth/user',
				shippingStatus: 'transactions/shipping/status',
			}),
		},
		methods: {
			...mapActions({
				setAppState: 'setAppIsBusy',
				attemptShippingUpdate:
					'transactions/shipping/attemptShippingUpdate',
			}),
			updateShipping() {
				this.setAppState(true);

				this.attemptShippingUpdate({
					data: this.form,
					quoteId: this.quoteId,
				})
					.then(() => {
						window.$('#edit-shipping').modal('hide');

						this.$emit('update');
					})
					.catch((e) => {
						this.validationErrors(e);
					})
					.finally(() => this.setAppState(false));
			},
		},
	};
</script>

<style>
</style>
