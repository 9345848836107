import {
	isEmpty
} from 'lodash'
import bsCustomFileInput from 'bs-custom-file-input'

export default {
	mounted() {
		bsCustomFileInput.init()
	},
	data() {
		return {
			fileName: this.$t('select-import'),
			accepted: ".xlsx, .xls, .csv"
		}
	},
	methods: {
		selectFile(e) {
			if (e.target.files.length === 1) {
				const {
					0: file
				} = e.target.files

				this.form.file = file

				this.fileName = this.$options.filters.truncate(file.name)
			} else {
				this.fileName = this.$t('select-file')
			}
		},
		newFile() {
			if (this.form.file) {
				this.form.file = null
				this.fileName = this.$t('select-file')
			}
		},
		checkFile() {
			if (this.form.file) {
				const {
					errors
				} = this.parsedFile

				if (isEmpty(errors)) {
					return this.proceed = true
				}
			}

			alert('Please select a file!')

			return this.proceed = false
		},
		previewFile(file) {
			if (file) {
				const reader = new FileReader();

				reader.addEventListener(
					'load',
					() => {
						this.$refs[this.type].src = reader.result;
					},
					false
				);

				if (file) {
					reader.readAsDataURL(file);
				}

				return;
			}

			this.$nextTick(() => {
				this.$refs[this.type].src = this.noImage
			});
		},
	}
}
