<template>
    <div class="card shadow-sm mb-5" :class="{ 'card-waves':waves }">
        <div class="card-body">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: ["waves"]
}
</script>

<style lang="scss" scoped>
    .card-waves {
        .card-body {
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-position: center bottom;
            background-image: url(../../assets/img/backgrounds/bg-waves.svg);
        }
    }

    .card-angles {
        .card-body {
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-position: center bottom;
        }
    }
</style>

<style>

</style>
