import {
	mapKeys,
	mapValues,
	camelCase
} from 'lodash'
import {
	request
} from '@/config'
import store from '@/store'

export default {
	async sanitizeMembershipTypes(_, data) {
		return mapValues(data, (type) => mapKeys(type, (value, key) => camelCase(key)))
	},
	async attemptGetMarketplaces({
		commit
	}) {
		try {
			const response = await request.get('/marketplace/all')
				.then(({
					data: {
						data: {
							marketplaces
						}
					}
				}) => marketplaces)
				.catch(({
					response: error
				}) => {
					throw error
				})

			commit('SET_MARKETPLACES', response)
		} catch (error) {
			commit('SET_MARKETPLACES', null)
		}
	},
	async attemptGetMarketplace({
		commit
	}) {
		const {
			companyInfo: {
				marketplace: {
					domain
				}
			}
		} = store.state.auth.user

		try {
			const response = await request.get('/marketplace', {
					params: {
						domain
					}
				})
				.then(({
					data: {
						data: {
							marketplace
						}
					}
				}) => marketplace)
				.catch(({
					response: error
				}) => {
					throw error
				})

			commit('SET_MARKETPLACE', response)

			return response
		} catch (error) {
			commit('SET_MARKETPLACE', null)
		}
	},
	async attemptUploadLibrary(_, body) {
		const response = await request.post(`/marketplace/upload/library`, body,{
			headers: { "Content-Type": "multipart/form-data" }
		})
			.then((res) => console.log(res))
			.catch(({
						response: error
					}) => {
				throw error
			}).finally(() => store.dispatch('setAppIsBusy', false))

		store.commit('SET_MESSAGE', `files saved successfully.`)

		return response
	},
	async attemptUploadImage(_, body) {
		const response = await request.post(`/marketplace/upload/image`, body,{
			headers: { "Content-Type": "multipart/form-data" }
		})
			.then((res) => {
				return res.data.data
			})
			.catch(({
						response: error
					}) => {
				throw error
			}).finally(() => store.dispatch('setAppIsBusy', false))

		store.commit('SET_MESSAGE', `files saved successfully.`)

		return response
	},
	async attemptDeleteFile(_, fileId) {
		const response = await request.post(`/marketplace/delete/library`, {
			fileId
		})
			.then((res) => console.log(res))
			.catch(({
						response: error
					}) => {
				throw error
			}).finally(() => store.dispatch('setAppIsBusy', false))

		store.commit('SET_MESSAGE', `File deleted successfully.`)

		return response
	},
	async attemptGetMarketplaceLibrary(_, body) {
		return request.get(`/marketplace/library`,{
			params: body
		})
			.then(({data :{ data }}) => data)
			.catch(({
						response: error
					}) => {
				throw error
			}).finally(() => store.dispatch('setAppIsBusy', false))
	},
	async attemptGetMembershipTypes({
		commit,
		dispatch,
		state
	}) {
		const {
			stripeProductId,
			stripeAccountId
		} = state.marketplace

		try {
			const response = await request.get(`/marketplace/membership-types/${stripeProductId}`,{
				params: {
					stripeAccountId
				}
			})
				.then(({
					data: {
						data: {
							membershipTypes
						}
					}
				}) => membershipTypes)
				.catch(({
					response: error
				}) => {
					throw error
				})

			commit('SET_MEMBERSHIP_TYPES', await dispatch('sanitizeMembershipTypes', response))

			return response
		} catch (error) {
			commit('SET_MEMBERSHIP_TYPES', null)
		}
	},
	async attemptGetEmailTemplates({
			state
	}) {
		const {
			id:marketplaceId
		} = state.marketplace


		return request.get(`/emails/templates?marketplaceId=${marketplaceId}`)
			.then(({data:{data}}) => data)
	},
	async attemptSendEmail(_, data) {
		const {
			message
		} = await request.post('/emails/templates/send', data)
			.then((response) => response.data)
			.catch(({
						response: error
					}) => {
				throw error
			})
			.finally(() => store.dispatch('setAppIsBusy', false))

		store.commit('SET_MESSAGE', message)

		return message
	},
	async attemptGetMarketplaceCategories(_, marketplaceId) {
		const {
			locale
		} = store.state.app

		return request.get('/categories/resource', {
			params: {
				marketplaceId,
				locale
			}
		}).then(({
			data: {
				data: {
					categories
				}
			}
		}) => categories).finally(() => store.dispatch('setAppIsLoading', false))
	},
	async attemptProcessResources(_, data) {
		const {
			message
		} = await request.post('/marketplace/cross/resources', data)
			.then((response) => response.data)
			.catch(({
				response: error
			}) => {
				throw error
			})
			.finally(() => store.dispatch('setAppIsBusy', false))

		store.commit('SET_MESSAGE', message)

		return message
	}
}
