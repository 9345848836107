<template>
    <div>
        <!-- <div v-show="isFiltering" id="payouts-table-search-pane" class="card shadow-sm mb-3"></div>
		<card-component :waves="true">
			<table class="table compact hover display responsive nowrap w-100" id="payouts-table" aria-describedby="payouts-table">
				<thead>
					<tr>
						<th scope="row">ID</th>
						<th scope="col">Name</th>
						<th scope="col">Email</th>
						<th scope="col">Account Number</th>
						<th scope="col">Institution Number</th>
						<th scope="col">Routing Number</th>
						<th scope="col">Payout Status</th>
						<th scope="col">Invoice Final Amount</th>
						<th scope="col">Options</th>
					</tr>
				</thead>
			</table>
		</card-component>

		-->

		<payouts-table-filters v-show="state.isFiltering"></payouts-table-filters>
		<table-component
            :header="header"
            :data="data"
            :total="total"
            :cta="cta"
            :state="state"
            :id="'payouts'"
            @setData="setData"
        >
        </table-component>
		<update-payout-modal-component @update="getData"></update-payout-modal-component>
    </div>
</template>

<script>
	// import { mapGetters } from 'vuex';
	// import { mapActions } from 'vuex';
	import { request } from '@/config';
	import { table, transactions } from '@/mixins';

	export default {
		name: 'PayoutsTableComponent',
		mixins: [table, transactions.payouts],
		data() {
			return {
				header: [
					{ label: 'ID', type: 'id' },
					{ label: this.$t('last-name'), type: 'text' },
					{ label: this.$t('email'), type: 'email' },
					{ label: this.$t('account-number'), type: 'text' },
					{ label: this.$t('institution-number'), type: 'text' },
					{ label: this.$t('routing-number'), type: 'text' },
					{ label: this.$t('payout-status'), type: 'html' },
					{ label: this.$t('invoice-final-amount'), type: 'text' },
				],
				data: {
					table: [],
					metaData: [],
				},
				total: 0,
				cta: null,
			};
		},
		mounted() {
			this.getData();
		},
		watch: {
			'state.page': function (page) {
				if (page) {
					this.getData();
				}
			},
		},
		methods: {
			setData(data) {
				this.data = data;
			},
			getData: async function () {
				const { toCurrency } = this.$options.filters;

				try {
					const data = {
						table: [],
						metaData: [],
					};

					const { data: payouts, total } = await request
						.get('/payout', {
							headers: {
								Authorization: `Bearer ${this.token}`,
							},
							params: {
								marketplaceId: this.marketplaceId,
								locale: this.locale,
								isDatatable: true,
								page: this.state.page,
								load: this.state.perPage,
							},
						})
						.then(({ data: { data: response } }) => response);

					payouts.forEach((item) => {
						const { firstName, lastName } = item.account;

						data.table.push([
							item.id,
							`${firstName} ${lastName}`,
							item.account.email,
							item.account.accountNumber,
							item.account.institutionNumber,
							item.account.routingNumber,
							this.payoutStatus(item.status.desc),
							toCurrency(item.amount),
						]);
					});

					this.data = data;
					this.total = total;
					this.state.isLoading = false;
				} catch (err) {
					console.log({ err });
				}
			},
		},
		// mounted() {
		// 	this.table = window.$('#payouts-table').DataTable({
		// 		processing: true,
		// 		ajax: {
		// 			headers: {
		// 				Authorization: `Bearer ${this.token}`,
		// 			},
		// 			url: `${process.env.VUE_APP_API_URL}/payout`,
		// 			data: {
		// 				topCompanyId: this.topCompanyId,
		// 				locale: 'default',
		// 			},
		// 			dataSrc: 'data.payouts',
		// 		},
		// 		...this.config,
		// 		deferRender: true,
		// 		columns: [
		// 			{ data: 'id' },
		// 			{ data: 'account' },
		// 			{ data: 'account.email' },
		// 			{ data: 'account.accountNumber' },
		// 			{ data: 'account.institutionNumber' },
		// 			{ data: 'account.routingNumber' },
		// 			{ data: 'status.desc' },
		// 			{ data: 'amount' },
		// 			{ data: 'id' },
		// 		],
		// 		columnDefs: [
		// 			{
		// 				targets: [3, 4, 5, 6],
		// 				className: 'text-center',
		// 			},
		// 			{
		// 				targets: 1,
		// 				render: ({ firstName, lastName }) =>
		// 					`${firstName} ${lastName}`,
		// 			},
		// 			{
		// 				targets: 2,
		// 				render: (data) =>
		// 					`<div class="email" onclick="setuid('${data}')">
		// 							${data}
		// 						</div>`,
		// 			},
		// 			{
		// 				targets: 6,
		// 				render: (data) => this.payoutStatus(data),
		// 			},
		// 			{
		// 				targets: 7,
		// 				render: (data) => this.$options.filters.toCurrency(data),
		// 			},
		// 			{
		// 				targets: 8,
		// 				orderable: false,
		// 				render: (data, type, { status: { id: status } }) => {
		// 					const disabled = status === 11 ? 'disabled' : '';
		// 					return `<a href="#edit-payout" data-toggle="modal" data-id="${data}" data-status="${status}" class="btn btn-white btn-sm shadow-sm ${disabled}"><em data-feather="pencil"></em> Update</a>`;
		// 				},
		// 				className: 'text-center',
		// 			},
		// 		],
		// 		searchPanes: {
		// 			columns: [1, 2, 3, 4, 5, 6],
		// 			cascadePanes: true,
		// 			viewTotal: true,
		// 			layout: 'columns-3',
		// 		},
		// 		language: this.setLanguage(),
		// 		initComplete: () => {
		// 			this.scrollDrag('payouts-table_wrapper');
		// 		},
		// 		drawCallback: () => {},
		// 		stateSave: false,
		// 	});
		// 	this.initDataTableButtons(
		// 		this.table,
		// 		'#payouts-table_length',
		// 		this.filename,
		// 		[0, 1, 2, 3, 4, 5]
		// 	);
		// 	this.initDataTableFilter(
		// 		this.table,
		// 		'#payouts-table_filter',
		// 		'#payouts-table-search-pane'
		// 	);
		// },
		// data() {
		// 	return {
		// 		table: null,
		// 		filename: 'Payouts',
		// 	};
		// },
		// computed: {
		// 	...mapGetters({
		// 		marketplace: 'marketplace/marketplace',
		// 	}),
		// 	topCompanyId() {
		// 		const { topCompanyId } = this.marketplace;
		// 		return topCompanyId;
		// 	},
		// },
	};
</script>

<style>
</style>
