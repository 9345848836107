<template>
    <div>
        <div v-show="isFiltering" id="negotiations-table-search-pane" class="card shadow-sm mb-3"></div>
		<card-component :waves="true">
			<table class="table compact hover display responsive nowrap w-100" id="negotiations-table" aria-describedby="negotiations-table">
				<thead>
					<tr>
						<th scope="col">{{$t('buyer')}}</th>
						<th scope="col">{{$t('seller')}}</th>
						<th scope="col">{{$t('resource')}}</th>
						<th scope="col">{{$t('price')}}</th>
						<th scope="col">{{$t('start-date')}}</th>
						<th scope="col">{{$t('end-date')}}</th>
						<th scope="col">{{$t('status')}}</th>
						<th scope="col">{{$t('created-date')}}</th>
						<th scope="row">ID</th>
						<th scope="col">Options</th>
					</tr>
				</thead>
			</table>
		</card-component>
    </div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex';
	import { datatables, transactions } from '@/mixins';

	export default {
		name: 'NegotiationsTableComponent',
		mixins: [datatables, transactions.negotiations],
		mounted() {
			window.cancelNegotiation = (negotiationId) =>
				this.updateNegotiation(negotiationId, 'cancel');
			window.activateNegotiation = (negotiationId) =>
				this.updateNegotiation(negotiationId, 'reactivate');

			const { toCurrency, toDate, placeholder } = this.$options.filters;

			this.table = window.$('#negotiations-table').DataTable({
				processing: true,
				ajax: {
					headers: {
						Authorization: `Bearer ${this.token}`,
					},
					url: `${process.env.VUE_APP_API_URL}/negotiations`,
					data: {
						marketplaceId: this.marketplaceId,
						locale: 'en',
					},
					dataSrc: 'data.negotiations',
				},
				...this.config,
				deferRender: true,
				columns: [
					{ data: 'buyer.email' },
					{ data: 'seller.email' },
					{ data: 'resource' },
					{ data: 'finalPrice' },
					{ data: 'startDate' },
					{ data: 'endDate' },
					{ data: 'status' },
					{ data: 'createdAt' },
					{ data: 'id' },
					{ data: 'id' },
				],
				columnDefs: [
					{
						targets: [4, 7],
						className: 'text-center',
					},
					{
						targets: 0,
						render: (data) =>
							`<div class="email" onclick="setuid('${data}')">
									${data}
								</div>`,
					},
					{
						targets: 1,
						render: (data) =>
							`<div class="email" onclick="setuid('${data}')">
									${data}
								</div>`,
					},
					{
						targets: 2,
						render: ({ locale }) => {
							const localeData = this.getObjectLocale(locale);
							if (localeData) {
								return placeholder(localeData.title.toString());
							}
							return '';
						},
					},
					{
						targets: 3,
						render: (data) => toCurrency(data),
					},
					{
						targets: 4,
						render: (data) => placeholder(toDate(data, 'Do MMMM YYYY')),
					},
					{
						targets: 5,
						render: (data) => placeholder(toDate(data, 'Do MMMM YYYY')),
					},
					{
						targets: 6,
						render: (data) => this.negotiationStatus(data),
					},
					{
						targets: 7,
						render: (data) => toDate(data, 'Do MMMM YYYY'),
					},
					{
						targets: 9,
						orderable: false,
						render: (data, type, { status }) => {
							const canCancel =
								status === 1
									? `<button type="button" class="btn btn-danger btn-sm shadow-sm" onclick="cancelNegotiation('${data}')"><em data-feather="x-circle"></em> Cancel</button>`
									: '';
							const canReactivate =
								status === 3
									? `<button type="button" class="btn btn-success btn-sm shadow-sm" onclick="activateNegotiation('${data}')"><em data-feather="x-circle"></em> Re-activate</button>`
									: '';

							return `<a href="/dashboard/transactions/negotiations/${data}" class="btn btn-white btn-sm shadow-sm mr-2"><em data-feather="eye"></em> View</a> ${canCancel} ${canReactivate}`;
						},
					},
				],
				searchPanes: {
					columns: [0, 1, 2, 3, 4, 5, 6, 7, 8],
					cascadePanes: true,
					viewTotal: true,
					layout: 'columns-4',
				},
				language: this.setLanguage(),
				initComplete: () => {
					this.scrollDrag('negotiations-table_wrapper');
				},
				drawCallback: () => {},
				stateSave: false,
			});

			this.initDataTableButtons(
				this.table,
				'#negotiations-table_length',
				this.filename,
				[0, 1, 2, 3, 4, 5, 6, 7, 8]
			);
			this.initDataTableFilter(
				this.table,
				'#negotiations-table_filter',
				'#negotiations-table-search-pane'
			);
		},
		data() {
			return {
				filename: 'Negotiations',
			};
		},
		computed: {
			...mapGetters({
				marketplace: 'marketplace/marketplace',
			}),
			marketplaceId() {
				const { id } = this.marketplace;

				return id;
			},
		},
		methods: {
			...mapActions({
				attemptNegotiationUpdate:
					'transactions/negotiations/attemptNegotiationUpdate',
			}),
			updateNegotiation(negotiationId, type) {
				const answer = window.confirm(
					`Do you really want to ${type} this negotiation?\nNegotiation ID: ${negotiationId}`
				);

				if (answer) {
					this.attemptNegotiationUpdate({ negotiationId, type })
						.then(() => this.reloadTable())
						.catch((e) => this.validationErrors(e));
				}
			},
		},
	};
</script>

<style>
</style>
