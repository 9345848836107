import administrators from './administrators'
import banners from './banners'
import resources from './resources'
import library from './library'
import email from './emails'
import create from './create'

export default [
	...administrators,
	...banners,
	...resources,
	...library,
	...email,
	...create
]
