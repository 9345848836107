<template>
	<div>
		<form v-if="masterCategories" @submit.prevent="updateSubAdministrator">
			<breadcrumb :label="$t('manage-admin')">
				<button-component :label="$t('save')" :busy="isBusy" :button-class="'btn btn-primary btn-sm mr-2'"></button-component>
				<router-link class="btn btn-white btn-sm shadow-sm" :to="{ name: 'dashboard.sub-administrators.index'}" exact>
					{{$t('cancel')}}
				</router-link>
			</breadcrumb>

			<div class="row">
				<div class="col-lg-6">
					<card-component :waves="true">
						<div class="row">
							<div class="form-group col-12">
								<label>{{$t('first-name')}}</label>
								<input v-model="form.firstName" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs['firstName']" required>
								<div class="invalid-feedback d-block" v-html="validation.message"></div>
							</div>

							<div class="form-group col-12">
								<label>{{$t('last-name')}}</label>
								<input v-model="form.lastName" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs['lastName']" required>
								<div class="invalid-feedback d-block" v-html="validation.message"></div>
							</div>

							<div class="form-group col-12">
								<label>{{$t('username')}}</label>
								<p class="mb-0">{{ form.username|placeholder }}</p>
							</div>

							<div class="form-group col-12">
								<label>Locale</label>
								<select v-model="form.locale" class="form-control form-control-sm shadow-sm" required>
									<option disabled :value="null">{{$t('select-option')}}</option>
									<option v-for="locale in locales" :value="locale" :key="locale" >{{ locale|toStartCase }}</option>
								</select>
								<div class="invalid-feedback d-block" v-html="validation.message"></div>
							</div>
						</div>
					</card-component>
				</div>

				<div class="col-lg-6">
					<card-component :waves="true">
						<permissions-component :permissions="form.permissions" :categories="masterCategories" :field="'permissions'" :validation="validation" @input="setInput"></permissions-component>
					</card-component>
				</div>
			</div>

		</form>
		<div v-else>
			<div v-if="isBusy" class="spinner">
				<spinner-component></spinner-component>
			</div>
		</div>
	</div>
</template>

<style>
</style>

<script>
	import { mapGetters, mapActions } from 'vuex';
	import { activity, validation } from '@/mixins';

	export default {
		name: 'SubAdministratorsEdit',
		mixins: [activity, validation],
		created() {
			this.administratorId = this.$route.params.administratorId;

			this.fetchData(this.administratorId);
		},
		data() {
			return {
				administratorId: null,
				form: {
					firstName: null,
					lastName: null,
					username: null,
					locale:null,
					userRoleId: null,
					isInitialized: false,
					permissions: {
						users: {
							setuid: false,
							activation_links: false,
							members: { report: false, import: false, edit: false },
						},
						resources: { report: false, import: false, edit: false },
						categories: {
							resource: {
								create: false,
								edit: false,
								delete: false,
								report: false,
							},
							member: {
								create: false,
								edit: false,
								delete: false,
								report: false,
							},
						},
						banners: { create: false, edit: false },
						transactions: {
							negotiations: { report: false, edit: false },
							payments: { report: false, edit: false },
							shipping: { report: false, edit: false },
							fees: { create: false, report: false, edit: false },
							payouts: { report: false, edit: false },
						},
						groupAccess: [],
					},
				},
			};
		},
		computed: {
			...mapGetters({
				isBusy: 'isBusy',
				masterCategories: 'categories/companyCategories',
				marketplace: 'marketplace/marketplace',
				administrator: 'administration/administrator',
			}),
			locales() {
				const { locales } = this.marketplace;

				return locales;
			},
			partners() {
				const { partners } = this.marketplace;

				partners.map(p => {
					p.text = p.name
				})

				return partners;
			}
		},
		watch: {
			$route: function () {
				this.fetchData(this.administratorId);
			},
			administrator(administrator) {
				const {
					firstName,
					lastName,
					username,
					locale,
					userRole: { id: userRoleId, permissions },
				} = administrator;


				this.form = {
					firstName,
					lastName,
					username,
					locale : locale ? locale : null,
					isInitialized: true,
					userRoleId,
					permissions,
				};
			},
		},
		methods: {
			...mapActions({
				setAppState: 'setAppIsBusy',
				attemptGetCompanyCategories:
					'categories/attemptGetCompanyCategories',
				attemptSubAdministratorUpdate:
					'administration/attemptSubAdministratorUpdate',
				attemptGetSubAdministrator:
					'administration/attemptGetSubAdministrator',
			}),
			fetchData(administratorId) {
				this.setAppState(true);
				this.attemptGetSubAdministrator(administratorId);
			},
			performHouseActivities() {},
			updateSubAdministrator() {
				this.setAppState(true);

				if(typeof(this.form.permissions.groupAccess) === 'undefined' || this.form.permissions.groupAccess.length === 0){
					this.$delete(this.form.permissions, 'groupAccess')
				}

				this.attemptSubAdministratorUpdate({
					data: this.form,
					administratorId: this.administratorId,
				})
					.then(() => {
						this.hasActivity = false;
						this.shouldWatch = false;
						this.fetchData(this.administratorId);
					})
					.catch((e) => this.validationErrors(e));
			},
		},
	};
</script>
