export default {
	beforeRouteLeave(to, from, next) {
		if (this.hasActivity) {
			const answer = window.confirm(this.activityMessage)

			if (answer) {
				this.performHouseActivities()
			} else {
				return next(false)
			}
		}

		return next()
	},
	data() {
		return {
			hasActivity: false,
			shouldWatch: false,
			activityMessage: 'Do you really want to leave? You have unsaved changes!'
		}
	},
	watch: {
		form: {
			handler(current, old) {
				if (this.shouldWatch) {
					if (!this.hasActivity) {
						this.hasActivity = true
					}
				} else {
					this.hasActivity = false
				}

				this.shouldWatch = old.isInitialized
			},
			deep: true
		}
	},
	methods: {
		performHouseActivities() {

		}
	}
}
