<template>
	<div id="activation-links-table-filter"></div>
</template>

<script>
	export default {};
</script>

<style>
</style>
