import {
	isEmpty
} from 'lodash'

export default {
	isAuthenticated(state) {
		return !isEmpty(state.token)
	},
	token(state) {
		return state.token
	},
	user(state) {
		return state.user
	},
	role(state) {
		return state.role
	},
	permissions(state) {
		return state.permissions
	},
	marketplace(state) {
		return state.marketplace
	}
}
