<template>
	<div>
		<form v-if="marketplaces" @submit.prevent="processResources">
			<breadcrumb :label="$t('resources')">
				<button-component :label="'Save'" :busy="isBusy" :button-class="'btn btn-primary btn-sm mr-2'"></button-component>
				<router-link class="btn btn-white btn-sm shadow-sm" :to="{ name: 'dashboard'}" exact>
					{{$t('cancel')}}
				</router-link>
			</breadcrumb>

			<div class="row">
				<div class="col-md-5">
					<card-component :waves="true">
						<h5>From</h5>
						<div class="form-group">
							<label>Marketplace</label>
							<select v-model="form.from.marketplaceId" class="form-control form-control-sm shadow-sm" :class="validation.inputs['from.marketplaceId']" required>
								<option disabled :value="null">Select marketplace</option>
								<option v-for="(option, index) in options.from.marketplaces" :value="option.id" v-bind:key="index">{{ option.name|toStartCase }}</option>
							</select>
							<div class="invalid-feedback d-block" v-html="validation.errors['from.marketplaceId']"></div>
						</div>
						<div class="form-group">
							<label>{{$t('category')}}</label>
							<select v-model="form.from.categoryId" class="form-control form-control-sm shadow-sm" :class="validation.inputs['from.categoryId']" :disabled="form.from.marketplaceId === null" required>
								<option disabled :value="null">{{$t('select-option')}}</option>
								<option v-for="(option, index) in options.from.categories" :value="option.id" v-bind:key="index">{{ option.text|toStartCase }}</option>
							</select>
							<div class="invalid-feedback d-block" v-html="validation.errors['from.categoryId']"></div>
						</div>
						<div class="form-group">
							<label>{{$t('resources')}}</label>
							<select class="select2-multiple form-control" :v-model="form.from.resources" multiple="multiple" id="resources" :disabled="form.from.categoryId === null" required>
								<option disabled :value="null">{{$t('select-option')}}</option>
							</select>
						</div>
						<div class="form-group">
							<label>Action</label>
							<select v-model="form.action" class="form-control form-control-sm shadow-sm" :class="validation.inputs['action']" :disabled="!form.from.resources.length" required>
								<option disabled :value="null">{{$t('select-option')}}</option>
								<option value="copy">{{$t('copy')}}</option>
								<option value="move">{{$t('move')}}</option>
							</select>
							<div class="invalid-feedback d-block" v-html="validation.errors['action']"></div>
						</div>
					</card-component>

					<card-component :waves="true" v-if="options.from.specificities.length && options.to.specificities.length">
						<h5>Specificities</h5>

						<div class="row" v-for="(option, index) in options.from.specificities" v-bind:key="index">
							<div class="form-group col-6">
								{{ option.specificitiesListLocales[0].name }}

							</div>
							<div class="form-group col-6">
								<select v-model="form.specificities[index].to" class="form-control form-control-sm shadow-sm">
									<option disabled :value="null" selected>{{$t('select-option')}}</option>
									<option :value="option.id" v-for="(option, index) in filteredSpecificities(form.specificities[index].to, option.type)" v-bind:key="index">{{ option.specificitiesListLocales[0].name }}</option>
								</select>
							</div>
						</div>
					</card-component>
				</div>

				<div class="col-md-2">

				</div>

				<div class="col-md-5">
					<card-component>
						<h5>Destination</h5>
						<div class="form-group">
							<label>Marketplace</label>
							<select v-model="form.to.marketplaceId" class="form-control form-control-sm shadow-sm" :class="validation.inputs['to.marketplaceId']" :disabled="form.from.marketplaceId === null" required>
								<option disabled :value="null">Select marketplace</option>
								<option v-for="(option, index) in options.to.marketplaces" :value="option.id" v-bind:key="index">{{ option.name|toStartCase }}</option>
							</select>
							<div class="invalid-feedback d-block" v-html="validation.errors['to.marketplaceId']"></div>
						</div>
						<div class="form-group">
							<label>{{$t('category')}}</label>
							<select v-model="form.to.categoryId" class="form-control form-control-sm shadow-sm" :class="validation.inputs['to.categoryId']" :disabled="form.to.marketplaceId === null" required>
								<option disabled :value="null">{{$t('select-option')}}</option>
								<option v-for="(option, index) in options.to.categories" :value="option.id" v-bind:key="index">{{ option.text|toStartCase }}</option>
							</select>
							<div class="invalid-feedback d-block" v-html="validation.errors['to.categoryId']"></div>
						</div>
					</card-component>
				</div>
			</div>

		</form>
	</div>
</template>

<style>
	.select2-container--default .select2-selection--multiple {
		border: 1px solid #ced4da;
		box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
	}

	.select2-container--default.select2-container--focus.select2-selection--multiple,
	.select2-container--default.select2-container--focus
		.select2-selection--multiple {
		border: 1px solid #ced4da;
		box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
	}

	.select2-container .select2-search--inline .select2-search__field {
		font-size: 90%;
		margin-top: 5px;
		margin-left: 5px;
		padding: 0px 5px;
		color: #495057;
	}

	.select2-container--default
		.select2-selection--multiple
		.select2-selection__choice {
		background-color: #ffffff;
	}
</style>

<script>
	import { mapGetters, mapActions } from 'vuex';
	import { activity, validation, category } from '@/mixins';

	export default {
		name: 'ManageReources',
		mixins: [activity, validation, category],
		created() {
			this.fetchData();
		},
		data() {
			return {
				selectComponent: null,
				form: {
					action: null,
					from: {
						marketplaceId: null,
						categoryId: null,
						resources: [],
					},
					to: {
						marketplaceId: null,
						categoryId: null,
					},
					specificities: [],
				},
				options: {
					from: {
						marketplaces: [],
						categories: [],
						specificities: [],
					},
					to: {
						marketplaces: [],
						categories: [],
						specificities: [],
					},
				},
			};
		},
		mounted() {},
		computed: {
			...mapGetters({
				isBusy: 'isBusy',
				marketplaces: 'marketplace/marketplaces',
			}),
		},
		watch: {
			$route: function () {
				this.fetchData();
			},
			marketplaces(marketplaces) {
				this.options.from.marketplaces = marketplaces;
			},
			'form.from.marketplaceId': async function (value) {
				if (value) {
					this.options.to.marketplaces = this.marketplaces.filter(
						({ id }) => value !== id
					);

					const categories = await this.attemptGetMarketplaceCategories(
						value
					);

					this.options.from.categories = this.mapCategories(categories);
				}
			},
			'form.from.categoryId': async function (value) {
				if (value) {
					if (this.selectComponent != null) {
						this.selectComponent.val(null).trigger('change');
						this.selectComponent.empty().select2('destroy');
					}

					const resources = await this.attemptGetCategoryResources(value);

					this.selectComponent = window
						.$('#resources')
						.select2({
							data: resources,
							placeholder: 'Select resources',
							allowClear: true,
						})
						.on('change', () => {
							this.form.from.resources = this.selectComponent
								.select2('data')
								.map(({ id }) => id);
						});

					const { specificitiesList } =
						this.options.from.categories.filter(
							(el) => el.id === value
						)[0];

					this.options.from.specificities = specificitiesList;
				}
			},
			'form.to.marketplaceId': async function (value) {
				if (value) {
					this.options.from.marketplaces = this.marketplaces.filter(
						({ id }) => value !== id
					);

					const categories = await this.attemptGetMarketplaceCategories(
						value
					);

					this.options.to.categories = this.mapCategories(categories);
				}
			},
			'form.to.categoryId': async function (value) {
				const { specificitiesList } = this.options.to.categories.filter(
					(el) => el.id === value
				)[0];

				this.options.to.specificities = specificitiesList;
			},
			'options.from.specificities': {
				handler(value) {
					this.form.specificities = value.map((el) => {
						return {
							from: el.id,
							to: null,
						};
					});
				},
				deep: true,
			},
		},
		methods: {
			...mapActions({
				setAppState: 'setAppIsBusy',
				attemptGetCategoryResources:
					'categories/attemptGetCategoryResources',
				attemptGetMarketplaces: 'marketplace/attemptGetMarketplaces',
				attemptGetMarketplaceCategories:
					'marketplace/attemptGetMarketplaceCategories',
				attemptProcessResources: 'marketplace/attemptProcessResources',
			}),
			fetchData() {
				this.attemptGetMarketplaces();
			},
			performHouseActivities() {},
			filteredSpecificities(to, type) {
				return this.options.to.specificities.filter(
					(el) =>
						el.type === type &&
						this.form.specificities.every(
							(_el) => _el.to !== el.id || to === el.id
						)
				);
			},
			processResources() {
				this.setAppState(true);

				this.attemptProcessResources(this.form)
					.then(() => this.resetForm())
					.catch((e) => this.validationErrors(e));
			},
		},
	};
</script>
