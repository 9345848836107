<template>
    <div class="copy">
        <div class="copy__text" @click="copy" :ref="id">
            <slot></slot>
        </div>
        <div class="copy__tooltip" :class="{active: isActive, inactive: !isActive, bottom: bottom }">
            Text copied
        </div>
    </div>
</template>
<script>
	export default {
		name: 'Copy',
		data() {
			return {
				isActive: false,
			};
		},
		methods: {
			selectText(element) {
				const range = document.createRange();
				range.selectNode(element);
				window.getSelection().removeAllRanges();
				window.getSelection().addRange(range);
			},
			copy() {
				try {
					this.selectText(this.$refs[this.id]);
					document.execCommand('copy');
					window.getSelection().removeAllRanges();
					this.isActive = true;
					setTimeout(() => {
						this.isActive = false;
					}, 1000);
				} catch (err) {
					console.log({ err });
				}
			},
		},
		props: {
			id: {
				type: String,
			},
			bottom: {
				type: Boolean,
				required: false
			}
		},
	};
</script>

<style lang="scss">
	.copy {
		width: 100%;
		height: 100%;
		cursor: pointer;
		position: relative;
		&__text {
			width: 100%;
			height: 100%;
		}
		&__tooltip {
			position: absolute;
			bottom: 0%;
			right: 0;
			margin: auto;
			z-index: 2;
			background: rgb(36, 36, 36);
			padding: 0.2rem 0.75rem;
			transition: all 0.3s ease-in;
			color: white;
			width: max-content;
			border-radius: 5px;

			&::after {
				position: absolute;
				top: 98%;
				left: 0;
				right: 0;
				margin: auto;
				content: '';
				width: 10px;
				height: 10px;
				background: rgb(36, 36, 36);
				clip-path: polygon(50% 100%, 0 0, 100% 0);
			}

			&.bottom {
				&::after {
					top: -25%;
					clip-path: polygon(50% 0, 0 100%, 100% 100%);
				}
			}

			&.active {
				transform: translateY(-100%) scale(1);
				&.bottom {
					transform: translateY(150%) scale(1);
				}
			}
			&.inactive {
				transform: translateY(0%) scale(0);
			}
		}
	}
</style>
