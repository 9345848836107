import {
	request
} from '@/config'
import store from '@/store'

export default {
	setMessage({
		commit
	}, value) {
		commit('SET_MESSAGE', value)
	},
	async attemptSetUid(_, credentials) {
		return request.post('/uid/request', credentials)
			.then(({
				data: {
					data: {
						signedUrl
					}
				}
			}) => signedUrl)
			.catch(({
				response: error
			}) => {
				throw error
			})
	},
	async attemptResendActivationLink(_, params) {
		const {
			companyInfo: {
				marketplace: {
					id: marketplaceId
				}
			}
		} = store.state.auth.user

		console.log(params.set)
		const response = await request.post(`/email/resend/${params.email}`, {
			marketplaceId,
			set : params.set
		}).then(() => `An activation link has been sent to "${params.email}".`).catch(({
			response: error
		}) => {
			throw error
		})

		store.commit('SET_MESSAGE', response)

		return response
	},
	async attemptGetMember({
		commit
	}, memberId) {
		const {
			id: marketplaceId
		} = store.getters['marketplace/marketplace']

		const {
			locale
		} = store.state.app

		const member = await request.get(`/users/company/${memberId}`, {
			params: {
				marketplaceId,
				locale
			}
		}).then(({
			data: {
				data: {
					member: _member
				}
			}
		}) => _member).catch(({
			response: {
				data: {
					message: error
				}
			}
		}) => {
			throw error
		}).finally(() => store.dispatch('setAppIsBusy', false))

		commit('SET_MEMBER', member)
	},
	async attemptUpdateMemberStatus(_, {
		memberId,
		status
	}) {
		const response = await request.post('/users/set-status', {
				memberId,
				status: !status
			})
			.then(({
				data: {
					message
				}
			}) => message)
			.catch(({
				response: error
			}) => {
				throw error
			})

		store.commit('SET_MESSAGE', response)

		return response
	},
	async attemptMembersUpload(_, {
		data,
		sendPasswordEmail
	}) {
		const {
			companyInfo: {
				marketplace: {
					domain
				}
			}
		} = store.state.auth.user

		const response = await request.post('/users/upload', {
				data,
				domain,
				sendPasswordEmail
			})
			.then(({
				data: {
					message
				}
			}) => message)
			.catch(({
				response: error
			}) => {
				throw error
			}).finally(() => store.dispatch('setAppIsBusy', false))

		store.commit('SET_MESSAGE', response)

		return response
	},
	async attemptMemberUpdate(_, {
		data,
		memberId
	}) {
		const response = await request.patch(`/users/company/${memberId}`, data)
			.then(({
				data: {
					message
				}
			}) => message)
			.catch(({
				response: error
			}) => {
				throw error
			}).finally(() => store.dispatch('setAppIsBusy', false))

		store.commit('SET_MESSAGE', response)

		return response
	},
	setMembersList({
		commit
	}, list) {
		commit('SET_MEMBERS_LIST', list)
	}
}
