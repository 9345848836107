<template>
    <form @submit.prevent="resetPassword">
        <img class="mb-4" src="@/assets/img/logo.png" alt="logo" width="72" height="72">

        <h1 class="h3 mb-3 font-weight-normal">Create a New Password</h1>
        <label for="new-password" class="sr-only">New Password</label>
        <input v-model="form.password" type="password" id="new-password" class="form-control form-control-sm new-password" :class="validation.inputs.password" placeholder="Enter a new password">

        <label for="confirm-password" class="sr-only">Confirm Password</label>
        <input v-model="form.confirmPassword" type="password" id="confirm-password" class="form-control form-control-sm confirm-password" :class="validation.inputs.confirmPassword" placeholder="Enter password again">

        <button-component :label="'Change Password'"></button-component>

        <div class="invalid-feedback d-block" v-html="validation.message"></div>
    </form>
</template>

<style scoped>
    .new-password {
        margin-bottom: -1px!important;
        border-top-right-radius: .25rem!important;
        border-top-left-radius: .25rem!important;
        border-bottom-right-radius: 0!important;
        border-bottom-left-radius: 0!important;
    }

    .confirm-password {
        margin-bottom: 10px!important;
        border-bottom-right-radius: .25rem!important;
        border-bottom-left-radius: .25rem!important;
    }
</style>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import { validation } from '@/mixins'

    export default {
        name: "ResetView",
        mixins: [validation],
        data() {
            const {params, query} = this.$route
            const {id, confirmEmailToken} = params
            const {signature} = query

            return{
                form: {
                    id,
                    confirmEmailToken,
                    signature,
                    password: null,
                    confirmPassword: null
                }
            }
        },
        watch: {},
        computed: {
            ...mapGetters({
                isBusy: 'isBusy'
            })
        },
        methods: {
            ...mapActions({
                setAppState: 'setAppIsBusy',
                attemptResetPassword: 'auth/attemptResetPassword'
            }),
            close() {
                this.resetLinkSent = null
            },
            resetPassword() {
                this.setAppState(true)

                this.attemptResetPassword(this.form).then(() => {
                    this.$router.push({name: 'login'})
                }).catch((e) => {
                    this.validationErrors(e)
                }).finally(() => {
                    this.setAppState(false)
                })
            }
        }
    }
</script>

