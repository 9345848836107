<template>
	<div>
		<form @submit.prevent="createFee">
			<breadcrumb :label="$t('new-fee')">
				<button-component :label="$t('save')" :busy="isBusy" :button-class="'btn btn-primary btn-sm mr-2'"></button-component>
				<router-link class="btn btn-white btn-sm shadow-sm" :to="{ name: 'dashboard.transactions.fees.index'}">
					{{$t('cancel')}}
				</router-link>
			</breadcrumb>

			<div class="col-lg-4 col-md-6 m-auto">
                <card-component :waves="true">
					<div class="form-group">
						<label>{{$t('title')}}</label>
						<input v-model="form.title" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs['title']">
						<div class="invalid-feedback d-block" v-html="validation.errors['title']"></div>
					</div>
					<div class="form-group">
						<label>{{$t('description')}} / en</label>
						<input v-model="form.description.en" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs['description.en']">
						<div class="invalid-feedback d-block" v-html="validation.errors['description.en']"></div>
					</div>
					<div class="form-group">
						<label>{{$t('description')}} / fr</label>
						<input v-model="form.description.fr" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs['description.fr']">
						<div class="invalid-feedback d-block" v-html="validation.errors['description.fr']"></div>
					</div>
					<div class="form-group">
						<label>{{$t('value')}}</label>
						<input v-model="form.value" type="number" step="any" class="form-control form-control-sm shadow-sm" :class="validation.inputs['value']">
						<div class="invalid-feedback d-block" v-html="validation.errors['value']"></div>
					</div>
					<div class="form-group">
						<label>{{$t('subscription-type')}}</label>
						<select v-model="form.membershipType" class="form-control form-control-sm shadow-sm" :class="validation.inputs['membershipType']">
							<option disabled :value="null">{{$t('select-option')}}</option>
							<option v-for="(type, index) in this.filteredTypes" :value="index" v-bind:key="index">{{ feeMembershipType(index) }}</option>
						</select>
						<div class="invalid-feedback d-block" v-html="validation.errors['membershipType']"></div>
					</div>
					<div class="form-group">
							<label>{{$t('charged-to')}}</label>
							<select v-model="form.chargedTo" class="form-control form-control-sm shadow-sm" :class="validation.inputs['chargedTo']">
								<option disabled :value="null">{{$t('select-option')}}</option>
								<option v-for="(value, index) in chargedTo" :value="value" v-bind:key="index">{{ $t(value) }}</option>
							</select>
							<div class="invalid-feedback d-block" v-html="validation.errors['chargedTo']"></div>
						</div>
					<div class="form-group">
						<label>{{$t('status')}}</label>
						<select v-model="form.active" class="form-control form-control-sm shadow-sm" :class="validation.inputs['active']">
							<option disabled :value="null">{{$t('select-option')}}</option>
							<option v-for="({value, name}, index) in status" :value="value" v-bind:key="index">{{ $t(name) }}</option>
						</select>
						<div class="invalid-feedback d-block" v-html="validation.errors['active']"></div>
					</div>
                </card-component>
            </div>
		</form>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex';
	import { activity, validation, transactions } from '@/mixins';

	export default {
		name: 'FeesCreate',
		mixins: [activity, validation, transactions.fees],
		data() {
			return {
				form: {
					isInitialized: true,
					title: null,
					description: {
						en: null,
						fr: null,
					},
					membershipType: null,
					value: null,
					chargedTo: null,
					active: null,
					marketplaceId: null,
				},
			};
		},
		computed: {
			...mapGetters({
				isBusy: 'isBusy',
				marketplace: 'marketplace/marketplace',
				chargedTo: 'transactions/fees/chargedTo',
				status: 'transactions/fees/status',
				membershipTypes: 'marketplace/membershipTypes',
				user: 'auth/user'
			}),
			filteredTypes(){
				let types = this.membershipTypes
				let obj = {}
				if (this.user.permissions.groupAccess){
					const filtered = this.marketplace.partners
						.filter((part) => this.user.permissions.groupAccess.includes(part.categoryId))
						.map(p => p.id)

					types = Object.entries(this.membershipTypes)
						.map(item => {
							item[1].id = item[0]
							return item[1]
						})
						.filter(item => filtered.includes(item.partnerId))

					types.forEach(function(data){
						obj[data.id] = data
					});
				}else{
					obj = this.membershipTypes
				}

				return obj
			}
		},
		methods: {
			...mapActions({
				setAppState: 'setAppIsBusy',
				attemptFeeCreation: 'transactions/fees/attemptFeeCreation',
			}),
			createFee() {
				this.setAppState(true);
				const { id } = this.marketplace;
				this.attemptFeeCreation({
					...this.form,
					marketplaceId: id,
				})
					.then(() => {
						this.setAppState(false)
						this.hasActivity = false;
						this.shouldWatch = false;
						this.$router.push({
							name: 'dashboard.transactions.fees.index'
						})
					})
					.catch((e) => this.validationErrors(e));
			},
		},
	};
</script>
