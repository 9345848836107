import {
	findKey, map
} from 'lodash';
import style from '@/store/json/badgeStyle.json'
import constants from './constants'

const {
	_class,
} = style

const {
	NEGOTIATION_STATUS
} = constants

export default {
	methods: {
		getNegotiationParties(parties){
			const [ buyer, seller ] = map(parties, ({ firstName, lastName }) => `${firstName} ${lastName}`)

			return {
				buyer,
				seller
			}
		},
		paymentStatus(status, isBlock = false) {
			const block = isBlock ? 'd-block' : ''
			status = (!status) ? 'unknown' : findKey(NEGOTIATION_STATUS, (value) => value === status)

			return `<span class="badge badge-sm badge-dim ${
                _class[status.toLowerCase()]
            } ${block}">${status.toUpperCase()}</span>`
		},
	}
}
