import store from '@/store'

export default [{
		path: 'negotiations',
		name: 'dashboard.transactions.negotiations.index',
		component: () => import('@/views/dashboard/transactions/negotiations/NegotiationsView.vue'),
		beforeEnter: (to, from, next) => {
			const {
				report
			} = store.getters['auth/permissions'].transactions.negotiations

			if (report) {
				return next()
			}

			return next({
				name: 'dashboard'
			})
		},
		meta: {
			parent: 'transactions'
		}
	},
	{
		path: 'negotiations_report',
		name: 'dashboard.transactions.negotiations.report',
		component: () => import('@/views/dashboard/transactions/negotiations/NegotiationsReport.vue'),
		meta: {
			parent: 'transactions'
		}
	},
	{
		path: 'incoming_report',
		name: 'dashboard.marketplaces.incoming.funds',
		component: () => import('@/views/dashboard/marketplace/report/IncomingFundReport.vue'),
	},
	{
		path: 'negotiations/:negotiationId',
		name: 'dashboard.transactions.negotiations.view',
		component: () => import('@/views/dashboard/transactions/negotiations/NegotiationView.vue'),
		beforeEnter: (to, from, next) => {
			const {
				edit
			} = store.getters['auth/permissions'].transactions.negotiations

			if (edit) {
				return next()
			}

			return next({
				name: 'dashboard'
			})
		},
		meta: {
			parent: 'transactions'
		}
	}
]
