<template>
	<div>
		<h5>
			{{$t('permissions')}}

			<div class="custom-control custom-checkbox d-inline float-right">
				<input type="checkbox" class="custom-control-input" id="selectAll" v-model="selectAll">
				<label class="custom-control-label" for="selectAll">{{$t('select-all')}}</label>
			</div>
		</h5>
		<permissions-tree-component :permissions="permissions"></permissions-tree-component>

		<h5>{{$t('group-access')}}</h5>
		<group-access-component :group-access="permissionsList.groupAccess" :field="'permissions.groupAccess'" :partners="partners" @setGroupAccess="setGroupAccess">

		</group-access-component>
	</div>
</template>

<script>
	import { isObject } from 'lodash';

	export default {
		name: 'PermissionsComponent',
		data() {
			return {
				permissionsList: this.permissions,
				selectAll: false,
			};
		},
		watch: {
			permissionsList: {
				handler: function (value) {
					this.$emit('input', {
						field: this.field,
						value,
					});
				},
				deep: true,
			},
			permissions(value) {
				this.permissionsList = value;
			},
			selectAll(value) {
				this.setAll(this.permissionsList, value);
			},
		},
		methods: {
			setGroupAccess(value) {
				this.permissionsList.groupAccess = value;
			},
			setAll(obj, value) {
				Object.keys(obj).forEach((index) => {
					if (index !== 'groupAccess') {
						if (isObject(obj[index])) {
							this.setAll(obj[index], value);
						} else {
							obj[index] = value;
						}
					}
				});
			},
		},
		props: {
			partners: {
				type: Array,
				required: true,
			},
			field: {
				type: String,
				required: true,
			},
			permissions: {
				type: Object,
				required: true,
			},
			validation: {
				type: Object,
			},
			isEdit: {
				type: Boolean,
				default: false,
			},
		},
	};
</script>

<style>
</style>
