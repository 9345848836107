import store from '@/store'

function middleware(to, from, next) {
	const {
		name
	} = store.getters['auth/user'].role

	if (name === 'admin') {
		return next()
	}

	return next({
		name: 'dashboard'
	})
}

export default [{
		path: 'create-marketplace',
		name: 'dashboard.create-marketplace',
		component: () => import('@/views/dashboard/marketplace/create/CreateMarketplace.vue'),
		beforeEnter: (to, from, next) => middleware(to, from, next)
	}
]
