<template>
    <div>
        <breadcrumb :label="$t('members')">
            <router-link class="btn btn-outline-primary btn-sm shadow-sm" :to="{ name: 'dashboard.users.members.import'}">
                <em class="bi bi-upload"></em> {{$t('import')}}
            </router-link>
        </breadcrumb>
        <members-table-component></members-table-component>
    </div>
</template>

<script>
	export default {
		name: 'MembersView',
    }
</script>

