<template>
	<div>
	<breadcrumb :label="$t('incomingFundReport')"></breadcrumb>
	<div class='card'>
		<div class='card-body' style='justify-content: center; display: flex'>
			<form @submit.prevent="exportResources" ref="form">
				<div class='row'>
					<div class="form-group col-md-6">
						<label>{{$t('start-date')}}</label>
						<input v-model="startDate" type="date" class="form-control form-control-sm">
					</div>

					<div class="form-group col-md-6">
						<label>{{$t('end-date')}}</label>
						<input v-model="endDate" type="date" class="form-control form-control-sm">
					</div>
				</div>

				<button-component :label="this.$t('generate')" :busy="isBusy" :buttonClass="'btn btn-primary'"></button-component>
			</form>
		</div>
	</div>
	</div>
</template>

<script>


import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'Incoming Fund',
	data() {
		return {
			startDate:null,
			endDate:null
		};
	},
	computed: {
		...mapGetters({
			isBusy: 'isBusy',
			marketplace: 'marketplace/marketplace'
		}),
	},
	methods: {
		...mapActions({
			setAppState: 'setAppIsBusy',
			attemptGetNegotiationReport: 'resources/attemptGetNegotiationReport',
			attemptGetIncomingReport: 'resources/attemptGetIncomingReport',
		}),
		exportResources() {
			this.setAppState(true);

			console.log(this.marketplace)

			this.attemptGetIncomingReport({
				marketplaceId : this.marketplace.id ,
				productId: this.marketplace.stripeProductId,
				startDate: this.startDate,
				endDate: this.endDate
			})
				.then(() => window.$('#export-as').modal('hide'))
				.catch((e) => this.validationErrors(e));
		},
	},
};
</script>

<style>
</style>
