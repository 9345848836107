<template>
    <div>
        <form-wizard title="Create Marketplace" subtitle="">
            <tab-content title="Branding">
                <div class='row'>
                    <div class="col-xl-3 form-group" style='display: grid'>
                        <h7> Name (Fullname)</h7>
                        <input type='text' class='form-control shadow-sm' v-model="form.fullname"  >
                    </div>

                    <div class="col-xl-3 form-group" style='display: grid'>
                        <h7> Short name (no spaces)</h7>
                        <input type='text' class='form-control shadow-sm' v-model="form.shortname"  >
                    </div>

                    <div class="col-xl-3 form-group" style='display: grid'>
                        <h7> Marketplace Domain (No www or https)</h7>
                        <input type='text' class='form-control shadow-sm' v-model="form.domain" >
                    </div>	
                    
                    <div class="col-xl-3 form-group" style='display: grid'>
                        <h7> Public URL (No www or https)</h7>
                        <input type='text' class='form-control shadow-sm' v-model="form.public_url"  >
                    </div>	
		        </div>
            </tab-content>
            <tab-content title="Logos / Images">
                <div class='row'>
                    <div class="col-xl-2 form-group" style='display: grid'>
                        <h7> Dark logo</h7>
                        <input type='file' class='form-control shadow-sm' v-on:keydown.enter.prevent='addEmailToList'  >
                    </div>

                    <div class="col-xl-2 form-group" style='display: grid'>
                        <h7> Bright logo</h7>
                        <input type='file' class='form-control shadow-sm' v-on:keydown.enter.prevent='addEmailToList'  >
                    </div>

                    <div class="col-xl-2 form-group" style='display: grid'>
                        <h7> Favicon</h7>
                        <input type='file' class='form-control shadow-sm' v-on:keydown.enter.prevent='addEmailToList'  >
                    </div>
                    
                    <div class="col-xl-3 form-group" style='display: grid'>
                        <h7> Email top banner (optional)</h7>
                        <input type='file' class='form-control shadow-sm' v-on:keydown.enter.prevent='addEmailToList'  >
                    </div>

                    <div class="col-xl-3 form-group" style='display: grid'>
                        <h7> Email bottom banner (optional) </h7>
                        <input type='file' class='form-control shadow-sm' v-on:keydown.enter.prevent='addEmailToList'  >
                    </div>
		        </div>
            </tab-content>

            <tab-content title="Colors">
                <div class='row'>
                    <div class="col-xl-2 form-group" style='display: grid'>
                        <h7> Dark </h7>
                        <p class="form-label"><verte style="position:absolute;display: contents" v-model='form.color.dark' picker="wheel" model="hex"></verte></p>				</div>
                    <div class="col-xl-2 form-group" style='display: grid'>
                        <h7> Semi-bright </h7>
                        <p class="form-label"><verte style="position:absolute;display: contents" v-model='form.color.semiDark' picker="wheel" model="hex"></verte></p>				</div>
                    <div class="col-xl-2 form-group" style='display: grid'>
                        <h7> Medium (Main color)</h7>
                        <p class="form-label"><verte style="position:absolute;display: contents" v-model='form.color.medium' picker="wheel" model="hex"></verte></p>				</div>	
                    <div class="col-xl-2 form-group" style='display: grid'>
                        <h7> Semi-bright </h7>
                        <p class="form-label"><verte style="position:absolute;display: contents" v-model='form.color.semiBright' picker="wheel" model="hex"></verte></p>				</div>	
                    <div class="col-xl-2 form-group" style='display: grid'>
                        <h7> Bright </h7>
                        <p class="form-label"><verte style="position:absolute;display: contents" v-model='form.color.bright' picker="wheel" model="hex"></verte></p>				</div>			
		        </div>
            </tab-content>

            <tab-content title="Languages">
                <div class='row'>
                    <div class="col-xl-3 form-group" style='display: grid'>
                        <select class="form-control shadow-sm" v-model="form.language">
                                <option disabled  selected :value="null">Default Language</option>
                                <option value='fr'>FR</option>
                                <option value='en'>EN</option>
                                <option value="es">ES</option>
                        </select>
                    </div>

                    <div class="col-xl-3 form-group" style='display: grid'>
                        <select class="form-control shadow-sm" v-model="form.second_language" :disabled="!form.language">
                                <option disabled :value="null"  selected>Second Language (optional)</option>
                                <option v-show="form.language !== 'fr'" value='fr'>FR</option>
                                <option v-show="form.language !== 'en'" value='en'>EN</option>
                                <option v-show="form.language !== 'es'" value="es">ES</option>
                        </select>
                    </div>

                    <div class="col-xl-3 form-group" style='display: grid'>
                        <select class="form-control shadow-sm" v-model="form.third_language" :disabled="!form.second_language">
                                <option disabled :value="null"  selected>Third Language (optional)</option>
                                <option v-show="form.language !== 'fr' && form.second_language !== 'fr'" value='fr'>FR</option>
                                <option v-show="form.language !== 'en' && form.second_language !== 'en'" value='en'>EN</option>
                                <option v-show="form.language !== 'es' && form.second_language !== 'es'" value="es">ES</option>
                        </select>
                    </div>

                </div>
            </tab-content>

            <tab-content title="Support Account ">
                <div class='row'>
                    <div class="col-xl-3 form-group" style='display: grid'>
                            <h7> Support Email</h7>
                            <input type='email' class='form-control shadow-sm' v-on:keydown.enter.prevent='addEmailToList'  >
                    </div>		

                    <div class="col-xl-3 form-group" style='display: grid'>
                            <h7> Password</h7>
                            <input type='password' class='form-control shadow-sm' v-on:keydown.enter.prevent='addEmailToList'  >
                    </div>		

                    <div class="col-xl-3 form-group" style='display: grid'>
                            <h7> Contact Person first name</h7>
                            <input type='text' class='form-control shadow-sm' v-on:keydown.enter.prevent='addEmailToList'  >
                    </div>	
                    
                    <div class="col-xl-3 form-group" style='display: grid'>
                            <h7> Contact Person last name</h7>
                            <input type='text' class='form-control shadow-sm' v-on:keydown.enter.prevent='addEmailToList'  >
                    </div>	
                </div>

                <div class='row'>
                    <div class="col-xl-3 form-group" style='display: grid'>
                            <h7> Phone number </h7>
                            <input type='email' class='form-control shadow-sm' v-on:keydown.enter.prevent='addEmailToList'  >
                    </div>		

                    <div class="col-xl-3 form-group" style='display: grid'>
                            <h7> Address </h7>
                            <input type='password' class='form-control shadow-sm' v-on:keydown.enter.prevent='addEmailToList'  >
                    </div>		

                </div>
            </tab-content>

            <tab-content title="Links">
                <div class='row'>
                    <div class="col-xl-3 form-group" style='display: grid'>
                            <h7> Blog </h7>
                            <input type='text' class='form-control shadow-sm' v-on:keydown.enter.prevent='addEmailToList'  >
                    </div>		

                    <div class="col-xl-3 form-group" style='display: grid'>
                            <h7> Media </h7>
                            <input type='password' class='form-control shadow-sm' v-on:keydown.enter.prevent='addEmailToList'  >
                    </div>		

                    <div class="col-xl-3 form-group" style='display: grid'>
                            <h7> FAQ </h7>
                            <input type='text' class='form-control shadow-sm' v-on:keydown.enter.prevent='addEmailToList'  >
                    </div>	
                    
                    <div class="col-xl-3 form-group" style='display: grid'>
                            <h7> Contact </h7>
                            <input type='text' class='form-control shadow-sm' v-on:keydown.enter.prevent='addEmailToList'  >
                    </div>	
                </div>

                <div class='row'>
                    <div class="col-xl-2 form-group" style='display: grid'>
                            <h7> FB  </h7>
                            <input type='text' class='form-control shadow-sm' v-on:keydown.enter.prevent='addEmailToList'  >
                    </div>		

                    <div class="col-xl-2 form-group" style='display: grid'>
                            <h7> Linkedin </h7>
                            <input type='password' class='form-control shadow-sm' v-on:keydown.enter.prevent='addEmailToList'  >
                    </div>		

                    <div class="col-xl-2 form-group" style='display: grid'>
                            <h7> Youtube </h7>
                            <input type='text' class='form-control shadow-sm' v-on:keydown.enter.prevent='addEmailToList'  >
                    </div>	
                    
                    <div class="col-xl-2 form-group" style='display: grid'>
                            <h7> Instagram  </h7>
                            <input type='text' class='form-control shadow-sm' v-on:keydown.enter.prevent='addEmailToList'  >
                    </div>	

                    <div class="col-xl-2 form-group" style='display: grid'>
                            <h7> Twitter  </h7>
                            <input type='text' class='form-control shadow-sm' v-on:keydown.enter.prevent='addEmailToList'  >
                    </div>	

                    <div class="col-xl-2 form-group" style='display: grid'>
                            <h7> TikTok  </h7>
                            <input type='text' class='form-control shadow-sm' v-on:keydown.enter.prevent='addEmailToList'  >
                    </div>	
                </div>
            </tab-content>

            <tab-content title="Tracking">
                <div class="col-xl-4 form-group" style='display: grid'>
                            <h7> Google Analytics - tracking code  </h7>
                            <input type='text' class='form-control shadow-sm' v-on:keydown.enter.prevent='addEmailToList'  >
                </div>	

                <div class="col-xl-4 form-group" style='display: grid'>
                            <h7> Hubspot - tracking code </h7>
                            <input type='text' class='form-control shadow-sm' v-on:keydown.enter.prevent='addEmailToList'  >
                </div>	

                <div class="col-xl-4 form-group" style='display: grid'>
                    <h7> Other tracking tags </h7>

                    <textarea class='form-control shadow-sm' placeholder="Paste your tracking tag here">
                        
                    </textarea>
                </div>	
            </tab-content>

            <tab-content title="Subscribtion plans">
                <div class='row' v-for="(item, index) in form.plans" :key="index">

                    <div v-show="form.language" class="col-xl-3 form-group" style='display: grid'>
                        <h7> Plan name ({{form.language}}) </h7>
                        <input type='text' class='form-control shadow-sm' :name="`data.members_categories[${index}]name_${form.language}`" v-model="item.name_en" >
                    </div>	

                    <div v-show="form.second_language" class="col-xl-3 form-group" style='display: grid'>
                        <h7> Plan Name ({{form.second_language}}) </h7>
                        <input type='text' class='form-control shadow-sm' :name="`data.members_categories[${index}]name_${form.second_language}`"  v-model="item.name_fr">
                    </div>	

                    <div v-show="form.third_language" class="col-xl-3 form-group" style='display: grid'>
                        <h7> Plan Name ({{form.third_language}}) </h7>
                        <input type='text' class='form-control shadow-sm' :name="`data.members_categories[${index}]name_${form.third_language}`"   v-model="item.name_es">
                    </div>	

                    <div v-show="form.language" class="col-xl-3 form-group" style='display: grid'>
                        <h7> Amount </h7>
                        <input type='number' class='form-control shadow-sm' :name="`data.members_categories[${index}]amount`"   v-model="item.amount">
                    </div>	

                    <div v-show="form.language" class="col-xl-3 form-group" style='display: grid'>
                        <h7> Interval</h7>
                        <select class="form-control shadow-sm" v-model="item.interval">
                                <option selected value='monthly'>Monthly</option>
                                <option value="annually">Annual</option>
                        </select>
                    </div>	


                    <div class="col-xl-1 form-group" style='display: grid'>
                        <h7>  </h7>
                        <button class="btn btn-danger btn-sm" v-on:click="form.plans.splice(index, 1)" type="button">
                            &times;
                        </button>
                    </div>	


                </div>

                <button type="button" class="btn btn-primary btn-sm mr-2" @click="form.plans.push({})">Add</button>
            </tab-content>

            <tab-content title="Member's categories">
                <div class='row' v-for="(item, index) in form.members_categories" :key="index">

                    <div v-show="form.language" class="col-xl-3 form-group" style='display: grid'>
                        <h7> Category Name ({{form.language}}) </h7>
                        <input type='text' class='form-control shadow-sm' :name="`data.members_categories[${index}]name_${form.language}`" v-model="item.name_en" >
                    </div>	

                    <div v-show="form.second_language" class="col-xl-3 form-group" style='display: grid'>
                        <h7> Category Name ({{form.second_language}}) </h7>
                        <input type='text' class='form-control shadow-sm' :name="`data.members_categories[${index}]name_${form.second_language}`"  v-model="item.name_fr">
                    </div>	

                    <div v-show="form.third_language" class="col-xl-3 form-group" style='display: grid'>
                        <h7> Category Name ({{form.third_language}}) </h7>
                        <input type='text' class='form-control shadow-sm' :name="`data.members_categories[${index}]name_${form.third_language}`"   v-model="item.name_es">
                    </div>	

                    
                    <div class="col-xl-1 form-group" style='display: grid'>
                        <h7>  </h7>
                        <button class="btn btn-danger btn-sm" v-on:click="form.members_categories.splice(index, 1)" type="button">
                            &times;
                        </button>
                    </div>	

                    
                </div>

                <button type="button" class="btn btn-primary btn-sm mr-2" @click="form.members_categories.push({})">Add</button>

            </tab-content>

            <tab-content title="Payment Info">
                <div class="row">
                    <div class="col-xl-3 form-group" style='display: grid'>
                        <h7> Business Location </h7>
                        <select class="form-control shadow-sm">
                            <option selected value='monthly'>Canada</option>
                            <option value="annually">USA</option>
                        </select>
                    </div>	


                    <div class="col-xl-3 form-group" style='display: grid'>
                        <h7> Type of business </h7>
                        <select class="form-control shadow-sm">
                            <option value="corporation">Corporation / Société</option>
                            <option value="sole_prop">Individual or sole proprietorship / Particulier ou entreprise individuelle</option>
                            <option value="non_profit">Nonprofit organization / Organisation à but non lucratif</option>
                            <option value="partnership">Partnership / Société de personnes</option>
                        </select>
                    </div>

                    <div class="col-xl-3 form-group" style='display: grid'>
                        <h7> Legal business name </h7>
                        <input type='text' class='form-control shadow-sm' placeholder="Company, Inc.">
                    </div>	

                    <div class="col-xl-3 form-group" style='display: grid'>
                        <h7> Product Description </h7>
                        <textarea class="form-control shadow-sm">

                        </textarea>
                    </div>

                </div>
                

                <div class="row">
                    <div class="col-xl-3 form-group" style='display: grid'>
                        <h7> Legal first name </h7>
                        <input type='text' class='form-control shadow-sm' placeholder="First name">
                    </div>

                    <div class="col-xl-3 form-group" style='display: grid'>
                            <h7> Legal last name </h7>
                            <input type='text' class='form-control shadow-sm' placeholder="Last name">
                    </div>

                    <div class="col-xl-3 form-group" style='display: grid'>
                            <h7> Email Address </h7>
                            <input type='email' class='form-control shadow-sm' placeholder="Email Address">
                    </div>

                    <div class="col-xl-3 form-group" style='display: grid'>
                        <h7> Currency </h7>
                        <select class="form-control shadow-sm">
                            <option value="cad">CAD</option>
                            <option value="usd">USD</option>
                        </select>
                    </div>	
                </div>


                <div class="row">
                    <div class="col-xl-3 form-group" style='display: grid'>
                        <h7> Transit Number </h7>
                        <input type='number' class='form-control shadow-sm' placeholder="12345">
                    </div>

                    <div class="col-xl-3 form-group" style='display: grid'>
                            <h7> Institution number </h7>
                            <input type='number' class='form-control shadow-sm' placeholder="000">
                    </div>

                    <div class="col-xl-3 form-group" style='display: grid'>
                            <h7> Account Number </h7>
                            <input type='number' class='form-control shadow-sm' placeholder="000123456789">
                    </div>

                    <div class="col-xl-3 form-group" style='display: grid'>
                            <h7> Confirm Account Number </h7>
                            <input type='number' class='form-control shadow-sm' placeholder="000123456789">
                    </div>
                </div>         
            </tab-content>

            <tab-content title="Last step">
                Time to submit
            </tab-content>
        </form-wizard>
        
    </div>
</template>

<script>

    import Verte from 'verte';
	import 'verte/dist/verte.css';
    import {FormWizard, TabContent} from 'vue-form-wizard'
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'

    export default {
        name: "CreateMarketplace",
        components: {
			Verte,
            FormWizard,
            TabContent
		},
        data() {
			return {
				form: {
                    fullname:'',
                    shortname:'',
                    domain:'',
                    public_url:'',
                    language:null,
                    second_language: null,
                    third_language: null,
                    color:{
                        dark:'#222222',
                        semiDark:'#2D3E65',
                        medium:'#F1592A',
                        semiBright:'#BEBCBC',
                        bright:'#e2e2e2'
                    },
                    members_categories: [
                        {
                            name_en: '',
                            name_fr: '',
                            name_es: ''
                        }
                    ],
                    plans: [
                        {
                            name_en: 'Free',
                            name_fr: 'Gratuit',
                            name_es:'Gratis',
                            amount: 0,
                            interval:'monthly'
                        }
                    ],
				},
			};
		},
    }
</script>

<style>

</style>
