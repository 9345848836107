<template>
	<div>
		<breadcrumb :label="$t('negotiations')" :hide-sm="false">
			<negotiation-view-toggle-component :parties="parties" @toggle-party="toggleParty"></negotiation-view-toggle-component>
		</breadcrumb>

		<div v-if="negotiation" class="card shadow-sm mb-5">
			<div class="card-header">
				{{ resourceName }}

				<a href="#resource-details" class="float-right" data-toggle="modal">{{$t('details')}}</a>
			</div>
			<div class="negotiation">
				<div class="messages">
					<negotiation-messages-component @update-negotiation="update" :messages="messages" :view-as="viewingAs" :details="details" :price-term="priceTerm" :is-typing="isTyping"></negotiation-messages-component>
					<form @submit.prevent="sendMessage" class="text-area">
						<div class="input">
							<input v-model="content" type="text" class="form-control" :placeholder="$t('message-enter')"/>
							<button class="send-button" type="submit">
								<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
									<g>
										<g>
											<path d="M481.508,210.336L68.414,38.926c-17.403-7.222-37.064-4.045-51.309,8.287C2.86,59.547-3.098,78.551,1.558,96.808
												L38.327,241h180.026c8.284,0,15.001,6.716,15.001,15.001c0,8.284-6.716,15.001-15.001,15.001H38.327L1.558,415.193
												c-4.656,18.258,1.301,37.262,15.547,49.595c14.274,12.357,33.937,15.495,51.31,8.287l413.094-171.409
												C500.317,293.862,512,276.364,512,256.001C512,235.638,500.317,218.139,481.508,210.336z"/>
										</g>
									</g>
								</svg>
							</button>
						</div>
					</form>
				</div>
			</div>

			<negotiation-resource-component :details="details"></negotiation-resource-component>
			<negotiation-new-offer-component @update-negotiation="update" :price-term="priceTerm"></negotiation-new-offer-component>
		</div>
		<div v-else>
			<div v-if="isBusy" class="spinner">
				<spinner-component></spinner-component>
			</div>
			<error-component v-else :label="'No Negotiation Found.'" :return-to="{ name: 'dashboard.transactions.negotiations.index' }"></error-component>
		</div>
	</div>
</template>

<script>
	import { findKey } from 'lodash';
	import moment from 'moment';
	import { mapGetters, mapActions } from 'vuex';
	import { transactions } from '@/mixins';
	import newMessageMp3 from '@/assets/sounds/new_message.mp3';

	export default {
		name: 'NegotiationView',
		mixins: [transactions.negotiations],
		created() {
			this.negotiationId = this.$route.params.negotiationId;

			this.fetchData(this.negotiationId);
		},
		mounted() {
			this.$socket.on('negotiation', () => {
				console.log(
					`Joined Negotiation as ${this.viewAs}: ${this.viewingAs}`
				);
			});

			this.$socket.on('is-typing', (data) => (this.isTyping = data));

			this.$socket.on('new-message', (data) => {
				const { fromId, isAdmin } = data;
				if (fromId !== this.viewingAs || !isAdmin) {
					this.pushMessage(data);
					this.playSound();
				}
			});

			this.$socket.on('offer-edited', (data) => {
				const { id: offerId } = this.getLastOffer();
				this.updateOffer({
					offerId,
					status: {
						desc: 'Rejected',
						id: 6,
					},
				});

				this.pushMessage(data);
				window.$('#new-offer').modal('hide');
			});

			this.$socket.on('offer-accepted', ({ offer: { id: offerId } }) => {
				this.updateOffer({
					offerId,
					status: {
						desc: 'Accepted',
						id: 7,
					},
				});
			});

			this.$socket.on('negotiation-cancelled', ({ negotiationId }) =>
				this.cancelNegotiation(negotiationId)
			);
		},
		data() {
			return {
				newMessageMp3: new Audio(newMessageMp3),
				negotiationId: null,
				details: null,
				messages: [],
				content: null,
				parties: {
					buyer: null,
					seller: null,
				},
				viewingAs: null,
				isTyping: null,
			};
		},
		computed: {
			...mapGetters({
				isBusy: 'isBusy',
				marketplace: 'marketplace/marketplace',
				negotiation: 'transactions/negotiations/negotiation',
				viewAs: 'transactions/negotiations/viewAs',
				seenBy: 'transactions/negotiations/seenBy',
				types: 'resources/types',
				priceTerms: 'resources/priceTerms',
				categories: 'categories/resourcesCategories',
			}),
			domain() {
				const { domain } = this.marketplace;

				return domain;
			},
			priceTerm() {
				if (this.details) {
					return this.priceTerms.find(
						({ id }) => id == this.details.priceTerm
					);
				}

				return {};
			},
			resource() {
				if (this.details) {
					return this.details.resource;
				}

				return null;
			},
			resourceName() {
				if (this.resource) {
					const locale = this.getObjectLocale(this.resource.locale);

					return locale.title;
				}

				return null;
			},
			message() {
				return {
					domain: this.domain,
					content: this.content,
					recipient: this.parties[this.seenBy],
					createdAt: moment().format(),
					fromId: this.parties[this.viewAs],
					negotiationId: this.negotiationId,
					status: 0,
					type: 'message',
					isAdmin: true,
				};
			},
		},
		watch: {
			$route: function () {
				this.fetchData(this.negotiationId);
			},
			negotiation(negotiation) {
				const { messages, offers, ...details } = negotiation;

				this.messages = [...messages, ...offers].sort(
					(a, b) => new Date(a.createdAt) - new Date(b.createdAt)
				);
				this.parties = {
					buyer: details.buyerId,
					seller: details.sellerId,
				};
				this.details = details;

				this.scrollToLatest();
			},
			parties(value) {
				this.toggleParty(value[this.viewAs]);
			},
		},
		methods: {
			...mapActions({
				setAppState: 'setAppIsBusy',
				attemptGetNegotiation:
					'transactions/negotiations/attemptGetNegotiation',
			}),
			playSound() {
				return this.newMessageMp3.play();
			},
			fetchData(negotiationId) {
				this.setAppState(true);
				this.attemptGetNegotiation(negotiationId);
			},
			toggleParty(party) {
				this.$socket.emit('leave-negotiation', {
					negotiationId: this.negotiationId,
				});

				this.viewingAs = party;

				this.$store.commit(
					'transactions/negotiations/SET_VIEW_AS',
					findKey(this.parties, (value) => value === party)
				);

				this.$socket.emit('join-negotiation', {
					negotiationId: this.negotiationId,
					as: party,
					isAdmin: true,
				});

				this.scrollToLatest();
			},
			sendMessage() {
				if (this.content) {
					this.$socket.emit('send-message', this.message);

					this.pushMessage(this.message);
				}
			},
			update(params, newOffer = null) {
				return this.updateOffer(params, newOffer);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.card-header,
	.card-footer {
		background-color: #ffffff;
	}

	.negotiation {
		.messages {
			float: left;
			width: 100%;

			.text-area {
				border-top: 1px solid #dee2e6;
				position: relative;

				.input {
					padding: 10px 70px 10px 25px;

					input {
						background: #ebebeb none repeat scroll 0 0;
						border: medium none;
						border-radius: 5px;
						color: #4c4c4c;
						font-size: 14px;
						min-height: 35px;
						width: 100%;
						padding: 0 10px;
					}

					.send-button {
						border: medium none;
						border-radius: 50%;
						background: #fff;
						cursor: pointer;
						font-size: 17px;
						position: absolute;
						right: 20px;
						top: 10px;
						height: 35px;
						width: 35px;

						> svg {
							fill: var(--color-medium);
						}
					}
				}
			}
		}
	}
</style>
