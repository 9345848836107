<template>
    <div>
		<table-component
            :header="header"
            :data="data"
            :total="total"
            :cta="cta"
            :state="state"
            :id="'resources'"
			:searchTerm="searchTerm"
			:isFetching="isFetching"
			:isInitializing="isInitializing"
			@setSearchTerm="setSearchTerm"
            @setSort="setSort"
			@setPerPage="setPerPage"
        >
			<template v-slot:filters>
				<resources-table-filters
					:filters="filters"
					@setFilters="setFilters"
					@resetFilters="resetFilters"
				>
				</resources-table-filters>
			</template>
        </table-component>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
	import { request } from '@/config';
	import { table } from '@/mixins';

	export default {
		name: 'ResourcesTable',
		mixins: [table],
		data() {
			return {
				header: [
					{
						label: this.$t("title"),
						type: 'text',
						sort: true,
						key: 'resourceTitle',
					},
					{
						label: this.$t("company-name"),
						type: 'text',
						sort: true,
						key: 'companyName',
					},
					{ label: this.$t("email"), type: 'email', sort: true, key: 'email' },
					{ label: 'Type', type: 'text', sort: true, key: 'type' },
					{
						label: this.$t("created-date"),
						type: 'date',
						sort: true,
						key: 'createdAt',
					},
					{ label: this.$t("private-public"), type: 'text' },
					{
						label: this.$t("master-category"),
						type: 'text',
						sort: true,
						key: 'masterCategory',
					},
					{
						label: this.$t("sub-category"),
						type: 'text',
						sort: true,
						key: 'subCategory',
					},
					{ label: this.$t("price"), type: 'text', sort: true, key: 'price' },
					{ label: this.$t("price-term"), type: 'text' },
					{ label: this.$t("featured"), type: 'text' },
					{ label: this.$t("city"), type: 'text' },
					{ label: 'Desciption' },
					{ label: 'ID', type: 'id' },
				],
				data: {
					table: [],
					metaData: [],
				},
				total: 0,
				cta: [
					{
						label: () => this.$t("edit"),
						onClick: (resource) => {
							const id = resource[13];

							this.editResource(id);
						},
					},
					{
						label: () => this.$t("delete"),
						onClick: (resource) => {
							console.log(resource)
							const id = resource[13];

							console.log(resource)

							this.deleteResource(id);
						},
						style: () => 'danger',
					},
				],
				sort: {
					index: null,
					direction: null,
				},
				filters: {
					categories: {},
					status: null,
					type: null,
					featured: null
				},
			};
		},
		mounted() {
			this.getData();
		},
		computed: {
			...mapGetters({
				categories: 'categories/resourcesCategories',
			}),
		},
		watch: {
			state: {
				handler: function () {
					this.getData();
				},
				deep: true,
			},
			searchTerm: function () {
				this.getData();
			},
			filters: {
				handler: function () {
					this.getData();
				},
				deep: true,
			},
		},
		methods: {
			...mapActions({
				attemptResourceDelete: 'resources/attemptResourceDelete',
			}),
			setFilters(filters) {
				this.filters = filters;
			},
			editResource(resourceId) {
				this.$router.push({ path: `/dashboard/resources/${resourceId}` });
			},
			deleteResource(resourceId) {
				const answer = window.confirm(
					`Do you really want to delete this resource?\nResource ID: ${resourceId}`
				);
				if (answer) {
					this.attemptResourceDelete(resourceId)
						.then(() => this.getData())
						.catch((e) => this.validationErrors(e));
				}
			},
			getData: async function () {
				const { toCurrency, toUpperCase } = this.$options.filters;

				this.isFetching = true;

				try {
					const data = {
						table: [],
						metaData: [],
					};

					const requestParams = {
						locale: this.locale,
						isDatatable: true,
						page: this.state.page,
						load: this.state.perPage,
						q: {
							filter: {},
							search: {
								columns: [
									'id',
									'$locale.title$',
									'$company.id$',
									'$company.email$',
									'$resourceCategoryObj.category_id$',
									'$resourceCategoryObj.category.parent_id$',
									'$resourceCategoryObj.visible$',
									'city',
									'type',
								],
								searchTerm: this.searchTerm,
							},
						},
					};

					const isFilteringByCategory = Object.keys(this.filters.categories).length > 0;

					if (
						isFilteringByCategory ||
						this.filters.status ||
						this.filters.type ||
						this.filters.featured
					) {
						if (isFilteringByCategory) {
							const masterCategoryId = Object.keys(
								this.filters.categories
							)[0];
							requestParams.q.filter[
								'$resourceCategoryObj.category.parent_id$'
							] = masterCategoryId;
							if (
								this.filters.categories[masterCategoryId].displayed
									.length > 0
							) {
								requestParams.q.filter[
									'resourceCategoryObj.category.id$'
								] =
									this.filters.categories[
										masterCategoryId
									].displayed[0];
							}
						}
						if (this.filters.status) {
							requestParams.q.filter.public =
								this.filters.status === 'PUBLIC' ? '1' : '0';
						}
						if (this.filters.type) {
							requestParams.q.filter.type = this.filters.type;
						}

						if (this.filters.featured) {
							requestParams.q.filter[
								'resourceCategoryObj.featured$'
								] = this.filters.featured;
						}
					}

					if (
						this.state.sort.index != null &&
						this.state.sort.direction != null
					) {
						requestParams.orderBy =
							this.header[this.state.sort.index].key;
						requestParams.orderDirection =
							this.state.sort.direction.toUpperCase();
					}

					const res = await request
						.get('resources', {
							headers: {
								Authorization: `Bearer ${this.token}`,
							},
							params: requestParams,
						})
						.then(({ data: { data: response } }) => response);

					const { data: resources, total } = res

					this.validateResponse(res)

					resources.forEach((item) => {
						data.table.push([
							item.locale && item.locale.length > 0
								? item.locale[0].title
								: '',
							item.company &&
							item.company.locale &&
							item.company.locale.length > 0
								? item.company.locale[0].title
								: '',
							item.company ? item.company.email : '',
							item.type,
							item.createdAt,
							item.resourceCategoryObj.visible ? 'PUBLIC' : 'PRIVATE',
							this.getCategory(item, 'master'),
							this.getCategory(item, 'sub'),
							toCurrency(item.price),
							toUpperCase(item.priceTermDetails.name),
							item.resourceCategoryObj.featured ? this.$t("yes") : this.$t("no"),
							item.city ? item.city : '', //item.company.city,
							item.locale &&
							item.locale.length > 0 &&
							item.locale[0].description
								? item.locale[0].description.replace(/<[^>]+>/g, '')
								: '',
							item.id,
						]);
					});

					this.data = data;
					this.total = total;
					this.isFetching = false;
					this.isInitializing = false;
					this.scrollTableToTop()
				} catch (err) {
					console.log({ err });
				}
			},
			getCategory(item, type) {
				const {
					resourceCategoryObj: resourceCategory,
				} = item;

				const master = this.categories.find(c => c.id === resourceCategory.category.parentId)

				console.log({resourceCategory , locale: this.locale})

				if (!resourceCategory || !resourceCategory.category) return '';
				if (type === 'sub') {
					const locale = master.children.find(c => c.id === resourceCategory.categoryId)
					return locale.text;
				}else{
					return master.text
				}
			},
		},
	};
</script>

