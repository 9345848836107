import {
	request
} from '@/config'
import store from '@/store'

export default {
	setMessage({
		commit
	}, value) {
		commit('SET_MESSAGE', value)
	},
	async attemptGetProfile({
		commit
	}) {
		let endpoint, params;
		const {
			role
		} = store.state.auth.user

		if (role.name !== 'super_admin') {
			const {
				companyInfo: {
					id,
					marketplace: {
						id: marketplaceId
					}
				},
			} = store.state.auth.user

			endpoint = `/users/company/${id}`

			params = {
				marketplaceId
			}
		} else {
			endpoint = '/user'
		}

		try {
			const response = await request.get(endpoint, {
					params
				})
				.then(({
					data: {
						data: {
							user,
							member
						}
					}
				}) => user || member)
				.catch(({
					response: error
				}) => {
					throw error
				})

			commit('SET_PROFILE', response)

			return response
		} catch (error) {
			commit('SET_PROFILE', null)
		}
	},
	async attemptUpdateProfile(_, {
		memberId,
		...data
	}) {
		const response = await request.patch(`/users/company/${memberId}`, data)
			.then(({
				data: {
					message
				}
			}) => {
				store.dispatch('profile/attemptGetProfile')
				store.dispatch('marketplace/attemptGetMarketplace')

				return message
			})
			.catch(({
				response: error
			}) => {
				throw error
			}).finally(() => store.dispatch('setAppIsBusy', false))

		store.commit('SET_MESSAGE', response)

		return response
	},
	attemptGetCharges() {},
	attemptGetPayments() {},
	async attemptChangePassword(_, data) {
		const response = await request.post('/user/change-password', data)
			.then(({
				data: {
					message
				}
			}) => message)
			.catch(({
				response: error
			}) => {
				throw error
			}).finally(() => store.dispatch('setAppIsBusy', false))

		store.commit('SET_MESSAGE', response)

		return response
	},
}
