export default {
	chargedTo: ['seller', 'buyer'],
	status: [{
		value: true,
		name: 'active'
	}, {
		value: false,
		name: 'inactive'
	}]
}
