<template>
	<div class="image-wrapper">
		<div class="image-view">
			<img :src="src" alt="" ref="preview" class="img rounded-circle mb-2" >
			<a v-if="hasEdit" href="#edit-image" class="d-block" @click.prevent="editImage">{{$t('edit-image')}}</a>
		</div>
		<input type="file" accept="image/x-png,image/gif,image/jpeg" ref="input" @change="setImage"/>
		<div class="image-tooltip">
			<em class="bi bi-info-circle"></em>
			<div>{{$t('ideal-image')}}</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	.image-wrapper{
		.image-view {
			text-align: center;
			margin: 0 1rem;

			img,.img{
				height: 100px;
				width: 100px;
				object-fit: cover;
			}

			.rounded-circle {
				border: 1px solid rgba(0, 0, 0, 0.125);
				border-radius: 50% !important;
				box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
			}

			a {
				font-size: 14px;
			}
		}

		input[type='file']{
			position: absolute;
			top: -1000px;
		}

		span.label {
			display: block;
			font-size: .85rem;
			text-transform: initial;
			color: #6c757d !important;
		}

		.image-tooltip {
			position: absolute;
			top: 0;
			right: 1rem;

			div {
				position: absolute;
				right: 0;
				top: 100%;
				width: max-content;
				background: #222222;
				color: white;
				padding: .5rem;
				border-radius: 5px;
				display: none;
			}

			&:hover {
				div {
					display: block;
				}
			}
		}
	}
</style>

<script>
	import noImage from '@/assets/img/noPhoto.png';
	import { resizeImageFile } from '@/scripts/resizeImage'
	import { file } from '@/mixins';

	export default {
		name: 'PhotoComponent',
		mixins: [file],
		data() {
			return {
				src: this.image || noImage
			};
		},
		watch: {
			image(value) {
				setTimeout(() => {
					this.src = value
				}, 2000);
				//this.src = value;
			},
		},
		methods: {
			editImage() {
				const input = this.$refs.input;

				input.click();
			},
			async setImage(image) {
				let { files: { 0: file } } = image.target;
				if(file.size > 1000000){
					file = await resizeImageFile(file)
				}
				console.log({ file })
				this.previewFile(file);
				this.$emit('input', {
					field: this.field,
					value: file,
				});
			},
		},
		props: {
			field: {
				type: String,
			},
			image: {
				type: [String, Object],
			},
			hasEdit: {
				type: Boolean,
				default: true,
			},
			type: {
				type: String
			}
		},
	};
</script>
