<template>
    <div>
        <breadcrumb :label="$t('shipping')"></breadcrumb>

        <shipping-table-component></shipping-table-component>
    </div>
</template>

<script>
	export default {
		name: 'ShippingView',
	};
</script>

<style>
</style>
