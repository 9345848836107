export default {
	NEGOTIATION_STATUS: {
		"on going": 1,
		succeeded: 2,
		canceled: 3,
		sent: 4,
		seen: 5,
		rejected: 6,
		accepted: 7,
		"pending payment": 8,
		"paid in": 9,
		requested: 10,
		"paid out": 11,
		failed: 12,
		"waiting for approval": 13
	}
};
