import store from '@/store'

function middleware(to, from, next) {
	const {
		name
	} = store.getters['auth/user'].role

	if (name === 'admin') {
		return next()
	}

	return next({
		name: 'dashboard'
	})
}

export default [{
		path: 'sub-administrators',
		name: 'dashboard.sub-administrators.index',
		component: () => import('@/views/dashboard/marketplace/sub-administrators/SubAdministratorsView.vue'),
		beforeEnter: (to, from, next) => middleware(to, from, next),
		meta: {
			parent: 'marketplace.sub-administrators'
		}
	}, {
		path: 'sub-administrators/create',
		name: 'dashboard.sub-administrators.create',
		component: () => import('@/views/dashboard/marketplace/sub-administrators/SubAdministratorsCreate.vue'),
		beforeEnter: (to, from, next) => middleware(to, from, next),
		meta: {
			parent: 'marketplace.sub-administrators'
		}
	},
	{
		path: 'sub-administrators/:administratorId',
		name: 'dashboard.sub-administrators.edit',
		component: () => import('@/views/dashboard/marketplace/sub-administrators/SubAdministratorsEdit.vue'),
		beforeEnter: (to, from, next) => middleware(to, from, next),
		meta: {
			parent: 'marketplace.sub-administrators'
		}
	}
]
