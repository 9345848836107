export default {
	resourcesMasterCategories(state) {
		return state.resourcesMasterCategories
	},
	resourcesCategories(state) {
		return state.resourcesCategories
	},
	resourceCategory(state) {
		return state.resourceCategory
	},
	companyCategories(state) {
		return state.companyCategories
	},
	companyCategory(state) {
		return state.companyCategory
	}
}
