export default {
	negotiation(state) {
		return state.negotiation
	},
	viewAs(state) {
		return state.viewAs
	},
	seenBy(state) {
		return state.viewAs === 'buyer' ? 'seller' : 'buyer'
	}
}
