import store from '@/store'

export default [{
	path: 'payouts',
	name: 'dashboard.transactions.payouts.index',
	component: () => import('@/views/dashboard/transactions/payouts/PayoutsView.vue'),
	beforeEnter: (to, from, next) => {
		const {
			report
		} = store.getters['auth/permissions'].transactions.payouts

		if (report) {
			return next()
		}

		return next({
			name: 'dashboard'
		})
	},
	meta: {
		parent: 'transactions'
	}
}]
