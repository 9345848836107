import Vue from 'vue'
import moment from 'moment'
import numeral from 'numeral'
import {
	isEmpty,
	startCase,
} from 'lodash'
import pluralize from 'pluralize'

Vue.filter('toNumber', (value, format = '0,0.00') => {
	return numeral(value).format(format)
})

Vue.filter('toCurrency', (value, currency = 'CAD', format = 'en') => {
	const number = parseFloat(value)

	if (typeof number !== "number") {
		return value
	}

	const formatter = new Intl.NumberFormat(format, {
		style: 'currency',
		currency: currency,
		minimumFractionDigits: 2
	})

	return formatter.format(number)
})

Vue.filter('toPercentage', (value, isDecimal = false) => {
	const percent = (isDecimal) ? value * 100 : value

	return `${percent.toFixed(2)}%`
})

Vue.filter('toDate', (value, format = 'MM/DD/YYYY') => {
	if (!value) {
		return value
	}

	return moment(String(value)).format(format)
})

Vue.filter('toAnswer', (value) => {
	return (value) ? "Yes" : "No"
})

Vue.filter('placeholder', (value, placeholder = 'N/A') => {
	return (!isEmpty(value)) ? value : placeholder
})

Vue.filter('toLorem', (value, length = 11) => {
	const string = 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam in reiciendis beatae est totam accusantium, excepturi quae natus illo corrupti quibusdam ratione, saepe fugit itaque consequuntur suscipit quisquam eligendi odio!'

	return (!isEmpty(value)) ? value : string.substr(0, length);
})

Vue.filter('truncate', (value, maxLength = 20) => {
	if (!value) return null

	return (String(value).trim().length <= maxLength) ? value : `${value.substring(0, maxLength)}...`
})

Vue.filter('toLowerCase', (value) => {
	return value.toLowerCase()
})

Vue.filter('toUpperCase', (value) => {
	return value.toUpperCase()
})

Vue.filter('toStartCase', (value) => {
	return startCase(value)
})

Vue.filter('initials', (value) => {
	return value.split(" ").map((n) => n[0]).join("");
})

Vue.filter('pluralize', (value, quantity = 0) => {
	return pluralize(value, quantity)
})
