<template>
	<span class="time-stamp">{{ timestamp }}</span>
</template>

<script>
	import moment from 'moment';
	export default {
		name: 'NegotiationMessageTimestampComponent',
		computed: {
			timestamp() {
				moment.locale(this.$i18n.locale)
				return moment(this.createdAt, 'YYYY-MM-DDTHH:mm:ssZ').calendar(
					null,
					{
						sameDay: '[Today] [at] LT',
						lastDay: '[Yesterday], MMM D [at] LT',
						lastWeek: '[Last] dddd, MMM D [at] LT',
						sameElse: 'MMMM D YYYY [at] LT',
					}
				);
			},
		},
		props: {
			createdAt: {
				type: [Date, String],
				default: () => new Date(),
			},
		},
	};
</script>
