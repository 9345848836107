<template>
	<div v-if="viewAs">
		<span class="mr-2">{{$t('view-as')}}</span>
		<div class="btn-group btn-group-toggle" data-toggle="buttons">
			<label class="btn btn-white btn-sm" :class="{ 'active': viewAs === 'buyer' }">
				<input v-model="party" type="radio" name="parties" id="as-buyer" :value="parties.buyer" :checked="viewAs === 'buyer'"> {{$t('buyer')}}
			</label>
			<label class="btn btn-white btn-sm" :class="{ 'active': viewAs === 'seller' }">
				<input v-model="party" type="radio" name="parties" id="as-seller" :value="parties.seller" :checked="viewAs === 'seller'"> {{$t('seller')}}
			</label>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';

	export default {
		name: 'NegotiationViewToggleComponent',
		data() {
			return {
				party: null,
			};
		},
		computed: {
			...mapGetters({
				viewAs: 'transactions/negotiations/viewAs',
			}),
		},
		watch: {
			party(value) {
				this.toggle(value);
			},
			parties: {
				handler(value) {
					if (value) {
						this.party = value[this.viewAs];
					}
				},
				deep: true,
			},
		},
		methods: {
			toggle(value) {
				this.$emit('toggle-party', value);
			},
		},
		props: {
			parties: {
				type: Object,
				required: true,
			},
		},
	};
</script>

<style lang="scss" scoped>
	.btn-group-toggle {
		label.active {
			background-color: var(--color-medium);
			border-color: var(--color-medium);
			color: #fff;
		}
	}
</style>
