<template>
	<div class="modal" id="crop-banner-photo" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="crop-banner-photoLabel" aria-hidden="true">
		<div class="modal-dialog modal-lg modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="crop-banner-photoLabel">{{$t('crop-image')}}</h5>
				</div>
				<div class="modal-body">
					<cropper ref="cropper" class="cropper" :src="cropable" @change="change"/>
				</div>
				<div class="modal-footer" v-if="cropped">
					<button type="button" class="btn btn-primary shadow-sm" @click="save">{{$t('save')}}</button>
					<button type="button" class="btn btn-secondary shadow-sm" @click="cancel">{{$t('cancel')}}</button>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	@import '~vue-advanced-cropper/dist/style.css';

	.cropper {
		height: 600px;
		background: #DDD;
	}
</style>

<script>
	import { v4 as uuidv4 } from 'uuid';
	import noImage from '@/assets/img/noPhoto.png';

	export default {
		mounted() {
			window.$('#crop-banner-photo').on('show.bs.modal', () => {
				this.$refs.cropper.refresh();
			});
		},
		data() {
			return {
				cropable: null,
				cropped: null,
			};
		},
		watch: {
			crop({ image }) {
				if (image) {
					this.preview(image);
				}
			},
			cropped() {},
		},
		methods: {
			preview(file) {
				const reader = new FileReader();

				reader.addEventListener(
					'load',
					() => {
						this.$nextTick(() => {
							this.cropable = reader.result;
						});
					},
					false
				);

				if (file) {
					reader.readAsDataURL(file);
				}
			},
			change({ canvas }) {
				if (canvas) {
					canvas.toBlob((blob) => {
						const { type } = blob;

						this.cropped = new File([blob], uuidv4(), {
							lastModified: new Date(0),
							type,
						});
					});
				}
			},
			save() {
				this.$emit('save', { type: this.crop.type, image: this.cropped });
			},
			cancel() {
				this.cropped = null;

				this.$emit('cancel', { type: this.crop.type, image: this.cropped });

				window.$('#crop-banner-photo').modal('hide');
			},
		},
		props: {
			crop: {
				type: [Object, String],
				default: noImage,
			},
		},
	};
</script>

