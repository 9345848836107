import fees from './fees'
import negotiations from './negotiations'
import payout from './payout'
import payments from './payments'
import shipping from './shipping'


export default [{
	path: 'transactions',
	component: () => import('@/views/dashboard/transactions/Transactions.vue'),
	children: [
		...fees,
		...negotiations,
		...payout,
		...payments,
		...shipping
	]
}]
