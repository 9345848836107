export default {
	categories(state) {
		return state.categories
	},
	marketplaces(state) {
		return state.marketplaces
	},
	marketplace(state) {
		return state.marketplace
	},
	membershipTypes(state) {
		return state.membershipTypes
	}
}
