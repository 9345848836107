export default {
	isLoading(state) {
		return state.app.isLoading
	},
	isBusy(state) {
		return state.app.isBusy
	},
	message(state) {
		return state.message
	},
	logo(state) {
		return state.app.logo
	},
	locale(state) {
		return state.app.locale
	},
	navbarLogo(state) {
		return state.app.navbarLogo
	},
	menuToggled(state) {
		return state.app.menuToggled
	},
	appLanguage(state) {
		return state.app.appLanguage
	},
	currencies: state => (params) => {
		if (params.locale) {
			return state.currenciesLocale
		}

		return state.currencies
	},
	googleCloudUrl() {
		return `${process.env.VUE_APP_GOOGLE_CLOUD_URL}/${process.env.VUE_APP_GOOGLE_CLOUD_BUCKET}`
	}
}
