<template>
    <div>
        <breadcrumb :label="$t('fees')">
			<router-link class="btn btn-outline-primary btn-sm shadow-sm" :to="{ name: 'dashboard.transactions.fees.create'}">
                <em class="bi bi-plus-circle-dotted"></em> {{$t('new-fees')}}
            </router-link>
		</breadcrumb>

        <fees-table-component></fees-table-component>
    </div>
</template>

<script>
	export default {
		name: 'FeesView',
	};
</script>

<style>
</style>
