<template>
    <div id="members-table-filter">
        <card-component v-if="isReady">
            <div class="row  py-0 px-3 mb-3">
                <div class="w-100">
                    <label class="py-0">{{$t('category')}}:</label>
                </div>
                <div
                    v-for="item in Object.values(filtersData.categories)"
                    :key="item.id"
                    class="col-auto button-filter mb-3 mr-2 py-2 px-3"
                    :class="{active: filters.category === item.id}"
                    @click="selectCategoryFilter(item.id)"
                >
                    <span class="mr-2">{{ item.text }}</span>
                </div>
            </div>
            <div class="row py-0">
                <div class="col-3 px-3">
                    <label class="px-0">{{$t('active')}}</label>
                    <div class="row py-0 px-3">
                        <div
                            class="button-filter mr-2 py-2 px-3"
                            @click="selectStatusFiler('active')"
                            :class="{active: filters.status === 'active'}"
                        >
                            <span>{{$t('yes')}}</span>
                        </div>
                        <div
                            class="button-filter py-2 px-3"
                            @click="selectStatusFiler('inactive')"
                            :class="{active: filters.status === 'inactive'}"
                        >
                            <span>{{$t('no')}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <label class="w-100">
                        {{$t('city')}}
                    </label>
                    <input v-model="filters.city" type="text" class="form-control  shadow-sm">
                </div>
                <div class="col-2"></div>
                <div class="col-3 cta">
                    <div>
                        <span class="mr-2">{{$t('active-filters')}}</span>
                        <span>{{ activeFiltersCounter }}</span>
                    </div>
                    <div class="cta__clear" @click="clearFilters">{{$t('clear')}}</div>
                </div>
            </div>
        </card-component>
    </div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex';
	export default {
		name: 'MembersTableFilters',
		data() {
			return {
				filtersData: {
					categories: null,
					status: null,
				}
			};
		},
		props: {
			filters: {
				type: Object
			}
		},
		created() {
			if (this.companyCategories.length === 0) {
				this.attemptGetCompanyCategories();
			}
		},
		mounted() {
			if (this.areDataLoaded) {
				this.initializeFilters();
			}
		},
		computed: {
			...mapGetters({
				companyCategories: 'categories/companyCategories',
				membersList: 'users/membersList',
				user: 'auth/user',
			}),
			isReady: function () {
				return (
					this.filtersData.categories !== null &&
					this.filtersData.status !== null
				);
			},
			areDataLoaded: function () {
				return this.companyCategories && this.companyCategories.length > 0;
			},
			marketplaceId() {
				const { id } = this.user.companyInfo.marketplace;
				return id;
			},
			activeFiltersCounter() {
				const categoriesFilterCounter = this.filters.category ? 1 : 0;
				const statusCounter = this.filters.status ? 1 : 0;
				return categoriesFilterCounter + statusCounter;
			},
		},
		methods: {
			...mapActions({
				attemptGetCompanyCategories:
					'categories/attemptGetCompanyCategories',
			}),
			initializeFilters: async function () {
				const _filtersData = {
					categories: {},
					status: {
						active: 0,
						inactive: 0,
					},
				};
				this.companyCategories.forEach((category) => {
					_filtersData.categories[category.id] = {...category};
				});
				this.filtersData = _filtersData;

				const queryFilters = { }
				const query = this.$route.query

				if(query.category){
					const categoryIsValid = this.companyCategories.findIndex(item => item.id === query.category) > -1
					if(categoryIsValid){
						queryFilters.category = query.category
					} else {
						this.updateRouter("category", null)
					}
				}

				if(query.status){
					const statusIsValid = query.status === "active" || query.status === "inactive"
					if(statusIsValid){
						queryFilters.status = query.status
					} else {
						this.updateRouter("status", null)
					}
				}

				if(Object.keys(queryFilters).length > 0){
					const initialQuery = {
						...this.filters,
						...queryFilters
					}
					this.$emit('setFilters', initialQuery);
				}

			},
			clearFilters: function () {
				const filters = {
					category: "",
					status: ""
				};
				this.$emit('setFilters', filters);
				this.$emit('resetFilters')
			},
			selectCategoryFilter: function (categoryId) {
				const filters = {
					...this.filters,
					category: !this.filters.category || this.filters.category !== categoryId ? categoryId : null
				}
				this.updateRouter("category", filters.category)
				this.$emit('setFilters', filters)
			},
			selectStatusFiler: function (status) {
				const memberStatus = status === this.filters.status ? null : status;
				const updatedFilters = {
					...this.filters,
					status: memberStatus,
				};
				this.updateRouter("status", updatedFilters.status)
				this.$emit('setFilters', updatedFilters)
			},
			updateRouter(key, value){
				const currentQuery = {...this.$route.query}
				if(value){
					currentQuery[key] = value
				} else {
					delete currentQuery[key]
				}
				this.$router.push({ query: currentQuery })
			}
		},
		watch: {
			areDataLoaded: async function (value) {
				if (value && !this.isReady) {
					this.initializeFilters();
				}
			}
		},
	};
</script>

<style lang="scss" scoped>
	#members-table-filter {
		.card {
			margin-bottom: 10px !important;
		}
		.button-filter {
			border: 1px solid var(--color-semi-bright);
			cursor: pointer;
			background: rgb(248, 248, 248);
			border-radius: 5px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			min-width: 130px;
			&.active {
				background: var(--color-medium);
				color: white;
				border-color: var(--color-medium);
			}
		}
		input {
			padding: 0.5rem 1rem;
			height: unset;
		}
		label {
			font-size: 14px;
		}

		.cta {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			&__clear {
				color: var(--color-semi-bright);
				cursor: pointer;
				&:hover {
					color: black;
				}
			}
		}
	}
</style>
