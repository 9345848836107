<template>
    <div>
        <breadcrumb :label="$t('import-resources')">
            <router-link class="btn btn-white btn-sm shadow-sm" :to="{ name: 'dashboard.resources.index'}">
                {{$t('cancel')}}
            </router-link>
        </breadcrumb>

        <form class="row" enctype="multipart/form-data" @submit.prevent="upload">
            <div v-if="!proceed" class="col-lg-4 col-md-6 m-auto">
                <card-component :waves="true">
                    <div class="d-flex flex-column -align-items-center justify-content-center">
						<div class="text-center">
							<svg xmlns="http://www.w3.org/2000/svg" height="5em" version="1.1" viewBox="-53 1 511 511.99906" width="5em">
								<path d="M 276.410156 3.957031 C 274.0625 1.484375 270.84375 0 267.507812 0 L 67.777344 0 C 30.921875 0 0.5 30.300781 0.5 67.152344 L 0.5 444.84375 C 0.5 481.699219 30.921875 512 67.777344 512 L 338.863281 512 C 375.71875 512 406.140625 481.699219 406.140625 444.84375 L 406.140625 144.941406 C 406.140625 141.726562 404.65625 138.636719 402.554688 136.285156 Z M 279.996094 43.65625 L 364.464844 132.328125 L 309.554688 132.328125 C 293.230469 132.328125 279.996094 119.21875 279.996094 102.894531 Z M 338.863281 487.265625 L 67.777344 487.265625 C 44.652344 487.265625 25.234375 468.097656 25.234375 444.84375 L 25.234375 67.152344 C 25.234375 44.027344 44.527344 24.734375 67.777344 24.734375 L 255.261719 24.734375 L 255.261719 102.894531 C 255.261719 132.945312 279.503906 157.0625 309.554688 157.0625 L 381.40625 157.0625 L 381.40625 444.84375 C 381.40625 468.097656 362.113281 487.265625 338.863281 487.265625 Z M 338.863281 487.265625 " style="stroke:none;fill-rule:nonzero;fill:#343a40;fill-opacity:1;" />
								<path d="M 305.101562 401.933594 L 101.539062 401.933594 C 94.738281 401.933594 89.171875 407.496094 89.171875 414.300781 C 89.171875 421.101562 94.738281 426.667969 101.539062 426.667969 L 305.226562 426.667969 C 312.027344 426.667969 317.59375 421.101562 317.59375 414.300781 C 317.59375 407.496094 312.027344 401.933594 305.101562 401.933594 Z M 305.101562 401.933594 " style=" stroke:none;fill-rule:nonzero;fill:#343a40;fill-opacity:1;" />
								<path d="M 140 268.863281 L 190.953125 214.074219 L 190.953125 349.125 C 190.953125 355.925781 196.519531 361.492188 203.320312 361.492188 C 210.125 361.492188 215.6875 355.925781 215.6875 349.125 L 215.6875 214.074219 L 266.640625 268.863281 C 269.113281 271.457031 272.332031 272.820312 275.667969 272.820312 C 278.636719 272.820312 281.730469 271.707031 284.078125 269.480469 C 289.027344 264.78125 289.398438 256.988281 284.699219 252.042969 L 212.226562 174.253906 C 209.875 171.78125 206.660156 170.296875 203.199219 170.296875 C 199.734375 170.296875 196.519531 171.78125 194.171875 174.253906 L 121.699219 252.042969 C 117 256.988281 117.371094 264.902344 122.316406 269.480469 C 127.511719 274.179688 135.300781 273.808594 140 268.863281 Z M 140 268.863281" style="stroke:none;fill-rule:nonzero;fill:#343a40;fill-opacity:1;" />
							</svg>
							<h3 class="tx-color-01 mt-3">{{$t('import-resources')}} (CSV)</h3>
							<p class="tx-color-03 tx-13">
								{{ (hasCategory) ? $t('accepted-file',{accepted}) : $t('select-category') }}
							</p>
						</div>

						<div v-show="hasCategory">
							<div class="form-group">
								<label>{{$t('selected_category')}}</label>
								<p class="mb-0">
									{{ categoryName }}
									<small class="float-right">
										<a href="#change-category" @click.prevent="changeCategory">{{$t('change')}}</a>
									</small>
								</p>
							</div>

							<div class="form-group">
								<label>{{$t('upload-type')}}</label>
								<select v-model="form.type" class="form-control form-control-sm shadow-sm" required>
									<option disabled :value="null">{{$t('select-option')}}</option>
									<option v-for="(value, index) in uploadTypes" :value="index" :key="index" >{{ value|toStartCase }}</option>
								</select>
							</div>

							<div class="form-group">
								<label class="wd-100p">{{$t('file')}}</label>
								<div class="custom-file">
									<input type="file" class="custom-file-input" name="file" :accept="accepted" id="file" @change="selectFile" :class="validation.inputs.data">
									<label class="custom-file-label shadow-sm" for="customFile" :data-browse="$t('select-file')">{{ fileName }}</label>
									<div class="invalid-feedback d-block" v-html="validation.errors.data"></div>
								</div>
							</div>

							<p style='color: red'>{{error}}</p>

							<div>
								<button-component :label="this.$t('upload')" :busy="isBusy" :button-class="'btn btn-primary btn-sm btn-block'"></button-component>
								<div class="or-divider" :data-content="$t('or')"></div>
								<button class="btn btn-white btn-sm btn-block shadow-sm" type="button" @click="checkFile">{{$t('edit-file-content')}}</button>
							</div>
						</div>
						<div v-show="!hasCategory">
							<div class="form-group">
								<label>{{$t('category')}}</label>
								<select-component :options="categories" :value="form.categoryId" :field="'categoryId'" @input="setInput"/>
								<div class="invalid-feedback d-block" v-html="validation.message"></div>
							</div>

							<div v-if="form.categoryId" class="text-center">
								<button class="btn btn-outline-primary btn-sm btn-block shadow-sm" type="button" @click="setCategory">{{$t('proceed')}}</button>
									<div class="or-divider" :data-content="$t('or')"></div>
								<a href="#download-template" @click.prevent="downloadTemplate(form.categoryId, categoryName)">{{$t('download-template')}}</a>
							</div>
						</div>

                    </div>
                </card-component>
            </div>
            <div v-else class="col-12 m-auto">
                <card-component :waves="true">
					<div class="row flex-between-center mb-3">
						<div class="col-6 col-sm-auto d-flex align-items-center pr-0">
							<h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">{{ form.file.name }}</h5>
						</div>
						<div class="col-6 col-sm-auto ml-auto text-right pl-0">
							<div id="table-purchases-replace-element">
								<router-link class="btn btn-white shadow-sm btn-sm mr-2" :to="{ name: 'dashboard.resources.import'}">{{$t('new-file')}}</router-link>
								<button-component :label="this.$t('upload')" :busy="isBusy" :button-class="'btn btn-primary btn-sm'"></button-component>
							</div>
						</div>
					</div>
					<div class="table-responsive" id="resourceList">
						<table class="table" aria-describedby="resources">
							<thead>
								<tr>
									<th v-for="(field,index) in parsedFile.meta.fields" :key="index" scope="col">{{ field|toStartCase }}</th>
								</tr>
							</thead>
							<tbody class="list">
								<tr v-for="(field,index) in parsedFile.data" :key="index">
									<td v-for="(value, index) in field" :key="index">{{ value }}</td>
								</tr>
							</tbody>
						</table>
					</div>
                </card-component>
            </div>
        </form>
    </div>
</template>

<style scoped>
	.table {
		width: 100%;
		font-size: 12px;
	}

	.table th,
	.table td {
		padding: 0.5rem;
		vertical-align: top;
		border-top: 1px solid #dee2e6;
	}

	.table tr {
		font-size: 9px;
	}

	.error-message{
		color: red;
	}
</style>

<script>
	import Papa from 'papaparse';
	import { mapGetters, mapActions } from 'vuex';
	import { validation, file, helpers } from '@/mixins';
	import * as XLSX from "xlsx/xlsx.mjs";

	const placeholders = require('@/store/json/resourceData.json');

	export default {
		name: 'ResourcesImport',
		mixins: [validation, file, helpers],
		data() {
			return {
				proceed: false,
				parsedFile: null,
				accepted: '.csv',
				uploadTypes: [this.$t('update'), this.$t('create')],
				form: {
					file: null,
					data: null,
					categoryId: null,
					type: null,
				},
				categoryName: null,
				hasCategory: false,
				error: ""
			};
		},
		computed: {
			...mapGetters({
				isBusy: 'isBusy',
				categories: 'categories/resourcesCategories',
			}),
		},
		watch: {
			'form.file': function (file) {
				if (file instanceof File) {
					Papa.parse(file, {
						header: true,
						escapeChar: '\\',
						skipEmptyLines: true,
						complete: (results) => (this.parsedFile = results),
					});
				} else {
					this.parsedFile = null;
					this.checkFile();
				}
			},
			'form.categoryId': function (value) {
				if (value) {
					console.log(value)
					const { text } = this.findDeep(this.categories, 'id', value);

					this.categoryName = text;
				}
			},
			proceed: function (value) {
				if (value) {
					window.feather.replace();
				}
			},
		},
		methods: {
			...mapActions({
				setAppState: 'setAppIsBusy',
				attemptGetResourceCategories:
					'resources/attemptGetResourceCategories',
				attemptResourceUpload: 'resources/attemptResourceUpload',
				attemptGetResourceCategory: 'categories/attemptGetResourceCategory',
				attemptResourceUploadTemplateDownload:
					'categories/attemptResourceUploadTemplateDownload',
				attemptGetCategoryResources: 'categories/attemptGetCategoryResources',
				attemptGetResource: 'categories/attemptGetResource',
				attemptGetSpecs: 'categories/attemptGetSpecs'
			}),
			setCategory() {
				const { categoryId } = this.form;

				return (this.hasCategory = categoryId != null);
			},
			changeCategory() {
				this.newFile();
				this.categoryName = null;

				return (this.hasCategory = false);
			},
			toCamelCase(strings){
				for(var i = 0; i < strings.length; i++){
					if(strings[i].includes("_")){
						var tokens = strings[i].split('_');
						tokens[1] = tokens[1].charAt(0).toUpperCase() + tokens[1].slice(1)
						strings[i] = tokens.join('')
					}

					strings[i] = strings[i].replace(/\r?\n|\r/g, "");
				}
				return strings
			},
			generateTemplate(headers){
				var heads = headers.split(",");
				return XLSX.utils.aoa_to_sheet([heads]);
			},
			generateExample(headers, resources, specs){
				//im sorry in advance...
				//convert headers into array, and convert each header to camelCase
				var heads = headers.split(",");
				heads = this.toCamelCase(heads)
				heads.unshift("")

				//specifically grab titles, the formatting of the data requires this
				var titleEn = ""
				var descriptionEn = ""
				var titleFr = ""
				var descriptionFr = ""

				for(var j = 0; j < resources['locale'].length; j++){
					if(resources['locale'][j]['locale'] == "en"){
						titleEn = resources['locale'][j]['title']
						descriptionEn = resources['locale'][j]['description']
					}
					if(resources['locale'][j]['locale'] == "fr"){
						titleFr = resources['locale'][j]['title'];
						descriptionFr = resources['locale'][j]['description'];
					}
				}
				resources["titleEn"] = titleEn
				resources["descriptionEn"] = descriptionEn
				resources["titleFr"] = titleFr
				resources["descriptionFr"] = descriptionFr
				resources["userId"] = resources["user"]["id"]

				//these arrays hold all the spec information, to be pushed to the sheet later
				var resource = []
				var specRequired = []
				var specPlaceholder = []
				var specType = []
				var specOptions = []

				specRequired.push("Required?")
				specPlaceholder.push("Placeholder")
				specType.push("Type")
				specOptions.push("Options/Default Measurement")
				resource.push("Example")
				//this array holds all the resource values
				//determines if the value in the headers was found in the resource
				var found = false;
				var phFound = false;
				var optFound = false;
				for(var i = 1; i < heads.length; i++){
					//once we get to the specs, which are all prefixed with "spec", we should stop checking for matches, there won't be any
					if(heads[i].substring(0, 4) == "spec")
						break;
					for(var key in resources){
						//if there is a match, input the appropriate value for the resource
						if(key == heads[i]){
							if(resources[key] == null){
								resource.push("")
								specType.push("")
							}
							else{
								resource.push(resources[key]);
								switch(typeof(resources[key])){
									case "string":
										specType.push("TEXT"); break;
									case "number":
										specType.push("NUMBER"); break;
								}
							}

							phFound = false;
							//if there was a match, we don't need to input "". Not checking this will result in an improperly formatted sheet
							found = true;
							break;
						}
					}
					for(j = 0; j < placeholders.length; j++){
						if(placeholders[j]["field"] == heads[i]){
							if(placeholders[j]["row"] == "placeholder"){
								specPlaceholder.push(placeholders[j]["placeholder"]);
								for(k = 0; k < placeholders.length; k++){
									if(placeholders[k]["field"] == heads[i]){
										if(placeholders[k]["row"] == "options"){
											specOptions.push(placeholders[k]["options"])
											optFound = true;
											break;
										}
									}
								}
								if(!optFound){
									specOptions.push("");
								}
								optFound = false

							}
							phFound = true;
							break;
						}
					}
					if(!phFound){
						specPlaceholder.push("");
						specOptions.push("")
					}
					phFound = false
					//if there was no match, we're missing a value, so we'll put
					if(!found){
						resource.push("")
						specType.push("")
					}
					found = false;
					if(heads[i] == "accessibility" || heads[i].includes("description"))
						specRequired.push("Not Required")
					else
						specRequired.push("Required")
				}

				//this just formats the specs to camelCase, again, to work with formatting
				var spec = []
				for (i = 0; i < specs.length; i++){
					specs[i]["specificitiesList"]["name"] = specs[i]["specificitiesList"]["name"].charAt(0).toUpperCase() + specs[i]["specificitiesList"]["name"].slice(1)
					if(specs[i]["specificitiesList"]["name"].includes("_")){
						var tokens = specs[i]["specificitiesList"]["name"].split('_');
						tokens[1] = tokens[1].charAt(0).toUpperCase() + tokens[1].slice(1)
						specs[i]["specificitiesList"]["name"] = tokens.join('')
					}
					specs[i]["specificitiesList"]["name"] = specs[i]["specificitiesList"]["name"].replace(/\r?\n|\r/g, "");
					spec.push(specs[i])
				}

				//same as resource, found just checks if there was a match
				console.log(spec)
				found = false
				var idx = -1
				var optsArr = []
				for(i = 1; i < heads.length; i++){
					//until we reach the specs, we input blank spaces, except for in type, because type inputs the type for the resources fields
					//once we're passed the resources, push the correct values for the specs
					if(heads[i].substring(0, 4) == "spec"){
						for(j = 0; j < spec.length; j++){
							if(heads[i].substring(4, heads[i].length) == spec[j]["specificitiesList"]["name"]){
								if(spec[j]["specificitiesList"]["required"] == true)
									specRequired.push("Required")
								else
									specRequired.push("Not Required")

								specPlaceholder.push(spec[j]["specificitiesList"]["specificitiesListLocales"][0]["placeholder"])
								specType.push(spec[j]["specificitiesList"]["type"])
								if(spec[j]["specificitiesList"]["specificitiesListLocales"][0]["options"] == "[]"){
									specOptions.push("No options")
								}
								else{
									optsArr = spec[j]["specificitiesList"]["specificitiesListLocales"][0]["options"].split(',')
									for(k = 0; k < optsArr.length; k++){
										optsArr[k] = optsArr[k].replaceAll(/[[\]']+/g, '')
									}
									console.log(optsArr)
									if(spec[j]["specificitiesList"]["type"] == "MEASURE"){
										idx = spec[j]["specificitiesList"]["defaultUnit"]
										specOptions.push(optsArr[idx - 1])
									}
									else{
										var opts = ""
										for(k = 0; k < optsArr.length; k++)
											opts += k + " = " + optsArr[k] + ", "

										specOptions.push(opts)
										//specOptions.push(spec[j]["specificitiesList"]["specificitiesListLocales"][0]["options"])
									}
								}
								for(var k = 0; k < resources["specificities"].length; k++){
									if(resources["specificities"][k]["specificitiesListId"] == spec[j]["specificitiesList"]["id"])
										resource.push(resources["specificities"][k]["specificitiesLocales"][0]["value"])
								}
								found = true;
								break
							}
						}
						//same as for the resources, if the spec is not found, push a blank space
						if(!found){
							specRequired.push("")
							specPlaceholder.push("")
							specType.push("")
							specOptions.push("")
							if(heads[i].substring(0, 4) == "spec")
								resource.push("")
						}
					}
					found = false;
				}
				return XLSX.utils.aoa_to_sheet([heads, specRequired, specPlaceholder, specType, specOptions, [], resource]);
			},
			downloadTemplate(categoryId, categoryName){
				this.attemptGetResourceCategory(categoryId)
					.then(async () => {
						const {
							csvHeaders,
							fileName,
						} = await this.attemptResourceUploadTemplateDownload({
							categoryId,
							categoryName,
						});

						var wb = XLSX.utils.book_new();
						wb.SheetNames.push("Template");
						wb.Sheets["Template"] = this.generateTemplate(csvHeaders);
						const { resource, specs } = await this.getResourceAndSpecs(categoryId)
						if(resource.length != 0){
							wb.SheetNames.push("Example");
							wb.Sheets["Example"] = this.generateExample(csvHeaders, resource, specs);
						}
						else
							console.log("For devs: resource not found")
						XLSX.writeFile(wb, fileName);
					})
			},
			async getResourceAndSpecs(categoryId){
				const resource = await this.attemptGetResource({categoryId});
				if(resource != undefined){
					const resourceId = resource.id
					const specs = await this.attemptGetSpecs({resourceId});
					return { resource: resource, specs: specs}
				}
				else
					return { resource: [], specs: []}
			},
			upload() {
				this.setAppState(true);

				this.form.data = this.parsedFile ? this.parsedFile.data : null;

				this.attemptResourceUpload(this.form)
					.then(() => {
						this.error = ""
						return this.$router.push({ name: 'dashboard.resources.import' })
					})
					.catch((e) => {
						console.log(e.data.message)
						this.error = e.data.message
						return this.validationErrors(e)
					})
					.finally(() => this.setAppState(false));
			},
		},
	};
</script>
