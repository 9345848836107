import types from '../../json/resourceTypes.json'
import {
	resource
} from '../../json/importHeaders.json'

export default {
	permissions: {
		createPerm: false,
		delegatePerm: false,
		deletePerm: true,
		publishPerm: false,
		readPerm: true,
		updatePerm: true
	},
	status: {
		published: 1,
		unpublished: 8,
		hidden: 4,
		hiddenFromPublic: 16
	},
	types,
	headers: resource,
	priceTerms: [],
	resource: null
}
