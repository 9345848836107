import Vue from 'vue'
import {
	request,
	socket
} from '@/config'
import store from '@/store'

export default function (mutation) {
	const {
		type,
		payload
	} = mutation

	switch (type) {
		case 'auth/SET_TOKEN':
			if (payload) {
				const io = socket(payload)

				io.on('connect', () => {
					console.log('Connected to socket.');
					Vue.prototype.$socket = io;
				});


				request.defaults.headers.common['Authorization'] = `Bearer ${payload}`
				localStorage.setItem('token', payload)
			} else {
				delete request.defaults.headers.common["Authorization"];

				localStorage.clear();
			}
			break

		case 'auth/SET_USER':
			if (payload) {
				const {
					role,
					permissions		
				} = payload

				if(localStorage.getItem('appLanguage') === null || typeof(localStorage.getItem('appLanguage') ==='undefined')){
					store.dispatch('setAppLanguage', payload.locale)
					localStorage.setItem('appLanguage',payload.locale)
				}

				store.dispatch('auth/setUserRole', role)
				store.dispatch('auth/setUserPermissions', permissions)
				store.dispatch('profile/attemptGetProfile')

				if (localStorage.getItem("marketplace") === null && role.name !== 'super_admin') {
					store.dispatch('marketplace/attemptGetMarketplace')
				} else {
					store.commit('marketplace/SET_MARKETPLACE', store.state.marketplace.marketplace)
				}

				const bodyStyles = document.body.style;
				const { bright, dark, medium, semiBright, semiDark , gradientPrimary } =
				store.state.marketplace.marketplace.colorPalette;

				bodyStyles.setProperty('--color-bright', bright);
				bodyStyles.setProperty('--color-dark', dark);
				bodyStyles.setProperty('--color-medium', medium);
				bodyStyles.setProperty('--color-semi-bright', semiBright);
				bodyStyles.setProperty('--color-semi-dark', semiDark);
				bodyStyles.setProperty('--color-gradient-primary', gradientPrimary);

				bodyStyles.setProperty('--primary', medium);
			}
			break
		default:
			break
	}
}
