<template>
	<div class="modal" id="new-offer" tabindex="-1" aria-labelledby="new-offerLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<form @submit.prevent="newOffer" class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="new-offerLabel"></h5>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="form-group col-md-6">
							<label>{{$t('start-date')}}</label>
							<input v-model="form.startDate" type="date" class="form-control form-control-sm" :class="validation.inputs['startDate']" :required="attributes.startDate">
							<div class="invalid-feedback d-block" v-html="validation.errors['startDate']"></div>
						</div>
						<div class="form-group col-md-6">
							<label>{{$t('end-date')}}</label>
							<input v-model="form.endDate" type="date" class="form-control form-control-sm" :class="validation.inputs['endDate']" :required="attributes.endDate">
							<div class="invalid-feedback d-block" v-html="validation.errors['endDate']"></div>
						</div>
						<div class="form-group col-md-6">
							<label>{{$t('price')}}</label>
							<input v-model.number="form.price" type="number" step="any" class="form-control form-control-sm" :class="validation.inputs['price']" :required="attributes.price">
							<div class="invalid-feedback d-block" v-html="validation.errors['price']"></div>
						</div>
						<div class="form-group col-md-6">
							<label>{{$t('quantity')}}</label>
							<input v-model.number="form.quantity" type="number" step="any" class="form-control form-control-sm" :class="validation.inputs['quantity']" :required="attributes.quantity">
							<div class="invalid-feedback d-block" v-html="validation.errors['quantity']"></div>
						</div>
					</div>
				</div>
				<button type="submit" class="modal-footer btn btn-white" :disabled="isDisabled">{{$t('send')}}</button>
			</form>
		</div>
	</div>
</template>

<script>
	import { isEqual } from 'lodash';
	import { validation } from '@/mixins';

	export default {
		name: 'NegotiationNewOfferComponent',
		mixins: [validation],
		mounted() {
			const { toStartCase, toDate } = this.$options.filters;

			window.$('#new-offer').on('show.bs.modal', (event) => {
				const button = window.$(event.relatedTarget);
				const modal = window.$('#new-offer');
				const { startDate, endDate, price, quantity } =
					button.data('details');

				this.type = button.data('type');
				this.offer = button.attr('data-offer');
				this.negotiation = button.data('negotiation');
				this.originalFormData = {
					startDate: toDate(startDate, 'YYYY-MM-DD'),
					endDate: toDate(endDate, 'YYYY-MM-DD'),
					price,
					quantity,
				};

				this.form = JSON.parse(JSON.stringify(this.originalFormData));

				modal.find('.modal-title').text(`${toStartCase(this.type)} Offer`);
			});

			window.$('#new-offer').on('hidden.bs.modal', () => {
				this.counter = 0;
			});
		},
		data() {
			return {
				type: null,
				offer: null,
				negotiation: null,
				originalFormData: null,
				form: {
					startDate: null,
					endDate: null,
					price: null,
					quantity: null,
				},
				isDisabled: true,
			};
		},
		computed: {
			attributes() {
				const { attributes } = this.priceTerm;

				return attributes;
			},
		},
		watch: {
			form: {
				handler: function (value) {
					const data = JSON.parse(JSON.stringify(value));
					const original = JSON.parse(
						JSON.stringify(this.originalFormData)
					);

					this.isDisabled = isEqual(data, original);
				},
				deep: true,
			},
		},
		methods: {
			newOffer() {
				this.$emit(
					'update-negotiation',
					{
						type: this.type,
						offerId: this.offer,
						negotiationId: this.negotiation,
						status: {
							desc: 'Rejected',
							id: 6,
						},
					},
					this.form
				);
			},
		},
		props: {
			priceTerm: {
				type: Object,
				required: true,
			},
		},
	};
</script>

<style lang="scss" scoped>
	.modal-content {
		.modal-header,
		.modal-footer {
			align-items: normal;
			justify-content: center;

			&.btn {
				border: 0;
				color: #212529;
			}
		}

		.modal-body {
			background-color: #ebebeb;

			.form-group {
				label {
					color: #464646;
				}
			}
		}
	}
</style>
