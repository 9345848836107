export default {
	SET_MESSAGE(state, message) {
		state.message = message
	},
	SET_MEMBER(state, member) {
		state.member = member
	},
	SET_MEMBERS_LIST(state, list){
		state.list = list
	}
}
