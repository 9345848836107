import {
	request
} from '@/config'
import store from '@/store'

export default {
	async attemptGetNegotiation({
		commit
	}, negotiationId) {
		const {
			locale
		} = store.getters

		const negotiation = await request.get(`/negotiations/${negotiationId}`, {
			params: {
				isAdmin: true,
				locale
			}
		}).then(async ({
			data: {
				data: {
					negotiation: response
				}
			}
		}) => {
			await store.dispatch('resources/attemptGetResourcePriceTerms')


			return response
		}).catch(({
			response: {
				data: {
					message: error
				}
			}
		}) => {
			throw error
		}).finally(() => store.dispatch('setAppIsBusy', false))

		commit('SET_NEGOTIATION', negotiation)
	},
	async attemptNegotiationUpdate(_, {
		negotiationId,
		type
	}) {
		return request.post(`/negotiations/${negotiationId}/${type}`)
			.then(() => true)
			.catch(({
				response: error
			}) => {
				throw error
			})
	},
}
