<template>
    <div class="d-flex" :class="{ 'toggled': menuToggled }" id="wrapper">
        <sidebar></sidebar>

        <div id="page-content-wrapper">
            <navbar></navbar>
            <div class="container-fluid">
                <toast-component></toast-component>
				
                <router-view v-if="$route.name !=='dashboard'"></router-view>

				<div v-else>
					<breadcrumb></breadcrumb>
				</div>
            </div>
        </div>
    </div>
</template>

<script>
	import { mapGetters } from 'vuex';

	export default {
		name: 'Dashboard',
		computed: {
			...mapGetters({
				menuToggled: 'menuToggled',
			}),
		},
	};
</script>

<style scoped>
	#wrapper {
		overflow-x: hidden;
	}

	#page-content-wrapper {
		min-width: 100vw;
	}

	#wrapper.toggled #sidebar-wrapper {
		margin-left: 0;
	}

	@media (min-width: 768px) {
		#page-content-wrapper {
			min-width: 0;
			width: 100%;
		}
	}
	/** */
	.footer {
		z-index: 1020;
	}

	@media (min-width: 768px) {
		.footer {
			margin-left: 25%;
		}
	}

	@media (min-width: 992px) {
		.footer {
			margin-left: 16.666667%;
		}
	}
</style>
