import store from '@/store'
import {
	request
} from '@/config'

export default {
	async attemptGetInvoice({
		commit
	}, invoiceId) {
		const invoice = await request.get(`/payment/invoices/${invoiceId}`)
			.then(({
				data: {
					data: {
						invoice: response
					}
				}
			}) => response)
			.catch(({
				response: error
			}) => {
				throw error
			})

		commit('SET_INVOICE', invoice)

		return invoice
	},
	async attemptUpdateInvoice({
		commit
	}, {
		invoiceId,
		data,
		type
	}) {
		const invoice = await request.patch(`/payment/invoices/${invoiceId}`, data)
			.then(({
				data: {
					data: {
						invoice: response
					}
				}
			}) => response)
			.catch(({
				respose: error
			}) => {
				throw error
			})

		commit('SET_INVOICE', invoice)

		store.commit('SET_MESSAGE', `${type} invoice updated successfully.`)

		return invoice
	},
}
