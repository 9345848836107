<template>
	<div class="col-12">
		<div class="bz-container" :style="{'background-color':color , 'color': font}">
			<div class="bz-text-container" :class="type">
				<p class="bz-type" :style="{'color': font}" >{{ isToggle ? preview.TYPE[0].enValue : preview.TYPE[0].frValue|toLorem(5) }}</p>
				<h1 class="bz-title">{{ isToggle ? preview.TITLE[0].enValue : preview.TITLE[0].frValue|toLorem(18) }}</h1>
				<p class="bz-subtitle">{{ isToggle ? preview['SUB-TITLE'][0].enValue : preview['SUB-TITLE'][0].frValue|toLorem(118) }}</p>

				<a :href="isToggle ? preview['BUTTON-LINK'][0].enValue : preview['BUTTON-LINK'][0].frValue" target="_blank" class="btn btn-primary btn-lg bz-button" role="button">
					{{ isToggle ? preview['BUTTON-TEXT'][0].enValue : preview['BUTTON-TEXT'][0].frValue|toLorem(11) }}
				</a>

				<a href="#toggle-locale" class="bz-toggle-locale" @click.prevent="toggleLocale">{{$t('locale-change')}}</a>
			</div>
			<div class="bz-image-container">
				<img :class="type" :src="image" :ref="type" alt="bz-image-preview">
			</div>
		</div>

		<div v-if="!isPreview" class="my-3 font-weight-lighter">
			<small class="d-block">{{$t('image-rec')}}</small>
			<small class="text-muted d-block">{{$t('image-example')}}</small>
		</div>
	</div>
</template>

<script>
	import noImage from '@/assets/img/noPhoto.png';

	import { groupBy } from 'lodash';
	import { file } from '@/mixins';

	export default {
		name: 'BannersPreviewComponent',
		mixins: [file],
		mounted() {
			this.setPreview(this.locale);
		},
		data() {
			return {
				noImage,
				isToggle: true,
				preview: {
					TYPE: [{ enValue: null, frValue: null }],
					TITLE: [{ enValue: null, frValue: null }],
					IMAGE: [{ enValue: null, frValue: null }],
					'SUB-TITLE': [{ enValue: null, frValue: null }],
					'BUTTON-TEXT': [{ enValue: null, frValue: null }],
					'BUTTON-LINK': [{ enValue: null, frValue: null }],
				},
			};
		},
		watch: {
			locale: {
				handler: function (locale) {
					this.setPreview(locale);
				},
				deep: true,
			},
			image(value) {
				if (value) {
					this.previewFile(value);
				}
			},
		},
		methods: {
			toggleLocale() {
				this.isToggle = !this.isToggle;
			},
			setPreview(locale) {
				this.preview = groupBy(locale, (obj) => obj.type);

				if (typeof this.image === 'object') {
					this.previewFile(this.image);
				}
			},
		},
		props: {
			id: {
				type: String,
				required: false,
			},
			type: {
				type: String,
				required: true,
			},
			color:{
				type: String
			},
			font:{
				type: String
			},
			locale: {
				type: Array,
				required: true,
			},
			image: {
				type: [Object, String, File],
				default: noImage,
			},
			isPreview: {
				type: Boolean,
				default: true,
			},
		},
	};
</script>

<style lang="scss" scoped>
	.bz-container {
		min-height: 200px;
		padding: 50px 40px;
		position: relative;
		background-color: #e9ecef;
		border-radius: 0.3rem;
		font-family: 'Lato', sans-serif;
		box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0,.08) !important;

		.bz-text-container {
			width: 100%;

			button {
				padding: 10px 20px;
				font-size: 14px;
				font-family: Lato;
				box-shadow: 0 3px 18px 0 rgba(241, 89, 42, 0.34);
				max-width: 260px;
				background: gradient
			}

			@media (max-width: 400px) {
				padding-left: 20px;
				padding-right: 20px;
			}

			.bz-title {
				font-size: 30px;
				margin: 20px 0;
				font-weight: 400;

				@media (max-width: 500px) {
					line-height: unset;
				}
			}

			.bz-button {
				padding: 0.75rem 24px;
				text-transform: uppercase;
				font-size: 0.875rem;
				letter-spacing: 0.37px;
				color: white;
				border: none;
				border-radius: 500px;
				transition: all 0.4s;
				cursor: pointer;
				background-image: var(--color-gradient-primary);
			}

			.bz-type {
				font-size: 14px;
				text-transform: uppercase;
				color: #BEBCBC;
			}

			.bz-subtitle {
				width: 50%;
				max-width: 380px;
				font-size: 14px;
				margin-bottom: 20px;

				@media (max-width: 500px) {
					width: unset;
					max-width: unset;
				}
			}

			.bz-toggle-locale {
				font-size: 10px;
				margin-left: 1rem;
				text-decoration: none;
			}
		}

		@media (min-width: 480px) {
			.bz-text-container.BOTTOM{
				margin-left: 50%;
			}
		}

		@media (max-width: 425px) {
			.bz-text-container{
				.bz-title {
					font-size: 20px;
					margin: 10px 0;
					font-weight: 400;
				}

				.bz-button {
					padding: 0.5rem 15px;
					text-transform: uppercase;
					font-size: 0.75rem;
					letter-spacing: 0.37px;
					color: #fff;
					border: none;
					border-radius: 500px;
					transition: all 0.4s;
					cursor: pointer;
					background-image: var('--color-gradient-primary')
				}

				.bz-type {
					font-size: 12px;
					text-transform: uppercase;
					color: #BEBCBC;
				}

				.bz-subtitle {
					width: 100%;
					font-size: 12px;
					margin-bottom: 20px;
				}
			}
		}

		.bz-image-container {
			width: 60%;

			img {
				position: absolute;
				bottom: 0;
				height: 100%;
				object-fit: cover;
				z-index: 1;
				width: 40%;

				@media (max-width: 630px){
					display: none;
				}
			}

			img.TOP {
				right: 0;
			}

			img.BOTTOM {
				left: 0;
			}
		}
	}
</style>


