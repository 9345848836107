import store from '@/store'
import {
	request
} from '@/config'

export default {
	async attemptFeeCreation(_, data) {
		const response = await request.post(`/fees`, data)
			.then(() => 'Fee created successfully')
			.catch(({
				response: error
			}) => {
				throw error
			})

		store.commit('SET_MESSAGE', response)

		return response
	},
	async attemptFeeUpdate(_, {
		feeId,
		data
	}) {
		const response = await request.patch(`/fees/${feeId}`, data)
			.then(({
				data: {
					message
				}
			}) => message)
			.catch(({
				response: error
			}) => {
				throw error
			})

		store.commit('SET_MESSAGE', response)

		return response
	},
}
