export default {
	invoice: null,
	status: [{
			id: 8,
			value: "Pending Payment"
		},
		{
			id: 9,
			value: "Paid In"
		}, {
			id: 13,
			value: "Waiting For Approval"
		}
	]
}
