import store from '@/store'

export default function (mutation) {
	const {
		type
	} = mutation

	const {
		menuToggled
	} = store.getters

	switch (type) {
		case 'SET_APP_MENU_TOGGLED':
			localStorage.setItem('menuToggled', menuToggled)
			break

		default:
			break
	}
}
