import store from '@/store'

export default [{
		path: 'resources',
		name: 'dashboard.resources.index',
		component: () => import('@/views/dashboard/resources/ResourcesView.vue'),
		beforeEnter: (to, from, next) => {
			const {
				report
			} = store.getters['auth/permissions'].resources

			if (report) {
				return next()
			}

			return next({
				name: 'dashboard'
			})
		}
	},
	{
		path: 'resources/import',
		name: 'dashboard.resources.import',
		component: () => import('@/views/dashboard/resources/ResourcesImport.vue'),
		beforeEnter: async (to, from, next) => {
			await store.dispatch('categories/attemptGetResourceCategories')

			const {
				import: canImport
			} = store.getters['auth/permissions'].resources

			if (canImport) {
				return next()
			}

			return next({
				name: 'dashboard.resources.index'
			})
		}
	},
	{
		path: 'resources/:resourceId',
		name: 'dashboard.resources.edit',
		component: () => import('@/views/dashboard/resources/ResourcesEdit.vue'),
		beforeEnter: (to, from, next) => {
			const {
				edit
			} = store.getters['auth/permissions'].resources

			if (edit) {
				return next()
			}

			return next({
				name: 'dashboard.resources.index'
			})
		}
	},
]
