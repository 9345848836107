<template>
	<div class="card-footer text-center">
		<div v-if="isPending" class="offer-options">
			<a v-if="can.accept" href="#accept-offer" @click.prevent="accept" class="btn btn-outline-success btn-sm">{{$t('accept')}}</a>
			<a v-if="can.edit" href="#new-offer" data-toggle="modal" :data-offer="offerId" :data-negotiation="negotiationId" :data-details="offerDetails" data-type="edit" class="btn btn-light btn-sm">{{$t('edit-offer')}}</a>
			<a v-if="can.counter" href="#new-offer" data-toggle="modal" :data-offer="offerId" :data-negotiation="negotiationId" :data-details="offerDetails" data-type="counter" class="btn btn-outline-secondary btn-sm">{{$t('counter')}}</a>
		</div>
		<span v-else :class="classObject">
			<strong>{{ statusDescription }}</strong>
		</span>
	</div>
</template>

<script>
	export default {
		name: 'NegotiationMessageOptionComponent',
		computed: {
			statusDescription() {
				const { id, desc } = this.status;

				return id === 6 ? 'Cancelled' : desc;
			},
			isPending() {
				return this.status.id === 4;
			},
			classObject() {
				return this.status.id === 7 ? 'text-success' : 'text-danger';
			},
			can() {
				const edit = this.viewAs !== this.receiver;
				const accept = !edit;
				const counter = !edit;

				return {
					edit,
					accept,
					counter,
				};
			},
			offerDetails() {
				const { startDate, endDate, price, quantity } = this.message;

				return JSON.stringify({
					startDate,
					endDate,
					price,
					quantity,
				});
			},
		},
		methods: {
			accept() {
				this.$emit('accept', {
					type: 'accept',
					offerId: this.offerId,
					negotiationId: this.negotiationId,
					offerDetails: JSON.parse(this.offerDetails),
					status: {
						desc: 'Accepted',
						id: 7,
					},
				});
			},
		},
		props: {
			offerId: {
				type: String,
				required: true,
			},
			negotiationId: {
				type: String,
				required: true,
			},
			status: {
				type: [Object, Boolean],
				default: null,
			},
			viewAs: {
				type: String,
				required: true,
			},
			receiver: {
				type: String,
				required: true,
			},
			message: {
				type: Object,
				required: true,
			},
		},
	};
</script>

<style lang="scss" scoped>
	.offer-options {
		a,
		button {
			margin-right: 0.5rem;
		}
	}
</style>
