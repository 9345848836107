export default {
	setAppIsBusy({
		commit,
	}, value) {
		commit('SET_APP_IS_BUSY', value)
	},
	setAppIsLoading({
		commit
	}, value) {
		commit('SET_APP_IS_LOADING', value)
	},
	setMessage({
		commit
	}, value) {
		commit('SET_MESSAGE', value)
	},
	setAppLogo({
		commit
	}, logo) {
		commit('SET_APP_LOGO', logo)
	},
	setAppMenuToggled({
		commit
	}) {
		commit('SET_APP_MENU_TOGGLED')
	},
	setAppLanguage({
		commit
	}, locale) {
		commit('SET_APP_LANGUAGE', locale)
	}
}
