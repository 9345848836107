export default {
	SET_MARKETPLACES(state, marketplaces) {
		state.marketplaces = marketplaces.map((marketplace) => {
			const {
				id,
				data: {
					name
				}
			} = marketplace

			return {
				id,
				name
			}
		})
	},
	SET_MARKETPLACE(state, marketplace) {
		state.marketplace = marketplace
	},
	SET_MEMBERSHIP_TYPES(state, membershipTypes) {
		state.membershipTypes = membershipTypes
	},
	SET_MARKETPLACE_TEMPLATES(state, templates) {
		state.templates = templates
	}
}
