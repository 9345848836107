import store from '@/store'

export default [{
	path: 'profile',
	component: () => import('@/views/dashboard/profile/Profile.vue'),
	redirect: {
		name: 'dashboard.profile.account'
	},
	children: [{
		path: 'account',
		name: 'dashboard.profile.account',
		component: () => import('@/views/dashboard/profile/Account.vue'),
		children: [{
			path: 'invoices',
			name: 'dashboard.profile.account.invoices',
			component: () => import('@/views/dashboard/profile/account/InvoicesView.vue'),
			beforeEnter: async (to, from, next) => {
				if (store.getters['auth/role'] !== 'Super Admin') {
					return next()
				}

				return next({
					name: 'dashboard'
				})
			},
		}, {
			path: 'payment',
			name: 'dashboard.profile.account.payments',
			component: () => import('@/views/dashboard/profile/account/PaymentsView.vue')
		}, {
			path: 'settings',
			name: 'dashboard.profile.account.settings',
			component: () => import('@/views/dashboard/profile/account/SettingsView.vue')
		}]
	}]
}]
