<template>
	<div class="row">
		<div class="form-group col-12">
			<label class="w-100">{{$t('type')}} <a href="#remove" @click.prevent="remove" class="float-right">{{$t('remove')}}</a></label>
			<select v-model="specificity.type" class="form-control form-control-sm shadow-sm" required>
				<option disabled :value="null" >{{$t('select-option')}}</option>
				<option v-for="({id, text}, index) in specificityTypes" :value="id" :key="index">{{ $t(text) }}</option>
			</select>
			<div class="invalid-feedback d-block" v-html="validation.message"></div>
		</div>

		<template v-if="specificity.type != null">
			<template v-if="isMeasure">
				<div class="col-md-12 row">
					<div class="form-group col-md-6">
						<label class="w-100">{{$t('measure-type')}} <a v-if="quantityType != null" href="#view-options" data-toggle="modal" class="float-right">{{$t('view-options')}}</a></label>
						<select v-model="quantityType" class="form-control form-control-sm shadow-sm" required>
							<option disabled :value="null" >{{$t('select-option')}}</option>
							<option v-for="(value, index) in measureTypes" :value="index" :key="index" >{{ $t(`measureTypes.${index}.title`) }}</option>
						</select>

						<div class="modal" id="view-options" tabindex="-1" aria-labelledby="view-options-label" aria-hidden="true">
							<div class="modal-dialog modal-dialog-scrollable modal-sm">
								<div class="modal-content">
									<div class="modal-header">
										<h5 class="modal-title" id="view-options-label">{{ quantityType }}</h5>
									</div>
									<div class="modal-body">
										<ul class="measureOptions">
											<li v-for="(option, index) in measureOptions" :key="index">{{ $t(option) }}</li>
										</ul>
									</div>
									<div class="modal-footer">
										<button type="button" class="btn btn-primary btn-sm shadow-sm" data-dismiss="modal">{{$t('close')}}</button>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="form-group col-md-6">
						<label class="w-100">{{$t('default-unit')}}</label>
						<select v-model="specificity.defaultUnit" class="form-control form-control-sm shadow-sm" required>
							<option disabled :value="null" >{{$t('select-unit')}}</option>
							<option v-for="(option, index) in measureOptions" :value="index" :key="index" >{{ $t(option) }}</option>
						</select>
						<div class="invalid-feedback d-block" v-html="validation.message"></div>
					</div>
				</div>

			</template>

			<div class="col-md-12 row" v-for="locale in this.specificity.locale" :key="locale.locale">
				<div class="form-group col-md-6">
					<label>{{locale.locale.toUpperCase()}} {{$t('input-name')}}</label>
					<input v-model="locale.name" type="text" class="form-control form-control-sm shadow-sm" required>
					<div class="invalid-feedback d-block" v-html="validation.message"></div>
				</div>
				<div class="form-group col-md-6">
					<label>{{locale.locale.toUpperCase()}} {{$t('input-placeholder')}}</label>
					<input v-model="locale.placeholder" type="text" class="form-control form-control-sm shadow-sm" required>
					<div class="invalid-feedback d-block" v-html="validation.message"></div>
				</div>
			</div>

<!--			<div class="form-group col-md-6">-->
<!--				<label>Input Name</label>-->
<!--				<input v-model="specificity.locale[1].name" type="text" class="form-control form-control-sm shadow-sm" required>-->
<!--				<div class="invalid-feedback d-block" v-html="validation.message"></div>-->
<!--			</div>-->
<!--			<div class="form-group col-md-6">-->
<!--				<label>Input Placeholder</label>-->
<!--				<input v-model="specificity.locale[1].placeholder" type="text" class="form-control form-control-sm shadow-sm" required>-->
<!--				<div class="invalid-feedback d-block" v-html="validation.message"></div>-->
<!--			</div>-->

<!--			<div class="form-group col-md-6">-->
<!--				<label>French Input Name</label>-->
<!--				<input v-model="specificity.locale[0].name" type="text" class="form-control form-control-sm shadow-sm" required>-->
<!--				<div class="invalid-feedback d-block" v-html="validation.message"></div>-->
<!--			</div>-->
<!--			<div class="form-group col-md-6">-->
<!--				<label>French Input Placeholder</label>-->
<!--				<input v-model="specificity.locale[0].placeholder" type="text" class="form-control form-control-sm shadow-sm" required>-->
<!--				<div class="invalid-feedback d-block" v-html="validation.message"></div>-->
<!--			</div>-->

			<template v-if="isSelect">
				<div class="form-group col-12">
					<label class="w-100">{{$t('options')}} <a v-if="hasOptions" :href="`#options-modal-${index}`" data-toggle="modal" class="float-right">{{$t('edit')}}</a></label>
					<ul v-if="hasOptions" class="list-group">
						<li v-for="(option, index) in selectOptions" :key="index" class="list-group-item">
							{{ `${option.en}/${option.default}` }} <a href="#remove-option" @click.prevent="removeOption(index, true)" class="float-right">{{$t('remove')}}</a>
						</li>
					</ul>
					<p v-else class="mb-0">{{$t('no-options')}} <small><a :href="`#options-modal-${index}`" data-toggle="modal" @click="addOption">{{$t('new-option')}}.</a></small></p>
				</div>

				<div class="modal" :id="`options-modal-${index}`" data-backdrop="static" data-keyboard="true" tabindex="-1" :aria-labelledby="`options-modal-${index}-label`" aria-hidden="true">
					<form @submit.prevent="saveOptions" class="modal-dialog modal-dialog-scrollable">
						<div class="modal-content">
							<div class="modal-header">
								<h5 class="modal-title" :id="`options-modal-${index}-label`">{{ hasOptions ? $t('edit-options') : $t('add-options') }}</h5>
								<a v-if="tempSelectOptions.length" href="#remove-all" @click.prevent="removeOption()">{{$t('remove-all')}}</a>
							</div>
							<div class="modal-body">
								<fieldset v-for="(option, index) in tempSelectOptions" :key="index" class="row">
									<legend class="w-100 px-3">{{$t('option')}} {{ index + 1 }} <small><a href="#remove-option" @click.prevent="removeOption(index)" class="float-right">{{$t('remove')}}</a></small></legend>
									<div class="form-group col-md-6">
										<label>EN {{$t('name')}}</label>
										<input v-model="tempSelectOptions[index].en" type="text" class="form-control form-control-sm shadow-sm" required>
									</div>
									<div class="form-group col-md-6">
										<label>FR {{$t('name')}}</label>
										<input v-model="tempSelectOptions[index].default" type="text" class="form-control form-control-sm shadow-sm" required>
									</div>
								</fieldset>

								<small>
									<a href="#add-option" @click.prevent="addOption">{{$t('add-option')}}</a>
								</small>
							</div>
							<div class="modal-footer">
								<button type="submit" class="btn btn-primary btn-sm shadow-sm">{{$t('save')}}</button>
								<button type="button" class="btn btn-white btn-sm shadow-sm" @click="close">{{$t('cancel')}}</button>
							</div>
						</div>
					</form>
				</div>
			</template>

			<div class="form-group col-12">
				<div class="custom-control custom-checkbox">
					<input type="checkbox" class="custom-control-input" :id="`check-${index}`" v-model="specificity.required">
					<label class="custom-control-label" :for="`check-${index}`">{{$t('required-field')}}</label>
				</div>
				<div class="invalid-feedback d-block" v-html="validation.message"></div>
			</div>
		</template>
	</div>
</template>

<style lang="scss" scoped>
	legend {
		font-size: .85rem;
		margin-bottom: 0rem;
	}

	.list-group {
		display: flex;
		flex-direction: column;
		padding-left: 0;
		margin-bottom: 0;
		border-radius: 0.25rem;
		box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

		.list-group-item {
			position: relative;
			display: block;
			padding: 0.15rem .8rem;
			background-color: #ffff;

			a {
				font-size: .85rem;
			}
		}
	}

	#view-options {
		.modal-body {
			padding: 1rem 0rem 0rem;
		}
	}

	.measureOptions {
		margin-bottom: 0;
	}
</style>

<script>
	import { mapGetters } from 'vuex';
	import { isEmpty, map, snakeCase } from 'lodash';

	export default {
		name: 'SpecificityListInformationComponent',
		mounted() {
			window.$(`#options-modal-${this.index}`).on('hidden.bs.modal', () => {
				const options = this.selectOptions;

				this.tempSelectOptions = options;
			});
		},
		data() {
			return {
				quantityType: null,
				selectOptions: [],
				tempSelectOptions: [],
				measureOptions: [],
				isMeasure: false,
				isSelect: false
			};
		},
		computed: {
			...mapGetters({
				type: 'categories/specificities/type',
				specificityTypes: 'categories/specificities/specificityTypes',
				measureTypes: 'categories/specificities/measureTypes'
			}),
			hasOptions() {
				if (this.specificity.type === 'SELECT' || this.specificity.type === 'MULTI_SELECT') {
					const [fr, en] = this.specificity.locale;

					return !isEmpty(fr.options) || !isEmpty(en.options);
				}

				return false;
			},
		},
		watch: {
			'specificity.type': function (value) {
				const { simple, complex } = this.type;

				this.isMeasure = this.isSelect = false;

				this.specificity.locale.map((e) => (e.options = null));

				if (simple.includes(value)) {
					//
				}

				if (complex.includes(value)) {
					if (value === 'MEASURE') {
						return (this.isMeasure = true);
					}

					if (value === 'SELECT' || value === 'MULTI_SELECT') {
						return (this.isSelect = true);
					}
				}
			},
			'specificity.locale': {
				handler: function (value) {
					this.specificity.name = snakeCase(value[1].name);
				},
				deep: true,
			},
			quantityType(value) {
				this.measureOptions = this.measureTypes[value].map(m => `measureTypes.${value}.${m}`)

				this.specificity.locale.map(
					(e) => (e.options = this.measureOptions)
				);
			},
		},
		methods: {
			remove() {
				this.$emit('remove', this.index);
			},
			close() {
				window.$(`#options-modal-${this.index}`).modal('hide');
			},
			addOption() {
				this.tempSelectOptions.push({
					default: null,
					en: null,
				});
			},
			removeOption(index = null, update = false) {
				if (index) {
					if (update) {
						this.selectOptions.splice(index, 1);

						this.tempSelectOptions = new Array(...this.selectOptions);

						return this.saveOptions();
					} else {
						return this.tempSelectOptions.splice(index, 1);
					}
				}

				return (this.tempSelectOptions = []);
			},
			saveOptions() {
				const [fr, en] = this.specificity.locale;

				this.selectOptions = new Array(...this.tempSelectOptions);

				fr.options = map(this.selectOptions, 'default');
				en.options = map(this.selectOptions, 'en');

				this.specificity.locale = [fr, en];

				return this.close();
			},
		},
		props: {
			index: {
				type: Number,
				required: true,
			},
			specificity: {
				type: Object,
				required: true,
			},
			validation: {
				type: Object,
			},
			isEdit: {
				type: Boolean,
				default: false,
			},
		},
	};
</script>
