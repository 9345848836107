<template>
	<div>
		<form v-if="member" @submit.prevent="updateMember">
			<breadcrumb :label="$t('members')">
				<button-component :label="'Save'" :busy="isBusy" :button-class="'btn btn-primary btn-sm mr-2'"></button-component>
				<router-link class="btn btn-white btn-sm shadow-sm" :to="{ name: 'dashboard.users.members.index'}" exact>
					{{$t('cancel')}}
				</router-link>
			</breadcrumb>

			<div class="row col-lg-12 col-md-12 m-auto">
				<div class="col-2"></div>
				<div class="col-6">
					<card-component :waves="true">
						<h5>{{$t('company-information')}}</h5>
						<div class="row">
							<div class="form-group col-md-6">
								<label>{{$t('category')}}</label>
								<select-component :options="categories" :value="form.categoryId" :field="'categoryId'" @input="setCategory"/>
								<div class="invalid-feedback d-block" v-html="validation.errors['categoryId']"></div>
							</div>
							<div class="form-group col-md-6">
								<label>{{$t('email')}}</label>
								<input v-model="form.company.email" type="email" class="form-control form-control-sm shadow-sm" :class="validation.inputs['company.email']" disabled>
								<div class="invalid-feedback d-block" v-html="validation.errors['company.email']"></div>
							</div>
							<div class="form-group col-md-6">
								<label>{{$t('phone-number')}}</label>
								<phone-number-component :ref-id="'company-phone-number'" :phone-number="form.company.phoneNumber" @update-phone="updateCompanyPhone" :hasError="validation.inputs['company.phoneNumber']"></phone-number-component>
								<div class="invalid-feedback d-block" v-html="validation.errors['company.phoneNumber']"></div>
							</div>
							<div class="form-group col-md-6">
								<label>{{$t('address')}}</label>
								<input v-model="form.company.streetAddress" type="email" class="form-control form-control-sm shadow-sm" :class="validation.inputs['company.streetAddress']" disabled>
								<div class="invalid-feedback d-block" v-html="validation.errors['company.streetAddress']"></div>
							</div>

							<div class="form-group col-12" v-for="locale in form.company.locales" :key="`${locale.locale}-title`">
								<label>{{locale.locale.toUpperCase()}} {{$t('title')}}</label>
								<input v-model="locale.title" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs['locale.title']" required>
								<div class="invalid-feedback d-block" v-html="validation.errors['locale.title']"></div>
							</div>

							<div class="form-group col-12" v-for="locale in form.company.locales" :key="`${locale.locale}-description`">
								<label>{{locale.locale.toUpperCase()}} Description</label>
								<textarea v-model="locale.description" class="form-control shadow-sm" rows="4" :class="validation.inputs['locale.description']" required></textarea>
								<div class="invalid-feedback d-block" v-html="validation.errors['locale.description']"></div>
							</div>

<!--							<div class="form-group col-12">-->
<!--								<label>Title</label>-->
<!--								<input v-model="form.company.locales[1].title" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs['company.locales[1].title']" required>-->
<!--								<div class="invalid-feedback d-block" v-html="validation.errors['company.locales[1].title']"></div>-->
<!--							</div>-->
<!--							<div class="form-group col-12">-->
<!--								<label>French Title</label>-->
<!--								<input v-model="form.company.locales[0].title" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs['company.locales[0].title']" required>-->
<!--								<div class="invalid-feedback d-block" v-html="validation.errors['company.locales[0].title']"></div>-->
<!--							</div>-->
<!--							<div class="form-group col-12">-->
<!--								<label>Description</label>-->
<!--								<textarea v-model="form.company.locales[1].description" class="form-control shadow-sm" rows="4" :class="validation.inputs['company.locales[1].description']" required></textarea>-->
<!--								<div class="invalid-feedback d-block" v-html="validation.errors['company.locales[1].description']"></div>-->
<!--							</div>-->
<!--							<div class="form-group col-12">-->
<!--								<label>French Description</label>-->
<!--								<textarea v-model="form.company.locales[0].description" class="form-control shadow-sm" rows="4" :class="validation.inputs['company.locales[0].description']" required></textarea>-->
<!--								<div class="invalid-feedback d-block" v-html="validation.errors['company.locales[0].description']"></div>-->
<!--							</div>-->
						</div>

						<h5>{{$t('account')}}</h5>
						<div class="row">
							<div class="form-group col-md-6">
								<label>{{$t('first-name')}}</label>
								<input v-model="form.user.firstName" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs['user.firstName']" required>
								<div class="invalid-feedback d-block" v-html="validation.errors['user.firstName']"></div>
							</div>
							<div class="form-group col-md-6">
								<label>{{$t('last-name')}}</label>
								<input v-model="form.user.lastName" type="text" class="form-control form-control-sm shadow-sm" :class="validation.inputs['user.lastName']" required>
								<div class="invalid-feedback d-block" v-html="validation.errors['user.lastName']"></div>
							</div>
							<div class="form-group col-md-6">
								<label>{{$t('email')}}</label>
								<input v-model="form.user.email" type="email" class="form-control form-control-sm shadow-sm" :class="validation.inputs['user.email']" disabled>
								<div class="invalid-feedback d-block" v-html="validation.errors['user.email']"></div>
							</div>
							<div class="form-group col-md-6">
								<label>{{$t('phone-number')}}</label>
								<phone-number-component :ref-id="'user-phone-number'" :phone-number="form.user.phoneNumber" @update-phone="updateUserPhone" :hasError="validation.inputs['user.phoneNumber']"></phone-number-component>
								<div class="invalid-feedback d-block" v-html="validation.errors['user.phoneNumber']"></div>
							</div>
						</div>
					</card-component>
				</div>
				<div class="col-4">
					<card-component>
						<div v-for="item in memberSummary" :key="item.label" class="row mb-3 summary">
							<label class="col-12">{{item.label}} :</label>
							<div class="col-12">
								<copy-to-clipboard v-if="item.copyId" :id="item.copyId">
									{{item.value}}
								</copy-to-clipboard>
								<div v-else>
									{{item.value}}
								</div>
							</div>
						</div>
					</card-component>
				</div>

			</div>
		</form>
		<div v-else>
			<div v-if="isBusy" class="spinner">
				<spinner-component></spinner-component>
			</div>
			<error-component v-else :label="'No Member Found.'" :return-to="{ name: 'dashboard.users.members.index' }"></error-component>
		</div>
	</div>
</template>

<script>
	import { head } from 'lodash';
	import { mapGetters, mapActions } from 'vuex';
	import { activity, validation } from '@/mixins';

	export default {
		name: 'MembersEdit',
		mixins: [activity, validation],
		created() {
			this.memberId = this.$route.params.memberId;
			this.userId = this.$route.params.userId;

			this.fetchData(this.memberId);
		},
		data() {
			return {
				memberId: null,
				userId: null,
				form: {
					categories: [
						{
							id: null,
							default: true,
							marketplaceId: null,
						},
					],
					isInitialized: false,
					categoryId: null,
					company: {
						email: null,
						phoneNumber: null,
						streetAddress: null,
						locales: [],
					},
					user: {
						id: null,
						firstName: null,
						lastName: null,
						email: null,
						phoneNumber: null,
					},
				},
			};
		},
		computed: {
			...mapGetters({
				isBusy: 'isBusy',
				categories: 'categories/companyCategories',
				member: 'users/member',
				marketplace: 'marketplace/marketplace',
			}),
			locales() {
				const { locales } = this.marketplace;

				return locales;
			},
			memberSummary: function () {
				const { toDate, placeholder } = this.$options.filters;
				return [
					{
						label: this.$t('created-date'),
						value: placeholder(
							toDate(this.member.createdAt, 'Do MMMM YYYY [at] h:mma')
						),
					},
					// {
					// 	label: "Last Login Date",
					// 	value: placeholder(toDate(this.member.aclManagedObject.user.lastLoginDate, 'Do MMMM YYYY [at] h:mma'))
					// },
					{
						label: this.$t('company-id'),
						value: this.member.id,
						copyId: `edit-member-${this.member.id}`,
					},
					{
						label: this.$t('user-id'),
						value: this.member.users[0].id,
						copyId: `edit-member-${this.member.users[0].id}`,
					},
				];
			},
		},
		watch: {
			$route: function () {
				this.fetchData(this.memberId);
			},
			member(member) {
				const {
					email,
					phoneNumber,
					streetAddress,
					category,
					locale,
					users,
				} = member;

				const locales = this.locales.map((el) =>
					this.getObjectLocale(locale, el)
				);

				this.form = {
					categoryId: category[0].categoryId,
					categories: [
						{
							id: category[0].categoryId,
							default: category[0].default,
							marketplaceId:
							category[0].category.marketplaceId,
						},
					],
					company: {
						email,
						phoneNumber,
						streetAddress,
						locales,
					},
					isInitialized: true,
					user: head(users),
				};
			},
			'form.categoryId': function (value) {
				if (value) this.form.isInitialized = true;
			},
		},
		methods: {
			...mapActions({
				setAppState: 'setAppIsBusy',
				attemptGetMember: 'users/attemptGetMember',
				attemptMemberUpdate: 'users/attemptMemberUpdate',
			}),
			performHouseActivities() {},
			fetchData(memberId) {
				this.setAppState(true);
				this.attemptGetMember(memberId);
			},
			updateCompanyPhone(phoneNumber) {
				this.form.company.phoneNumber = phoneNumber;
			},
			updateUserPhone(phoneNumber) {
				this.form.user.phoneNumber = phoneNumber;
			},
			setCategory(category) {
				this.form.categories[0].id = category.value;
			},
			updateMember() {
				this.setAppState(true);

				this.attemptMemberUpdate({
					data: this.form,
					memberId: this.memberId,
				})
					.then(() => {
						this.hasActivity = false;
						this.shouldWatch = false;
						this.fetchData(this.memberId);
					})
					.catch((e) => this.validationErrors(e));
			},
		},
	};
</script>

<style lang="scss">
	.summary {
		> label {
			display: inline-block;
			margin-bottom: 0rem;
			color: #bebcbc;
			font-size: 15px;
		}
	}
</style>
