<template>
	<div class="container-fluid">
		<sapporo></sapporo>
		<toast-component></toast-component>
		
		<router-view v-if="$route.name !=='dashboard'"></router-view>

		<div v-else>
			<breadcrumb></breadcrumb>
		</div>
	</div>
</template>
<style>

</style>
<script>
    import { mapGetters } from 'vuex';
import sapporo from '../../../components/dashboard/iframes/sapporo.vue';

	export default {
  components: { sapporo },
		name: 'Dashboard',
		computed: {
			...mapGetters({
				menuToggled: 'menuToggled',
			}),
		},
	};
</script>
