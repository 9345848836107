export default {
	message(state) {
		return state.message
	},
	member(state) {
		return state.member
	},
	membersList(state){
		return state.list
	}
}
