import currencies from './json/currencies.json'
import currenciesLocale from './json/currenciesLocale.json'

export default {
	app: {
		isLoading: false,
		isBusy: false,
		logo: (typeof localStorage.logo !== 'undefined') ? JSON.parse(localStorage.getItem('logo')) : null,
		navbarLogo: (typeof localStorage.navbarLogo !== 'undefined') ? JSON.parse(localStorage.getItem('navbarLogo')) : null,
		menuToggled: (typeof localStorage.menuToggled !== 'undefined') ? JSON.parse(localStorage.getItem('menuToggled')) : false,
		locale: (typeof localStorage.locale !== 'undefined') ? JSON.parse(localStorage.getItem('locale')) : 'fr',
		appLanguage: (typeof localStorage.appLanguage !== 'undefined') ? localStorage.getItem('appLanguage') : 'fr',
	},
	currencies,
	currenciesLocale,
	message: null,
}
