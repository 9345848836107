<template>
    <div class="tx-center">
        <div :class="(classes) ? classes : 'spinner-border spinner-border-xl'" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>

<style lang="scss" scoped>
	.spinner-border {
		color: var(--color-medium);
    }

    .spinner-border-xl {
        width: 3rem;
        height: 3rem;
    }

      .spinner-border-s {
        width: 1.5rem;
        height: 1.5rem;
    }
</style>

<script>
	export default {
        name: "SpinnerComponent",
		props: ["classes"]
	};
</script>
