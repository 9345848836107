<template>
	<div class="row">
		<div class="col-12">
			<ol class="text-black-50 ">
				<li class="text-sm" v-for="(value, index) in list" v-bind:key="index">
					{{ value }}
					<a href="#remove" class="float-right" @click.prevent="removeIndex(index)">{{$t('remove')}}</a>
				</li>
			</ol>
		</div>
		<div class="form-group col-12">
			<select-component :options="partners" :value="selected" :field="'permissions.groupAccess'" @input="setGroupAccess"/>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	export default {
		data() {
			return {
				selected: null,
				list: this.groupAccess,
			};
		},
		watch: {
			list: {
				handler: function (value) {
					this.$emit('setGroupAccess', value);
				},
				deep: true,
			},
			groupAccess: {
				handler: function (value) {
					this.list = value;
				},
				deep: true,
			},
		},
		computed: {
			...mapGetters({
				companyCategories: 'categories/companyCategories',
				marketplace : 'marketplace/marketplace'
			})
		},
		methods: {
			setGroupAccess({ value }) {
				if (value) {
					const partner = this.partners.find((f) => f.id = value)
					//const partnerId = this.companyCategories.find((c) => c.id === value)
					this.list.push(partner.categoryId);

					this.$nextTick(() => {
						this.selected = null;
					});
				}
			},
			removeIndex(index) {
				this.list.splice(index, 1);
			},
		},
		props: {
			groupAccess: {
				type: Array,
			},
			partners: {
				type: Array,
			},
		},
	};
</script>

<style>
</style>
