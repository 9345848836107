export const resizeImageFile = async (img, maxSize = 1500) => {

    var file = img;
    var reader = new FileReader();
    var image = new Image();
    var canvas = document.createElement('canvas');


    var getImageSrc = async image => {
        return new Promise(function(resolve){
            const reader = new FileReader()
            reader.onload = function (e){
                resolve( e.target.result) 
            }
            reader.readAsDataURL(image)
        })
    }
  
    var dataURItoBlob = async function (dataURI) {
        var bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
            atob(dataURI.split(',')[1]) :
            unescape(dataURI.split(',')[1]);
        var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var max = bytes.length;
        var ia = new Uint8Array(max);
        for (var i = 0; i < max; i++)
            ia[i] = bytes.charCodeAt(i);
        const blob = new Blob([ia], { type: mime })
        const file = new File([blob], "image.jpeg", {
            type: "image/jpeg"
        })
        file.src = await getImageSrc(file)
        return file
    };
  
    var resize = function () {
        var width = image.width;
        var height = image.height;
        if (width > height) {
            if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
            }
        } else {
            if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
            }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
        var dataUrl = canvas.toDataURL('image/jpeg');
        return dataURItoBlob(dataUrl);
    };
    
    return new Promise(function (ok, no) {
        if (!file.type.match(/image.*/)) {
            no(new Error("Not an image"));
            return;
        }
        reader.onload = function (readerEvent) {
            image.onload = function () { return ok(resize()); };
            image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
    });
}