<template>
    <div class="report">
        <div class="head">
            <h1 class="h2">{{$t('dashboard')}}</h1>
        </div>
        <div id="report-panel" v-if="userHasReport()">
            <div id="headers">
                <ol id="tabs">
                    <button v-for="(src, index) in sources" v-bind:key="index" @click="setReport(index)">{{ sources[index]['title'] }}</button>  
                </ol>
            </div>
            <div :class="[(hideSm) ? 'd-none d-md-block' : '']">
                <slot></slot>
            </div>
            <div class="frame">
                <iframe
                    id="report" 
                    width="100%" height="100%" 
                    :src="sources[currentSrc]['src']" 
                    frameborder="1" style="border:0" allowfullscreen>
                </iframe>
            </div>
        </div>
        <div id="no-report-panel" v-else>
            <h4>{{$t('sapporo-error')}}</h4>
        </div>
    </div>
</template>

<style>
    #tabs{
        display: flex;
        flex-direction: row;
        justify-content: left; 
    }
    ol button{
        scale: 1;
        border-radius: 4px;
        margin-left: 10px;
    }

    button:hover:enabled {
        background-image: linear-gradient(rgba(0, 0, 0, 0.2) 0 0);
    }

    .report{
        width: 100%;
        height: 100%;
        border: 5px;
    }

    .frame{
        border: lightgrey solid;
        border-width: 1px;
        height: 750px;
        overflow: hidden;
        border-radius: 4px;
        filter: drop-shadow(0px 0px 2px lightgrey);
    }
</style>

<script>
import { startCase } from 'lodash';
import { mapGetters } from 'vuex';

const data = require('@/store/json/reportData.json');
export default {
    name: 'ReportComponent',
    computed:{
        ...mapGetters({
            user: 'auth/user',
        }),
        username() {
            if (this.user) {
                const { username } = this.user;

                return startCase(username);
            }

            return null;
        },
        sources() {
            for (let i = 0; i < data.length; i++) {
                if(data[i]["username"] == this.username){
                    return data[i]["reports"];
                }
            }
            return [];
        }
    },
    data() {
        return {
            currentSrc: 0
        }
    },
    methods: {
        determineColor: function(){
            var color = "#ff7b00"
            switch(this.username){
                case "Admin Bizbizstock":
                    color = "#a10000"; break;
                case "Admin Construction":
                    color = "#0ba380"; break;
                case "Bleu Admin": 
                    color = "#42c96d"; break;
            }
            return color;
        },
        setReport(index){ 
            this.currentSrc = index;
            var listOfButtons = document.querySelector("#tabs").childNodes;
            for(let i = 0; i < listOfButtons.length; i++){
                listOfButtons[i].disabled = false;
                listOfButtons[i].style.color = "#000000";
            }
            listOfButtons[index].style.color = this.determineColor(); 
            listOfButtons[index].disabled = true;
        },
        pageLoadPrep: function(){
            if(this.userHasReport()){
                var listOfButtons = document.querySelector("#tabs").childNodes;
                listOfButtons[0].disabled = true;
                listOfButtons[0].style.color = this.determineColor();
            }
        },
        userHasReport: function(){
            var userHasReport = false;
            
            for (let i = 0; i < data.length; i++){ 
                if(data[i]["username"] == this.username){
                    if(data[i]["reports"].length > 0){
                        userHasReport = true;
                        break;
                    }
                }
            }
            return userHasReport;
        }
    },
    mounted(){
        this.pageLoadPrep();
    }
};
</script>