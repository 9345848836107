import Vue from 'vue'
import {
	sync
} from 'vuex-router-sync'
import router from '@/router'
import store from '@/store'
import App from '@/App.vue'
import i18n from './i18n'

Vue.config.productionTip = false

const unsync = sync(store, router)

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('@/bootstrap')

const files = require.context("./", true, /\.vue$/i)
files.keys().map(key =>
	Vue.component(
		key
		.split("/")
		.pop()
		.split(".")[0],
		files(key).default
	)
)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

store.dispatch('auth/attemptGetUser', localStorage.getItem('token')).then(() => {
	new Vue({
        store,
        router,
        i18n,
        render: h => h(App)
    }).$mount('#app')
})

unsync()
