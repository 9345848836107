<template>
    <div id="members-table-filter" v-show="showFilters">
        <card-component v-if="isReady">
            <div class="row  py-0 px-3 mb-3">
                <div class="w-100">
                    <label class="py-0">Category:</label>
                </div>
                <div
                    v-for="item in Object.values(filtersData.categories)"
                    :key="item.id"
                    class="col-auto button-filter mb-3 mr-2 py-2 px-3"
                    :class="{active: filters.categories.includes(item.id)}"
                    @click="selectCategoryFilter(item.id)"
                >
                    <span class="mr-2">{{ item.text }}</span>
                    <span>{{ item.counter }}</span>
                </div>
            </div>    
            <div class="row py-0 px-3">
                <div class="col-3 pl-0">
                    <label class="px-0">Active</label>
                    <div class="py-0 d-flex">
                        <div
                            class="button-filter mr-2 py-2 px-3"
                            @click="selectStatusFiler('active')"
                            :class="{active: filters.status === 'active'}"
                        >
                            <span>YES</span>
                            <span>{{ filtersData.status.active }}</span>
                        </div>
                        <div
                            class="button-filter py-2 px-3"
                            @click="selectStatusFiler('inactive')"
                            :class="{active: filters.status === 'inactive'}"
                        >
                            <span>NO</span>
                            <span>{{ filtersData.status.inactive }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-4"> 
                    <label class="w-100">
                        City
                    </label>
                    <input v-model="filters.city" type="text" class="form-control  shadow-sm">
                </div>
                <div class="col-2"></div>
                <div class="col-3 d-flex justify-content-between align-items-end">
                    <div>
                        <span class="mr-2">Active filters</span>
                        <span>{{ activeFiltersCounter }}</span>
                    </div>
                    <div class="clear" @click="clearFilters">CLEAR ALL</div>
                </div>
            </div>
        </card-component>
    </div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex';
    import es from '@/config/elasticSearch'
    export default {
        name: "MembersTableFilters",
        props: {
            showFilters: {
                type: Boolean,
            }
        },
        data(){
            return {
                filtersData: {
                    categories: null,
                    status: null,
                },
                filters: {
                    city: "",
                    categories: [],
                    status: null
                }
            }
        },
        created(){
            if(this.companyCategories.length === 0){
                this.attemptGetCompanyCategories()
            } 
        },
        mounted(){
            if(this.areDataLoaded){
                this.initializeFilters()
            }
        },
        computed: {
			...mapGetters({
				companyCategories: 'categories/companyCategories',
				user: 'auth/user',
			}),
            isReady: function(){
                return this.filtersData.categories !== null && this.filtersData.status !== null
            },
            areDataLoaded: function(){
                return this.companyCategories && this.companyCategories.length > 0
            },
            marketplaceId() {
				const { id } = this.user.companyInfo.marketplace;
				return id;
			},
            activeFiltersCounter(){
                const categoriesFilterCounter = this.filters.categories.length
                const statusCounter = this.filters.status ? 1 : 0
                return categoriesFilterCounter + statusCounter
            }
        },
        methods: {
            ...mapActions({
                attemptGetCompanyCategories: "categories/attemptGetCompanyCategories"
            }),
            getCategoriesCounters: async function(categories){
                const filters = {}
                categories.forEach(category => {
                    filters[category.id] = {
                        match: {
                            [`marketplaces.${this.marketplaceId}.id.keyword`]: category.id
                        }
                    }
                })
                const res = await es.client.search({
                    index: "company",
                    type: "_doc",
                    body: {
                        size: 0,
                        aggs: {
                            categories: {
                                nested: {
                                    path: "marketplaces"
                                },
                                aggs: {
                                    counter: {
                                        filters: { filters }
                                    }
                                }
                            },
                            companies: {
                                filter: {
                                    nested: {
                                        path: "marketplaces",
                                        query: {
                                            bool: {
                                                must: {
                                                    exists: {
                                                        field: `marketplaces.${this.marketplaceId}`
                                                    }
                                                }
                                            }
                                        }
                                    }
                                },
                                aggs: {
                                    status: {
                                        filters: {
                                            filters: {
                                                inactive: {
                                                    match: { hasActiveSubscription: false }
                                                },
                                                active: {
                                                    match: { hasActiveSubscription: true }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                })
                return {
                    categories: res.aggregations.categories.counter.buckets,
                    status: {
                        active: res.aggregations.companies.status.buckets.active.doc_count,
                        inactive: res.aggregations.companies.status.buckets.inactive.doc_count,
                    }
                }
            },
            initializeFilters: async function(){
                   const _filtersData = {
                        categories: {},
                        status: {
                            active: 0,
                            inactive: 0
                        }
                    }
                    this.companyCategories.forEach(category => {
                        _filtersData.categories[category.id] = {
                            ...category,
                            counter: 0
                        }
                    })
                    const counters =  await this.getCategoriesCounters(this.companyCategories)

                    Object.keys(counters.categories).forEach(categoryId => {
                        _filtersData.categories[categoryId].counter = counters.categories[categoryId].doc_count
                    })
                    _filtersData.status = counters.status
                    this.filtersData = _filtersData
            },
            clearFilters: function(){
                this.filters = {
                    city: "",
                    categories: []
                }
                this.$emit('updateDisplayedData', this.membersList)   
            },
            selectCategoryFilter: function(categoryId){
                const updatedFilters = this.filters.categories.includes(categoryId) ? this.filters.categories.filter(id => id !== categoryId) : [...this.filters.categories, categoryId]
                this.filters = {
                    ...this.filters,
                    categories: updatedFilters
                }
            },
            selectStatusFiler: function(status){
                const memberStatus = status === this.filters.status ? null : status
                this.filters = {
                    ...this.filters,
                    status: memberStatus
                }
            }
        },
        watch: {
            areDataLoaded: async function(value){
                if(value && !this.isReady){
                    this.initializeFilters()
                }
            },
            filters: function(filters){
                let updatedData = this.membersList
                if(filters.categories.length > 0){
                    updatedData = this.membersList.filter(member => filters.categories.includes(member.categoryCategoryId))
                } 
                if(filters.status){
                    updatedData = updatedData.filter(member => filters.status === "active" ? member.disabled === 0 : member.disabled === 1 )
                }
                this.$emit('updateDisplayedData', updatedData)
            }
        }
    }
</script>

<style lang="scss" scoped>

    #members-table-filter {
        .card {
            margin-bottom: 10px !important;
        }
        .button-filter {
            border: 1px solid var(--color-semi-bright);
            cursor: pointer;
            background: rgb(248, 248, 248);
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-width: 130px;  
            &.active {
                background: var(--color-medium);
                color: white;
                border-color: var(--color-medium);
            }
        }
        input {
            padding: 0.5rem 1rem;
            height: unset;
        }
        label {
            font-size: 14px;
        }

        .clear {
            color: var(--color-semi-bright);
            cursor: pointer;
            &:hover {
                color: black;
            }
        }
    }

  
</style>