<template>
	<div>
		<breadcrumb :label="$t('manage-admin')">
            <router-link class="btn btn-outline-primary btn-sm shadow-sm" :to="{ name: 'dashboard.sub-administrators.create'}">
                <em class="bi bi-plus-circle"></em> {{$t('admin')}}
            </router-link>
        </breadcrumb>

		<sub-adminitrators-table-component></sub-adminitrators-table-component>
	</div>
</template>

<script>
	export default {};
</script>

<style>
</style>
