export default {
	type(state) {
		return state.type
	},
	options(state) {
		return state.options
	},
	specificityTypes(state) {
		return state.specificityTypes
	},
	measureTypes(state) {
		return state.measureTypes
	}
}
