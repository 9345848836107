import auth from './auth'
import dashboard from './dashboard'
import errors from './errors'

const routes = [{
        path: '',
        redirect: {
            name: 'login'
        }
    },
    ...auth,
    ...dashboard,
    ...errors
]

export default routes