import {
	find,
	isEmpty
} from 'lodash'
import Vue from 'vue'
import store from '@/store'
import activity from './activity'
import auth from './auth'
import category from './categories'
import datatables from './datatables'
import validation from './validation'
import file from './file'
import transactions from './transactions'
import helpers from './helpers'
import table from './table'

Vue.mixin({
	data() {
		return {}
	},
	methods: {
		setInput(prop) {
			const { field , value } = prop
			console.log(prop)
			this.form[field] = value
		},
		getObjectLocale(array, locale = null) {
			const language = (locale) ? locale : store.getters.locale

			const obj = find(array, ['locale', language])

			return (isEmpty(obj)) ? find(array, ['locale', 'default']) : obj
		}
	}
})

export {
	activity,
	auth,
	category,
	datatables,
	validation,
	file,
	helpers,
	transactions,
	table
}
