<template>
    <router-view></router-view>
</template>

<script>
    export default {

    }
</script>

<style>
    .signin-wrapper {
        text-align: center;
        padding: 15px;
    }

    .signin-wrapper form {
        width: 100%;
        max-width: 385px;
        margin: 15rem auto 0;
    }
    
    .signin-wrapper form .checkbox {
        font-weight: 400;
    }

    .signin-wrapper form .form-control {
        position: relative;
        box-sizing: border-box;
        height: auto;
    }

    .signin-wrapper form .form-control:focus {
        z-index: 2;
    }

    .signin-wrapper form input[type="text"],
    .signin-wrapper form input[type="email"] {
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .signin-wrapper form input[type="password"] {
        margin-bottom: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
</style>