import {
	io
} from 'socket.io-client';

const socket = (token, locale = 'en') => io(process.env.VUE_APP_SOCKET_CLIENT, {
	transports: ["polling", "websocket"],
	query: {
		locale,
		administrator: true
	},
	transportOptions: {
		polling: {
			extraHeaders: {
				"Authorization": `Bearer ${token}`
			}
		}
	}
})

export default socket
