export default {
	SET_APP_IS_LOADING(state, value) {
		state.app.isLoading = value
	},
	SET_APP_LOCALE(state, value) {
		state.app.locale = value
	},
	SET_APP_IS_BUSY(state, value) {
		state.app.isBusy = value
	},
	SET_APP_LANGUAGE(state, value){
		localStorage.setItem('appLanguage',value)
		state.app.appLanguage = value
	},
	SET_APP_LOGO(state, image) {
		if (image) {
			const {
				path
			} = image

			const favicon = document.querySelector('[rel=icon]')

			favicon.href = `${process.env.VUE_APP_GOOGLE_CLOUD_URL}/${process.env.VUE_APP_GOOGLE_CLOUD_BUCKET}/photos/favicons/${path}`
			const logo = `${process.env.VUE_APP_GOOGLE_CLOUD_URL}/${process.env.VUE_APP_GOOGLE_CLOUD_BUCKET}/photos/logos/dark/${path}`

			const navbarLogo = `<img src="${logo}" height="40" class="d-inline-block align-top" alt="Logo" loading="lazy">`

			localStorage.setItem('logo', JSON.stringify(logo))
			localStorage.setItem('navbarLogo', JSON.stringify(navbarLogo))

			state.app.logo = logo
			state.app.navbarLogo = navbarLogo

			return
		}

		return state.app.logo = 'Bizbizshare'
	},
	SET_APP_MENU_TOGGLED(state) {
		const {
			menuToggled
		} = state.app

		return state.app.menuToggled = !menuToggled
	},
	SET_MESSAGE(state, message) {
		state.message = message
	}
}
