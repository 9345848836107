import moment from 'moment'
import {
	request
} from '@/config'
import store from '@/store'

export default {
	setYears({
		commit
	}) {
		const {
			invoiceStartDate
		} = store.state.marketplace.marketplace
		const currentYear = moment()
		const startYear = moment(invoiceStartDate)
		const difference = currentYear.diff(startYear, 'years') + 1

		const years = Array(currentYear.year() - (currentYear.year() - difference)).fill('').map((value, index) => currentYear.year() - index)

		commit('SET_YEARS', years)
	},
	setMonths({
		commit
	}, year) {
		const monthLength = (year !== moment().year()) ? moment(year).month() + 1 : moment().month()

		const months = Array(monthLength).fill('').map((value, index) => moment().month(index).format('MMMM'))

		commit('SET_MONTHS', months)
	},
	async attemptInvoiceRequest(_, data) {
		const response = await request.post('/emails/invoice-request', data)
			.then(({
				data: {
					message
				}
			}) => message)
			.catch(({
				response: error
			}) => {
				throw error
			}).finally(() => store.dispatch('setAppIsBusy', false))

		store.commit('SET_MESSAGE', response)

		return response
	},
}
