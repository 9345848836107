import axios from 'axios'

axios.defaults.maxContentLength = 50 * 1024 * 1024;

const request = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	timeout: 100000,
	headers: {
		'Content-Type': 'application/json',
		'Accept': 'application/json'
	}
})

export default request
