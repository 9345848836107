<template>
	<div v-if="isLoading" class="spinner">
		<spinner-component></spinner-component>
	</div>
	<router-view v-else></router-view>
</template>

<style lang="scss">
	@import '@/assets/scss/app';

	#app {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
	}

	.spinner {
		background-color: $off-white;
		z-index: 100;
		height: 80vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>

<script>
	import { mapGetters } from 'vuex';

	export default {
		name: 'App',
		data: function () {
			if(this.user){
				this.$i18n.locale = this.user.locale
			}
			return {
				isLoading: false,
			};
		},
		computed: {
			...mapGetters({
				user: 'auth/user',
				marketplace: 'marketplace/marketplace',
				appLanguage: 'appLanguage',
			}),
		},
		watch: {
			'$store.state.isLoading': function (state) {
				this.isLoading = state;
			},
			'$store.state.marketplace.marketplace': function () {
				this.$i18n.locale = this.appLanguage 
				const bodyStyles = document.body.style;
				const { bright, dark, medium, semiBright, semiDark , gradientPrimary } =
					this.marketplace.colorPalette;

				bodyStyles.setProperty('--color-bright', bright);
				bodyStyles.setProperty('--color-dark', dark);
				bodyStyles.setProperty('--color-medium', medium);
				bodyStyles.setProperty('--color-semi-bright', semiBright);
				bodyStyles.setProperty('--color-semi-dark', semiDark);
				bodyStyles.setProperty('--color-gradient-primary', gradientPrimary);

				bodyStyles.setProperty('--primary', medium);
			},
		},
	};
</script>
