import store from '@/store'

export default [{
	path: '/',
	component: () => import('@/views/auth/Auth.vue'),
	beforeEnter: (to, from, next) => {
		const isAuthenticated = store.getters['auth/isAuthenticated']
		const authRoutes = ['login', 'password.email', 'password.reset'].includes(to.name)

		if (isAuthenticated && authRoutes) {
			return next({
				name: 'report'
			})
		}

		return next()
	},
	children: [{
		name: 'login',
		path: '/login',
		component: () => import('@/views/auth/LoginView.vue')
	}, {
		path: '/password',
		component: () => import('@/views/auth/passwords/Passwords.vue'),
		children: [{
				name: 'password.email',
				path: 'email',
				component: () => import('@/views/auth/passwords/EmailView.vue'),
				redirect: {
					name: 'login'
				}
			},
			{
				name: 'password.reset',
				path: 'reset/:id?/:confirmEmailToken?',
				component: () => import('@/views/auth/passwords/ResetView.vue'),
				beforeEnter: async (to, from, next) => {
					const {
						id,
						confirmEmailToken
					} = to.params
					const {
						signature
					} = to.query

					if (id && confirmEmailToken && signature) {
						const isVerified = await store.dispatch('auth/attemptVerifyPasswordReset', {
							id,
							confirmEmailToken,
							signature
						})

						if (isVerified) {
							return next()
						}
					}

					return next({
						name: 'login'
					})
				}
			},
		],
	}]
}]
